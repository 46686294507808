import { useAppContext } from "@/contexts/AppContext"
import { Button } from "@/components/ui/button"
import UpdateInventoryPad from "@/components/order/UpdateInventoryPad"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { useMemo, useState, useEffect } from "react"
import { StateType } from "@/types"
import { useSelector } from "react-redux"

export const CheckinSideBar = ({
  gotToNextStep,
  updateInventory,
  className,
  currentStep,
  totalSteps,
  closeCheckinModal,
  mercurialeInfos,
  hasGotToNextStepTimeBeforeClick = 0,
  hasSkipButtonTimeBeforeClick = 0,
}: {
  gotToNextStep: () => void
  updateInventory?: (value: string) => Promise<void>
  className?: string
  currentStep?: number
  totalSteps?: number
  closeCheckinModal: () => void
  mercurialeInfos: AllMercurialInfo[]
  hasGotToNextStepTimeBeforeClick?: number
  hasSkipButtonTimeBeforeClick?: number
}) => {
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const { headerHeight } = useAppContext()
  const isLastStep = currentStep === totalSteps
  const [mainButtonTimeRemaining, setMainButtonTimeRemaining] = useState(
    hasGotToNextStepTimeBeforeClick,
  )
  const [isMainButtonLoading, setIsMainButtonLoading] = useState(
    hasGotToNextStepTimeBeforeClick > 0,
  )

  const [continueButtonTimeRemaining, setContinueButtonTimeRemaining] =
    useState(hasSkipButtonTimeBeforeClick)
  const [isContinueButtonLoading, setIsContinueButtonLoading] = useState(
    hasSkipButtonTimeBeforeClick > 0,
  )

  const groupedMercurialeInfos = useMemo(() => {
    return mercurialeInfos.reduce((acc, item) => {
      acc.push([item])
      return acc
    }, [] as AllMercurialInfo[][])
  }, [mercurialeInfos])

  useEffect(() => {
    if (hasGotToNextStepTimeBeforeClick <= 0) return

    const timer = setInterval(() => {
      setMainButtonTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          setIsMainButtonLoading(false)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [hasGotToNextStepTimeBeforeClick])

  useEffect(() => {
    if (hasSkipButtonTimeBeforeClick <= 0) return

    const timer = setInterval(() => {
      setContinueButtonTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          setIsContinueButtonLoading(false)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [hasSkipButtonTimeBeforeClick])

  const mainButtonProgressPercentage =
    hasGotToNextStepTimeBeforeClick > 0
      ? ((hasGotToNextStepTimeBeforeClick - mainButtonTimeRemaining) /
          hasGotToNextStepTimeBeforeClick) *
        100
      : 0

  const continueButtonProgressPercentage =
    hasSkipButtonTimeBeforeClick > 0
      ? ((hasSkipButtonTimeBeforeClick - continueButtonTimeRemaining) /
          hasSkipButtonTimeBeforeClick) *
        100
      : 0

  return (
    <div
      className={`hidden md:flex flex-col flex-end justify-end sticky md:w-2/5 lg:w-1/4 h-full gap-2 ${className}`}
      style={{ top: headerHeight + 15 }}
    >
      {updateInventory && (
        <UpdateInventoryPad
          isLoading={false}
          updateInventory={updateInventory}
          mercurialeInfos={mercurialeInfos}
          groupedMercurialeInfos={groupedMercurialeInfos}
        />
      )}

      <div className="relative w-full overflow-hidden rounded-md">
        {isMainButtonLoading && (
          <div className="absolute inset-0 flex rounded-md">
            <div
              className="h-full bg-green-600 rounded-l-md"
              style={{
                width: `${mainButtonProgressPercentage}%`,
                transition: "width 1s linear",
              }}
            />
            <div
              className="h-full bg-green-500 rounded-r-md"
              style={{
                width: `${100 - mainButtonProgressPercentage}%`,
                transition: "width 1s linear",
              }}
            />
          </div>
        )}

        <Button
          className={`w-full py-7 text-base text-white relative z-10 rounded-md ${isMainButtonLoading ? "bg-transparent" : "bg-green-600 hover:bg-green-700"}`}
          onClick={gotToNextStep}
          disabled={isMainButtonLoading}
        >
          <span className="flex items-center">
            {isLastStep ? "Passer commande" : "Continuer"}
            {isMainButtonLoading && mainButtonTimeRemaining > 0 && (
              <span className="ml-2">({mainButtonTimeRemaining}s)</span>
            )}
          </span>
        </Button>
      </div>

      {!isLastStep && (
        <div className="relative w-full overflow-hidden rounded-md">
          {isContinueButtonLoading && (
            <div className="absolute inset-0 rounded-md flex">
              <div
                className="h-full rounded-l-md bg-white"
                style={{
                  width: `${continueButtonProgressPercentage}%`,
                  transition: "width 1s linear",
                }}
              />
              <div
                className="h-full rounded-r-md bg-slate-100"
                style={{
                  width: `${100 - continueButtonProgressPercentage}%`,
                  transition: "width 1s linear",
                }}
              />
            </div>
          )}

          <Button
            variant="outline"
            className={`w-full py-7 relative z-10 rounded-md ${isContinueButtonLoading ? "bg-transparent border-0" : ""}`}
            onClick={closeCheckinModal}
            disabled={isContinueButtonLoading}
          >
            <div className="flex flex-row items-center justify-between gap-2">
              <p className="text-lg">Passer commande</p>
              {isContinueButtonLoading && continueButtonTimeRemaining > 0 && (
                <span className="ml-2">({continueButtonTimeRemaining}s)</span>
              )}
            </div>
          </Button>
        </div>
      )}

      {/* Bouton de test */}
      {isTestMode && (
        <Button
          variant="outline"
          className="w-full py-7 bg-gray-100 hover:bg-gray-100 border-0 text-black font-bold rounded-md"
          onClick={closeCheckinModal}
        >
          <div className="flex flex-row items-center justify-between gap-2">
            <p className="text-lg text-black">Ignorer</p>
          </div>
        </Button>
      )}
    </div>
  )
}
