"use client"

import { useState } from "react"
import { useOutletContext } from "react-router-dom"
import { Card, CardContent } from "@/components/ui/card"
import { PerformancesPageContext } from "../PerformancesPage"
import { useModificationSummary } from "../components/scoring/useModificationSummary"

import SummaryCards from "../components/scoring/SummaryCards"
import WeeklyBreakdown from "../components/scoring/weeklyBreakdown"
import RecurringIssues from "../components/scoring/RecurringIssues"
import InfoPanel from "../components/scoring/infoPanel"
import LoadingState from "../components/scoring/loadingState"
import ErrorState from "../components/scoring/errorState"
import EmptyState from "../components/scoring/emptyState"

export default function ModificationSummaryDashboard() {
  const [selectedStore] = useState<string>("all")
  const [localLoading] = useState<boolean>(false)

  const { selectedStoreId, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  const {
    storeData,
    storeComparisonData,
    summaryMetrics,
    isLoading,
    hasError,
  } = useModificationSummary({
    selectedStoreId: selectedStoreId ?? undefined,
    fromDate,
    toDate,
    selectedStore,
  })

  return (
    <div className="flex flex-col gap-4 h-full overflow-hidden">
      <div className="flex-1 p-4 overflow-auto">
        <Card className="border-0">
          <CardContent className="p-6">
            {isLoading || localLoading ? (
              <LoadingState />
            ) : hasError ? (
              <ErrorState />
            ) : storeData.length === 0 ? (
              <EmptyState />
            ) : (
              <>
                <SummaryCards summaryMetrics={summaryMetrics} />

                {/* New RecurringIssues component integrated between SummaryCards and WeeklyBreakdown */}
                <RecurringIssues
                  storeComparisonData={storeComparisonData}
                  selectedStore={selectedStore}
                />

                <WeeklyBreakdown
                  selectedStore={selectedStore}
                  storeComparisonData={storeComparisonData}
                />
                <InfoPanel />
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
