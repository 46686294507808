import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Card, CardContent } from "@/components/ui/card"
import { StoreSalesProductToConsider } from "@/utils/__generated__/graphql"
import type { StateType } from "@/types"
import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import {
  Loader2,
  AlertCircle,
  LineChart,
  ChevronUp,
  ChevronDown,
  ChevronRight,
} from "lucide-react"
import { useWindowSize } from "@/hooks/useWindowSize"
import { Button } from "@/components/ui/button"

export const ProductToConciderTable = ({
  products,
  loading = false,
  withColorfulHeader = false,
}: {
  products: StoreSalesProductToConsider[]
  loading?: boolean
  withColorfulHeader?: boolean
}) => {
  const { storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const currencyCode = storeCurrency ?? "EUR"

  const [sortedProducts, setSortedProducts] = useState(products)
  const [sortDirection, setSortDirection] = useState("desc")
  const [showAll, setShowAll] = useState(false)

  const handleSort = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc"
    setSortDirection(newDirection)

    const sorted = [...sortedProducts].sort((a, b) => {
      if (newDirection === "asc") {
        return a.potential_revenue_loss - b.potential_revenue_loss
      } else {
        return b.potential_revenue_loss - a.potential_revenue_loss
      }
    })

    setSortedProducts(sorted)
  }

  useEffect(() => {
    const initialSorted = [...products].sort(
      (a, b) => b.potential_revenue_loss - a.potential_revenue_loss,
    )
    setSortedProducts(initialSorted)
  }, [products])

  const displayedProducts = showAll
    ? sortedProducts
    : sortedProducts.slice(0, 10)
  const hasMoreProducts = sortedProducts.length > 10

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={4} className="h-24 md:h-32">
            <div className="w-full h-full flex items-center justify-center">
              <Loader2 className="size-6 md:size-8 animate-spin text-blue-400" />
            </div>
          </TableCell>
        </TableRow>
      )
    }

    if (!displayedProducts || displayedProducts.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} className="h-24 md:h-32">
            <div className="w-full h-full flex flex-col items-center justify-center gap-1 md:gap-2 text-gray-500">
              <AlertCircle className="size-6 md:size-8" />
              <p className="text-sm md:text-base">Aucune donnée disponible</p>
            </div>
          </TableCell>
        </TableRow>
      )
    }

    return displayedProducts.map((product, i) => (
      <TableRow
        key={`${product.sale_id}-${i}`}
        className="hover:bg-slate-50 transition-colors"
      >
        <TableCell className="hidden md:table-cell text-gray-600 font-medium p-2 md:p-4">
          {i + 1}
        </TableCell>
        <TableCell className="font-medium text-gray-800 p-2 md:p-4 max-w-[120px] md:max-w-none truncate">
          {product.sale_name}
        </TableCell>
        <TableCell className="font-medium text-gray-800 p-2 md:p-4 max-w-[120px] md:max-w-none truncate">
          {product.sale_name_ida}
        </TableCell>
        <TableCell className="p-2 md:p-4">
          <span className="text-blue-600 font-medium">
            {product.potential_revenue_loss > 1 && (
              <span className="hidden md:inline text-gray-700 text-sm">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: currencyCode,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(product.potential_revenue_loss)}
              </span>
            )}
          </span>
        </TableCell>
      </TableRow>
    ))
  }

  const { isSM } = useWindowSize()

  const getHeaderStyle = () => {
    if (withColorfulHeader) {
      return {
        background: `radial-gradient(
            circle at 50% 50%, 
            rgba(239, 246, 255, 0.9) 0%, 
            rgba(219, 234, 254, 0.6) 35%, 
            rgba(191, 219, 254, 0.3) 70%, 
            rgba(147, 197, 253, 0.1) 100%
          )`,
      }
    }
    return {}
  }

  return (
    <Card
      className={`${withColorfulHeader ? "border-0 shadow-md" : "shadow-sm"}`}
    >
      <div
        className={`rounded-t-xl ${
          withColorfulHeader
            ? "text-gray-800 bg-white"
            : "bg-white text-gray-800"
        } p-4 md:p-6`}
        style={getHeaderStyle()}
      >
        <div className="flex flex-col space-y-1 md:space-y-2">
          <div className="flex flex-row items-center gap-1 md:gap-2">
            <LineChart className="size-5 md:size-6 text-blue-500" />
            <h3 className="text-lg md:text-xl font-semibold text-gray-800">
              Produits à considérer
            </h3>
          </div>
          <p className="hidden md:block text-sm text-gray-600">
            Produits absents de votre assortiment mais qui génèrent des ventes
            dans les magasins comparables.
          </p>
        </div>
      </div>
      <CardContent className="p-0">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-50 border-b">
                <TableHead className="hidden md:table-cell w-8 md:w-12 font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  #
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "Prod." : "Produit"}
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "Code Vente" : "Code Vente"}
                </TableHead>
                <TableHead
                  className="font-semibold text-gray-700 cursor-pointer hover:bg-gray-100 transition-colors p-2 md:p-4 text-xs md:text-sm"
                  onClick={handleSort}
                >
                  <div className="flex items-center">
                    {isSM ? "Potentiel" : "Potentiel de CA"}
                    {sortDirection === "desc" ? (
                      <ChevronDown className="ml-1 size-3 md:size-4" />
                    ) : (
                      <ChevronUp className="ml-1 size-3 md:size-4" />
                    )}
                  </div>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>{renderTableContent()}</TableBody>
          </Table>

          {hasMoreProducts && (
            <div className="flex justify-center p-4 border-t">
              <Button
                variant="ghost"
                onClick={() => setShowAll(!showAll)}
                className="text-blue-600 hover:text-blue-800 hover:bg-blue-50"
              >
                {showAll
                  ? "Afficher moins"
                  : `Voir plus (${sortedProducts.length - 10})`}
                <ChevronRight
                  className={`ml-1 h-4 w-4 transition-transform ${showAll ? "rotate-90" : ""}`}
                />
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
