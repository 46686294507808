import { CreateUserForm } from "@/pages/admin/UsersPage/components/CreateUserForm"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

interface CreateUserModalProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  handleCreateUser: () => void
}

export function CreateUserModal({
  isOpen,
  setIsOpen,
  handleCreateUser,
}: CreateUserModalProps) {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Créer un utilisateur</DialogTitle>
          <DialogDescription>
            Remplissez les informations ci-dessous pour créer un nouvel
            utilisateur.
          </DialogDescription>
        </DialogHeader>

        <CreateUserForm handleFormSubmit={handleCreateUser} />
      </DialogContent>
    </Dialog>
  )
}
