"use client"

import { useState, useEffect } from "react"
import { matchPath, Link, useLocation } from "react-router-dom"
import { twJoin } from "tailwind-merge"
import {
  ChevronDown,
  ChevronRight,
  PanelRightClose,
  PanelRightOpen,
} from "lucide-react"

import { Button } from "@/components/ui/button"
import { useIsMobile } from "@/hooks/use-mobile"
import { usePermissions } from "@/hooks/usePermissions"
import { useAppContext } from "@/contexts/AppContext"

type AdminSidebarNavItem = {
  id: string
  title: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  permission?: "isCompanyAdmin" | "isIdaAdmin" | "canReadPerformances"
} & (
  | {
      children: AdminSidebarNavItem[]
      filters?: undefined
    }
  | {
      children?: undefined
      filters: {
        store: boolean
        dates: boolean
      }
    }
)

interface AdminSidebarProps {
  sidebarNavItems: AdminSidebarNavItem[]
  franchiseParam?: string
  companyName: string
  createPathPatterns: (basePath: string) => string[]
  getLinkPath: (itemId: string, childId?: string) => string
  onSidebarToggle: (isOpen: boolean) => void
}

export function AdminSidebar({
  sidebarNavItems,
  companyName,
  createPathPatterns,
  getLinkPath,
  onSidebarToggle,
}: AdminSidebarProps) {
  const location = useLocation()
  const isMobile = useIsMobile()
  const { isCompanyAdmin, isIdaAdmin, canReadPerformances } = usePermissions()
  const { headerHeight } = useAppContext()

  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile)
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>(
    {},
  )

  const isAdminPath = location.pathname.includes("/admin/")

  useEffect(() => {
    if (typeof isMobile !== "undefined") {
      setIsSidebarOpen(!isMobile)
    }
  }, [isMobile])

  useEffect(() => {
    onSidebarToggle(isSidebarOpen)
  }, [isSidebarOpen, onSidebarToggle])

  const filteredNavItems = sidebarNavItems.filter((item) => {
    if (!isAdminPath && item.id !== "taux-de-modification") {
      return false
    }

    if (item.id === "predicted-ca-vs-sales") {
      if (isIdaAdmin) return true
      if (
        isCompanyAdmin &&
        companyName !== "naturalia" &&
        companyName !== "marceletfils"
      )
        return true

      return false
    }

    if (item.permission === "isCompanyAdmin" && !isCompanyAdmin) return false
    if (item.permission === "isIdaAdmin" && !isIdaAdmin) return false
    if (item.permission === "canReadPerformances" && !canReadPerformances)
      return false
    return true
  })

  const handleToggle = (itemId: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }))
  }

  const isItemActive = (item: AdminSidebarNavItem) => {
    if (item.children) {
      return item.children.some((child) => {
        return createPathPatterns(`/performances/${item.id}/${child.id}`).some(
          (pattern) => matchPath(pattern, location.pathname) !== null,
        )
      })
    } else {
      return createPathPatterns(`/performances/${item.id}`).some(
        (pattern) => matchPath(pattern, location.pathname) !== null,
      )
    }
  }

  const isChildActive = (parentId: string, childId: string) => {
    return createPathPatterns(`/performances/${parentId}/${childId}`).some(
      (pattern) => matchPath(pattern, location.pathname) !== null,
    )
  }

  return (
    <aside
      className={twJoin(
        "fixed left-0 bg-white border-r shadow-sm transition-all duration-300 ease-in-out z-10 flex flex-col",
        isSidebarOpen ? "w-64" : "w-14",
      )}
      style={{
        top: `${headerHeight}px`,
        height: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      <div className="flex items-center p-4 justify-between border-b">
        {isSidebarOpen && (
          <h2 className="text-lg font-semibold truncate text-gray-800">
            Performances
          </h2>
        )}
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsSidebarOpen((prev) => !prev)}
          className="text-gray-500 hover:text-gray-800"
        >
          {isSidebarOpen ? (
            <PanelRightOpen className="h-5 w-5" />
          ) : (
            <PanelRightClose className="h-5 w-5" />
          )}
        </Button>
      </div>

      <nav className="flex-1 overflow-y-auto pt-2 px-2">
        <div className="space-y-1">
          {filteredNavItems.map((item) => {
            if (isMobile && item.children) {
              return null
            }

            const Icon = item.icon
            const isActive = isItemActive(item)
            const isExpanded = expandedItems[item.id]

            if (!item.children) {
              return (
                <Link
                  key={item.id}
                  to={getLinkPath(item.id)}
                  className={twJoin(
                    "flex items-center py-2 px-3 rounded-md transition-colors",
                    isActive
                      ? "bg-gray-900 text-white"
                      : "text-gray-700 hover:bg-gray-100 hover:text-gray-900",
                  )}
                >
                  {Icon && <Icon className="h-5 w-5 mr-2 flex-shrink-0" />}
                  {isSidebarOpen && (
                    <span className="truncate">{item.title}</span>
                  )}
                </Link>
              )
            }

            return (
              <div key={item.id} className="space-y-1">
                <div
                  onClick={() => handleToggle(item.id)}
                  className={twJoin(
                    "flex cursor-pointer items-center w-full rounded-md py-2 transition-colors",
                    isActive && !isExpanded
                      ? "bg-gray-900 text-white"
                      : "text-gray-700 hover:bg-gray-100 hover:text-gray-900",
                    isSidebarOpen
                      ? "px-3 justify-between"
                      : "px-2 justify-center",
                  )}
                >
                  {isSidebarOpen ? (
                    <>
                      <div className="flex items-center">
                        {Icon && (
                          <Icon className="h-5 w-5 mr-2 flex-shrink-0" />
                        )}
                        <span className="truncate">{item.title}</span>
                      </div>
                      {isExpanded ? (
                        <ChevronDown className="h-4 w-4 flex-shrink-0" />
                      ) : (
                        <ChevronRight className="h-4 w-4 flex-shrink-0" />
                      )}
                    </>
                  ) : (
                    <div className="relative group">
                      {Icon && <Icon className="h-5 w-5 flex-shrink-0" />}
                      <div className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity whitespace-nowrap z-20">
                        {item.title}
                      </div>
                    </div>
                  )}
                </div>

                {(isExpanded || !isSidebarOpen) && item.children && (
                  <div
                    className={twJoin(
                      "space-y-1",
                      isSidebarOpen ? "ml-4" : "mt-1",
                    )}
                  >
                    {item.children
                      .filter((child) => {
                        if (
                          child.permission === "isCompanyAdmin" &&
                          !isCompanyAdmin
                        )
                          return false
                        if (child.permission === "isIdaAdmin" && !isIdaAdmin)
                          return false
                        return true
                      })
                      .map((child) => {
                        const ChildIcon = child.icon
                        const isChildItemActive = isChildActive(
                          item.id,
                          child.id,
                        )

                        if (!isSidebarOpen) {
                          return (
                            <Link
                              key={child.id}
                              to={getLinkPath(item.id, child.id)}
                              className="relative block group"
                            >
                              <div
                                className={twJoin(
                                  "flex justify-center py-2 px-2 rounded-md",
                                  isChildItemActive
                                    ? "bg-gray-900 text-white"
                                    : "text-gray-600 hover:bg-gray-100",
                                )}
                              >
                                {ChildIcon && (
                                  <ChildIcon className="h-4 w-4 flex-shrink-0" />
                                )}
                              </div>
                              <div className="absolute left-full ml-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity whitespace-nowrap z-20">
                                {child.title}
                              </div>
                            </Link>
                          )
                        }

                        return (
                          <Link
                            key={child.id}
                            to={getLinkPath(item.id, child.id)}
                            className={twJoin(
                              "flex items-center w-full py-2 px-3 rounded-md text-sm transition-colors",
                              isChildItemActive
                                ? "bg-gray-900 text-white"
                                : "text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                            )}
                          >
                            {ChildIcon && (
                              <ChildIcon className="h-4 w-4 mr-2 flex-shrink-0" />
                            )}
                            <span className="truncate">{child.title}</span>
                          </Link>
                        )
                      })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </nav>
    </aside>
  )
}

export default AdminSidebar
