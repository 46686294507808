import PotentialRevenue from "./PotentialRevenue"
import UpdateInventoryPad from "./UpdateInventoryPad"
import OrderButton from "../button/OrderButton"
import { Weather } from "../Weather"
import { twMerge } from "tailwind-merge"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { Button } from "../../ui/Button"
import { CalendarDaysIcon } from "@heroicons/react/24/outline"
import { getTimeAgo } from "../../utils/getTimeAgo"
import { NotAvailableProductsBlock } from "./notAvailableProductBlock"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { Switcher } from "../molecules/switcher"
import { Card } from "../ui/card"

interface SiderbarProps {
  mercurialInfoLoading?: boolean
  mercurialAdditionalInfoLoading?: boolean
  batchUpdateLoading?: boolean
  updateInventory?: (value: string) => Promise<void>
  hidePotentialRevenue?: boolean
  hideOrderButton?: boolean
  className?: string
  setIsOrderReceptionDateModalOpen: Dispatch<SetStateAction<boolean>>
  companyName: string
  previousOrderInventoryComputeDate: string | undefined
  notAvailableProducts: AllMercurialInfo[]
  deduplicateFilteredMercurialeInfos: AllMercurialInfo[]
  mercurialeInfos: AllMercurialInfo[]
}

export function Sidebar({
  mercurialInfoLoading = false,
  mercurialAdditionalInfoLoading = false,
  batchUpdateLoading = false,
  hidePotentialRevenue = false,
  hideOrderButton = false,
  updateInventory,
  className,
  setIsOrderReceptionDateModalOpen,
  companyName,
  mercurialeInfos,
  previousOrderInventoryComputeDate,
  notAvailableProducts,
  deduplicateFilteredMercurialeInfos,
}: SiderbarProps): JSX.Element {
  const groupedMercurialeInfos = useMemo(() => {
    const grouped: Record<string, AllMercurialInfo[]> = {}

    mercurialeInfos.forEach((item) => {
      if (item.sale_id !== null && item.sale_id !== undefined) {
        if (!grouped[item.sale_id]) {
          grouped[item.sale_id] = []
        }
        grouped[item.sale_id].push(item)
      }
    })

    return Object.values(grouped)
  }, [mercurialeInfos])

  const [selected, setSelected] = useState<number>(0)

  const mergedClassName = twMerge(
    "grid grid-cols-1 gap-8 items-center overflow-y-auto max-h-screen ",
    className,
  )

  const optionsAndComponents = useMemo(() => {
    const data = [
      {
        title: "Météo",
        component: <Weather key="weather" />,
      },
    ]

    if (notAvailableProducts.length > 0) {
      data.push({
        title: "Ruptures",
        component: (
          <NotAvailableProductsBlock
            notAvailableProducts={notAvailableProducts}
            key="notAvailableProducts"
          />
        ),
      })
    }

    return data
  }, [notAvailableProducts])

  const displayIndex = optionsAndComponents.length > 1 ? selected : 0

  return (
    <div className={mergedClassName}>
      {previousOrderInventoryComputeDate !== undefined && (
        <p className="text-[#8a8a8a] text-sm text-center">
          Mise à jour des stocks :{" "}
          <span className="font-bold">
            {getTimeAgo(new Date(previousOrderInventoryComputeDate))}
          </span>
        </p>
      )}
      <Card className="overflow-hidden">
        {optionsAndComponents.length > 1 && (
          <Switcher
            options={optionsAndComponents.map((item) => item.title)}
            onToggle={setSelected}
            selected={selected}
          />
        )}
        {optionsAndComponents[displayIndex]?.component}
      </Card>
      {!hidePotentialRevenue && (
        <PotentialRevenue
          isLoading={mercurialInfoLoading || mercurialAdditionalInfoLoading}
        />
      )}
      {updateInventory !== undefined && (
        <UpdateInventoryPad
          mercurialeInfos={deduplicateFilteredMercurialeInfos}
          groupedMercurialeInfos={groupedMercurialeInfos}
          isLoading={
            mercurialInfoLoading ||
            mercurialAdditionalInfoLoading ||
            batchUpdateLoading
          }
          updateInventory={updateInventory}
        />
      )}
      {!hideOrderButton && (
        <div className="w-full flex items-center justify-center gap-1">
          <OrderButton
            isLoading={
              mercurialInfoLoading ||
              mercurialAdditionalInfoLoading ||
              batchUpdateLoading
            }
          />
          {companyName === "biomonde" && (
            <Button
              onClick={() => {
                setIsOrderReceptionDateModalOpen(true)
              }}
              className="text-green-900 p-0 size-14"
              color="ghost"
            >
              <CalendarDaysIcon className="size-6" />
            </Button>
          )}
        </div>
      )}
      <div className="h-[100px]" />
    </div>
  )
}
