import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { StoreReducerState } from "../../reducers/storeReducer"
import {
  InProgressDeliveriesItem,
  MercurialeInfo,
} from "../../utils/__generated__/graphql"
import { UserInterfaceReducerState } from "../../reducers/userInterfaceReducer"
import { LastPerformingTable } from "../../ui/LastPerformingTable"
import { Badges } from "../../ui/Badges"
import { LabelAndValue, ReferenceDetails } from "../../ui/ReferenceDetails"
import { useEffect, useMemo, useState } from "react"
import { Modal } from "../../ui/Modal"
import { Img } from "react-image"
import { LocalFlagButton } from "../button/LocalFlagButton"
import { usePermissions } from "@/hooks/usePermissions"
import { InventoriesInputs } from "./InventoriesInputs/InventoriesInputs"
import { upperFirst } from "lodash"
import {
  BadgePercent,
  CalendarClock,
  Package2,
  PackageIcon,
} from "lucide-react"
import { getDisplayedUnit } from "@/utils/getUnit"
import { InventoryRowLayout } from "./InventoryRowLayout"
import { IventorySupplierRowLayout } from "./InventorySupplierRowLayout"
import { Card } from "../ui/card"
import { isOrderDisabled } from "@/utils/isOrderDisabled"
import { twJoin } from "tailwind-merge"

export interface RowProps {
  index: number
  bestSellers: MercurialeInfo[]
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  displayShelfFloorSize: boolean
  storeSettings: StoreReducerState["storeSettings"]
  storeSuppliers: StoreReducerState["storeSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  companyId: StoreReducerState["companyId"]
  storeId: StoreReducerState["storeId"]
  references: AllMercurialInfo[]
  isOnline: boolean
  selectedInventory: UserInterfaceReducerState["selectedInventory"]
  setCurrentIndex?: (index: number) => void
  searchTerm?: string
  companyName: string | null
  franchise: string | null
  className?: string
  isInCheckinPage?: boolean
  setDisplayedList?: () => void
  inProgressDeliveries?: InProgressDeliveriesItem[] | undefined
  isRowGreyed?: boolean
  isRuptureToVerify?: boolean
}

export function InventoryRow({
  bestSellers,
  storeSettings,
  updateInventory,
  storeSuppliers,
  storeCurrency,
  companyId,
  isOnline,
  storeId,
  references,
  selectedInventory,
  setCurrentIndex,
  index: SaleIdIndex,
  searchTerm,
  companyName,
  franchise,
  className,
  isInCheckinPage,
  setDisplayedList,
  inProgressDeliveries,
  isRowGreyed,
  isRuptureToVerify,
}: RowProps) {
  const { isIdaAdmin, canUpdateOrderQuantity } = usePermissions()
  const [isOpen, setIsOpen] = useState(false)
  const isGreyed = companyName === "biomonde" ? false : isRowGreyed

  const orderableReferences = useMemo(() => {
    return references.filter((reference) => {
      const isSoldButNonActive = reference.sold_but_not_active ?? false
      return reference.active !== false && !isSoldButNonActive
    })
  }, [references])

  const notOrderableReferences = useMemo(() => {
    return references.filter(
      (reference) =>
        !orderableReferences.some(
          (orderableReference) =>
            orderableReference.mercuriale_id === reference.mercuriale_id,
        ),
    )
  }, [orderableReferences, references])

  const sortedReferences = useMemo(() => {
    const sortedOrderableReferences = [...orderableReferences].sort((a, b) => {
      const paA = typeof a.pa === "number" ? a.pa : Infinity
      const paB = typeof b.pa === "number" ? b.pa : Infinity

      return paA - paB
    })

    return [...sortedOrderableReferences, ...notOrderableReferences]
  }, [notOrderableReferences, orderableReferences])

  const firstRow = sortedReferences[0]
  const isBestSeller = bestSellers.some(
    (bestSeller) => bestSeller.mercuriale_id === firstRow?.mercuriale_id,
  )

  const isActive =
    (firstRow.active ?? true) ||
    firstRow.sold_but_not_active === true ||
    firstRow.mercuriale_id === selectedInventory?.mercurialeId

  const bestMargin = useMemo(() => {
    if (sortedReferences.length <= 1) {
      return null
    }
    const margins = sortedReferences.map((reference) => {
      if (
        typeof reference.pa === "number" &&
        reference.pa > 0 &&
        typeof reference.pv === "number" &&
        reference.pv > 0
      ) {
        return (reference.pv - reference.pa) / reference.pv
      }
      return -Infinity
    })

    const uniqueMargins = [...new Set(margins)]
    if (uniqueMargins.length === 1) {
      return null
    }

    return Math.max(...margins)
  }, [sortedReferences])

  const hasBigBreakage = useMemo(() => {
    return references.some(
      (reference) =>
        typeof reference.breakage_percentage === "number" &&
        reference.breakage_percentage > 10,
    )
  }, [references])

  const itemIsNew = useMemo(() => {
    return references.some((reference) => reference.new_reference === true)
  }, [references])

  useEffect(() => {
    if (searchTerm === "" && setCurrentIndex) {
      setCurrentIndex(SaleIdIndex - 1)
    }
  }, [setCurrentIndex, SaleIdIndex, searchTerm])

  const promoMessageToDisplay = useMemo(() => {
    const firstValidPromotion = references.find(
      (reference) =>
        reference.message_promotion != null &&
        reference.message_promotion !== "",
    )

    return firstValidPromotion ? firstValidPromotion.message_promotion : null
  }, [references])

  const hasPromo = useMemo(() => {
    return references.some((reference) => reference.promotion === true)
  }, [references])

  if (!firstRow) return null

  return (
    <>
      <InventoryRowLayout
        isGreyed={isGreyed}
        tableElement={
          <LastPerformingTable
            isIdaAdmin={isIdaAdmin}
            colisage={firstRow.colisage}
            saleHistoricalQuantities={firstRow.sale_historical_quantities}
            inventoryHistoricalField={firstRow.inventory_historical_field}
            predictionHistoricalField={firstRow.prediction_historical_field}
            deliveryHistoricalQuantities={
              firstRow.delivery_historical_quantities
            }
            orderExpectedReceptionDate={
              firstRow.order_expected_reception_date ??
              firstRow.mercuriale_reception_date
            }
            orderNextExpectedReceptionDate={
              firstRow.order_next_expected_reception_date
            }
            viewSalesFirst={storeSettings?.view_sales_first ?? true}
            localDeliveryDays={firstRow.local_delivery_days ?? undefined}
          />
        }
        isActive={isActive}
        badgesElement={
          <Badges
            data={firstRow}
            isBestSeller={isBestSeller}
            isFirstRow
            storeCurrency={storeCurrency}
            promoMessageToDisplay={promoMessageToDisplay ?? ""}
            hasPromo={hasPromo}
            isInCheckinPage={isInCheckinPage}
          />
        }
        isDanger={hasBigBreakage}
        isWarning={itemIsNew}
        internalReferenceElement={
          storeSettings?.display_sale_name_ida &&
          firstRow.sale_name_ida !== null && (
            <p className={twJoin("font-light", isGreyed && "opacity-20")}>
              {firstRow.sale_name_ida}
            </p>
          )
        }
        imageSource={
          firstRow?.photo_id &&
          `https://storage.googleapis.com/references_images/${companyId}/${firstRow?.photo_id}.jpeg`
        }
        productName={upperFirst(firstRow?.mercuriale_name?.toLowerCase())}
        onImageClick={() => setIsOpen(true)}
        isSticky={sortedReferences.length > 1}
        typology={firstRow?.typology}
        className={className}
      >
        {inProgressDeliveries?.map((delivery, index) => {
          const isOrderIdInReferences = references.some(
            (reference) => reference.order_id === delivery.order_id,
          )

          const supplier =
            (storeSuppliers ?? []).length > 1 &&
            storeSuppliers?.find(
              (supplier) => supplier.id === delivery.supplier_id,
            )

          return (
            !isOrderIdInReferences && (
              <Card
                className="flex gap-4 border-none rounded-lg shadow-none bg-slate-50 p-1"
                key={`${delivery.order_id}-${index}`}
              >
                {supplier && (
                  <div className="capitalize text-base font-bold">
                    <p>{supplier.supplier_name.toLocaleLowerCase()}</p>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <CalendarClock className="text-muted-foreground/80 w-3.5 h-3.5 inline" />
                  <p className="text-muted-foreground/80 text-xs font-semibold">
                    En-cours
                  </p>
                  <p className="text-black text-xs font-semibold">
                    {delivery.quantity / Number(delivery.colisage)} cs
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <Package2 className="text-muted-foreground/80 w-3.5 h-3.5 inline" />
                  <p className="text-black text-xs font-bold">
                    {Math.floor(Number(delivery.colisage))}{" "}
                    {getDisplayedUnit(firstRow.unit, companyName, franchise)}
                  </p>
                </div>
              </Card>
            )
          )
        })}
        {sortedReferences.map((reference, index) => {
          const isNotOrderable = isOrderDisabled(
            reference,
            bestSellers,
            storeSettings,
            canUpdateOrderQuantity,
          )

          const supplier =
            (storeSuppliers ?? []).length > 1 &&
            storeSuppliers?.find(
              (supplier) => supplier.id === reference.supplier_id,
            )
          const isMercurialeNameBox =
            reference.mercuriale_name?.toLowerCase().includes("box") === true

          const isPromotion =
            reference?.promotion === true ||
            typeof reference.message_promotion === "string"

          const isPromotionWithOffer =
            isPromotion &&
            typeof reference.message_promotion === "string" &&
            reference.message_promotion?.toLowerCase() !== "sans offre"

          const promotionDate = isPromotion
            ? (() => {
                const dateFormatter = new Intl.DateTimeFormat("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                })

                if (
                  reference.promotion_start_date &&
                  reference.date_integration &&
                  new Date(reference.date_integration) <
                    new Date(reference.promotion_start_date)
                ) {
                  return ` Début le ${dateFormatter.format(
                    new Date(reference.promotion_start_date),
                  )}`
                } else if (
                  reference.date_integration &&
                  reference.promotion_end_date &&
                  new Date(reference.date_integration) <
                    new Date(reference.promotion_end_date)
                ) {
                  return ` Fin le ${dateFormatter.format(
                    new Date(reference.promotion_end_date),
                  )}`
                }

                return ""
              })()
            : ""

          return (
            <IventorySupplierRowLayout
              key={index}
              packagingElement={
                reference?.colisage !== null && (
                  <LabelAndValue
                    value={`${reference?.colisage} ${getDisplayedUnit(reference.unit, companyName, franchise)}`}
                    icon={
                      <PackageIcon className="text-muted-foreground/80 w-4 h-4 inline" />
                    }
                  />
                )
              }
              referencesElement={
                <ReferenceDetails
                  bestMargin={bestMargin}
                  reference={reference}
                  storeCurrency={storeCurrency}
                  storeSettings={storeSettings}
                />
              }
              supplierElement={
                storeSettings?.show_suppliers &&
                supplier &&
                supplier.supplier_name.toLowerCase()
              }
              boxElement={
                isMercurialeNameBox && (
                  <LabelAndValue
                    value="Box"
                    icon={
                      <Package2 className="text-muted-foreground/80 w-4 h-4 inline" />
                    }
                  />
                )
              }
              promotionElement={
                isPromotion && (
                  <LabelAndValue
                    value={
                      isPromotionWithOffer
                        ? `${reference.message_promotion}${promotionDate}`
                        : "Promotion"
                    }
                    icon={
                      <BadgePercent className="text-cyan-800 w-4 h-4 inline" />
                    }
                    className="text-cyan-800"
                  />
                )
              }
              localToggleElement={
                storeSettings?.view_local && (
                  <LocalFlagButton reference={reference} storeId={storeId} />
                )
              }
              mercurialeNameElement={
                firstRow?.mercuriale_name !== reference.mercuriale_name && (
                  <p className="text-xs uppercase truncate max-w-[300px]">
                    {reference.mercuriale_name}
                  </p>
                )
              }
            >
              <InventoriesInputs
                firstReference={firstRow}
                reference={reference}
                referenceIndex={index}
                SaleIdIndex={SaleIdIndex}
                isOnline={isOnline}
                isOrderDisabled={isNotOrderable}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                hasBigBreakage={hasBigBreakage}
                isBestSeller={isBestSeller}
                companyName={companyName}
                franchise={franchise}
                setDisplayedList={setDisplayedList}
                isRuptureToVerify={isRuptureToVerify ?? false}
              />
            </IventorySupplierRowLayout>
          )
        })}
      </InventoryRowLayout>
      <Modal
        title={firstRow?.mercuriale_name}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Badges
          expanded
          data={firstRow}
          isBestSeller={isBestSeller}
          isFirstRow={true}
          storeCurrency={storeCurrency}
          isInCheckinPage={isInCheckinPage}
          promoMessageToDisplay={promoMessageToDisplay ?? ""}
        />
        {isOnline && typeof firstRow.photo_id === "string" && (
          <Img
            src={`https://storage.googleapis.com/references_images/${companyId}/${firstRow?.photo_id}.jpeg`}
            className="rounded"
          />
        )}
        <LastPerformingTable
          isIdaAdmin={isIdaAdmin}
          colisage={firstRow?.colisage}
          predictionHistoricalField={firstRow?.prediction_historical_field}
          inventoryHistoricalField={firstRow?.inventory_historical_field}
          saleHistoricalQuantities={firstRow?.sale_historical_quantities}
          deliveryHistoricalQuantities={
            firstRow?.delivery_historical_quantities
          }
          orderExpectedReceptionDate={
            firstRow?.order_expected_reception_date ??
            firstRow.mercuriale_reception_date
          }
          orderNextExpectedReceptionDate={
            firstRow?.order_next_expected_reception_date
          }
          viewSalesFirst={storeSettings?.view_sales_first ?? false}
          localDeliveryDays={firstRow.local_delivery_days ?? undefined}
        />
      </Modal>
    </>
  )
}
