import { useLastMonthUnmatchedReferences } from "@/hooks/useLastMonthUnmatchedReferences"
import { useNavigate } from "react-router-dom"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { ArrowRight, Bell } from "lucide-react"
import { Alert, AlertDescription } from "../ui/alert"
import { Button } from "../ui/button"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { Dispatch, SetStateAction } from "react"
import { SaveDataModalState } from "./modals/RetrySave/RetrySaveModal"
import { pluralize } from "@/utils/pluralize"

interface NotificationsProps {
  setSaveDataModalState: Dispatch<SetStateAction<SaveDataModalState>>
}

export function Notifications({ setSaveDataModalState }: NotificationsProps) {
  const navigate = useNavigate()

  const { unMatchedReferencesCount } = useLastMonthUnmatchedReferences()
  const dataSynchronizationStatus = useSelector(
    (state: StateType) => state.connectionReducer.dataSynchronizationStatus,
  )
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  return (
    <Popover>
      <PopoverTrigger
        className="relative"
        disabled={unMatchedReferencesCount === 0}
      >
        <Bell className="size-5 text-gray-500" />
        {unMatchedReferencesCount > 0 && (
          <div className="absolute size-2 -top-1 -right-1 rounded-full bg-black" />
        )}
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <Alert className="p-2">
          <AlertDescription className="flex justify-between items-center gap-1">
            Vous avez {unMatchedReferencesCount}{" "}
            {pluralize("nouvelle", unMatchedReferencesCount)}{" "}
            {pluralize("référence", unMatchedReferencesCount)} non{" "}
            {pluralize("matchée", unMatchedReferencesCount)}.
            <Button
              variant="outline"
              disabled={!isOnline}
              onClick={() => {
                if (!isOnline) return
                if (
                  dataSynchronizationStatus ===
                  DataSynchronizationStatus.FAILURE
                ) {
                  setSaveDataModalState({ isOpen: true, to: "/matching" })
                  return
                }
                navigate("/matching")
              }}
            >
              <ArrowRight className="size-4" />
            </Button>
          </AlertDescription>
        </Alert>
      </PopoverContent>
    </Popover>
  )
}
