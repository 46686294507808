import router from "./router/index"
import { Analytics } from "@vercel/analytics/react"
import { useDispatch } from "react-redux"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client"
import { RouterProvider } from "react-router-dom"
import { setContext } from "@apollo/client/link/context"
import { DispatchActionType } from "./types"
import { Auth0Client } from "@auth0/auth0-spa-js"
import { auth0Config } from "./config"
import { decodeToken } from "./utils/jwt"
import { Toaster } from "./components/ui/sonner"
import { AppProvider } from "./contexts/AppContext"
import { PostHogProvider } from "posthog-js/react"

function createClient(auth0: Auth0Client, dispatch: DispatchActionType) {
  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_API_URL,
  })

  /**
   * Checks the response for GraphQL errors and dispatches a logout action if an authorization error is detected.
   */
  const authErrorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const { data, errors } = response
      const hasUnauthorized =
        errors?.some((error) => error.message.includes("Unauthorized")) ?? false
      const hasAuthorizedError = Object.values(data ?? {}).some(
        (query) => query?.error?._typename === "AuthorizationError",
      )
      if (hasUnauthorized || hasAuthorizedError) {
        const path = window.location.pathname + window.location.search
        dispatch({ type: "setRedirectPath", payload: path })
        window.location.href = "/logout"
      }

      return response
    })
  })

  /**
   * Sets the authorization header for GraphQL requests using the current access token.
   */
  const authLink = setContext(async (_, { headers }) => {
    const token = await auth0.getTokenSilently({
      authorizationParams: auth0Config.authorizationParams,
    })
    const tokenData = decodeToken(token)
    if (tokenData !== null) {
      dispatch({ type: "setTokenData", payload: tokenData })
    }

    return {
      headers: {
        ...headers,
        authorization: token !== undefined ? `Bearer ${token}` : "",
      },
    }
  })

  return new ApolloClient({
    link: authLink.concat(authErrorLink).concat(httpLink),
    cache: new InMemoryCache(),
  })
}

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  auto_capture: false,
  capture_pageview: false,
  capture_pageleave: false,
  capture_pageview_event: false,
  capture_pageleave_event: false,
  capture_pageview_event_name: false,
  capture_pageleave_event_name: false,
}

export function App() {
  const dispatch = useDispatch<DispatchActionType>()
  const auth0 = new Auth0Client(auth0Config)
  const client = createClient(auth0, dispatch)

  return (
    <ApolloProvider client={client}>
      <PostHogProvider
        apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY ?? ""}
        options={options}
      >
        <AppProvider>
          <Toaster closeButton theme="light" richColors position="top-right" />
          <RouterProvider router={router} />
          <Analytics />
        </AppProvider>
      </PostHogProvider>
    </ApolloProvider>
  )
}
