"use client"

import { useState, useEffect, useMemo } from "react"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { add, differenceInCalendarDays } from "date-fns"
import { toast } from "sonner"

import { Spinner } from "@/ui/Spinner"
import { Card, CardHeader, CardContent, CardTitle } from "@/components/ui/card"

import { Send } from "lucide-react"

import { useGetPredictionsModifByStoreAndReferenceQuery } from "@/utils/__generated__/graphql"
import { PerformancesPageContext } from "../PerformancesPage"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { usePermissions } from "@/hooks/usePermissions"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import OrderTable, { OrderRecord } from "../components/orderDash/OrderTable"
import OrderStatsBar from "../components/orderDash/OrderStatsBar"
import PaginationControls from "../components/orderDash/PaginationControls"
import OrderDashFilters from "../components/orderDash/OrderDashFilters"
import MobileOrderList from "../components/orderDash/OrderTableMobile/OrderCard"
// Single column sort config
type SortDirection = "none" | "asc" | "desc"
interface SortConfig {
  key: string
  direction: SortDirection
}

export default function OrderDashPage() {
  // 1) Grab from context
  const { selectedStoreId, fromDate, stores, toDate } =
    useOutletContext<PerformancesPageContext>()

  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined

  const companyIdInParams = params.companyId
  const { companyId, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const { storeName: storeNameOfTheCurrentStore } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const [selectedSuppliers, setSelectedSuppliers] = useState(new Set<string>())

  const storeName =
    stores?.find((store) => store.id === selectedStoreId)?.store_name ?? ""

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const companyIdToUse = companyIdInParams ?? companyId
  const franchiseToUse = franchiseParam ?? storeFranchise

  const defaultStartDate = useMemo(() => add(new Date(), { days: -7 }), [])
  const defaultEndDate = useMemo(() => new Date(), [])

  const { isCompanyAdmin, isIdaAdmin } = usePermissions()

  const showPredAvantStock = !(isCompanyAdmin && !isIdaAdmin)

  const [referenceFilter, setReferenceFilter] = useState("")

  const [showPromo, setShowPromo] = useState(false)
  const [showTop, setShowTop] = useState(false)
  const [showStock, setShowStock] = useState(false)
  const [showLocal, setShowLocal] = useState(false)
  const [showBreakage, setShowBreakage] = useState(false)
  const [showHighStock, setShowHighStock] = useState(false)
  const [showLowStock, setShowLowStock] = useState(false)

  const [modifFilter, setModifFilter] = useState<
    "none" | "all" | "up" | "down"
  >("none")

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "date",
    direction: "desc",
  })

  const [page, setPage] = useState(1)
  const pageSize = 350

  const { data, loading, error } =
    useGetPredictionsModifByStoreAndReferenceQuery({
      variables: {
        input: {
          company_id: companyIdToUse!,
          store_id: storeId!,
          selected_store_id: selectedStoreId ?? undefined,
          franchise_name: franchiseToUse,
          start_date: formatDateToYYYYMMDD(fromDate ?? defaultStartDate),
          end_date: formatDateToYYYYMMDD(toDate ?? defaultEndDate),
        },
      },
      fetchPolicy: "cache-and-network",
    })

  const allRecords = useMemo<OrderRecord[]>(
    () =>
      data?.getPredictionsModifByStoreAndReference?.performances_daily ?? [],
    [data],
  )

  const totalOrderQuantity = useMemo(() => {
    return Math.round(
      allRecords.reduce((acc, curr) => {
        return acc + (curr.quantity_ordered_in_colis ?? 0)
      }, 0) / (getDiffBetweenDates(fromDate, toDate) || 1),
    )
  }, [allRecords, fromDate, toDate])

  const totalReferencesOrdered = useMemo(() => {
    return Math.round(
      allRecords.reduce((acc, curr) => {
        if ((curr.quantity_ordered_in_colis ?? 0) > 0) {
          return acc + 1
        }
        return acc
      }, 0) / (getDiffBetweenDates(fromDate, toDate) || 1),
    )
  }, [allRecords, fromDate, toDate])

  // Top 15 by (quantity_sold_in_colis * colisage)
  const topSaleIds = useMemo(() => {
    if (!allRecords.length) return new Set<string>()
    const sortedDesc = [...allRecords].sort(
      (a, b) =>
        (b.quantity_sold_in_colis ?? 0) * (b.colisage ?? 1) -
        (a.quantity_sold_in_colis ?? 0) * (a.colisage ?? 1),
    )
    const top15 = sortedDesc.slice(0, 15).map((rec) => rec.sale_id)
    return new Set(top15)
  }, [allRecords])

  function handleSort(columnKey: string) {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === "asc") {
        setSortConfig({ key: columnKey, direction: "desc" })
      } else if (sortConfig.direction === "desc") {
        setSortConfig({ key: columnKey, direction: "none" })
      } else {
        setSortConfig({ key: columnKey, direction: "asc" })
      }
    } else {
      setSortConfig({ key: columnKey, direction: "asc" })
    }
    setPage(1)
  }

  function renderSortIndicator(columnKey: string) {
    if (sortConfig.key !== columnKey) return "—"
    if (sortConfig.direction === "asc") return "↑"
    if (sortConfig.direction === "desc") return "↓"
    return "—"
  }

  const filteredRecords = useMemo(() => {
    const arr = allRecords.filter((rec) => {
      if (referenceFilter) {
        const searchLower = referenceFilter.toLowerCase()
        const refLower = rec.sale_name?.toLowerCase() ?? ""
        const storeLower = rec.store_name?.toLowerCase() ?? ""
        const dateStr = rec.order_request_date?.slice(0, 10) ?? ""
        const expectedReceptionDate =
          rec.expected_reception_date?.slice(0, 10) ?? ""
        if (
          !(
            refLower.includes(searchLower) ||
            storeLower.includes(searchLower) ||
            dateStr.includes(searchLower) ||
            expectedReceptionDate.includes(searchLower)
          )
        ) {
          return false
        }
      }
      if (
        showPromo ||
        showTop ||
        showStock ||
        showLocal ||
        showBreakage ||
        showHighStock ||
        showLowStock
      ) {
        const isPromo = (showPromo && rec.promotion) ?? false
        const isTop = (showTop && topSaleIds.has(rec.sale_id)) ?? false
        const isStock = (showStock && rec.stock_a_ecouler) ?? false
        const isLocal = (showLocal && rec.local_flag) ?? false
        const isBreakage = (showBreakage && rec.high_breakage_flag) ?? false
        const isHighStock = (showHighStock && rec.stock_too_high_flag) ?? false
        const isLowStock = (showLowStock && rec.stock_too_low_flag) ?? false
        if (
          !(
            isPromo ||
            isTop ||
            isStock ||
            isLocal ||
            isBreakage ||
            isHighStock ||
            isLowStock
          )
        ) {
          return false
        }
      }

      if (selectedSuppliers.size > 0) {
        if (!rec.supplier_name || !selectedSuppliers.has(rec.supplier_name)) {
          return false
        }
      }

      switch (modifFilter) {
        case "all": {
          if ((rec.updated ?? 0) <= 0) return false
          break
        }
        case "up": {
          const updated = rec.updated ?? 0
          const qtyOrdered = rec.quantity_ordered_in_colis ?? 0
          const qtyFinalPred = rec.final_quantity_predicted_in_colis ?? 0
          if (!(updated > 0 && qtyOrdered > qtyFinalPred)) {
            return false
          }
          break
        }
        case "down": {
          const updated = rec.updated ?? 0
          const qtyOrdered = rec.quantity_ordered_in_colis ?? 0
          const qtyFinalPred = rec.final_quantity_predicted_in_colis ?? 0
          if (!(updated > 0 && qtyOrdered < qtyFinalPred)) {
            return false
          }
          break
        }
        case "none":
        default:
          break
      }
      return true
    })

    if (sortConfig.direction === "none") {
      return arr
    }

    const newArr = [...arr]
    newArr.sort((a, b) => {
      let valA: string | number = ""
      let valB: string | number = ""

      switch (sortConfig.key) {
        case "store":
          valA = (a.store_name ?? "").toLowerCase()
          valB = (b.store_name ?? "").toLowerCase()
          break
        case "date":
          valA = a.order_request_date ?? ""
          valB = b.order_request_date ?? ""
          break
        case "sale_name_ida":
          valA = (a.sale_name_ida ?? "").toLowerCase()
          valB = (b.sale_name_ida ?? "").toLowerCase()
          break
        case "reference":
          valA = (a.sale_name ?? "").toLowerCase()
          valB = (b.sale_name ?? "").toLowerCase()
          break
        case "colisage":
          valA = a.colisage ?? 0
          valB = b.colisage ?? 0
          break
        case "ventes":
          valA = a.quantity_sold_in_colis ?? 0
          valB = b.quantity_sold_in_colis ?? 0
          break
        case "predAvt":
          valA = a.quantity_predicted_in_colis ?? 0
          valB = b.quantity_predicted_in_colis ?? 0
          break
        case "predPost":
          valA = a.final_quantity_predicted_in_colis ?? 0
          valB = b.final_quantity_predicted_in_colis ?? 0
          break
        case "cmds":
          valA = a.quantity_ordered_in_colis ?? 0
          valB = b.quantity_ordered_in_colis ?? 0
          break
        case "impact":
          valA = a.impact ?? 0
          valB = b.impact ?? 0
          break
        case "diff": {
          const diffA =
            (a.quantity_ordered_in_colis ?? 0) -
            (a.final_quantity_predicted_in_colis ?? 0)
          const diffB =
            (b.quantity_ordered_in_colis ?? 0) -
            (b.final_quantity_predicted_in_colis ?? 0)
          valA = diffA
          valB = diffB
          break
        }
        default:
          return 0
      }

      let compareResult = 0
      if (typeof valA === "number" && typeof valB === "number") {
        compareResult = valA - valB
      } else {
        compareResult = (valA as string).localeCompare(valB as string)
      }

      return sortConfig.direction === "asc" ? compareResult : -compareResult
    })
    return newArr
  }, [
    allRecords,
    referenceFilter,
    showPromo,
    showTop,
    showStock,
    showLocal,
    showBreakage,
    showHighStock,
    showLowStock,
    modifFilter,
    sortConfig,
    topSaleIds,
    selectedSuppliers,
  ])
  const totalCount = filteredRecords.length

  const countUp = useMemo(
    () =>
      filteredRecords.filter(
        (rec) =>
          (rec.updated ?? 0) > 0 &&
          (rec.quantity_ordered_in_colis ?? 0) >
            (rec.final_quantity_predicted_in_colis ?? 0),
      ).length,
    [filteredRecords],
  )

  const countDown = useMemo(
    () =>
      filteredRecords.filter(
        (rec) =>
          (rec.updated ?? 0) > 0 &&
          (rec.quantity_ordered_in_colis ?? 0) <
            (rec.final_quantity_predicted_in_colis ?? 0),
      ).length,
    [filteredRecords],
  )

  const dateMap = useMemo(() => {
    const map = new Map<string, { total: number; modif: number }>()
    for (const rec of filteredRecords) {
      const d = rec.order_request_date?.slice(0, 10) ?? ""
      if (!d) continue
      if (!map.has(d)) {
        map.set(d, { total: 0, modif: 0 })
      }
      const info = map.get(d)!
      info.total++
      if ((rec.updated ?? 0) > 0) {
        info.modif++
      }
    }
    return map
  }, [filteredRecords])

  const dateRatios = useMemo(() => {
    const arr: { date: string; ratio: number }[] = []
    for (const [dateStr, info] of dateMap.entries()) {
      const ratio = info.total ? info.modif / info.total : 0
      arr.push({ date: dateStr, ratio })
    }
    arr.sort((a, b) => a.date.localeCompare(b.date))
    return arr
  }, [dateMap])

  // trend
  const dateTrend = useMemo(() => {
    if (dateRatios.length < 2) return 0
    let sumDiff = 0
    for (let i = 1; i < dateRatios.length; i++) {
      sumDiff += dateRatios[i].ratio - dateRatios[i - 1].ratio
    }
    return Math.round(sumDiff * 100)
  }, [dateRatios])

  // Mean Distance Stats
  const daysCount = useMemo(() => {
    return (
      differenceInCalendarDays(
        toDate ?? defaultEndDate,
        fromDate ?? defaultStartDate,
      ) + 1
    )
  }, [fromDate, toDate, defaultStartDate, defaultEndDate])

  const updatedRecords = useMemo(
    () => filteredRecords.filter((rec) => (rec.updated ?? 0) > 0),
    [filteredRecords],
  )

  const modificationsCount = updatedRecords.length
  const totalModDistance = useMemo(() => {
    return updatedRecords.reduce((acc, rec) => {
      const diff =
        (rec.quantity_ordered_in_colis ?? 0) -
        (rec.final_quantity_predicted_in_colis ?? 0)
      return acc + Math.abs(diff)
    }, 0)
  }, [updatedRecords])

  const meanModDistance = modificationsCount
    ? totalModDistance / modificationsCount
    : 0

  const distancePerDay = daysCount > 0 ? totalModDistance / daysCount : 0

  const showImpactColumn = useMemo(() => {
    const totalSales = filteredRecords.reduce(
      (acc, rec) => acc + (rec.quantity_sold_in_colis ?? 0),
      0,
    )
    if (totalSales === 0) return false
    return filteredRecords.some((rec) => (rec.impact ?? 0) !== 0)
  }, [filteredRecords])

  const totalRows = filteredRecords.length
  const totalPages = Math.ceil(totalRows / pageSize)
  useEffect(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages)
    }
  }, [page, totalPages])

  const paginatedRecords = useMemo(() => {
    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize
    return filteredRecords.slice(startIndex, endIndex)
  }, [filteredRecords, page, pageSize])

  const totalGain = useMemo(() => {
    return filteredRecords.reduce((acc, rec) => {
      const impact = rec.impact ?? 0
      return impact > 0 ? acc + impact : acc
    }, 0)
  }, [filteredRecords])

  const totalLoss = useMemo(() => {
    return filteredRecords.reduce((acc, rec) => {
      const impact = rec.impact ?? 0
      return impact < 0 ? acc + Math.abs(impact) : acc
    }, 0)
  }, [filteredRecords])

  const uniqueStoresCount = useMemo(() => {
    const storesSet = new Set(
      filteredRecords.map((rec) => rec.store_name).filter(Boolean),
    )

    return storesSet.size || 1
  }, [filteredRecords])

  const meanGain = totalGain / (uniqueStoresCount * daysCount)
  const meanLoss = totalLoss / (uniqueStoresCount * daysCount)
  const netResult = meanGain - meanLoss

  useEffect(() => {
    if (error) {
      toast.error("Erreur lors du chargement des données.")
    }
  }, [error])
  const resetPage = () => setPage(1)

  return (
    <div className="flex flex-col p-2 gap-2 h-full overflow-hidden">
      <div className="flex-1 overflow-auto">
        <Card className="h-full flex flex-col">
          {/* Header */}
          <CardHeader className="p-2 md:p-4">
            <CardTitle className="flex items-center gap-2 py-2 md:py-4 whitespace-nowrap">
              <Send className="text-gray-500" />
              {storeName
                ? storeName
                : isCompanyAdmin || isIdaAdmin
                  ? "Tous les magasins"
                  : storeNameOfTheCurrentStore}
            </CardTitle>
            <OrderDashFilters
              referenceFilter={referenceFilter}
              setReferenceFilter={setReferenceFilter}
              showPromo={showPromo}
              setShowPromo={setShowPromo}
              showTop={showTop}
              setShowTop={setShowTop}
              showStock={showStock}
              setShowStock={setShowStock}
              showLocal={showLocal}
              setShowLocal={setShowLocal}
              showBreakage={showBreakage}
              setShowBreakage={setShowBreakage}
              showHighStock={showHighStock}
              setShowHighStock={setShowHighStock}
              showLowStock={showLowStock}
              setShowLowStock={setShowLowStock}
              modifFilter={modifFilter}
              setModifFilter={setModifFilter}
              resetPage={resetPage}
              records={allRecords}
              selectedSuppliers={selectedSuppliers}
              setSelectedSuppliers={setSelectedSuppliers}
            />
            {modificationsCount > 0 && (
              <OrderStatsBar
                countUp={countUp}
                countDown={countDown}
                totalCount={totalCount}
                dateTrend={dateTrend}
                meanModDistance={meanModDistance}
                distancePerDay={distancePerDay}
                totalOrderQuantity={totalOrderQuantity}
                totalReferencesOrdered={totalReferencesOrdered}
                meanGain={meanGain}
                meanLoss={meanLoss}
                netResult={netResult}
              />
            )}
          </CardHeader>

          <CardContent className="flex-1 overflow-auto p-2 pt-0">
            {loading ? (
              <div className="flex items-center justify-center min-h-[200px]">
                <Spinner />
              </div>
            ) : paginatedRecords.length === 0 ? (
              <div className="text-gray-500 p-4">
                Aucune donnée pour ces filtres.
              </div>
            ) : (
              <>
                <OrderTable
                  records={paginatedRecords}
                  topSaleIds={topSaleIds}
                  showPredAvantStock={showPredAvantStock}
                  showImpactColumn={showImpactColumn}
                  handleSort={handleSort}
                  renderSortIndicator={renderSortIndicator}
                />
                <MobileOrderList records={paginatedRecords} />
                <PaginationControls
                  page={page}
                  totalPages={totalPages}
                  totalResults={filteredRecords.length}
                  onPageChange={setPage}
                />
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
