import { formatNumber } from "@/utils/formatting"

export function FormatPackageUnit(
  value: number | null | undefined,
  colisage: number | null | undefined,
) {
  const isInvalid =
    value === null ||
    value === undefined ||
    colisage === undefined ||
    colisage === 0 ||
    colisage === null
  if (isInvalid) {
    return (
      <span className="text-gray-400 italic" title="Données insuffisantes">
        N/A
      </span>
    )
  }

  const packageUnits = value / colisage
  return <span>{formatNumber(packageUnits)}</span>
}
