import { AllMercurialInfo } from "@/reducers/mercurialReducer"

interface HasBadgesResult {
  hasBadges: boolean
  hasSignificantBreakageAmount: boolean
  isPromotion: boolean
  isNewReference: boolean
  isEngagementPromo: boolean
  isPasDeRupture: boolean
  isPasDeDemarque: boolean
  notSeenInThirtyDays: boolean
}

export function hasBadges(reference: AllMercurialInfo): HasBadgesResult {
  const breakageAmount = Math.round(reference?.breakage_amount ?? 0)
  const hasSignificantBreakageAmount = breakageAmount >= 1
  const isPromotion =
    reference?.promotion === true ||
    typeof reference.message_promotion === "string"
  const isNewReference = reference.new_reference === true
  const isEngagementPromo = reference.engagement_promo_flag === true
  const isPasDeRupture = reference.pas_de_rupture === true
  const notSeenInThirtyDays = reference.not_seen_in_thirty_days === true
  const isPasDeDemarque = reference.pas_de_demarque === true

  const shouldShowBadges =
    hasSignificantBreakageAmount ||
    isPromotion ||
    isNewReference ||
    isEngagementPromo ||
    isPasDeRupture ||
    isPasDeDemarque ||
    notSeenInThirtyDays

  return {
    hasBadges: shouldShowBadges,
    hasSignificantBreakageAmount,
    isPromotion,
    isNewReference,
    isEngagementPromo,
    isPasDeRupture,
    isPasDeDemarque,
    notSeenInThirtyDays,
  }
}
