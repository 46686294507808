import { Skeleton } from "@/components/ui/skeleton"
import { Img } from "react-image"
import { InventoryInput } from "../../../../components/order/InventoriesInputs/InventoryInput"
import { InventoryType } from "@/utils/__generated__/graphql"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { FlaggedRefGroup } from "@/components/organisms/flaggedRefGroup"
import { Card } from "@/components/ui/card"
import { Maximize2, Minimize2 } from "lucide-react"
import { getQuarterValue } from "@/utils/getQuarterValue"
import { getDisplayedUnit } from "@/utils/getUnit"
import { useOrderContext } from "@/contexts/OrderContext"

interface Props {
  references: AllMercurialInfo[]
  companyId: string | null
  companyName: string | null
  franchise: string | null
  updateInventory: (value: string) => Promise<void>
  flag: "underexposure" | "overexposure"
  setCurrentSelectedSection: () => void
}

export const OverAndUnderExposureCard = ({
  references,
  companyId,
  companyName,
  franchise,
  updateInventory,
  flag,
  setCurrentSelectedSection,
}: Props) => {
  const { setLastInputSelected } = useOrderContext()
  return (
    <FlaggedRefGroup
      header={
        <div className="flex gap-2 items-center">
          {flag === "overexposure" && (
            <Minimize2 className="size-6 text-red-800" />
          )}
          {flag === "underexposure" && (
            <Maximize2 className="size-6 text-custom-orange" />
          )}
          <div>
            <p
              className={`font-extrabold ${flag === "underexposure" ? "text-custom-orange" : "text-red-800"}`}
            >
              {flag === "underexposure" ? "Sous-exposition" : "Sur-exposition"}
            </p>
            <p
              className={`font-medium text-sm italic ${flag === "underexposure" ? "text-custom-orange" : "text-red-800"}`}
            >
              {flag === "underexposure"
                ? "Au vu des ventes élevées, nous vous suggérons de massifier davantage ces articles en rayon"
                : "Au vu des ventes basses, nous vous suggérons de réduire le linéaire sur ces articles"}
            </p>
          </div>
        </div>
      }
      backgroundColor={flag === "overexposure" ? "bg-red-20" : "bg-yellow-20"}
    >
      {references.map((reference, index) => {
        const tenDaysAgo = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)
        const isUpdated =
          reference.shelf_floor_size_updated_at !== null &&
          new Date(reference.shelf_floor_size_updated_at!) > tenDaysAgo
        const salesSum =
          reference.sale_historical_quantities?.reduce(
            (salesAverage, sale) => salesAverage + (sale.quantity ?? 0),
            0,
          ) ?? 0
        const salesCount = reference.sale_historical_quantities?.length ?? 1
        const colisage = reference.colisage ?? 1
        const salesAverage = salesSum / colisage / salesCount
        const salesAverageQuarter = getQuarterValue(salesAverage, "size-5")
        const statusStyles = isUpdated
          ? "border-green-500 bg-green-50"
          : "border-gray-200"

        const setLastInputSelectedShelf = () => {
          setCurrentSelectedSection()
          setLastInputSelected({
            type: InventoryType.Shelf,
            saleId: reference.sale_id ?? "",
            mercurialeId: reference.mercuriale_id ?? "",
            index: index + 1,
            saleIdIndex: 0,
          })
        }

        return (
          <Card
            key={reference.mercuriale_id!}
            id={`${reference.mercuriale_id}`}
            className={`transition-colors border-l-4 ${statusStyles} gap-4  flex flex-col sm:flex-row items-start justify-between bg-white shadow rounded-lg p-4`}
          >
            <div className="flex-1">
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm font-bold line-clamp-2">
                  {reference.mercuriale_name}
                </p>
              </div>
              <div className="flex items-center lg:items-start gap-2">
                {typeof reference.photo_id === "string" ? (
                  <Img
                    src={`https://storage.googleapis.com/references_images/${companyId}/${reference.photo_id}.jpeg`}
                    className="w-12 lg:w-9 h-16 lg:h-11 rounded object-cover"
                    loader={
                      <Skeleton className="w-12 lg:w-9 h-16 lg:h-11 rounded" />
                    }
                  />
                ) : (
                  <div className="w-12 lg:w-9 h-16 lg:h-11 rounded" />
                )}
                <div className="text-left">
                  <p className="text-sm font-medium text-gray-500">
                    {reference.colisage}{" "}
                    {getDisplayedUnit(reference.unit, companyName, franchise)}
                  </p>
                  <div className="text-gray-600 font-bold text-sm flex items-center flex-wrap gap-1">
                    Ventes moyennes journalières :
                    <span className="flex items-center">
                      <p className="text-base flex items-center">
                        {(salesAverageQuarter.value ?? 0) > 0
                          ? salesAverageQuarter.value
                          : 0}
                        {salesAverageQuarter.icon}
                      </p>
                      <p>cs</p>
                    </span>
                  </div>
                  <p className="text-xs text-gray-600">
                    (basé sur la semaine précédente)
                  </p>
                </div>
              </div>
            </div>
            <div className="self-center w-32 flex items-center justify-center gap-2 md:w-fit">
              <InventoryInput
                name={InventoryType.Shelf}
                mercurialeId={reference.mercuriale_id!}
                unit={reference.unit ?? ""}
                value={reference.shelf_floor_size ?? 0}
                floorInventoryQuantity={reference.floor_inventory_qty ?? 0}
                updateInventory={updateInventory}
                setLastInputSelected={setLastInputSelectedShelf}
              />
            </div>
          </Card>
        )
      })}
    </FlaggedRefGroup>
  )
}
