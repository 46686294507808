import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
  CardFooter,
} from "@/components/ui/card"
import { formatEuro } from "./formatter"
import { AlertTriangle, ShoppingBag, Package2, Loader2 } from "lucide-react"
import { StoreComparisonData } from "./useModificationSummary"
import { Link, useSearchParams, useOutletContext } from "react-router-dom"
import {
  DetailedModificationProductsInput,
  Exact,
  GetDetailedModificationProductsQuery,
  useGetDetailedModificationProductsLazyQuery,
} from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { PerformancesPageContext } from "../../PerformancesPage"
import { QueryResult } from "@apollo/client"
import { flatten, groupBy } from "lodash"

interface RecurringIssuesProps {
  storeComparisonData: StoreComparisonData[]
  selectedStore: string
}

interface ProblematicReference {
  sale_name: string
  sale_name_ida: string
  occurrence_count: number
  total_impact: number
  impact_type: string
}

interface ReferenceTableProps {
  references: ProblematicReference[]
  issueType: string
}

export default function RecurringIssues({
  storeComparisonData,
  selectedStore,
}: RecurringIssuesProps) {
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined

  const { selectedStoreId } = useOutletContext<PerformancesPageContext>()

  const { companyId, storeFranchise, storeId } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const allWeeks = useMemo(() => {
    const weeks: number[] = []

    storeComparisonData.forEach((store) => {
      if (store.weekly_stats) {
        store.weekly_stats.forEach((weekStat) => {
          if (!weeks.includes(weekStat.week)) {
            weeks.push(weekStat.week)
          }
        })
      }
    })

    return weeks.sort((a, b) => a - b)
  }, [storeComparisonData])

  const [getDetailedModificationProducts, { loading: isLoading }] =
    useGetDetailedModificationProductsLazyQuery()

  const [stockoutQueries, setStockoutQueries] = useState<
    QueryResult<
      GetDetailedModificationProductsQuery,
      Exact<{ input: DetailedModificationProductsInput }>
    >[]
  >([])

  const getStockoutQueries = useCallback(async () => {
    const queries = allWeeks.map((week) =>
      getDetailedModificationProducts({
        variables: {
          input: {
            store_id: storeId ?? "",
            company_id: companyId ?? "",
            franchise_name: franchiseParam ?? storeFranchise,
            week,
            modification_type: "USER_STOCKOUT",
            selected_store_id:
              selectedStoreId ??
              (selectedStore !== "all" ? selectedStore : undefined),
          },
        },
      }),
    )
    const results = await Promise.all(queries)
    setStockoutQueries(results)
  }, [
    allWeeks,
    companyId,
    franchiseParam,
    getDetailedModificationProducts,
    selectedStore,
    selectedStoreId,
    storeFranchise,
    storeId,
  ])

  useEffect(() => {
    getStockoutQueries()
  }, [getStockoutQueries])

  const [breakageQueries, setBreakageQueries] = useState<
    QueryResult<
      GetDetailedModificationProductsQuery,
      Exact<{ input: DetailedModificationProductsInput }>
    >[]
  >([])

  const getBreakageQueries = useCallback(async () => {
    const queries = allWeeks.map((week) =>
      getDetailedModificationProducts({
        variables: {
          input: {
            store_id: storeId ?? "",
            company_id: companyId ?? "",
            franchise_name: franchiseParam ?? storeFranchise,
            week,
            modification_type: "USER_BREAKAGE",
            selected_store_id:
              selectedStoreId ??
              (selectedStore !== "all" ? selectedStore : undefined),
          },
        },
      }),
    )
    const results = await Promise.all(queries)
    setBreakageQueries(results)
  }, [
    allWeeks,
    companyId,
    franchiseParam,
    getDetailedModificationProducts,
    selectedStore,
    selectedStoreId,
    storeFranchise,
    storeId,
  ])

  useEffect(() => {
    getBreakageQueries()
  }, [getBreakageQueries])

  const [replenishmentQueries, setReplenishmentQueries] = useState<
    QueryResult<
      GetDetailedModificationProductsQuery,
      Exact<{ input: DetailedModificationProductsInput }>
    >[]
  >([])

  const getReplenishmentQueries = useCallback(async () => {
    const queries = allWeeks.map((week) =>
      getDetailedModificationProducts({
        variables: {
          input: {
            store_id: storeId ?? "",
            company_id: companyId ?? "",
            franchise_name: franchiseParam ?? storeFranchise,
            week,
            modification_type: "USER_REPLENISHMENT",
            selected_store_id:
              selectedStoreId ??
              (selectedStore !== "all" ? selectedStore : undefined),
          },
        },
      }),
    )
    const results = await Promise.all(queries)
    setReplenishmentQueries(results)
  }, [
    allWeeks,
    companyId,
    franchiseParam,
    getDetailedModificationProducts,
    selectedStore,
    selectedStoreId,
    storeFranchise,
    storeId,
  ])

  useEffect(() => {
    getReplenishmentQueries()
  }, [getReplenishmentQueries])

  const breakageReferences = useMemo(() => {
    const products = flatten(
      breakageQueries.map(
        (query) => query.data?.getDetailedModificationProducts?.products,
      ),
    )
    const productsBySaleNameIda = groupBy(products, "sale_name_ida")
    const breakageReferences = Object.values(productsBySaleNameIda).reduce<
      ProblematicReference[]
    >((acc, products) => {
      return [
        ...acc,
        {
          sale_name: products[0]?.sale_name ?? "",
          sale_name_ida: products[0]?.sale_name_ida ?? "",
          occurrence_count: products.length,
          total_impact: products.reduce(
            (sum, product) => sum + (product?.value ?? 0),
            0,
          ),
          impact_type: "USER_BREAKAGE",
        },
      ]
    }, [])

    return breakageReferences
      .sort((a, b) => b.total_impact - a.total_impact)
      .slice(0, 5)
  }, [breakageQueries])

  const stockoutReferences = useMemo(() => {
    const products = flatten(
      [...stockoutQueries, ...replenishmentQueries].map(
        (query) => query.data?.getDetailedModificationProducts?.products,
      ),
    )
    const productsBySaleNameIda = groupBy(products, "sale_name_ida")
    const stockoutReferences = Object.values(productsBySaleNameIda).reduce<
      ProblematicReference[]
    >((acc, products) => {
      return [
        ...acc,
        {
          sale_name: products[0]?.sale_name ?? "",
          sale_name_ida: products[0]?.sale_name_ida ?? "",
          occurrence_count: products.length,
          total_impact: products.reduce(
            (sum, product) => sum + (product?.value ?? 0),
            0,
          ),
          impact_type: "USER_STOCKOUT",
        },
      ]
    }, [])

    return stockoutReferences
      .sort((a, b) => b.total_impact - a.total_impact)
      .slice(0, 5)
  }, [replenishmentQueries, stockoutQueries])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader2 className="w-4 h-4 animate-spin" />
      </div>
    )
  }

  const ReferenceTable = ({ references, issueType }: ReferenceTableProps) => (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-50">
            <th className="p-2 border text-left text-sm text-gray-700">
              Référence
            </th>
            <th className="p-2 border text-center text-sm text-gray-700">
              Occurrences
            </th>
            <th className="p-2 border text-right text-sm text-gray-700">
              Perte €
            </th>
            <th className="p-2 border text-center text-sm text-gray-700">
              Détails
            </th>
          </tr>
        </thead>
        <tbody>
          {references.length > 0 ? (
            references.map((reference, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="p-2 border text-sm font-medium text-gray-700">
                  <div>{reference.sale_name}</div>
                  <div className="text-xs text-gray-500">
                    {reference.sale_name_ida}
                  </div>
                </td>
                <td className="p-2 border text-center text-sm text-gray-700">
                  <span className="px-2 py-1 bg-amber-100 text-amber-800 rounded-full text-xs font-medium">
                    {reference.occurrence_count} fois
                  </span>
                </td>
                <td className="p-2 border text-right text-sm font-bold text-rose-600">
                  {formatEuro(reference.total_impact)}
                </td>
                <td className="p-2 border text-center text-sm text-gray-700">
                  <Link
                    to={`reference-details/${reference.sale_name_ida}/${reference.impact_type}${
                      typeof franchiseParam === "string"
                        ? `?franchise=${franchiseParam}`
                        : ""
                    }`}
                    className="px-3 py-1 bg-indigo-100 text-indigo-700 rounded-md text-xs font-medium hover:bg-indigo-200 transition-colors"
                  >
                    Voir
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={selectedStoreId ? 4 : 5}
                className="p-4 text-center text-gray-500"
              >
                Aucune référence{" "}
                {issueType === "breakage" ? "de démarque" : "de rupture"}{" "}
                récurrente détectée
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )

  return (
    <Card className="border border-amber-200 shadow-sm hover:shadow transition-shadow mt-6 mb-6">
      <CardHeader className="p-4 pb-2 flex flex-row items-center justify-between border-b bg-amber-50">
        <div>
          <CardTitle className="text-base font-semibold text-gray-800 flex items-center">
            <AlertTriangle className="w-5 h-5 text-amber-600 mr-2" />
            Actions prioritaires : Flop 5 des modifications récurrentes
          </CardTitle>
          <CardDescription className="text-xs text-gray-500 mt-1">
            Ces références apparaissent fréquemment dans nos analyses et
            génèrent des pertes significatives. Nous vous recommandons
            d&apos;arrêter de les modifier.
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="p-4">
        <div className="space-y-4">
          <Tabs defaultValue="breakage" className="mt-4">
            <TabsList className="w-full grid grid-cols-2">
              <TabsTrigger value="breakage" className="flex items-center gap-2">
                <Package2 className="h-4 w-4 text-red-600" />
                Flop 5 Démarques Utilisateur
              </TabsTrigger>
              <TabsTrigger value="stockout" className="flex items-center gap-2">
                <ShoppingBag className="h-4 w-4 text-blue-600" />
                Flop 5 Ruptures/Réassort Utilisateur
              </TabsTrigger>
            </TabsList>

            <TabsContent
              value="breakage"
              className="mt-4 bg-red-50/30 p-3 rounded-md"
            >
              <ReferenceTable
                references={breakageReferences}
                issueType="breakage"
              />
            </TabsContent>

            <TabsContent
              value="stockout"
              className="mt-4 bg-blue-50/30 p-3 rounded-md"
            >
              <ReferenceTable
                references={stockoutReferences}
                issueType="stockout"
              />
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
      <CardFooter className="bg-amber-50/50 p-3 text-xs text-gray-600 italic border-t">
        <div className="flex justify-between w-full">
          <div>
            Les références listées ci-dessus sont basées uniquement sur les
            modifications effectuées par les utilisateurs.
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}
