import { InventoryRow } from "@/components/order/InventoryRow"
import { FlaggedRefGroup } from "@/components/organisms/flaggedRefGroup"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"

import { useSelector } from "react-redux"

export const UsedToBePromote = ({
  references,
  updateInventory,
  setCurrentSelectedSection,
}: {
  references: AllMercurialInfo[]
  updateInventory: (value: string) => Promise<void>
  setCurrentSelectedSection: () => void
}) => {
  const {
    companyName,
    storeFranchise,
    storeId,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
  } = useSelector((state: StateType) => state.storeReducer)

  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.orderPage.displayShelfFloorSize,
  )

  return (
    <FlaggedRefGroup
      header={
        <div className="flex gap-2 items-center">
          <div>
            <p className="font-extrabold text-gray-500">
              Linéaires des anciennes promotions
            </p>
            <p className="font-medium text-sm italic text-gray-500">
              Le linéaire de ces références a sûrement changé
            </p>
          </div>
        </div>
      }
      backgroundColor="bg-gray-200"
    >
      {references.map((reference, index) => {
        return (
          <div key={reference.mercuriale_id!} id={reference.mercuriale_id!}>
            <InventoryRow
              index={index}
              storeId={storeId}
              bestSellers={[]}
              selectedInventory={selectedInventory}
              isOnline={isOnline}
              storeSettings={storeSettings}
              updateInventory={updateInventory}
              displayShelfFloorSize={displayShelfFloorSize}
              storeSuppliers={storeSuppliers}
              storeCurrency={storeCurrency}
              companyId={companyId}
              references={[reference]}
              companyName={companyName}
              franchise={storeFranchise}
              setDisplayedList={setCurrentSelectedSection}
            />
          </div>
        )
      })}
    </FlaggedRefGroup>
  )
}
