import { Skeleton } from "@/components/ui/skeleton"
import { useLastMonthUnmatchedReferences } from "@/hooks/useLastMonthUnmatchedReferences"
import { pluralize } from "@/utils/pluralize"
import { Bell } from "lucide-react"
import { Link } from "react-router-dom"

export function UnMatchedReferencesWidget() {
  const { unMatchedReferencesCount, isLoading } =
    useLastMonthUnmatchedReferences()

  if (isLoading) {
    return <Skeleton className="h-14" />
  }

  if (unMatchedReferencesCount === 0) {
    return null
  }

  return (
    <div className="shadow bg-white p-4 rounded-lg border flex items-center gap-2">
      <Bell className="size-4" />
      <p className="font-normal text-sm">
        Vous avez{" "}
        <Link to="/matching" className="underline font-medium">
          {unMatchedReferencesCount}{" "}
          {pluralize("nouvelle", unMatchedReferencesCount)}{" "}
          {pluralize("référence", unMatchedReferencesCount)}
        </Link>{" "}
        non {pluralize("matchée", unMatchedReferencesCount)}.
      </p>
    </div>
  )
}
