import { AlertTriangle } from "lucide-react"

export default function InfoPanel() {
  return (
    <div className="mt-6 bg-gray-50 p-4 rounded-lg text-sm text-gray-700 border border-gray-100">
      <h4 className="font-medium mb-2 text-gray-800 flex items-center">
        <AlertTriangle className="h-4 w-4 mr-2 text-gray-500" />
        Informations
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="font-medium text-gray-700 mb-1">Démarque</p>
          <p className="text-gray-600 text-xs">
            Représente les pertes liées aux produits qui sont jetés (démarque
            connue et inconnue). La démarque peut être causée par les
            utilisateurs lors de modifications à la hausse ou par
            l&apos;algorithme.
          </p>
        </div>
        <div>
          <p className="font-medium text-gray-700 mb-1">Rupture</p>
          <p className="text-gray-600 text-xs">
            Représente les situations où un produit n&apos;est pas disponible en
            rayon. Les ruptures peuvent être causées par des modifications à la
            baisse effectuées par les utilisateurs ou par l&apos;algorithme.
          </p>
        </div>
      </div>
    </div>
  )
}
