// hooks/useSalesPerformanceData.ts
import {
  useGetProductsToConciderQuery,
  useGetStoreSalesPerformanceQuery,
} from "@/utils/__generated__/graphql"

export const useSalesPerformanceData = (storeId: string) => {
  // Fetch sales performance data
  const {
    data: salesData,
    loading: salesLoading,
    refetch: refetchSales,
  } = useGetStoreSalesPerformanceQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
    },
  })

  // Fetch products to consider
  const {
    data: productsToConcider,
    loading: productsToConciderLoading,
    refetch: refetchProductsToConcider,
  } = useGetProductsToConciderQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
    },
  })

  // Process and filter data
  const bestItems =
    salesData?.getStoreSalesPerformance?.store_sales_performance?.filter(
      (item) => item.group === "best",
    ) ?? []

  const worstItems =
    salesData?.getStoreSalesPerformance?.store_sales_performance?.filter(
      (item) => item.group === "worst",
    ) ?? []

  const productsToConciderItems =
    productsToConcider?.productToConsider?.product_to_consider ?? []

  // Calculate totals
  const worstItemsTotalLoss = worstItems.reduce(
    (acc, item) => acc + item.potential_revenue_loss,
    0,
  )

  const potentialGain = productsToConciderItems.reduce(
    (acc, item) => acc + item.potential_revenue_loss,
    0,
  )

  // Function to refresh all data
  const refreshData = () => {
    refetchSales()
    refetchProductsToConcider()
  }

  return {
    bestItems,
    worstItems,
    productsToConciderItems,
    worstItemsTotalLoss,
    potentialGain,
    isLoading: salesLoading || productsToConciderLoading,
    refreshData,
  }
}
