import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { GroupedPromotion } from "../EngagementPromoPage"
import { Fragment } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Img } from "react-image"
import { toFixedNumber } from "@/utils/toFixedNumber"
import { EngagementPromoDrawer } from "./EngagementPromoDrawer"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { Calendar, Clock, Loader2 } from "lucide-react"
import { EngagementPromoBadge } from "./EngagementPromoBadge"
import { getDisplayedUnit } from "@/utils/getUnit"
import {
  StoreSettings,
  useUpdateEngagementPromoMutation,
} from "@/utils/__generated__/graphql"

interface EngagementPromoContentProps {
  groupedPromotions: GroupedPromotion[]
}

interface PromotionGroupProps {
  groupedPromotion: GroupedPromotion
  index: number
  isLastItem: boolean
}

interface PromotionItemProps {
  promotion: GroupedPromotion["promotions"][0]
  companyId: string | null
  storeSettings: StoreSettings | null
  storeCurrency: string | null
  companyName: string | null
  storeFranchise: string | null
  dates: Date[]
  isTestMode: boolean
  storeId: string
}

function PromotionItem({
  promotion,
  companyId,
  storeSettings,
  storeCurrency,
  companyName,
  storeFranchise,
  dates,
  isTestMode,
  storeId,
}: PromotionItemProps) {
  const margin =
    typeof promotion.pa === "number" &&
    promotion.pa > 0 &&
    typeof promotion.pv === "number" &&
    promotion.pv > 0
      ? (promotion.pv - promotion.pa) / promotion.pv
      : undefined

  const formattedMargin =
    margin !== undefined
      ? new Intl.NumberFormat("fr-FR", {
          maximumFractionDigits: 0,
          style: "percent",
        }).format(margin)
      : ""

  const [updateEngagementPromo, loadingUpdateEngagementPromo] =
    useUpdateEngagementPromoMutation()

  const quantityIsLoading = loadingUpdateEngagementPromo.loading

  return (
    <div className="flex flex-col md:flex-row gap-4 justify-between items-center pb-4 border-b last:border-b-0">
      <div className="flex items-center gap-4">
        {typeof promotion.photo_id === "string" && (
          <Img
            src={`https://storage.googleapis.com/references_images/${companyId}/${promotion?.photo_id}.jpeg`}
            className="size-20 rounded object-cover"
          />
        )}
        <div className="flex flex-col gap-2">
          <p>{promotion.order_name}</p>
          <div className="flex gap-8">
            <p>
              Colis {toFixedNumber(promotion.colisage)}{" "}
              {getDisplayedUnit(promotion.unit, companyName, storeFranchise)}
            </p>
            {storeSettings?.view_pa === true && (
              <p>
                PA :{" "}
                {new Intl.NumberFormat("fr-FR", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                }).format(promotion.pa)}
              </p>
            )}
            {storeSettings?.view_pv === true && (
              <p>
                PV :{" "}
                {new Intl.NumberFormat("fr-FR", {
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: storeCurrency ?? "EUR",
                }).format(promotion.pv)}
              </p>
            )}
            {formattedMargin && <p>{formattedMargin}</p>}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center">
        <p>Quantité totale engagée</p>
        {quantityIsLoading ? (
          <Loader2 className="size-4 animate-spin" />
        ) : (
          <p>
            {promotion.engagements.reduce(
              (acc, curr) => acc + (curr?.quantity ?? 0),
              0,
            )}
          </p>
        )}
        <EngagementPromoDrawer
          promotion={promotion}
          dates={dates}
          isTestMode={isTestMode}
          storeId={storeId}
          updateEngagementPromo={updateEngagementPromo}
        />
      </div>
    </div>
  )
}

function PromotionGroup({
  groupedPromotion,
  index,
  isLastItem,
}: PromotionGroupProps) {
  const {
    companyId,
    storeId,
    storeSettings,
    storeCurrency,
    companyName,
    storeFranchise,
  } = useSelector((state: StateType) => state.storeReducer)
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const dates = new Array(
    getDiffBetweenDates(
      new Date(groupedPromotion.start_date_opc),
      new Date(groupedPromotion.end_date_opc),
    ) + 1,
  )
    .fill(0)
    .map((_, index) => {
      const date = new Date(groupedPromotion.start_date_opc)
      date.setDate(date.getDate() + index)
      return date
    })
    .filter((date) => date.getDay() !== 0)

  const totalDays = getDiffBetweenDates(
    new Date(groupedPromotion.start_date),
    new Date(groupedPromotion.end_date),
  )
  const daysPassed = getDiffBetweenDates(
    new Date(groupedPromotion.start_date),
    new Date(),
  )
  const progress = (daysPassed / totalDays) * 100

  return (
    <Fragment>
      <Card id={`section-${index}`}>
        <CardHeader>
          <CardTitle>{groupedPromotion.operation_name}</CardTitle>
          <div className="mt-0 pt-0 flex gap-1 flex-wrap">
            <EngagementPromoBadge
              Icon={Calendar}
              title="Engagement"
              startDate={groupedPromotion.start_date}
              endDate={groupedPromotion.end_date}
              progress={progress}
              className="bg-blue-30 text-blue-900"
            />
            <EngagementPromoBadge
              Icon={Clock}
              title="Opération"
              startDate={groupedPromotion.start_date_opc}
              endDate={groupedPromotion.end_date_opc}
              className="bg-emerald-50 text-emerald-900"
            />
          </div>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          {groupedPromotion.promotions.map((promotion) => (
            <PromotionItem
              key={promotion.id}
              promotion={promotion}
              companyId={companyId}
              storeSettings={storeSettings}
              storeCurrency={storeCurrency ?? "EUR"}
              companyName={companyName}
              storeFranchise={storeFranchise}
              dates={dates}
              isTestMode={isTestMode}
              storeId={storeId!}
            />
          ))}
        </CardContent>
      </Card>
      {!isLastItem && (
        <div className="p-4">
          <hr />
        </div>
      )}
    </Fragment>
  )
}

export function EngagementPromoContent({
  groupedPromotions,
}: EngagementPromoContentProps) {
  return (
    <div className="h-full px-2 md:px-7 lg:px-14 flex flex-col gap-2 flex-1 overflow-y-auto">
      {groupedPromotions?.map((groupedPromotion, index) => (
        <PromotionGroup
          key={index}
          groupedPromotion={groupedPromotion}
          index={index}
          isLastItem={index === groupedPromotions.length - 1}
        />
      ))}
    </div>
  )
}
