import { Label } from "./Label"
import { ReceiptPercentIcon, SparklesIcon } from "@heroicons/react/24/solid"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import BreakageLabel from "../components/order/BreakageLabel"
import {
  HistoryIcon,
  ShieldAlert,
  ShoppingCart,
  Tag,
  TrophyIcon,
} from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { upperFirst } from "lodash"
import { hasBadges } from "@/utils/hasBadges"

interface BadgesProps {
  expanded?: boolean
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  isBestSeller?: boolean
  hideBreakageLabel?: boolean
  hide?: boolean
  isFirstRow?: boolean
  storeCurrency: string | null
  isInCheckinPage?: boolean
  promoMessageToDisplay?: string
  hasPromo?: boolean
}

export function Badges({
  expanded = false,
  data,
  isBestSeller = false,
  hideBreakageLabel = false,
  hide = false,
  isFirstRow = false,
  storeCurrency,
  isInCheckinPage = false,
  promoMessageToDisplay,
  hasPromo = false,
}: BadgesProps) {
  const breakagePercent =
    typeof data?.breakage_percentage === "number"
      ? Math.round(data.breakage_percentage)
      : 0

  const breakageAmount = Math.round(data?.breakage_amount ?? 0)
  const {
    hasSignificantBreakageAmount,
    isPromotion,
    isNewReference,
    isEngagementPromo,
    isPasDeRupture,
    isPasDeDemarque,
    notSeenInThirtyDays,
  } = hasBadges(data)

  const isPromotionWithOffer =
    hasPromo &&
    typeof promoMessageToDisplay === "string" &&
    promoMessageToDisplay?.toLowerCase() !== "sans offre" &&
    promoMessageToDisplay?.toLowerCase() !== ""

  const isExpanded = expanded
  const isBestSellerInFirstRow = isBestSeller && isFirstRow

  const shouldShowLabels =
    hasSignificantBreakageAmount ||
    isPromotion ||
    isExpanded ||
    isNewReference ||
    isBestSellerInFirstRow ||
    isEngagementPromo ||
    isPasDeRupture ||
    isPasDeDemarque ||
    notSeenInThirtyDays ||
    hasPromo

  if (!shouldShowLabels || hide) {
    return <></>
  }

  return (
    <div className="flex flex-wrap gap-2 max-w-72 md:max-w-full">
      {isEngagementPromo === true && (
        <Badge className="bg-gradient-to-r from-orange-500 to-red-500 transition-colors text-xs font-medium text-white border-none">
          <Tag className="w-3.5 h-3.5 mr-1.5" />
          ENGAGEMENT PROMO
        </Badge>
      )}
      {data.new_reference === true && !isEngagementPromo && (
        <Label
          color="yellow"
          variant="filled"
          leftIcon={<SparklesIcon className="w-4 h-4" />}
        >
          Nouveau
        </Label>
      )}
      {data.new_reference !== true && notSeenInThirtyDays && (
        <div className="inline-flex items-center px-3 py-1 rounded-md bg-zinc-100 hover:bg-zinc-200 transition-colors">
          <span className="text-sm font-medium inline-flex items-center text-zinc-700">
            <HistoryIcon className="w-3.5 h-3.5 mr-1.5" /> Retour à l&apos;offre
          </span>
        </div>
      )}
      {isBestSeller && isFirstRow && (
        <Badge
          variant="secondary"
          className="flex items-center font-semibold rounded-full  border-none bg-gradient-to-r from-amber-100 to-amber-200 text-amber-800"
        >
          <TrophyIcon className="w-3.5 h-3.5 mr-1.5" />
          Top vente
        </Badge>
      )}
      {isPasDeRupture && (
        <Badge
          variant="outline"
          className="flex items-center font-semibold rounded-full  text-xs  text-blue-900 border border-blue-400"
        >
          <ShieldAlert className="w-4 h-4 mr-2" />
          Attention à la rupture
        </Badge>
      )}
      {isPasDeDemarque && (
        <Badge
          variant="secondary"
          className="flex items-center font-semibold rounded-full  border-none bg-gradient-to-r from-red-100 to-red-200 text-white"
        >
          <ShieldAlert className="w-4 h-4 mr-2" />
          Attention à la démarque
        </Badge>
      )}
      {!hideBreakageLabel && breakageAmount > 0 && !isInCheckinPage && (
        <BreakageLabel
          shrinkage={breakagePercent}
          amount={breakageAmount}
          expanded={expanded}
          storeCurrency={storeCurrency}
        />
      )}
      {hasPromo && (
        <div className="flex items-center">
          <Label
            color="blue"
            variant="filled"
            leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
            className="text-nowrap"
          >
            {upperFirst(
              isPromotionWithOffer
                ? promoMessageToDisplay?.toLowerCase()
                : "Promo",
            )}
            {(() => {
              const dateFormatter = new Intl.DateTimeFormat("fr-FR", {
                day: "2-digit",
                month: "2-digit",
              })
              if (
                data.promotion_start_date &&
                data.date_integration &&
                new Date(data.date_integration) <
                  new Date(data.promotion_start_date)
              ) {
                return ` - Début le ${dateFormatter.format(
                  new Date(data.promotion_start_date),
                )}`
              } else if (
                data.date_integration &&
                data.promotion_end_date &&
                new Date(data.date_integration) <
                  new Date(data.promotion_end_date)
              ) {
                return ` - Fin le ${dateFormatter.format(
                  new Date(data.promotion_end_date),
                )}`
              }
              return ""
            })()}
          </Label>
        </div>
      )}
      {!data.typology_flag && (
        <Badge
          variant="secondary"
          className="text-xs font-medium bg-amber-100 text-amber-800 border border-amber-200  whitespace-nowrap hover:bg-amber-100"
        >
          <ShoppingCart className="w-4 h-4 mr-2" />
          GAMME COMPLEMENTAIRE
        </Badge>
      )}
    </div>
  )
}
