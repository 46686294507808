"use client"

import { ArrowNextIcon } from "../../ui/icons/ArrowNextIcon"
import { ArrowBackIcon } from "../../ui/icons/ArrowBackIcon"
import { Link } from "react-router-dom"
import { ArrowRightIcon } from "../../ui/icons/ArrowRightIcon"
import { GetCalendarQuery } from "../../utils/__generated__/graphql"
import { Button } from "../ui/button"
import { DateTime } from "luxon"
import { Card } from "../ui/card"
import { Badge } from "../ui/badge"

interface CalendarMobileProps {
  companyName: string | null
  currentDate: DateTime
  setCurrentDate: (date: DateTime) => void
  data: GetCalendarQuery | undefined
  allDays: DateTime[]
  storeCurrency: string | null
}

interface CalendarHeaderProps {
  currentDate: DateTime
  setCurrentDate: (date: DateTime) => void
}

interface DaysProps {
  allDays: DateTime[]
  currentDate: DateTime
  setCurrentDate: (date: DateTime) => void
  data: GetCalendarQuery | undefined
}

interface InfosProps {
  currentDate: DateTime
  data: GetCalendarQuery | undefined
  storeCurrency: string | null
}

const CalendarHeaderMobile = ({
  currentDate,
  setCurrentDate,
}: CalendarHeaderProps) => {
  const today = DateTime.now()
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ]
  const handleChangeMonth = (delta: number) => {
    const newDate = currentDate.plus({ months: delta }).startOf("month")
    if (newDate.hasSame(today, "month")) {
      setCurrentDate(today)
    } else {
      setCurrentDate(newDate)
    }
  }
  return (
    <div className="mb-4 flex items-center justify-between p-3">
      <p className="font-semibold capitalize text-xl text-zinc-800">
        {months[currentDate.month - 1]} {currentDate.year}
      </p>
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setCurrentDate(today)}
        >
          Aujourd&apos;hui
        </Button>
        <div className="flex gap-2">
          <div onClick={() => handleChangeMonth(-1)} className="cursor-pointer">
            <ArrowBackIcon className="w-5 h-5" />
          </div>
          <div onClick={() => handleChangeMonth(1)} className="cursor-pointer">
            <ArrowNextIcon className="w-5 h-5" />
          </div>
        </div>
      </div>
    </div>
  )
}
const WeekdaysMobile = () => {
  const weekDays = ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"]
  return (
    <div className="grid grid-cols-7 gap-1 text-center">
      {weekDays.map((day) => (
        <div
          key={day}
          className="py-2 text-sm font-medium text-muted-foreground"
        >
          {day}
        </div>
      ))}
    </div>
  )
}

const DaysMobile = ({
  allDays,
  currentDate,
  setCurrentDate,
  data,
}: DaysProps) => {
  const handleChangeDay = (day: DateTime) => {
    setCurrentDate(day)
  }
  return (
    <div className="grid grid-cols-7 gap-1 text-center">
      {allDays.map((day, index) => {
        const info = data?.getCalendar.calendar?.find((item) => {
          if (typeof item?.date !== "string") return false
          const date = DateTime.fromISO(item.date)
          return day.hasSame(date, "day")
        })
        const isCurrentDate = day.hasSame(currentDate, "day")
        // Multi-dot logic (holiday, order items, sales)
        const dots = []
        if (info?.holidayReason) {
          dots.push(
            <div key="holiday" className="w-1 h-1 bg-blue-500 rounded-full" />,
          )
        }
        if (info?.schoolHolidaysReason) {
          dots.push(
            <div
              key="school-holiday"
              className="w-1 h-1 bg-red-500 rounded-full"
            />,
          )
        }
        if (info?.orderItems && info.orderItems.length > 0) {
          dots.push(
            ...info.orderItems.map((orderItem) => (
              <div
                key={orderItem.dimOrderRequestId}
                className="w-1 h-1 bg-gray-400 rounded-full"
              />
            )),
          )
        }
        if (
          typeof info?.dailyCA === "number" ||
          typeof info?.dailyParcelCount === "number"
        ) {
          dots.push(
            <div key="sales" className="w-1 h-1 bg-green-800 rounded-full" />,
          )
        }
        return (
          <Button
            key={index}
            variant={isCurrentDate ? "default" : "ghost"}
            className={`h-12 hover:bg-muted ${
              isCurrentDate
                ? "bg-primary text-primary-foreground hover:bg-primary/90"
                : ""
            }`}
            onClick={() => handleChangeDay(day)}
          >
            <div className="flex flex-col items-center">
              <span>{day.day}</span>
              {dots.length > 0 && (
                <div className="mt-1 flex gap-0.5">{dots}</div>
              )}
            </div>
          </Button>
        )
      })}
    </div>
  )
}

const InfosMobile = ({ currentDate, data, storeCurrency }: InfosProps) => {
  const currencyCode = storeCurrency ?? "EUR"
  const currentData = data?.getCalendar.calendar?.find((dataItem) => {
    if (!dataItem) return false
    const infoDate = DateTime.fromISO(dataItem.date)
    return infoDate.hasSame(currentDate, "day")
  })

  if (!data) {
    return (
      <div className="flex gap-4 p-4">
        <Card className="p-4 flex-1">
          <div className="text-sm text-muted-foreground">
            CA Potentiel Commandé
          </div>
          <div className="mt-1 h-10 animate-pulse bg-gray-200 rounded"></div>
        </Card>
        <Card className="p-4 flex-1">
          <div className="text-sm text-muted-foreground">CA Réalisé</div>
          <div className="mt-1 h-10 animate-pulse bg-gray-200 rounded"></div>
        </Card>
      </div>
    )
  }

  const caRealise =
    typeof currentData?.dailyCA === "number" ? currentData.dailyCA : 0

  const cards = []
  if ((currentData?.orderItems ?? []).length > 0) {
    cards.push(
      ...(currentData?.orderItems.map((orderItem) => (
        <Card key={orderItem.dimOrderRequestId} className="p-4 flex-1">
          <Link
            key={orderItem.dimOrderRequestId}
            to={`/order/recap?orderId=${orderItem.dimOrderRequestId}`}
            className="w-full flex items-center gap-2"
          >
            <div className="flex flex-col">
              <div className="text-sm text-muted-foreground">
                CA Potentiel Commandé
              </div>
              <div className="mt-1 text-2xl font-semibold">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: currencyCode,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(orderItem.potentialCA)}
              </div>
            </div>
            <ArrowRightIcon className="w-8 h-8" />
          </Link>
        </Card>
      )) ?? []),
    )
  }
  if (caRealise !== 0) {
    cards.push(
      <Card key="realise" className="p-4 flex-1">
        <div className="text-sm text-muted-foreground">CA Réalisé</div>
        <div className="mt-1 text-2xl font-semibold">
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(caRealise)}
        </div>
      </Card>,
    )
  }

  const HolidayReason = () => {
    return (
      <Badge className="w-fit bg-blue-20 hover:bg-blue-30 border-blue-50 text-blue-900">
        {currentData?.holidayReason}
      </Badge>
    )
  }

  const SchoolHolidayReason = () => {
    return (
      <Badge className="w-fit bg-red-20 hover:bg-red-30 border-red-50 text-red-900">
        {currentData?.schoolHolidaysReason}
      </Badge>
    )
  }

  return (
    <>
      <div className="flex gap-2">
        {typeof currentData?.holidayReason === "string" && <HolidayReason />}
        {typeof currentData?.schoolHolidaysReason === "string" && (
          <SchoolHolidayReason />
        )}
      </div>
      <div className="bg-white shadow rounded-lg p-4">
        {cards.length > 0 ? (
          <div className="flex gap-4 overflow-x-auto">{cards}</div>
        ) : (
          <p className="text-center text-gray-500">Aucun évènement</p>
        )}
      </div>
    </>
  )
}

const CalendarMobile = ({
  currentDate,
  setCurrentDate,
  data,
  allDays,
  storeCurrency,
}: CalendarMobileProps) => {
  return (
    <div className="flex flex-col gap-2 h-full justify-center">
      <div className="shadow rounded-lg p-2 bg-white">
        <CalendarHeaderMobile
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
        <WeekdaysMobile />
        <DaysMobile
          allDays={allDays}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          data={data}
        />
      </div>
      <InfosMobile
        currentDate={currentDate}
        data={data}
        storeCurrency={storeCurrency}
      />
      <div className="h-[72px] md:hidden" />
    </div>
  )
}

export default CalendarMobile
