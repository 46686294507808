import { useState } from "react"
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts"
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu"
import { Loader2 } from "lucide-react"
import { useGetWeeksEvolutionQuery } from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

interface GroupedWeeks {
  month: string
  weeks: {
    weekNumber: number
    isCurrentYear: boolean
  }[]
}

const getWeekInfo = (
  weekNumber: number,
  year: number,
): {
  month: string
  weekStartDate: Date
} => {
  const startOfYear = new Date(year, 0, 1)
  const weekStartDate = new Date(startOfYear)
  weekStartDate.setDate(startOfYear.getDate() + (weekNumber - 1) * 7)

  return {
    month: weekStartDate.toLocaleString("fr-FR", { month: "long" }),
    weekStartDate,
  }
}

const getCurrentWeekNumber = () => {
  const now = new Date()
  const start = new Date(now.getFullYear(), 0, 1)
  const diff = (now.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
  return Math.ceil((diff + start.getDay() + 1) / 7)
}

const getAvailableWeeks = () => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentWeek = getCurrentWeekNumber()
  const twelveMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 11, 1)
  const weeks = []

  for (let week = 1; week <= currentWeek; week++) {
    const { weekStartDate } = getWeekInfo(week, currentYear)
    if (weekStartDate >= twelveMonthsAgo) {
      weeks.push({ weekNumber: week, year: currentYear })
    }
  }

  if (twelveMonthsAgo.getFullYear() < currentYear) {
    for (let week = 1; week <= 52; week++) {
      const { weekStartDate } = getWeekInfo(week, currentYear - 1)
      if (weekStartDate >= twelveMonthsAgo) {
        weeks.push({ weekNumber: week, year: currentYear - 1 })
      }
    }
  }

  return weeks.sort((a, b) => {
    if (a.year !== b.year) return b.year - a.year
    return b.weekNumber - a.weekNumber
  })
}

export const EvolutionOfTheWeek = () => {
  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const isMD = window.innerWidth >= 768

  const availableWeeks = getAvailableWeeks()
  const defaultSelectedWeeks = availableWeeks
    .slice(0, 4)
    .map((week) => week.weekNumber)
    .filter(Boolean)

  const [selectedWeeks, setSelectedWeeks] = useState(defaultSelectedWeeks)

  const { data, loading } = useGetWeeksEvolutionQuery({
    variables: {
      input: {
        store_id: storeId!,
        week_selected:
          selectedWeeks.length > 0
            ? selectedWeeks
            : availableWeeks.map((w) => w.weekNumber),
      },
    },
  })

  const toggleWeekSelection = (week: number) => {
    setSelectedWeeks((prev) =>
      prev.includes(week) ? prev.filter((w) => w !== week) : [...prev, week],
    )
  }

  const selectAllWeeks = () => {
    if (selectedWeeks.length === availableWeeks.length) {
      setSelectedWeeks(defaultSelectedWeeks)
    } else {
      setSelectedWeeks(availableWeeks.map((w) => w.weekNumber))
    }
  }

  const groupWeeksByMonth = () => {
    const grouped: Record<string, GroupedWeeks["weeks"]> = {}

    availableWeeks.forEach(({ weekNumber, year }) => {
      const { month } = getWeekInfo(weekNumber, year)
      if (!grouped[month]) {
        grouped[month] = []
      }
      grouped[month].push({
        weekNumber,
        isCurrentYear: year === new Date().getFullYear(),
      })
    })

    return Object.entries(grouped).map(([month, weeks]) => ({
      month,
      weeks: weeks.sort((a, b) => b.weekNumber - a.weekNumber),
    }))
  }

  const chartData =
    data?.weeksEvolution.weeks_evolution
      .map((item) => {
        const { month } = getWeekInfo(
          item.week_number,
          new Date().getFullYear(),
        )
        const currentMonth = new Date().toLocaleString("fr-FR", {
          month: "long",
        })
        return {
          week:
            month === currentMonth
              ? `S${item.week_number}`
              : `S${item.week_number} : ${month}`,
          "Commandes (PV TTC)": item.total_order_price
            ? Math.round(item.total_order_price)
            : 0,
          "Chiffre d'affaires (PV TTC)": item.revenue
            ? Math.round(item.revenue)
            : 0,
          week_number: item.week_number,
        }
      })
      .sort((a, b) => a.week_number - b.week_number) ?? []

  const monthsWithWeeks = groupWeeksByMonth()

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>
          {isMD ? "Réceptions / ventes par semaine" : "Semaines"}
        </CardTitle>
        <CardDescription>
          Comparez vos réceptions et votre chiffre d&apos;affaires par semaine
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="pb-4">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                {selectedWeeks.length === availableWeeks.length ? (
                  <>
                    <span className="text-gray-500">
                      Sélectionner les semaines
                    </span>{" "}
                    <span className="text-black">Toutes les semaines</span>
                  </>
                ) : selectedWeeks.length > (isMD ? 10 : 4) ? (
                  <>
                    <span className="text-gray-500">
                      Semaines sélectionnées
                    </span>{" "}
                    <span className="text-black">
                      {selectedWeeks.slice(0, isMD ? 10 : 4).join(", ")}...
                    </span>
                  </>
                ) : selectedWeeks.length > 0 ? (
                  <>
                    <span className="text-gray-500">
                      Semaines sélectionnées
                    </span>{" "}
                    <span className="text-black">
                      {selectedWeeks.join(", ")}
                    </span>
                  </>
                ) : (
                  <span className="text-gray-500">
                    Sélectionner les semaines
                  </span>
                )}
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="max-h-64 overflow-y-auto">
              <Button
                className="w-full text-left px-4 py-2"
                variant="ghost"
                onClick={selectAllWeeks}
              >
                {selectedWeeks.length === availableWeeks.length
                  ? "Sélectionner les 4 dernières semaines"
                  : "Sélectionner toutes les semaines"}
              </Button>
              {monthsWithWeeks.map(({ month, weeks }) => (
                <div key={month} className="mb-2">
                  <h3 className="font-bold text-sm px-4 py-2">{month}</h3>
                  {weeks.map(({ weekNumber }) => (
                    <DropdownMenuCheckboxItem
                      key={weekNumber}
                      checked={selectedWeeks.includes(weekNumber)}
                      onCheckedChange={() => toggleWeekSelection(weekNumber)}
                    >
                      Semaine {weekNumber}
                    </DropdownMenuCheckboxItem>
                  ))}
                </div>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {!loading ? (
          <div className="w-full h-64">
            <ResponsiveContainer>
              <BarChart
                data={chartData}
                margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="week" />
                <YAxis
                  label={{
                    angle: -90,
                    position: "insideLeft",
                    style: {
                      textAnchor: "middle",
                      fontSize: "12px",
                      fill: "#555",
                    },
                  }}
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: storeCurrency ?? "EUR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(value)
                  }
                />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="Commandes (PV TTC)"
                  fill="#CC1422"
                  radius={4}
                  className="border-black border-2 border-solid"
                >
                  <LabelList
                    dataKey="Commandes (PV TTC)"
                    position="top"
                    formatter={(value: number) =>
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: storeCurrency ?? "EUR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(value)
                    }
                  />
                </Bar>
                <Bar
                  dataKey="Chiffre d'affaires (PV TTC)"
                  fill="#6F6F6F"
                  radius={4}
                  className="border-black border-2 border-solid"
                >
                  <LabelList
                    dataKey="Chiffre d'affaires (PV TTC)"
                    position="top"
                    formatter={(value: number) =>
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: storeCurrency ?? "EUR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(value)
                    }
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className="flex justify-center items-center mt-20 p-4">
            <Loader2 className="w-10 h-10 animate-spin" />
          </div>
        )}
      </CardContent>
    </Card>
  )
}
