import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { Card } from "@/components/ui/card"
import { ProductImgAndName } from "@/components/molecules/productImgAndName"
import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom"
import { StateType } from "@/types"
import { useSelector } from "react-redux"

interface NotAvailableProductsProps {
  notAvailableProducts: AllMercurialInfo[]
}

export const NotAvailableProducts = ({
  notAvailableProducts,
}: NotAvailableProductsProps) => {
  const navigate = useNavigate()
  const { companyName } = useSelector((state: StateType) => state.storeReducer)
  return (
    <div className="flex flex-col gap-2">
      <p className="text-lg font-bold">
        Références indisponibles à la commande
      </p>
      <div className="flex flex-wrap gap-2">
        {notAvailableProducts.map((product) => (
          <Card
            key={product.mercuriale_id}
            className={`flex flex-col w-full md:max-w-[350px] justify-between items-start p-2 gap-4 `}
          >
            <ProductImgAndName product={product} isOnline={true} />
          </Card>
        ))}
      </div>
      <div className="flex gap-2 items-center">
        <Button
          onClick={() => {
            navigate("/order")
          }}
        >
          Retour à la {companyName === "auchan" ? "commande" : "mercuriale"}
        </Button>
      </div>
    </div>
  )
}
