import { useSaveData } from "@/hooks/useSaveData"
import { StateType } from "@/types"
import { SaveAlertIcon } from "@/ui/AlertIcons"
import { Button } from "@/ui/Button"
import { Modal } from "@/ui/Modal"
import { Dispatch, SetStateAction } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export interface SaveDataModalState {
  isOpen: boolean
  to?: string
}

interface SaveDataModalProps {
  saveDataModalState: SaveDataModalState
  setSaveDataModalState: Dispatch<SetStateAction<SaveDataModalState>>
}

export function RetrySaveModal({
  saveDataModalState,
  setSaveDataModalState,
}: SaveDataModalProps) {
  const navigate = useNavigate()
  const { saveData, isLoading } = useSaveData()

  const modifications = useSelector(
    (state: StateType) => state.mercurialReducer.modifications,
  )

  return (
    <Modal
      icon={<SaveAlertIcon />}
      title="Tentative de sauvegarde échouée"
      open={saveDataModalState.isOpen}
      hideCloseButton
      onClose={() => {
        setSaveDataModalState({ isOpen: false })
      }}
    >
      <p className="text-[#667085] text-sm font-normal text-center">
        Votre connexion internet est instable. Veuillez résoudre vos problèmes
        de réseau et réessayer de sauvegarder vos données en cliquant sur le
        bouton ci-dessous.
      </p>
      <Button
        onClick={async () => {
          if (modifications.length <= 0) return

          await saveData()

          setSaveDataModalState({ isOpen: false })
          if (typeof saveDataModalState.to === "string") {
            navigate(saveDataModalState.to)
          }
        }}
        loading={isLoading}
        className="w-full h-11 text-base mt-4 md:mt-8"
      >
        Réessayer
      </Button>
      <Button
        onClick={() => {
          setSaveDataModalState({ isOpen: false })
          if (typeof saveDataModalState.to === "string") {
            navigate(saveDataModalState.to)
          }
        }}
        className="w-full h-11 text-base"
        color="ghost"
      >
        Annuler
      </Button>
    </Modal>
  )
}
