"use client"

import { useMemo, useEffect } from "react"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import Skeleton from "react-loading-skeleton"
import { ChartBarIcon, TrendingUpIcon } from "lucide-react" // <-- Added TrendingUpIcon
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useGetBreakageStoresInfoQuery } from "@/utils/__generated__/graphql" // NEW HOOK!
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { format, parseISO } from "date-fns"
import { PerformancesPageContext } from "../../PerformancesPage"

export default function BreakagesWeeklyOverviewDashboard() {
  const { selectedStoreId, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { data, loading, error, refetch } = useGetBreakageStoresInfoQuery({
    variables: {
      input: {
        company_id: companyId ?? "",
        franchise_name: franchiseParam,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        store_id: storeId!,
      },
    },
    fetchPolicy: "cache-and-network",
  })

  // Refetch when dependencies change
  useEffect(() => {
    refetch({
      input: {
        company_id: companyId ?? "",
        franchise_name: franchiseParam,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        store_id: storeId!,
      },
    })
  }, [
    selectedStoreId,
    fromDate,
    toDate,
    refetch,
    companyId,
    franchiseParam,
    storeId,
  ])

  const weeklyData = useMemo(() => {
    return data?.getBreakagesStoresInfo?.breakage_info ?? []
  }, [data])

  const dataByStore = useMemo(() => {
    const mapping: Record<
      string,
      Record<string, { amount: number; percentage: number }>
    > = {}
    weeklyData.forEach((record) => {
      const store = record.store_name
      const week = record.breakage_week
      const amount = record.breakage_amount_week
      const percentage = record.breakage_percentage_week * 100
      if (!mapping[store]) {
        mapping[store] = {}
      }
      mapping[store][week] = { amount, percentage }
    })
    return mapping
  }, [weeklyData])

  const weeks = useMemo(() => {
    const weekSet = new Set<string>()
    Object.values(dataByStore).forEach((storeData) => {
      Object.keys(storeData).forEach((week) => {
        weekSet.add(week)
      })
    })
    return Array.from(weekSet).sort()
  }, [dataByStore])

  const lastWeek = weeks.length > 0 ? weeks[weeks.length - 1] : ""

  const stores = useMemo(() => {
    const unsorted = Object.keys(dataByStore)
    return unsorted.sort((a, b) => {
      const percA = dataByStore[a][lastWeek]?.percentage || 0
      const percB = dataByStore[b][lastWeek]?.percentage || 0
      return percB - percA
    })
  }, [dataByStore, lastWeek])

  if (loading) {
    return (
      <div className="p-6 px-2 min-w-full mx-auto">
        <Card className="animate-pulse">
          <CardHeader className="flex items-center justify-between p-4">
            <div className="flex items-center space-x-2">
              <Skeleton className="h-6 w-6 rounded-full" />
              <Skeleton className="h-6 w-32" />
            </div>
          </CardHeader>
          <CardContent>
            <Skeleton className="h-64 w-full" />
          </CardContent>
        </Card>
      </div>
    )
  }

  if (error) {
    return (
      <div className="p-6 max-w-4xl mx-auto text-red-500">
        Erreur lors du chargement des données.
      </div>
    )
  }

  return (
    <div className="p-6 px-2 min-w-full mx-auto">
      <Card className="shadow-lg">
        <CardHeader className="flex items-center justify-between bg-black text-white p-4 rounded-t">
          <div className="flex items-center space-x-2">
            <ChartBarIcon className="w-6 h-6" />
            <CardTitle className="text-lg font-bold">
              Evolution hebdomadaire de la démarque connue
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-4">
          <div className="overflow-x-auto">
            <Table className="min-w-full">
              <TableHeader>
                <TableRow>
                  <TableHead className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Magasin
                  </TableHead>
                  {weeks.map((week) => {
                    let formattedWeek = week
                    try {
                      formattedWeek = format(parseISO(week), "'Semaine' I yyyy")
                    } catch (e) {
                      formattedWeek = "Inconnu"
                    }
                    return (
                      <TableHead
                        key={week}
                        className="px-4 py-2 text-right text-sm font-semibold text-gray-700"
                      >
                        {formattedWeek}
                      </TableHead>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {stores.map((store) => {
                  // Get the data for the last week to check for high percentages.
                  const lastWeekData = dataByStore[store][lastWeek]
                  const rowHighlight =
                    lastWeekData && lastWeekData.percentage > 10

                  // Check if the trend is increasing across the weeks.
                  // We first extract all available percentages in the weeks order.
                  const percentages = weeks
                    .map((week) => dataByStore[store][week]?.percentage)
                    .filter((p) => p !== undefined) as number[]

                  const trendIncreasing =
                    percentages.length > 1 &&
                    percentages.every(
                      (val, i, arr) => i === 0 || val > arr[i - 1],
                    )

                  return (
                    <TableRow
                      key={store}
                      className={`${
                        rowHighlight
                          ? "bg-slate-200"
                          : "odd:bg-white even:bg-gray-50"
                      }`}
                    >
                      <TableCell className="px-4 py-2 text-sm font-medium text-gray-800 flex items-center">
                        {store}
                        {trendIncreasing && (
                          <TrendingUpIcon className="ml-2 w-5 h-5 text-red-500" />
                        )}
                      </TableCell>
                      {weeks.map((week) => {
                        const cellData = dataByStore[store][week]
                        return (
                          <TableCell
                            key={week}
                            className={`px-4 py-2 text-sm text-gray-600 text-right ${
                              cellData && cellData.percentage > 10
                                ? "text-red-600 font-semibold"
                                : ""
                            }`}
                          >
                            {cellData
                              ? cellData.percentage > 30
                                ? "€" + cellData.amount.toFixed(0)
                                : "€" +
                                  cellData.amount.toFixed(0) +
                                  " (" +
                                  cellData.percentage.toFixed(0) +
                                  "%)"
                              : "€0 (0%)"}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
