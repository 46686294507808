import { Navigate, createBrowserRouter } from "react-router-dom"
import Root from "./Root"
import NotFound from "../pages/NotFound"
import { OrderPage } from "../pages/OrderPage/OrderPage"
import CalendarPage from "../pages/CalendarPage"
import RecapPage from "../pages/recapPage/RecapPage"
import SettingsPage from "../pages/SettingsPage"
import { Admin } from "../pages/admin"
import { StoresPage } from "../pages/admin/StoresPage"
import { RedirectToStorePage } from "../pages/admin/RedirectToStorePage"
import PermissionsProtectedRoute from "./PermissionsProtectedRoute"
import CallbackRoute from "./CallbackRoute"
import { LogoutPage } from "../pages/LogoutPage"
import { CategoriesPage } from "../pages/CategoriesPage"
import { OrderRoot } from "../components/order/OrderRoot"
import { RedirectToPage } from "../pages/RedirectToPage"
import { StoreScheduler } from "../pages/admin/StoreScheduler"
import { UsersPage } from "@/pages/admin/UsersPage"
import { EngagementPromoPage } from "@/pages/engagementPromoPage/EngagementPromoPage"
import { PerformancesPage } from "@/pages/admin/PerformancesPage/PerformancesPage"
import { NoStoreLinkedPage } from "@/pages/NoStoreLinkedPage"
import { HomePage } from "@/pages/HomePage"
import { MessagesPage } from "@/pages/admin/MessagesPage/MessagesPage"
import { RegroupingIdaPage } from "@/pages/admin/MatchingPage/RegroupingIdaPage"
import { CompaniesPage } from "@/pages/admin/CompaniesPage"
import { InventoryPerformancesPage } from "@/pages/admin/PerformancesPage/pages/InventoryPerformancesPage"
import { PredictedCaVsSalesPerformancesPage } from "@/pages/admin/PerformancesPage/pages/PredictedCaVsSalesPerformancesPage"
import { OrderProvider } from "@/contexts/OrderContext"
import InventoriesAdmin from "@/pages/admin/PerformancesPage/components/inventories/InventoriesAdmin"
import OrderDashPage from "@/pages/admin/PerformancesPage/pages/OrderDashPage"
import PotentiallyRupturesAdmin from "@/pages/admin/PerformancesPage/components/rupture/PotentiallyRupturesAdmin"
import OrdersRegroupingTable from "@/pages/admin/MatchingPage/orders_regrouping_components/ordersRegroupingTable"
import UnmatchedReferencesTable from "@/pages/admin/MatchingPage/matching_dashboard_components/unmatchedReferencesTable"
import { MatchReferencesSalesPage } from "@/pages/admin/MatchingPage/sale_regrouping_components/MatchReferencesSalesPage"
import BreakagesWeeklyEvolutionDashboard from "@/pages/admin/PerformancesPage/components/breakages/BreakageWeeklyEvolutionPage"
import { MatchingPage } from "@/pages/MatchingPage"
import Header from "@/components/header"
import { ComparisonParcsPage } from "@/pages/ComparisonParcsPage"
import ModificationSummaryDashboard from "@/pages/admin/PerformancesPage/pages/ScoringPerformancePage"
import { CAPerformancesPage } from "@/pages/admin/PerformancesPage/components/performance/CAPerformancesPage"
import { SuccessPage } from "@/pages/recapPage/SuccessPage"
import { RootRecap } from "@/pages/recapPage/RootRecap"
import { DetailedBreakdown } from "@/pages/admin/PerformancesPage/components/scoring/DetailedBreakdown"
import { ReferenceDetailsView } from "@/pages/admin/PerformancesPage/components/scoring/ReferenceDetailsView"

const router = createBrowserRouter([
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "callback",
    element: <CallbackRoute />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <RedirectToPage to="/order" />,
      },
      {
        path: "home",
        element: <HomePage />,
      },
      {
        path: "recap",
        element: <RedirectToPage to="/order/recap" />,
      },
      {
        path: "inventory",
        element: <RedirectToPage to="/order" />,
      },
      {
        path: "inventory/recap",
        element: <RedirectToPage to="/order/recap" />,
      },
      {
        path: "order",
        element: <OrderRoot />,
        children: [
          {
            path: "",
            element: (
              <OrderProvider>
                <OrderPage />
              </OrderProvider>
            ),
          },
          {
            path: "recap",
            element: <RootRecap />,
            children: [
              {
                path: "",
                element: <RecapPage />,
              },
              {
                path: "success",
                element: <SuccessPage />,
              },
            ],
          },
        ],
      },
      {
        path: "matching",
        element: <MatchingPage />,
      },
      {
        path: "engagement-promo",
        element: <EngagementPromoPage />,
      },
      {
        path: "account/*",
        element: <Navigate to="/home" />,
      },
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },
      {
        path: "comparison-parcs",
        element: <ComparisonParcsPage />,
      },
      {
        path: "categories",
        element: <CategoriesPage />,
      },
      {
        path: "performances",
        element: (
          <div className="flex flex-col">
            <Header />
            <div className="w-full pt-2 pb-8">
              <PerformancesPage />
            </div>
          </div>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="taux-de-modification" />,
          },
          {
            path: "taux-de-modification",
            element: <OrderDashPage />,
          },
        ],
      },
      {
        path: "admin",
        element: (
          <PermissionsProtectedRoute permissions={["arolya:company:admin"]}>
            <Admin />
          </PermissionsProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="companies" />,
          },
          {
            path: "users",
            element: (
              <PermissionsProtectedRoute permissions={["ida:admin"]}>
                <UsersPage />
              </PermissionsProtectedRoute>
            ),
          },
          {
            path: "companies",
            children: [
              {
                path: ":companyId",
                element: <StoresPage />,
              },
              {
                path: ":companyId/store-scheduler",
                element: <StoreScheduler />,
              },
              {
                path: ":companyId/regrouping-ida",
                element: <RegroupingIdaPage />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="matching" />,
                  },
                  {
                    path: "matching",
                    element: <UnmatchedReferencesTable />,
                  },
                  {
                    path: "orders-regrouping",
                    element: <OrdersRegroupingTable />,
                  },
                  {
                    path: "sales-matching",
                    element: <MatchReferencesSalesPage />,
                  },
                ],
              },
              {
                path: ":companyId/performances",
                element: <PerformancesPage />,
                children: [
                  {
                    path: "",
                    element: <Navigate to="taux-de-modification" />,
                  },
                  {
                    path: "taux-de-modification",
                    element: <OrderDashPage />,
                  },
                  {
                    path: "scoring-prediction",
                    element: <ModificationSummaryDashboard />,
                  },
                  {
                    path: "scoring-prediction/details/:week/:modificationType",
                    element: <DetailedBreakdown />,
                  },
                  {
                    path: "scoring-prediction/reference-details/:referenceId/:issueType",
                    element: <ReferenceDetailsView />,
                  },
                  {
                    path: "indicators",
                    element: <InventoryPerformancesPage />,
                    children: [
                      { path: "", element: <Navigate to="by-store" /> },
                      {
                        path: "level-stock",
                        element: <InventoriesAdmin />,
                      },
                      {
                        path: "breakage",
                        element: <BreakagesWeeklyEvolutionDashboard />,
                      },
                      {
                        path: "potentially-ruptures",
                        element: <PotentiallyRupturesAdmin />,
                      },
                      {
                        path: "performance-board-ca",
                        element: <CAPerformancesPage />,
                      },
                    ],
                  },
                  {
                    path: "predicted-ca-vs-sales",
                    element: <PredictedCaVsSalesPerformancesPage />,
                  },
                ],
              },
              {
                path: ":companyId/messages",
                element: <MessagesPage />,
              },
              {
                path: "",
                element: <CompaniesPage />,
              },
            ],
          },
        ],
      },
      {
        path: "stores/:storeId",
        element: (
          <PermissionsProtectedRoute permissions={["arolya:company:admin"]}>
            <RedirectToStorePage />
          </PermissionsProtectedRoute>
        ),
      },
      {
        path: "no-store-linked",
        element: <NoStoreLinkedPage />,
      },
    ],
  },
])

export default router
