import { Checkbox } from "@/components/ui/checkbox"
import { useMemo } from "react"
import { useDrag } from "react-dnd"
import { twJoin } from "tailwind-merge"
import { DragPayload, ReferencesForMatching } from "./types"
import { getDisplayedUnit } from "@/utils/getUnit"

// Draggable unmatched item
interface UnmatchedReferenceCardProps {
  refItem: ReferencesForMatching[number]
  checked: boolean
  onToggleSelected: () => void
  rowSelection: Record<string, boolean>
  unmatched: ReferencesForMatching
  anyIsDragging: boolean
  dragType: string
  companyName: string
  franchise: string | undefined
}

export function UnmatchedReferenceCard({
  refItem,
  checked,
  onToggleSelected,
  rowSelection,
  unmatched,
  anyIsDragging,
  dragType,
  companyName,
  franchise,
}: UnmatchedReferenceCardProps) {
  // Gather multi-drag items if this is selected
  const itemsToDrag = useMemo(() => {
    if (checked) {
      return unmatched.filter((u) => rowSelection[u.sale_name_ida_base])
    }
    return [refItem]
  }, [checked, unmatched, rowSelection, refItem])

  const [{ isDragging }, dragRef] = useDrag<
    DragPayload,
    void,
    { isDragging: boolean }
  >({
    type: dragType,
    item: { items: itemsToDrag },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const shouldBlur = anyIsDragging && rowSelection[refItem.sale_name_ida_base]

  return (
    <div
      ref={dragRef}
      className={twJoin(
        "cursor-move mb-2 rounded border flex items-start gap-2 p-2 transition-colors",
        isDragging || shouldBlur
          ? "border-blue-400 border-dashed bg-blue-50 opacity-50"
          : "border-gray-300 bg-white opacity-100",
      )}
    >
      <Checkbox checked={checked} onCheckedChange={onToggleSelected} />
      <div className="flex-1">
        <p className="text-sm font-medium break-all">{refItem.libelle_base}</p>
        <div className="flex justify-between text-xs text-gray-600">
          <p>{refItem.sale_name_ida_base}</p>
          <p>{getDisplayedUnit(refItem.unit, companyName, franchise)}</p>
        </div>
      </div>
    </div>
  )
}
