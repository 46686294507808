"use client"

import { useEffect, useMemo, useState, useCallback } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  useGetReferenceWithRegroupingIdaQuery,
  useCreateRegroupingIdaMutation,
  useDeleteRegroupingIdaMutation,
  useGetLastMonthUnmatchedReferencesQuery,
} from "@/utils/__generated__/graphql"

import { StateType } from "@/types"
import { toast } from "sonner"
import { Link2, Square, Trash } from "lucide-react"

// For drag-and-drop
import { useDragLayer, DragLayerMonitor } from "react-dnd"

// Your existing components
import BatchMatchReferenceDialog from "./BatchMatchReferenceDialog"
import EditReferenceDialog from "./EditReferenceDialog"

// UI imports
import { Button } from "@/components/ui/button"
import { BatchDeleteReferenceDialog } from "./BatchDeleteReferenceDialog"
import { Spinner } from "@/ui/Spinner"
import { UnmatchedReferencesSidebar } from "../components/UnmatchedReferencesSidebar"
import { groupByMaster } from "../utils/groupByMaster"
import { MatchedReferencesContainer } from "../components/MatchedReferencesContainer"
import { CustomDragLayer } from "../components/CustomDragLayer"
import { ReferencesForMatching } from "../components/types"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

const DRAG_TYPE = "REFERENCE_ITEM"

// Hook to detect if anything is currently being dragged
function useAnyDragging() {
  const { isDragging } = useDragLayer((monitor: DragLayerMonitor) => ({
    isDragging: monitor.isDragging(),
  }))
  return isDragging
}

export function MatchReferencesSalesPage() {
  const {
    storeId,
    companyId: companyIdFromReducer,
    companyName,
    storeFranchise,
  } = useSelector((state: StateType) => state.storeReducer)

  const params = useParams()
  const [searchParams] = useSearchParams()
  const companyId = params.companyId ?? companyIdFromReducer
  const franchiseParam =
    params.companyId !== undefined
      ? (searchParams.get("franchise") ?? undefined)
      : (storeFranchise ?? undefined)

  const { data, loading, error, refetch } =
    useGetReferenceWithRegroupingIdaQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: storeId!,
        },
      },
      fetchPolicy: "cache-and-network",
    })

  const [includeAllUnmatched, setIncludeAllUnmatched] = useState(false)

  const lastMonthReferencesWithRegroupingIda =
    useGetLastMonthUnmatchedReferencesQuery({
      variables: {
        input: {
          company_id: companyId!,
          franchise_name: franchiseParam,
          store_id: storeId!,
          include_all_unmatched: includeAllUnmatched,
        },
      },
      skip: params.companyId !== undefined,
      notifyOnNetworkStatusChange: true,
    })

  const [deleteRegroupingIda] = useDeleteRegroupingIdaMutation()
  const [createRegroupingIda] = useCreateRegroupingIdaMutation()

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des références.")
    }
  }, [error])

  // Convert GQL records -> local references
  const rawRecords = useMemo(
    () => data?.getReferenceWithRegroupingIda.records ?? [],
    [data],
  )
  const [localReferences, setLocalReferences] =
    useState<ReferencesForMatching>(rawRecords)

  useEffect(() => {
    setLocalReferences(rawRecords)
  }, [rawRecords])

  // Mark isMaster if server doesn't
  const referencesWithMasterFlag = useMemo(() => {
    return localReferences.map((ref) => {
      const isMaster = localReferences.some(
        (other) =>
          other.sale_name_ida_cible === ref.sale_name_ida_base &&
          other.sale_name_ida_cible !== "-",
      )
      return { ...ref, isMaster }
    })
  }, [localReferences])

  // Group them
  const { groups, unmatched: rawUnmatched } = useMemo(
    () => groupByMaster(referencesWithMasterFlag),
    [referencesWithMasterFlag],
  )

  const unmatched = useMemo(() => {
    if (params.companyId !== undefined) {
      return rawUnmatched
    }

    const lastMonthReferencesWithRegroupingIdaRecords =
      lastMonthReferencesWithRegroupingIda.data?.lastMonthUnmatchedReferences
        .records

    return rawUnmatched?.filter((ref) =>
      lastMonthReferencesWithRegroupingIdaRecords?.some(
        (lastMonthRef) =>
          lastMonthRef.sale_name_ida_base === ref.sale_name_ida_base,
      ),
    )
  }, [
    lastMonthReferencesWithRegroupingIda.data?.lastMonthUnmatchedReferences
      .records,
    params.companyId,
    rawUnmatched,
  ])

  // === Batch logic ===
  const [selectedRecords, setSelectedRecords] = useState<ReferencesForMatching>(
    [],
  )
  const [isBatchMatchDialogOpen, setIsBatchMatchDialogOpen] = useState(false)
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  function resetSelection() {
    setRowSelection({})
    setSelectedRecords([])
  }

  const selected = useMemo(() => {
    return referencesWithMasterFlag.filter(
      (record) => rowSelection[record.sale_name_ida_base],
    )
  }, [rowSelection, referencesWithMasterFlag])

  const allSelectedMatched =
    selected.length > 0 && selected.every((r) => r.is_matched)
  const allSelectedUnmatched =
    selected.length > 0 && selected.every((r) => !r.is_matched)

  const handleBatchMatch = useCallback(() => {
    if (!allSelectedUnmatched) {
      toast.error("Toutes les références sélectionnées doivent être non liées.")
      return
    }
    setSelectedRecords(selected)
    setIsBatchMatchDialogOpen(true)
  }, [allSelectedUnmatched, selected])

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  function handleBatchDelete() {
    if (!allSelectedMatched) {
      toast.error("Toutes les références sélectionnées doivent être liées.")
      return
    }
    setSelectedRecords(selected)
    setIsDeleteDialogOpen(true)
  }

  // Edit
  const [selectedEditReference, setSelectedEditReference] =
    useState<ReferencesForMatching[number]>()

  // Mixed selection
  const [hasShownMixedSelectionToast, setHasShownMixedSelectionToast] =
    useState(false)
  useEffect(() => {
    if (selected.length > 0) {
      const hasMatched = selected.some((r) => r.is_matched)
      const hasUnmatched = selected.some((r) => !r.is_matched)
      if (hasMatched && hasUnmatched && !hasShownMixedSelectionToast) {
        toast.warning(
          "Vous avez sélectionné à la fois des références liées et non liées.",
        )
        setHasShownMixedSelectionToast(true)
      }
    } else {
      setHasShownMixedSelectionToast(false)
    }
  }, [selected, hasShownMixedSelectionToast])

  // Are we dragging at all?
  const anyIsDragging = useAnyDragging()

  if (loading) {
    return (
      <div className="w-full flex-1 flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="flex flex-col flex-1 overflow-y-hidden p-2 space-y-2">
      {/* Top bar: batch actions */}
      <div className="flex items-center gap-2">
        <Button onClick={handleBatchMatch} disabled={!allSelectedUnmatched}>
          <Link2 className="size-4" />
          Lier (Batch)
        </Button>
        <Button
          variant="destructive"
          onClick={handleBatchDelete}
          disabled={!allSelectedMatched}
        >
          <Trash className="size-4" />
          Supprimer (Batch)
        </Button>
        <Button
          variant="outline"
          onClick={resetSelection}
          disabled={selected.length === 0}
        >
          <Square className="size-4" />
          Désélectionner
        </Button>
        {params.companyId === undefined && (
          <>
            <Label htmlFor="includeAllUnmatched">
              Inclure toutes les références
            </Label>
            <Switch
              id="includeAllUnmatched"
              checked={includeAllUnmatched}
              onCheckedChange={setIncludeAllUnmatched}
              className="data-[state=checked]:bg-green-800"
            />
          </>
        )}
      </div>

      <div className="flex-1 flex gap-2 overflow-auto">
        {/* Unmatched Column */}
        <UnmatchedReferencesSidebar
          unmatched={unmatched}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          localReferences={localReferences}
          setLocalReferences={setLocalReferences}
          anyIsDragging={anyIsDragging}
          dragType={DRAG_TYPE}
          onDelete={async (draggedRef) => {
            const { data } = await deleteRegroupingIda({
              variables: {
                input: {
                  sale_name_ida_base: draggedRef.sale_name_ida_base,
                  company_id: companyId!,
                  franchise_name: franchiseParam,
                  store_id: storeId!,
                },
              },
            })
            if (data?.deleteRegroupingIda?.success) {
              toast.success("Référence dé-liée avec succès (optimiste).")
            } else {
              throw new Error("Échec du dé-liage.")
            }
          }}
          companyName={companyName!}
          franchise={storeFranchise ?? undefined}
        />

        <MatchedReferencesContainer
          groups={groups}
          dragType={DRAG_TYPE}
          setSelectedRecords={setSelectedRecords}
          setIsBatchMatchDialogOpen={setIsBatchMatchDialogOpen}
          onCreate={async (main, child) => {
            const { data } = await createRegroupingIda({
              variables: {
                input: {
                  sale_name_ida_base: child.sale_name_ida_base,
                  sale_name_ida_cible: main.sale_name_ida_base,
                  libelle_base: child.libelle_base,
                  libelle_cible: main.libelle_base,
                  store_id: storeId!,
                  company_id: companyId!,
                  franchise_name: franchiseParam,
                  unit: child.unit,
                },
              },
            })
            if (data?.createRegroupingIda?.records) {
              toast.success("Référence liée avec succès (optimiste).")
            } else {
              throw new Error("Échec de la liaison.")
            }
          }}
          anyIsDragging={anyIsDragging}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          setLocalReferences={setLocalReferences}
          setSelectedEditReference={setSelectedEditReference}
          companyName={companyName!}
          franchise={storeFranchise ?? undefined}
        />
      </div>

      {/* Edit Dialog */}
      <EditReferenceDialog
        isOpen={selectedEditReference !== undefined}
        onClose={() => setSelectedEditReference(undefined)}
        record={selectedEditReference}
        companyId={companyId!}
        franchiseParam={franchiseParam!}
        storeId={storeId!}
        refetch={refetch}
      />

      {/* Batch Match */}
      <BatchMatchReferenceDialog
        isOpen={isBatchMatchDialogOpen}
        onClose={() => {
          setIsBatchMatchDialogOpen(false)
          resetSelection()
        }}
        selectedReferences={selectedRecords}
        allReferences={referencesWithMasterFlag}
        storeId={storeId!}
        companyId={companyId!}
        franchiseParam={franchiseParam}
        setLocalReferences={setLocalReferences}
      />

      {/* Delete Dialog */}
      <BatchDeleteReferenceDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false)
          resetSelection()
        }}
        selectedReferences={selectedRecords}
        companyId={companyId!}
        franchiseParam={franchiseParam!}
        storeId={storeId!}
        setLocalReferences={setLocalReferences}
      />

      {/* Custom Drag Layer for multi-drag visualization */}
      <CustomDragLayer />
    </div>
  )
}
