import React from "react"
import { ReactNode } from "react"
import { Separator } from "@/components/ui/separator"
import { usePermissions } from "@/hooks/usePermissions"

interface InventoryNavbarLayoutProps {
  dateElement: ReactNode
  logoElement: ReactNode
  testModeElement: ReactNode
  onlineStatusElement: ReactNode
  breadcrumbElement: ReactNode
  suppliersElement: ReactNode
  adminButtonElement?: ReactNode
  warningElement?: ReactNode
  notificationElement?: ReactNode
}

export const InventoryNavbarLayout: React.FC<InventoryNavbarLayoutProps> = ({
  logoElement,
  testModeElement,
  dateElement,
  breadcrumbElement,
  onlineStatusElement,
  suppliersElement,
  adminButtonElement,
  warningElement,
  notificationElement,
}) => {
  // Appel correct du hook usePermissions
  const { isIdaAdmin } = usePermissions()

  return (
    <div className="sticky top-0 z-10 bg-white border-b h-[52px] min-h-[52px]">
      <div className="flex flex-shrink-0 h-full justify-between items-center pr-4">
        {logoElement}

        {testModeElement}

        <Separator orientation="vertical" className="mr-2" />

        <div className="flex-1 overflow-y-hidden">{breadcrumbElement}</div>

        <div className="hidden lg:flex gap-1 items-center">
          {suppliersElement}
        </div>

        <Separator orientation="vertical" className="hidden lg:block mx-3" />

        <div className="md:flex text-xs lg:text-sm text-zinc-800 gap-2 relative">
          {dateElement}
        </div>

        {isIdaAdmin && adminButtonElement && (
          <>
            <Separator
              orientation="vertical"
              className="ml-4 mr-4 hidden md:block"
            />

            <div className="flex items-center gap-2">{adminButtonElement}</div>
          </>
        )}
        <Separator
          orientation="vertical"
          className="ml-4 mr-4 hidden md:block"
        />

        <div className="hidden md:flex items-center gap-2">
          {warningElement}
          {notificationElement}
          {onlineStatusElement}
        </div>
      </div>
    </div>
  )
}
