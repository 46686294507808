import { useNavigate } from "react-router-dom"
import { Button } from "./ui/button"
import { usePermissions } from "@/hooks/usePermissions"
import { ArrowRight } from "lucide-react"

export const OrderButtonBottomFooter = () => {
  const navigate = useNavigate()
  const { isIdaAdmin } = usePermissions()

  return (
    <div className="fixed bottom-0 left-0 w-full bg-white border-t shadow-lg md:hidden">
      <div
        className={`flex flex-col justify-around py-2 ${isIdaAdmin ? "mb-10" : ""} gap-2`}
      >
        <Button
          onClick={() => {
            navigate("/order")
          }}
          className="bg-green-800 text-white  hover:bg-green-900 hover:text-white py-6 mx-4"
        >
          <p>Passer commande</p>
          <ArrowRight className="w-4 h-4" />
        </Button>
      </div>
    </div>
  )
}
