import { useMemo } from "react"
import { useGetInventoriesInfoQuery } from "@/utils/__generated__/graphql"

export const useInventoryData = (
  companyId: string,
  storeId: string,
  selectedStoreId: string | null,
  franchiseParam: string | undefined,
  formattedFromDate: string,
  formattedToDate: string,
) => {
  const {
    data: inventoriesData,
    loading: inventoriesLoading,
    error: inventoriesError,
  } = useGetInventoriesInfoQuery({
    variables: {
      input: {
        company_id: companyId ?? "",
        franchise_name: franchiseParam,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formattedFromDate,
        end_date: formattedToDate,
        store_id: storeId,
      },
    },
    fetchPolicy: "cache-and-network",
  })

  const rawRecords = useMemo(() => {
    return inventoriesData?.getInventoriesInfo?.inventory_info ?? []
  }, [inventoriesData?.getInventoriesInfo?.inventory_info])

  const inventoryRecords = useMemo(() => {
    return rawRecords.map((rec) => ({
      ...rec,
      floor_inventory_qty: rec.floor_inventory_qty
        ? parseFloat(rec.floor_inventory_qty.toFixed(1))
        : 0,
      back_inventory_qty: rec.back_inventory_qty
        ? parseFloat(rec.back_inventory_qty.toFixed(1))
        : 0,
      total_inventory_qty: rec.total_inventory_qty
        ? parseFloat(rec.total_inventory_qty.toFixed(1))
        : 0,
    }))
  }, [rawRecords])

  const groupedData = useMemo(() => {
    const map: Record<string, Record<string, number>> = {}
    for (const rec of inventoryRecords) {
      if (!rec.inventory_date) continue
      const dayStr = new Date(rec.inventory_date).toISOString().slice(0, 10)
      const store = rec.store_name ?? "—"

      if (!map[dayStr]) map[dayStr] = {}
      if (!map[dayStr][store]) map[dayStr][store] = 0

      map[dayStr][store] += rec.total_inventory_qty ?? 0
    }
    return map
  }, [inventoryRecords])

  const allStores = useMemo(() => {
    const s = new Set<string>()
    for (const day in groupedData) {
      Object.keys(groupedData[day]).forEach((store) => s.add(store))
    }
    return Array.from(s)
  }, [groupedData])

  const inventoryChartData = useMemo(() => {
    const daysSorted = Object.keys(groupedData).sort()
    return daysSorted.map((day) => {
      const row: Record<string, string | number> = { day }
      let sum = 0
      allStores.forEach((store) => {
        const val = groupedData[day][store] || 0
        const roundedVal = parseFloat(val.toFixed(1))
        row[store] = roundedVal
        sum += roundedVal
      })
      row.sum = parseFloat(sum.toFixed(1))
      return row
    })
  }, [groupedData, allStores])

  const avgStockPerDate = useMemo(() => {
    if (!inventoryRecords.length) return 0
    const totalStock = inventoryRecords.reduce(
      (acc, rec) => acc + (rec.total_inventory_qty ?? 0),
      0,
    )
    const uniqueDates = new Set(
      inventoryRecords.map((rec) => rec.inventory_date),
    )
    if (uniqueDates.size === 0) return 0
    const rawAvg = totalStock / uniqueDates.size
    return parseFloat(rawAvg.toFixed(1))
  }, [inventoryRecords])

  return {
    inventoryRecords,
    inventoryChartData,
    allStores,
    avgStockPerDate,
    isLoading: inventoriesLoading,
    error: inventoriesError,
    isEmpty: inventoryChartData.length === 0,
  }
}
