// CheckinPage.tsx

import { DispatchActionType, StateType } from "@/types"
import { useDispatch, useSelector } from "react-redux"
import { Stepper } from "@/components/stepper"
import { useCallback, useEffect, useMemo, useState, useRef } from "react"
import { filterSteps } from "@/pages/checkinPage/stepperData"

import {
  filteredMercurialeReducerSelector,
  selectedMercurialeInfosSelector,
  potentialRupturesSelector,
  bestSellersSelector,
} from "@/selectors/mercurialeSelectors"

import {
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { handleKeyboard } from "@/utils/handleKeyboard"

import { ValidationFooter } from "@/pages/checkinPage/components/validationFooter"
import { PriceChanges } from "@/pages/checkinPage/components/priceChanges"
import { hasFeatureFlag } from "@/components/order/modals/CheckInModal/checkInFeaturesFlags"
import { getVariation } from "@/utils/getVariation"
import { removeDuplicatesValues } from "@/utils/removeDuplicates"
import MinimalLogo from "@/assets/MinimalLogo"
import { useWindowSize } from "@/hooks/useWindowSize"
// import { useOrderContext } from "@/contexts/OrderContext"
import { HandleLinear } from "@/pages/checkinPage/components/handleLinear"
import { IdaRecommandations } from "./idaRecommandations"
import { MercurialeDateSelection } from "@/components/order/nav/MercurialeDateSelection"
import { Card } from "@/components/ui/card"
import { useFetchOrderStatus } from "@/components/order/useFetchOrderStatus"
import { useOutletContext } from "react-router-dom"
import { OrderRootOutletContext } from "@/components/order/OrderRoot"
import posthog from "posthog-js"

export const CheckinPage = ({
  setIsCheckinPage,
}: {
  setIsCheckinPage?: (value: boolean) => void
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)
  const dispatch = useDispatch<DispatchActionType>()
  const loadingEndTimeRef = useRef<string | null>(null)
  const [amountOfUpdate, setAmountOfUpdate] = useState(0)
  const selectedDimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const {
    companyName,
    storeName,
    storeFranchise,
    storeId,
    storeSettings,
    companyId,
  } = useSelector((state: StateType) => state.storeReducer)

  const [batchUpdateMutation] = useBatchUpdateMutation()

  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )

  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const bestSellers = useSelector(bestSellersSelector)

  const { mercurialAndStoreInventories, updatedReferences } = useSelector(
    filteredMercurialeReducerSelector,
  )
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  // For certain handleKeyboard calls
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  const mercurialeInfos = useSelector(selectedMercurialeInfosSelector)

  const potentialRuptures = useSelector(potentialRupturesSelector)
  const deduplicatedPotentialRuptures = useMemo(() => {
    return removeDuplicatesValues(potentialRuptures, "sale_id")
      .sort(
        (a, b) => (b.rupture_loss_amount ?? 0) - (a.rupture_loss_amount ?? 0),
      )
      .slice(0, 3)
  }, [potentialRuptures])

  const updateInventory = useCallback(
    async (
      value: string,
      _selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
    ): Promise<void> => {
      setAmountOfUpdate(amountOfUpdate + 1)
      console.log("test")
      await handleKeyboard({
        value,
        selectedInventory: _selectedInventory ?? selectedInventory,
        mercurialeInfos: mercurialAndStoreInventories,
        updatedReferences,
        storeSettings,
        dispatch,
        batchUpdateMutation,
        online,
        isTestMode,
        storeId,
        dimOrderRequestId,
        logsModificationUpdate,
      })
    },
    [
      amountOfUpdate,
      batchUpdateMutation,
      dimOrderRequestId,
      dispatch,
      isTestMode,
      mercurialAndStoreInventories,
      online,
      selectedInventory,
      storeId,
      storeSettings,
      updatedReferences,
      logsModificationUpdate,
    ],
  )

  /**
   * Shelf floor size over/under exposure
   */
  const shelfFloorSizeOverexposure = useMemo(() => {
    if (
      !hasFeatureFlag(companyName, storeFranchise, "shelfFloorSizeOverexposure")
    ) {
      return []
    }
    return mercurialeInfos
      .filter(
        (mercurialeInfo) =>
          mercurialeInfo.shelf_floor_size_flag === "overexposure",
      )
      .slice(0, 3)
  }, [companyName, mercurialeInfos, storeFranchise])

  const shelfFloorSizeUnderExposure = useMemo(() => {
    if (
      !hasFeatureFlag(
        companyName,
        storeFranchise,
        "shelfFloorSizeUnderExposure",
      )
    ) {
      return []
    }
    return mercurialeInfos
      .filter(
        (mercurialeInfo) =>
          mercurialeInfo.shelf_floor_size_flag === "underexposure",
      )
      .slice(0, 3)
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedShelfFloorSizeOverexposure = useMemo(() => {
    return removeDuplicatesValues(shelfFloorSizeOverexposure, "sale_id")
  }, [shelfFloorSizeOverexposure])

  const deduplicatedShelfFloorSizeUnderExposure = useMemo(() => {
    return removeDuplicatesValues(shelfFloorSizeUnderExposure, "sale_id")
  }, [shelfFloorSizeUnderExposure])

  /**
   * usedToBePromoReferences
   */
  const usedToBePromoReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "usedToBePromo")) {
      return []
    }
    return mercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.used_to_be_promo === true,
    )
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedUsedToBePromoReferences = useMemo(() => {
    return removeDuplicatesValues(usedToBePromoReferences, "sale_id")
  }, [usedToBePromoReferences])

  /**
   * Price changes (paChangedReferences)
   */
  const paChangedReferences = useMemo(() => {
    if (!hasFeatureFlag(companyName, storeFranchise, "paChanged")) {
      return []
    }
    const filteredMercurialeInfos = mercurialeInfos.filter((mercurialeInfo) => {
      const pa = mercurialeInfo.pa ?? 0
      const lastPa = mercurialeInfo.last_mercu_pa ?? 0
      if (pa === 0 || lastPa < 0.5) return false

      const variation = getVariation(pa, lastPa)
      const isPaDecreased = variation < 0
      const isEngagementPromo = mercurialeInfo.engagement_promo_flag ?? false

      return isEngagementPromo === false && isPaDecreased
    })

    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
      .sort((a, b) => {
        const aPa = a.pa ?? 0
        const aLastPa = a.last_mercu_pa ?? 0
        const bPa = b.pa ?? 0
        const bLastPa = b.last_mercu_pa ?? 0

        const aVariation = getVariation(aPa, aLastPa)
        const bVariation = getVariation(bPa, bLastPa)

        // smaller variation => bigger discount => put it first
        return aVariation - bVariation
      })
      .splice(0, 4)
  }, [companyName, mercurialeInfos, storeFranchise])

  const deduplicatedPaChangedReferences = useMemo(() => {
    return removeDuplicatesValues(paChangedReferences, "sale_id")
  }, [paChangedReferences])

  const stepsToDisplay = filterSteps(storeSettings, {
    isPaChanged: deduplicatedPaChangedReferences.length > 0,
    hasIdaRecommandations: true,
  })

  const closeCheckinModal = () => {
    setIsCheckinPage?.(false)
    dispatch({
      type: "closeCheckInModal",
      payload: {
        dimMercurialeId: selectedDimMercurialeId!,
        date: new Date(),
      },
    })
  }

  const isMobile = useWindowSize()

  useEffect(() => {
    const wasLoading = isLoading
    const newIsLoading = mercurialeInfos.length === 0

    setIsLoading(newIsLoading)

    if (wasLoading && !newIsLoading) {
      loadingEndTimeRef.current = new Date().toISOString()
      localStorage.setItem(
        "checkin_loading_end_time",
        loadingEndTimeRef.current,
      )
    }
  }, [isLoading, mercurialeInfos])

  // const { lastInputSelected } = useOrderContext()

  const generateUniqueId = () => {
    const id = Date.now().toString(36) + Math.random().toString(36).substring(2)
    localStorage.setItem("current_checkin_id", id)
    return id
  }

  // useEffect(() => {
  //   if (lastInputSelected.mercurialeId) {
  //     const element = document.getElementById(lastInputSelected.mercurialeId)
  //     if (element) {
  //       const elementTop = element.getBoundingClientRect().top
  //       const offsetPosition = window.scrollY + elementTop - 100
  //       window.scrollTo({
  //         top: offsetPosition,
  //         behavior: "smooth",
  //       })
  //     }
  //   }
  // }, [lastInputSelected, storeId])

  useEffect(() => {
    localStorage.setItem("checkin_start_time", new Date().toISOString())
  }, [])

  const calculateTimeSpent = () => {
    const startTime = localStorage.getItem("checkin_start_time")
    if (!startTime) return null

    const endTime = new Date()
    const startDate = new Date(startTime)
    return Math.floor((endTime.getTime() - startDate.getTime()) / 1000)
  }

  const calculateTimeSpentAfterLoading = () => {
    const loadingEndTime =
      localStorage.getItem("checkin_loading_end_time") ??
      loadingEndTimeRef.current
    if (!loadingEndTime) return null

    const endTime = new Date()
    const loadingEndDate = new Date(loadingEndTime)
    return Math.floor((endTime.getTime() - loadingEndDate.getTime()) / 1000)
  }

  const handleCheckinCompleted = () => {
    const completionTimeSeconds = calculateTimeSpent()
    const completionTimeSecondsAfterLoading = calculateTimeSpentAfterLoading()

    posthog.capture("checkin_completed", {
      checkin_start_time: localStorage.getItem("checkin_start_time"),
      store_name: storeName,
      update_amount: amountOfUpdate,
      completion_time_seconds: completionTimeSeconds,
      completion_time_seconds_after_loading: completionTimeSecondsAfterLoading,
      checkin_id: generateUniqueId(),
    })

    localStorage.removeItem("checkin_start_time")
    localStorage.removeItem("checkin_loading_end_time")
    loadingEndTimeRef.current = null
    closeCheckinModal()
  }

  const gotToNextStep = () => {
    if (currentStep < stepsToDisplay.length) {
      setCurrentStep(currentStep + 1)
    } else {
      handleCheckinCompleted()
    }
  }

  const { statuses } = useFetchOrderStatus({
    dimMercuriales,
    storeId: storeId!,
  })

  const status = selectedDimMercurialeId
    ? statuses[selectedDimMercurialeId]
    : undefined

  const receptionDate = status?.expectedReceptionDate
  const handleSelectMercurialeDate = (mercurialeId: string) => {
    dispatch({
      type: "setSelectedDimMercurialeId",
      payload: mercurialeId,
    })
  }
  const mercurialeDate = mercurialAndStoreInventories[0]?.date_integration
  const [{ synchronisationLoading }] =
    useOutletContext<OrderRootOutletContext>()

  const showStepper = stepsToDisplay.length > 1

  return (
    <div className="flex flex-col">
      <div className="px-2 pt-4 md:px-4 flex items-center gap-4">
        <MinimalLogo
          companyId={companyId}
          testMode={isTestMode}
          franchise={storeFranchise}
          className="w-6 lg:w-8"
        />
        <Card className="flex flex-row">
          <MercurialeDateSelection
            dimMercuriales={dimMercuriales}
            selectedDimMercurialeId={selectedDimMercurialeId}
            onSelect={handleSelectMercurialeDate}
            mercurialeReceptionDate={receptionDate}
            mercurialeDate={mercurialeDate}
            isMercurialeLoading={synchronisationLoading}
          />
        </Card>
      </div>

      <div className="flex flex-col gap-4 h-full">
        <div className="px-4 z-10 flex flex-col items-start gap-2">
          <div className="inline-block"></div>
          {showStepper && (
            <Stepper
              title="Vos vérifications quotidiennes"
              steps={stepsToDisplay}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              direction="row"
              loading={isLoading}
            />
          )}
        </div>
        <div className="w-full md:px-4 h-full md:mb-10">
          {stepsToDisplay[currentStep - 1]?.key === "ida-recommandations" && (
            <IdaRecommandations
              mercurialeInfos={mercurialeInfos}
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
              updateInventory={updateInventory}
              potentialRupturesData={deduplicatedPotentialRuptures}
              isPotentialRupturesDataLoading={isLoading}
            />
          )}
          {stepsToDisplay[currentStep - 1]?.key === "linear_capacity" && (
            <HandleLinear
              usedToBePromoReferences={deduplicatedUsedToBePromoReferences}
              shelfFloorSizeOverexposure={
                deduplicatedShelfFloorSizeOverexposure
              }
              shelfFloorSizeUnderExposure={
                deduplicatedShelfFloorSizeUnderExposure
              }
              updateInventory={updateInventory}
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
            />
          )}
          {stepsToDisplay[currentStep - 1]?.key === "price_modifications" && (
            <PriceChanges
              paChangedReferences={deduplicatedPaChangedReferences}
              bestSellers={bestSellers}
              gotToNextStep={gotToNextStep}
              currentStep={currentStep}
              totalSteps={stepsToDisplay.length}
              closeCheckinModal={closeCheckinModal}
              updateInventory={updateInventory}
            />
          )}
          {isMobile && (
            <div className="md:hidden">
              <ValidationFooter
                onClick={gotToNextStep}
                currentStep={currentStep}
                totalSteps={stepsToDisplay.length}
                closeCheckinModal={closeCheckinModal}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckinPage
