import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Card, CardContent } from "@/components/ui/card"
import { StoreSalesPerformance } from "@/utils/__generated__/graphql"
import { Loader2, AlertCircle, TrendingUp } from "lucide-react"
import { useState } from "react"
import { useWindowSize } from "@/hooks/useWindowSize"

export const PerformantProductTable = ({
  bestProducts,
  loading,
  withColorfulHeader = false,
}: {
  bestProducts: StoreSalesPerformance[]
  loading: boolean
  withColorfulHeader?: boolean
}) => {
  const [sortedProducts] = useState(bestProducts)
  const getHeaderStyle = () => {
    if (withColorfulHeader) {
      return {
        background: `radial-gradient(
          circle at 50% 50%, 
          rgba(240, 253, 244, 0.9) 0%, 
          rgba(220, 252, 231, 0.6) 35%, 
          rgba(167, 243, 208, 0.3) 70%, 
          rgba(110, 231, 183, 0.1) 100%
        )`,
      }
    }
    return {}
  }

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={4} className="h-24 md:h-32">
            <div className="w-full h-full flex items-center justify-center">
              <Loader2 className="size-6 md:size-8 animate-spin text-emerald-400" />
            </div>
          </TableCell>
        </TableRow>
      )
    }

    if (!sortedProducts || sortedProducts.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} className="h-24 md:h-32">
            <div className="w-full h-full flex flex-col items-center justify-center gap-1 md:gap-2 text-gray-500">
              <AlertCircle className="size-6 md:size-8" />
              <p className="text-sm md:text-base">Aucune donnée disponible</p>
            </div>
          </TableCell>
        </TableRow>
      )
    }

    return sortedProducts.map((row, i) => {
      const difference =
        row.product_store_quote_part - row.product_cluster_quote_part
      return (
        <TableRow
          key={row.sale_id}
          className="hover:bg-slate-50 transition-colors"
        >
          <TableCell className="hidden md:table-cell text-gray-600 font-medium p-2 md:p-4">
            {i + 1}
          </TableCell>
          <TableCell className="font-medium text-gray-800 p-2 md:p-4 max-w-[120px] md:max-w-none truncate">
            {row.sale_name}
          </TableCell>
          <TableCell className="p-2 md:p-4">
            <span className="text-emerald-600 font-medium">
              {typeof row.product_store_quote_part === "number"
                ? row.product_store_quote_part.toFixed(1)
                : row.product_store_quote_part}
              %
            </span>
          </TableCell>
          <TableCell className="p-2 md:p-4">
            <div className="flex items-center gap-2">
              <span className="text-gray-700 font-medium">
                {typeof row.product_cluster_quote_part === "number"
                  ? row.product_cluster_quote_part.toFixed(1)
                  : row.product_cluster_quote_part}
                %
              </span>
              {difference > 0 && (
                <span className="hidden md:inline text-emerald-600 font-medium">
                  +{difference.toFixed(1)}%
                </span>
              )}
            </div>
          </TableCell>
        </TableRow>
      )
    })
  }
  const { isSM } = useWindowSize()

  return (
    <Card
      className={`${withColorfulHeader ? "border-0 shadow-md" : "shadow-sm"}`}
    >
      <div
        className={`rounded-t-xl ${
          withColorfulHeader
            ? "text-gray-800 bg-white"
            : "bg-white text-gray-800"
        } p-4 md:p-6`}
        style={getHeaderStyle()}
      >
        <div className="flex flex-col space-y-1 md:space-y-2">
          <div className="flex flex-row items-center gap-1 md:gap-2">
            <TrendingUp className="size-5 md:size-6 text-emerald-500" />
            <h3 className="text-lg md:text-xl font-semibold text-gray-800">
              {isSM
                ? "Performances vs. région"
                : "Performances supérieures vs. comparables"}
            </h3>
          </div>
          <p className="hidden md:block text-sm text-gray-600">
            Produits qui se vendent mieux dans votre magasin que dans les
            magasins comparables. Continuez comme cela !
          </p>
        </div>
      </div>
      <CardContent className="p-0">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-50 border-b">
                <TableHead className="hidden md:table-cell w-8 md:w-12 font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  #
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "Prod." : "Produit"}
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "QP Mag" : "Quote part magasin"}
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "QP Comp." : "Quote part comparables"}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>{renderTableContent()}</TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  )
}
