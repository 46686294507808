// components/metric-card.tsx
import { TrendingDown, TrendingUp } from "lucide-react"

interface MetricCardProps {
  title: string
  value: number
  description: string
  type: "loss" | "gain"
}

export const MetricCard = ({
  title,
  value,
  description,
  type,
}: MetricCardProps) => {
  const isGain = type === "gain"
  const textColor = isGain ? "text-blue-500" : "text-red-500"
  const Icon = isGain ? TrendingUp : TrendingDown

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex items-center justify-between w-full max-w-xs">
      <div>
        <h3 className="text-sm font-medium text-gray-700">{title}</h3>
        <p className={`text-2xl font-bold ${textColor}`}>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value)}
        </p>
        <p className="text-xs text-gray-500">{description}</p>
      </div>
      <Icon className={textColor} size={20} />
    </div>
  )
}
