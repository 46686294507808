import { useDragLayer } from "react-dnd"
import { CSSProperties } from "react"

export function CustomDragLayer() {
  const { isDragging, item, offset } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    offset: monitor.getSourceClientOffset(),
  }))

  if (!isDragging || !item || !offset) {
    return null
  }

  const style: CSSProperties = {
    top: offset.y,
    left: offset.x,
  }

  const count = item.items.length
  const text = count > 1 ? `${count} références` : "1 référence"

  return (
    <div
      className="pointer-events-none fixed px-2 py-1 text-sm rounded bg-black/80 text-white -translate-y-10"
      style={style}
    >
      {text}
    </div>
  )
}
