import { Outlet } from "react-router-dom"
import {
  BatchUpdateMutation,
  LogsModificationUpdateMutation,
  MercurialeAdditionalInfo,
  MercurialeInfo,
  useBatchUpdateAnalyticsOrderPredictionMutation,
  useBatchUpdateMutation,
  useGetOrderPlanningLazyQuery,
  useLogsModificationUpdateMutation,
  useMercurialAdditionalInfoLazyQuery,
  useMercurialeRankingLazyQuery,
  useMercurialInfoLazyQuery,
  useUpdateMercurialeMutation,
} from "../../utils/__generated__/graphql"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "../../types"
import {
  AllMercurialInfo,
  MercurialReducerState,
} from "../../reducers/mercurialReducer"
import { omit } from "lodash"
import { captureException } from "@sentry/react"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { filteredMercurialeReducerSelector } from "../../selectors/mercurialeSelectors"
import { getBatchData } from "../../utils/getBatchData"
import { FetchResult } from "@apollo/client"
import { toast } from "sonner"

export type OrderRootOutletContext = [
  {
    isLoading: boolean
    synchronisationLoading: boolean
  },
  () => void,
]

export function OrderRoot() {
  const dispatch = useDispatch<DispatchActionType>()

  const {
    storeId,
    storeStoreSuppliers,
    storeSettings,
    companyName,
    storeCode,
  } = useSelector((state: StateType) => state.storeReducer)
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const { online, dataSynchronizationStatus } = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const {
    mercurialAndStoreInventories,
    updatedReferences,
    selectedDimMercurialeId,
    modifications,
  } = useSelector(filteredMercurialeReducerSelector)

  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  const [
    getOrderPlanning,
    { called: orderPlanningCalled, loading: orderPlanningLoading },
  ] = useGetOrderPlanningLazyQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
    },
    fetchPolicy: "network-only",
  })

  const [
    getMercurialInfo,
    { called: mercurialInfoCalled, loading: mercurialInfoLoading },
  ] = useMercurialInfoLazyQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
      sort: [],
    },
    fetchPolicy: "network-only",
  })

  const [
    getMercurialAdditionalInfo,
    {
      called: mercurialAdditionalInfoCalled,
      loading: mercurialAdditionalInfoLoading,
    },
  ] = useMercurialAdditionalInfoLazyQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
    },
    fetchPolicy: "network-only",
  })

  const [getMercurialeRanking] = useMercurialeRankingLazyQuery()
  const [batchUpdateAnalyticsOrderPrediction] =
    useBatchUpdateAnalyticsOrderPredictionMutation()
  const [batchUpdateMutation, { loading: batchUpdateLoading }] =
    useBatchUpdateMutation()
  const [
    logsModificationUpdateMutation,
    { loading: logsModificationUpdateLoading },
  ] = useLogsModificationUpdateMutation()
  const [updateMercurialeMutation] = useUpdateMercurialeMutation()

  const getLatestInfos = useCallback(async () => {
    if (window.navigator.onLine === false) return
    const latestUpdatesPayload: Record<
      string,
      string | number | undefined | null
    > = {}
    let orderDate: string | null | undefined = null

    try {
      const orderPlanningResult = await getOrderPlanning()
      if (orderPlanningResult.data?.getOrderPlanning.error !== null) {
        throw new Error("Can't get order planning")
      }

      orderDate = orderPlanningResult.data.getOrderPlanning.order_date
    } catch (error) {
      console.error(error)
    }

    let mercurialInfos: MercurialeInfo[] = []
    try {
      const mercurialInfoResult = await getMercurialInfo()
      if (mercurialInfoResult.data?.getLastMercurialeInfo.error !== null) {
        console.error(mercurialInfoResult.data?.getLastMercurialeInfo.error)
        throw new Error("Can't get mercurial info")
      }
      latestUpdatesPayload.latestMercurialeUpdate =
        mercurialInfoResult.data.getLastMercurialeInfo.latest_mercuriale_update
      mercurialInfos =
        mercurialInfoResult.data.getLastMercurialeInfo
          .mercuriale_and_store_inventories ?? []
    } catch (error) {
      console.error(error)
      toast.error("Aucune donnée")
      return
    }

    let mercurialAdditionalInfos: Record<string, MercurialeAdditionalInfo> = {}
    try {
      const mercurialAditionalInfoResult = await getMercurialAdditionalInfo()
      if (
        mercurialAditionalInfoResult.data?.getLastMercurialeAdditionalInfo
          .error !== null
      ) {
        throw new Error("Can't retrieve mercurial additional info")
      }
      latestUpdatesPayload.latestOrderInventoryUpdate =
        mercurialAditionalInfoResult.data.getLastMercurialeAdditionalInfo.latest_order_inventory_update
      latestUpdatesPayload.latestOrderRequestUpdate =
        mercurialAditionalInfoResult.data.getLastMercurialeAdditionalInfo.latest_order_request_update
      latestUpdatesPayload.lastWeekOrderQty =
        mercurialAditionalInfoResult.data.getLastMercurialeAdditionalInfo.last_week_order_qty
      // Create an oject like `{ [mercurial_id]: additional_object }` to simplify access below
      mercurialAdditionalInfos =
        mercurialAditionalInfoResult.data.getLastMercurialeAdditionalInfo.mercuriale_additional_infos?.reduce<
          Record<string, MercurialeAdditionalInfo>
        >((acc, curr) => {
          if (typeof curr.mercuriale_id !== "string") return acc
          if (acc[curr.mercuriale_id] !== undefined) return acc
          acc[curr.mercuriale_id] = curr
          return acc
        }, {}) ?? {}
    } catch (error) {
      console.error(error)
      toast.error("Aucune donnée")
      return
    }

    let completeMercurialInfos: AllMercurialInfo[] = [...mercurialInfos]
    // Merge additional info only if exists
    if (Object.keys(mercurialAdditionalInfos).length > 0) {
      completeMercurialInfos = mercurialInfos.map((mercurialInfo) => {
        const mercurialAdditionalInfo =
          mercurialAdditionalInfos[mercurialInfo.mercuriale_id ?? ""] ?? {}
        return omit<AllMercurialInfo>(
          {
            ...mercurialInfo,
            ...mercurialAdditionalInfo,
          },
          ["__typename"],
        ) as AllMercurialInfo
      })
    }

    // Update info in redux store
    dispatch({
      type: "dispatchMercurialeInfosAndAnalyticsEvent",
      payload: {
        mercurialAndStoreInventories: completeMercurialInfos,
        storeSuppliers: storeStoreSuppliers,
        storeSettings,
        batchUpdateAnalyticsOrderPrediction,
        storeId,
        orderDate,
        ...latestUpdatesPayload,
      },
    })
  }, [
    dispatch,
    storeStoreSuppliers,
    storeSettings,
    batchUpdateAnalyticsOrderPrediction,
    storeId,
    getOrderPlanning,
    getMercurialInfo,
    getMercurialAdditionalInfo,
  ])

  useEffect(() => {
    void getLatestInfos()
  }, [getLatestInfos])

  useEffect(() => {
    async function getRankings() {
      if (
        typeof storeId !== "string" ||
        dimMercuriales === undefined ||
        dimMercuriales.length === 0
      )
        return

      try {
        const results = await Promise.all(
          dimMercuriales.map((dimMercuriale) =>
            getMercurialeRanking({
              variables: {
                input: {
                  store_id: storeId,
                  dim_mercuriale_id: dimMercuriale.dimMercurialeId,
                },
              },
            }),
          ),
        )
        dispatch({
          type: "setRankings",
          payload: dimMercuriales.reduce<MercurialReducerState["rankings"]>(
            (rankings, dimMercuriale, index) => {
              return {
                ...rankings,
                [dimMercuriale.dimMercurialeId]:
                  results[index].data?.getMercurialeRanking?.rankings ?? [],
              }
            },
            {},
          ),
        })
      } catch (error) {
        console.error(error)
        captureException(error)
      }
    }

    void getRankings()
  }, [dimMercuriales, dispatch, getMercurialeRanking, storeId])

  useEffect(() => {
    if (
      !online ||
      dataSynchronizationStatus !== DataSynchronizationStatus.UNSYNCHRONIZED
    )
      return

    const synchroniseData = async (): Promise<void> => {
      try {
        const newUpdatedReferences = Object.values(updatedReferences)
          .filter((updatedReference) => {
            return (
              updatedReference.backInventoryQuantity !== undefined ||
              updatedReference.floorInventoryQuantity !== undefined ||
              updatedReference.shelfFloorSize !== undefined ||
              (updatedReference.orderInventoryQuantity !== undefined &&
                updatedReference.isOrderInventoryQuantityUpdated === true)
            )
          })
          .reduce<typeof updatedReferences>(
            (newUpdatedReferences, updatedReference) => {
              if (updatedReference.isOrderInventoryQuantityUpdated !== true) {
                newUpdatedReferences[updatedReference.mercurialeId] = {
                  ...updatedReference,
                  orderInventoryQuantity: undefined,
                }
                return newUpdatedReferences
              }
              newUpdatedReferences[updatedReference.mercurialeId] =
                updatedReference
              return newUpdatedReferences
            },
            {},
          )
        const batchData = getBatchData(
          mercurialAndStoreInventories,
          newUpdatedReferences,
        )

        let batchUpdateResult: FetchResult<BatchUpdateMutation> | undefined =
          undefined

        if (batchData.length > 0) {
          batchUpdateResult = await batchUpdateMutation({
            variables: {
              input: {
                batch_data: batchData,
                dim_order_request_id: dimOrderRequestId,
                store_id: storeId ?? "",
                inventory_type: undefined,
              },
            },
          })

          if (batchUpdateResult.data?.batchUpdate?.error !== null) {
            throw batchUpdateResult.data?.batchUpdate.error
          }
        }

        let logsModificationUpdateResult:
          | FetchResult<LogsModificationUpdateMutation>
          | undefined = undefined

        if (modifications.length > 0) {
          logsModificationUpdateResult = await logsModificationUpdateMutation({
            variables: {
              input: {
                store_id: storeId ?? "",
                modifications_logs_items: modifications,
              },
            },
          })
          if (
            logsModificationUpdateResult.data?.logsModificationUpdate?.error !==
            null
          ) {
            throw logsModificationUpdateResult.data?.logsModificationUpdate
              ?.error
          }
        }

        if (
          typeof batchUpdateResult?.data?.batchUpdate.dim_order_request_id ===
          "string"
        ) {
          dispatch({
            type: "setDimOrderRequestId",
            payload: {
              dimMercurialeId:
                mercurialAndStoreInventories[0].dim_mercuriale_id ?? "",
              dimOrderRequestId:
                batchUpdateResult.data?.batchUpdate.dim_order_request_id,
            },
          })
        }
        dispatch({
          type: "setModifications",
          payload: [],
        })
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.SYNCHRONIZED,
        })
      } catch (error) {
        console.error(error)
        captureException(error)
        toast.error("Données non sauvegardées")
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.FAILURE,
        })
      }
    }

    void synchroniseData()
  }, [
    batchUpdateMutation,
    dataSynchronizationStatus,
    dimOrderRequestId,
    dispatch,
    logsModificationUpdateMutation,
    mercurialAndStoreInventories,
    modifications,
    online,
    storeId,
    updatedReferences,
  ])

  const updateMercuriale = useCallback(
    async (
      storeId: string,
      storeCode: string,
      dimMercurialeId: string,
      companyName: string,
    ) => {
      const result = await updateMercurialeMutation({
        variables: {
          input: {
            store_id: storeId,
            store_code: storeCode,
            dim_mercuriale_id: dimMercurialeId,
            company: companyName,
          },
        },
      })

      if (
        result.data?.updateMercuriale.updated_flag !== true ||
        result.data?.updateMercuriale.mercuriale === null ||
        result.data?.updateMercuriale.mercuriale === undefined
      )
        return
      dispatch({
        type: "updateMercuriale",
        payload: result.data.updateMercuriale.mercuriale,
      })
    },
    [dispatch, updateMercurialeMutation],
  )

  useEffect(() => {
    if (
      typeof companyName !== "string" ||
      typeof selectedDimMercurialeId !== "string" ||
      typeof storeCode !== "string" ||
      typeof storeId !== "string" ||
      !online
    ) {
      return
    }

    const interval = setInterval(() => {
      updateMercuriale(storeId, storeCode, selectedDimMercurialeId, companyName)
    }, 900000) // 15 min

    return () => {
      clearInterval(interval)
    }
  }, [
    companyName,
    online,
    selectedDimMercurialeId,
    storeCode,
    storeId,
    updateMercuriale,
  ])

  const context: OrderRootOutletContext = [
    {
      isLoading:
        mercurialInfoLoading ||
        mercurialAdditionalInfoLoading ||
        orderPlanningLoading ||
        !mercurialInfoCalled ||
        !mercurialAdditionalInfoCalled ||
        !orderPlanningCalled,
      synchronisationLoading:
        batchUpdateLoading || logsModificationUpdateLoading,
    },
    getLatestInfos,
  ]
  return <Outlet context={context} />
}
