import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { useCallback, useMemo } from "react"
import { useDrag } from "react-dnd"
import { twJoin } from "tailwind-merge"
import { DragPayload, ReferencesForMatching } from "./types"

interface MatchedReferenceCardProps {
  child: ReferencesForMatching[number]
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  onEditReference: (ref: ReferencesForMatching[number]) => void
  siblings: ReferencesForMatching
  anyIsDragging: boolean
  dragType: string
}

export function MatchedReferenceCard({
  child,
  rowSelection,
  setRowSelection,
  onEditReference,
  siblings,
  anyIsDragging,
  dragType,
}: MatchedReferenceCardProps) {
  const checked = !!rowSelection[child.sale_name_ida_base]
  const handleToggleSelected = useCallback(() => {
    setRowSelection((prev) => ({
      ...prev,
      [child.sale_name_ida_base]: !prev[child.sale_name_ida_base],
    }))
  }, [child.sale_name_ida_base, setRowSelection])

  // Gather multi-drag items if this child is selected
  const itemsToDrag = useMemo(() => {
    if (checked) {
      return siblings.filter((s) => rowSelection[s.sale_name_ida_base])
    }
    return [child]
  }, [checked, siblings, rowSelection, child])

  const [{ isDragging }, dragRef] = useDrag<
    DragPayload,
    void,
    { isDragging: boolean }
  >({
    type: dragType,
    item: { items: itemsToDrag },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  // Blur if selected and anything is being dragged
  const shouldBlur = anyIsDragging && rowSelection[child.sale_name_ida_base]

  return (
    <li
      ref={dragRef}
      className={twJoin(
        "flex items-center gap-2 p-2 rounded border cursor-move transition-colors",
        isDragging || shouldBlur
          ? "border-blue-400 border-dashed bg-blue-50 opacity-50"
          : "border-gray-300 bg-gray-50",
      )}
    >
      <Checkbox checked={checked} onCheckedChange={handleToggleSelected} />
      <div className="flex-1">
        <div className="font-medium text-sm">{child.libelle_base}</div>
        <div className="text-xs text-gray-600">{child.sale_name_ida_base}</div>
      </div>
      {child.is_matched ? (
        <Badge variant="default" className="bg-green-700">
          Matché
        </Badge>
      ) : (
        <Badge variant="outline">Non matché</Badge>
      )}
      <Button
        variant="outline"
        size="sm"
        onClick={() => onEditReference(child)}
      >
        Modifier
      </Button>
    </li>
  )
}
