import { ReferencesForMatching } from "../components/types"

export interface GroupedMaster {
  master: ReferencesForMatching[number]
  children: ReferencesForMatching
}

interface GroupByMasterResult {
  groups: GroupedMaster[]
  unmatched: ReferencesForMatching
}

export function groupByMaster(
  refs: ReferencesForMatching,
): GroupByMasterResult {
  const masters = refs.filter((r) => r.isMaster && r.sale_name_ida_base !== "-")

  const groups: GroupedMaster[] = masters.map((master) => {
    const children = refs.filter(
      (child) =>
        child.sale_name_ida_cible === master.sale_name_ida_base &&
        child.sale_name_ida_base !== master.sale_name_ida_base,
    )
    return { master, children }
  })

  const unmatched = refs.filter(
    (r) => (!r.is_matched || r.sale_name_ida_cible === "-") && !r.isMaster,
  )
  return { groups, unmatched }
}
