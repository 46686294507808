import { GetQuarterValueResult } from "@/utils/getQuarterValue"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StoreReducerState } from "@/reducers/storeReducer"
import { UserInterfaceReducerState } from "@/reducers/userInterfaceReducer"
import { InventoryInput } from "./InventoryInput"
import { InventoryType } from "@/utils/__generated__/graphql"
import { useMemo } from "react"
import { usePermissions } from "@/hooks/usePermissions"
import { useOrderContext } from "@/contexts/OrderContext"
import { ResetPredictionPopover } from "../ResetPrediction/ResetPredictionPopover"

interface InventoriesInputsMobileLayoutProps {
  shelfFloorSizeQuarterValue: GetQuarterValueResult
  reference: AllMercurialInfo
  referenceIndex: number
  storeSettings: StoreReducerState["storeSettings"]
  isOrderDisabled: boolean
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  backInventoryEquivalent: number
  floorInventoryEquivalent: number
  shelfFloorSizeEquivalent: number
  hasBigBreakage: boolean
  isOnline: boolean
  isBestSeller: boolean
  setDisplayedList?: () => void
  SaleIdIndex: number
  isPopoverTriggerable?: boolean
  isRuptureToVerify?: boolean
}

export function InventoriesInputsMobileLayout({
  shelfFloorSizeQuarterValue,
  reference,
  referenceIndex,
  storeSettings,
  backInventoryEquivalent,
  floorInventoryEquivalent,
  updateInventory,
  shelfFloorSizeEquivalent,
  isOrderDisabled,
  hasBigBreakage,
  isBestSeller,
  setDisplayedList,
  SaleIdIndex,
  isOnline,
  isPopoverTriggerable,
  isRuptureToVerify,
}: InventoriesInputsMobileLayoutProps) {
  const backInventoryQuantity = useMemo(
    () =>
      referenceIndex > 0
        ? backInventoryEquivalent / (reference.colisage ?? 1)
        : (reference.back_inventory_qty ?? 0),
    [
      backInventoryEquivalent,
      reference.back_inventory_qty,
      reference.colisage,
      referenceIndex,
    ],
  )
  const floorInventoryQuantity = useMemo(
    () =>
      referenceIndex > 0
        ? floorInventoryEquivalent / (reference.colisage ?? 1)
        : (reference.floor_inventory_qty ?? 0),
    [
      floorInventoryEquivalent,
      reference.floor_inventory_qty,
      reference.colisage,
      referenceIndex,
    ],
  )
  const shelfFloorSizeQuantity = useMemo(
    () =>
      referenceIndex > 0
        ? shelfFloorSizeEquivalent / (reference.colisage ?? 1)
        : (reference.shelf_floor_size ?? null),
    [
      shelfFloorSizeEquivalent,
      reference.shelf_floor_size,
      reference.colisage,
      referenceIndex,
    ],
  )

  const { canUpdateOrderQuantity } = usePermissions()

  const hasBigBreakageNotBestSeller =
    hasBigBreakage &&
    !isBestSeller &&
    reference.promotion !== true &&
    storeSettings?.limit_order_for_high_breakage === true

  const isUpdateOrderDisabled =
    (isOrderDisabled ||
      !canUpdateOrderQuantity ||
      hasBigBreakageNotBestSeller) ??
    false

  const { setLastInputSelected } = useOrderContext()

  const setLastInputSelectedGeneric = (type: InventoryType) => {
    setDisplayedList?.()
    setLastInputSelected({
      type,
      saleId: reference.sale_id ?? "",
      mercurialeId: reference.mercuriale_id ?? "",
      index: referenceIndex,
      saleIdIndex: SaleIdIndex,
    })
  }

  const setLastInputSelectedBack = () =>
    setLastInputSelectedGeneric(InventoryType.Back)
  const setLastInputSelectedShelf = () =>
    setLastInputSelectedGeneric(InventoryType.Shelf)
  const setLastInputSelectedOrder = () =>
    setLastInputSelectedGeneric(InventoryType.Order)

  const potentialRuptureToVerify = useMemo(() => {
    return (
      (reference.time_rupture_flag && !reference.time_rupture_flag_verified) ??
      false
    )
  }, [reference.time_rupture_flag, reference.time_rupture_flag_verified])

  return (
    <div className="flex flex-col">
      <div className="flex">
        <p className="flex-1 text-xs text-zinc-500 font-bold text-center">
          RÉSERVE
        </p>
        <p className="flex-1 text-xs text-zinc-500 font-bold flex justify-center items-center">
          CAPA.RAYON
          <span className="ml-1 bg-gray-900 text-white rounded-full h-4 px-1 text-xxs flex justify-center items-center">
            {((shelfFloorSizeQuarterValue.value ?? 0) > 0 ||
              shelfFloorSizeQuarterValue.icon === undefined) &&
              shelfFloorSizeQuarterValue.value}
            {shelfFloorSizeQuarterValue.icon}
          </span>
        </p>
        <div className="flex-1">
          <ResetPredictionPopover
            reference={reference}
            disabled={isOrderDisabled || !isOnline}
            storeSettings={storeSettings}
            hasBigBreakage={hasBigBreakage}
          />
          <p className="text-xs text-zinc-500 font-bold text-center">
            COMMANDE
          </p>
        </div>
      </div>
      <div className="pt-2 flex items-start gap-1 md:gap-2 md:border md:bg-gray-100 rounded relative">
        <InventoryInput
          name={InventoryType.Back}
          stockAecouler={reference.stock_a_ecouler ?? false}
          stock_too_high_flag={reference.stock_too_high_flag}
          stock_too_low_flag={reference.stock_too_low_flag}
          stock_to_verify_flag={reference.stock_to_verify_flag}
          mercurialeId={reference.mercuriale_id!}
          unit={reference.unit ?? ""}
          waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
          promoWaitedQuantityOrdered={
            reference.promo_waited_quantity_ordered ?? 0
          }
          value={backInventoryQuantity}
          floorInventoryQuantity={floorInventoryQuantity}
          updateInventory={updateInventory}
          setLastInputSelected={setLastInputSelectedBack}
        />
        <InventoryInput
          name={InventoryType.Shelf}
          mercurialeId={reference.mercuriale_id!}
          unit={reference.unit ?? ""}
          value={shelfFloorSizeQuantity}
          floorInventoryQuantity={floorInventoryQuantity}
          updateInventory={updateInventory}
          setLastInputSelected={setLastInputSelectedShelf}
        />
        <InventoryInput
          name={InventoryType.Order}
          prediction_uncertain={reference.prediction_uncertain}
          mercurialeId={reference.mercuriale_id!}
          unit={reference.unit ?? ""}
          value={reference?.quantity_actual ?? 0}
          updateInventory={updateInventory}
          disabled={isUpdateOrderDisabled}
          isPrecommande={reference.is_precommande ?? false}
          isLineaireUpdatedTo0={
            reference.lineaire_updated_to_zero_flag ?? false
          }
          isPopoverTriggerable={isPopoverTriggerable}
          waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
          promoWaitedQuantityOrdered={
            reference.promo_waited_quantity_ordered ?? 0
          }
          potentialRuptureToVerify={
            (potentialRuptureToVerify && isRuptureToVerify) ?? false
          }
          setLastInputSelected={setLastInputSelectedOrder}
        />
      </div>
    </div>
  )
}
