import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Virtuoso } from "react-virtuoso"
import { twJoin } from "tailwind-merge"
import { DragPayload, ReferencesForMatching } from "./types"
import { useDrop } from "react-dnd"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { GroupedMaster } from "../utils/groupByMaster"
import { ParentReferenceCard } from "./MainReferenceCard"

interface MatchedReferencesContainerProps {
  groups: GroupedMaster[]
  dragType: string
  setSelectedRecords: Dispatch<SetStateAction<ReferencesForMatching>>
  setIsBatchMatchDialogOpen: Dispatch<SetStateAction<boolean>>
  onCreate: (
    parent: ReferencesForMatching[number],
    child: ReferencesForMatching[number],
  ) => Promise<void>
  anyIsDragging: boolean
  rowSelection: Record<string, boolean>
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>
  setLocalReferences: Dispatch<SetStateAction<ReferencesForMatching>>
  setSelectedEditReference: Dispatch<
    SetStateAction<ReferencesForMatching[number] | undefined>
  >
  companyName: string
  franchise: string | undefined
}

export function MatchedReferencesContainer({
  groups,
  dragType,
  setSelectedRecords,
  setIsBatchMatchDialogOpen,
  onCreate,
  anyIsDragging,
  rowSelection,
  setRowSelection,
  setLocalReferences,
  setSelectedEditReference,
  companyName,
  franchise,
}: MatchedReferencesContainerProps) {
  const [masterSearchTerm, setMasterSearchTerm] = useState("")

  const filteredGroups = useMemo(() => {
    if (!masterSearchTerm.trim()) {
      return groups
    }
    const lower = masterSearchTerm.toLowerCase()
    return groups.filter(({ master }) => {
      return (
        master.libelle_base.toLowerCase().includes(lower) ||
        master.sale_name_ida_base.toLowerCase().includes(lower)
      )
    })
  }, [groups, masterSearchTerm])

  const [{ isOver }, dropRef] = useDrop<DragPayload, void, { isOver: boolean }>(
    {
      accept: dragType,
      drop: (dragPayload, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) return
        setSelectedRecords(dragPayload.items)
        setIsBatchMatchDialogOpen(true)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
  )

  return (
    <Card
      ref={dropRef}
      className={twJoin(
        "flex-1 overflow-y-hidden flex flex-col transition-colors",
        isOver && "border-blue-600 border-2",
      )}
    >
      <CardHeader className="p-2">
        <CardTitle className="text-base">Références liées</CardTitle>
        <Input
          type="text"
          placeholder="Rechercher une référence parente..."
          value={masterSearchTerm}
          onChange={(e) => setMasterSearchTerm(e.target.value)}
          className="w-full rounded border px-2 py-1 text-sm"
        />
      </CardHeader>
      <CardContent className="p-2 flex-1 overflow-y-auto">
        {filteredGroups.length === 0 ? (
          <p className="text-gray-600 italic">Aucun Master correspondant.</p>
        ) : (
          <Virtuoso
            data={filteredGroups}
            itemContent={(_, { master, children }) => (
              <ParentReferenceCard
                key={master.sale_name_ida_base}
                main={master}
                childrenRefs={children}
                onEditReference={(ref) => {
                  setSelectedEditReference(ref)
                }}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                setLocalReferences={setLocalReferences}
                anyIsDragging={anyIsDragging}
                dragType={dragType}
                onCreate={onCreate}
                companyName={companyName}
                franchise={franchise}
              />
            )}
          />
        )}
      </CardContent>
    </Card>
  )
}
