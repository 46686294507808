import { partition } from "lodash"

export interface Predicate<T> {
  predicate: (item: T) => boolean
  label: string
  description?: string
}

interface Section {
  count: number
  index: number
  label: string
  description?: string
}

export const reduceInSections = <T>(
  list: T[],
  predicates: Predicate<T>[],
  extractId: (item?: T) => string | undefined,
) =>
  predicates.reduce<{
    remainingList: T[]
    sections: Section[]
  }>(
    ({ sections, remainingList }, { predicate, label, description }) => {
      const [matching, notMatching] = partition(remainingList, predicate)
      const index = list.findIndex(
        (item) => extractId(item) === extractId(matching[0]),
      )

      const section = {
        label,
        index,
        description,
        count: matching.length,
      }

      return {
        remainingList: notMatching,
        sections: [...sections, section],
      }
    },
    {
      remainingList: list,
      sections: [],
    },
  ).sections
