import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import MultipleSelector from "@/components/ui/multi-select"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Spinner } from "@/ui/Spinner"
import {
  FullUser,
  Role,
  Scope,
  useAddPermissionsToUserMutation,
  useAddRolesToUserMutation,
  useGetUserInformationsQuery,
  useRemovePermissionsFromUserMutation,
  useRemoveRolesFromUserMutation,
} from "@/utils/__generated__/graphql"
import { pluralize } from "@/utils/pluralize"
import { captureException } from "@sentry/react"
import { Trash2 } from "lucide-react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "sonner"

interface AddRolesToUserForm {
  roles: string[]
}

interface AddPermissionsToUserForm {
  permissions: string[]
}

interface UpdateUserModalProps {
  setIsOpen: (open: boolean) => void
  user: FullUser | undefined
  roles: Role[] | undefined
  scopes: Scope[] | undefined
}

export function UpdateUserModal({
  setIsOpen,
  user,
  roles,
  scopes,
}: UpdateUserModalProps) {
  const [addRolesToUser, { loading: isAddRolesToUserLoading }] =
    useAddRolesToUserMutation()
  const [removeRolesFromUser, { loading: isRemoveRolesFromUserLoading }] =
    useRemoveRolesFromUserMutation()
  const [addPermissionsToUser, { loading: isAddPermissionsToUserLoading }] =
    useAddPermissionsToUserMutation()
  const [
    removePermissionsFromUser,
    { loading: isRemovePermissionsFromUserLoading },
  ] = useRemovePermissionsFromUserMutation()

  const isMutationsLoading =
    isAddRolesToUserLoading ||
    isRemoveRolesFromUserLoading ||
    isAddPermissionsToUserLoading ||
    isRemovePermissionsFromUserLoading

  const {
    data: userInformationsData,
    loading: isUserInformationsLoading,
    refetch: refetchUserInformations,
  } = useGetUserInformationsQuery({
    variables: { input: { id: user?.id ?? "" } },
    skip: user === undefined,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  })

  const addRolesToUserForm = useForm<AddRolesToUserForm>({
    defaultValues: {
      roles: [],
    },
  })

  const addPermissionsToUserForm = useForm<AddPermissionsToUserForm>({
    defaultValues: {
      permissions: [],
    },
  })

  const onSubmitRoles: SubmitHandler<AddRolesToUserForm> = async (data) => {
    try {
      await addRolesToUser({
        variables: {
          input: {
            id: user!.id,
            roles: data.roles,
          },
        },
      })
      refetchUserInformations()
      addRolesToUserForm.reset()
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error(error instanceof Error ? error.message : "An error occurred.")
    }
  }

  const onSubmitPermissions: SubmitHandler<AddPermissionsToUserForm> = async (
    data,
  ) => {
    try {
      await addPermissionsToUser({
        variables: {
          input: {
            id: user!.id,
            permissions: data.permissions,
          },
        },
      })
      refetchUserInformations()
      addPermissionsToUserForm.reset()
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error(error instanceof Error ? error.message : "An error occurred.")
    }
  }

  async function onRemoveRole(roleId: string) {
    await removeRolesFromUser({
      variables: {
        input: {
          id: user!.id,
          roles: [roleId],
        },
      },
    })
    refetchUserInformations()
  }

  async function onRemovePermission(permissionName: string) {
    await removePermissionsFromUser({
      variables: {
        input: {
          id: user!.id,
          permissions: [permissionName],
        },
      },
    })
    refetchUserInformations()
  }

  const rolesSelectorOptions = roles?.map((role) => ({
    label: role.name,
    value: role.name,
    id: role.id,
  }))

  const permissionsSelectorOptions = scopes?.map((scope) => ({
    label: scope.value,
    value: scope.value,
    id: scope.value,
  }))

  return (
    <Dialog open={user !== undefined} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Modifier</DialogTitle>
          <DialogDescription>
            Modifier l&apos;utilisateur{" "}
            <span className="font-bold text-black">{user?.email}</span>
          </DialogDescription>
        </DialogHeader>
        {isUserInformationsLoading ? (
          <div className="w-full flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Tabs defaultValue="roles">
            <TabsList className="w-full flex">
              <TabsTrigger className="flex-1" value="roles">
                Roles
              </TabsTrigger>
              <TabsTrigger className="flex-1" value="permissions">
                Permissions
              </TabsTrigger>
            </TabsList>
            <TabsContent value="roles">
              <form
                onSubmit={addRolesToUserForm.handleSubmit(onSubmitRoles)}
                className="flex flex-col gap-2"
              >
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-1">
                    {userInformationsData?.userInformations.roles?.map(
                      (role) => (
                        <div
                          key={role.id}
                          className="p-2 flex justify-between items-center border rounded"
                        >
                          <p className="font-medium text-sm">{role.name}</p>
                          <Button
                            type="button"
                            variant="outline"
                            className="size-8"
                            disabled={isMutationsLoading}
                            onClick={() => onRemoveRole(role.id)}
                          >
                            <Trash2 className="size-4" />
                          </Button>
                        </div>
                      ),
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      control={addRolesToUserForm.control}
                      name="roles"
                      render={({ field: { onChange, value } }) => (
                        <MultipleSelector
                          options={rolesSelectorOptions?.filter(
                            (option) =>
                              !userInformationsData?.userInformations.roles.some(
                                (role) => role.id === option.id,
                              ),
                          )}
                          placeholder="Ajouter des roles"
                          value={rolesSelectorOptions?.filter((option) =>
                            value?.includes(option.id),
                          )}
                          emptyIndicator={
                            <p className="text-center text-gray-600 dark:text-gray-400">
                              Ce role n&apos;existe pas.
                            </p>
                          }
                          onChange={(values) =>
                            onChange(values.map((value) => value.id))
                          }
                          className="bg-white"
                        />
                      )}
                    />
                  </div>
                </div>
                <Button type="submit" disabled={isMutationsLoading}>
                  Ajouter
                </Button>
              </form>
            </TabsContent>
            <TabsContent value="permissions">
              <form
                onSubmit={addPermissionsToUserForm.handleSubmit(
                  onSubmitPermissions,
                )}
                className="flex flex-col gap-2"
              >
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-1">
                    {userInformationsData?.userInformations.permissions?.map(
                      (permission) => {
                        const sources = permission.sources.filter(
                          (source) => source.source_type !== "DIRECT",
                        )
                        const isDirect = permission.sources.some(
                          (source) => source.source_type === "DIRECT",
                        )

                        return (
                          <div
                            key={permission.permission_name}
                            className="p-2 flex justify-between items-center border rounded"
                          >
                            <div>
                              <p className="font-medium text-sm">
                                {permission.permission_name}
                              </p>
                              <p className="text-xs text-gray-600">
                                {sources.length > 0 && (
                                  <>
                                    {sources.length}{" "}
                                    {pluralize("role", sources.length)}
                                  </>
                                )}
                                {isDirect && sources.length > 0 && ", "}
                                {isDirect && "Direct"}
                              </p>
                            </div>
                            <Button
                              type="button"
                              variant="outline"
                              className="size-8"
                              disabled={!isDirect || isMutationsLoading}
                              onClick={() =>
                                onRemovePermission(permission.permission_name)
                              }
                            >
                              <Trash2 className="size-4" />
                            </Button>
                          </div>
                        )
                      },
                    )}
                  </div>
                  <div className="flex flex-col gap-1">
                    <Controller
                      control={addPermissionsToUserForm.control}
                      name="permissions"
                      render={({ field: { onChange, value } }) => (
                        <MultipleSelector
                          options={permissionsSelectorOptions?.filter(
                            (option) =>
                              !userInformationsData?.userInformations.permissions.some(
                                (permission) =>
                                  permission.permission_name === option.id,
                              ),
                          )}
                          placeholder="Ajouter des permissions"
                          value={permissionsSelectorOptions?.filter((option) =>
                            value?.includes(option.id),
                          )}
                          emptyIndicator={
                            <p className="text-center text-gray-600 dark:text-gray-400">
                              Cette permission n&apos;existe pas.
                            </p>
                          }
                          onChange={(values) =>
                            onChange(values.map((value) => value.id))
                          }
                          className="bg-white"
                        />
                      )}
                    />
                  </div>
                </div>
                <Button type="submit" disabled={isMutationsLoading}>
                  Ajouter
                </Button>
              </form>
            </TabsContent>
          </Tabs>
        )}
      </DialogContent>
    </Dialog>
  )
}
