interface PaginationControlsProps {
  page: number
  totalPages: number
  totalResults: number
  onPageChange: (newPage: number) => void
}

export default function PaginationControls(props: PaginationControlsProps) {
  const { page, totalPages, totalResults, onPageChange } = props
  return (
    <div className="flex items-center justify-between mt-4">
      <div className="text-sm text-gray-700">
        Page {page} / {totalPages === 0 ? 1 : totalPages} — {totalResults}{" "}
        résultats
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => onPageChange(Math.max(page - 1, 1))}
          disabled={page <= 1}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
          Précédent
        </button>
        <button
          onClick={() => onPageChange(page < totalPages ? page + 1 : page)}
          disabled={page >= totalPages}
          className="px-3 py-1 border rounded disabled:opacity-50"
        >
          Suivant
        </button>
      </div>
    </div>
  )
}
