import Header from "@/components/header"
import { MatchReferencesSalesPage } from "./admin/MatchingPage/sale_regrouping_components/MatchReferencesSalesPage"

export function MatchingPage() {
  return (
    <div className="h-screen flex flex-col pb-4">
      <Header />
      <div className="flex-1 w-full h-full overflow-x-hidden flex flex-col">
        <MatchReferencesSalesPage />
      </div>
    </div>
  )
}
