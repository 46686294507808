import { Button } from "../ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import { AlertTriangleIcon } from "lucide-react"

interface ReloadModalProps {
  isOpen: boolean
  companyName: string | null
  onCancel: () => void
  onReload: () => void
}

export default function ReloadModal({
  isOpen,
  companyName,
  onCancel,
  onReload,
}: ReloadModalProps) {
  const handleOpenChange = (open: boolean) => {
    if (open) {
      return
    }
    onCancel()
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="sm:max-w-[420px] flex flex-col items-center gap-8">
        <div className="relative">
          <div className="w-14 h-14 rounded-full bg-amber-300 blur"></div>
          <AlertTriangleIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
        <DialogHeader className="items-center gap-3">
          <DialogTitle className="text-center">
            Voulez-vous récupérer les dernières données de la{" "}
            {companyName === "auchan" ? "commande" : "mercuriale"} ?
          </DialogTitle>
          <DialogDescription className="text-center">
            La {companyName === "auchan" ? "commande" : "mercuriale"} n&apos;a
            pas été mise à jour depuis 10 heures.
          </DialogDescription>
        </DialogHeader>

        <div className="w-full flex items-center gap-3 justify-center">
          <Button
            onClick={onCancel}
            variant="outline"
            className="w-full font-semibold"
          >
            Annuler
          </Button>
          <Button
            onClick={onReload}
            className="w-full text-md font-semibold bg-[rgb(18,185,129)] hover:bg-[rgb(18,185,129)]/75 text-white"
          >
            Mettre à jour
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
