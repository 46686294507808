import { TriangleAlert } from "lucide-react"
import { Dispatch, SetStateAction } from "react"
import { SaveDataModalState } from "./RetrySaveModal"

interface RetrySaveButtonProps {
  setSaveDataModalState: Dispatch<SetStateAction<SaveDataModalState>>
}

export function RetrySaveButton({
  setSaveDataModalState,
}: RetrySaveButtonProps) {
  function handleClick() {
    setSaveDataModalState({ isOpen: true })
  }

  return (
    <button onClick={handleClick}>
      <TriangleAlert className="size-5 text-orange-400" />
    </button>
  )
}
