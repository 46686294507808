import { useMemo } from "react"
import { useSelector } from "react-redux"
import { add } from "date-fns"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import {
  ModificationWeeklyStats,
  useGetModificationSummaryQuery,
} from "@/utils/__generated__/graphql"
import { StateType } from "@/types"
import { useParams, useSearchParams } from "react-router-dom"

export interface StoreComparisonData {
  store: string
  // Value metrics
  demarqueUser: number
  demarqueAlgo: number
  ruptureUser: number
  ruptureAlgo: number
  reassortUser: number
  totalDemarque: number
  totalRupture: number

  // Count metrics (number of modifications)
  countDemarqueUser: number
  countDemarqueAlgo: number
  countRuptureUser: number
  countRuptureAlgo: number
  countReassortUser: number
  countSuivies: number
  countModifs: number
  totalCountDemarque: number
  totalCountRupture: number

  // Weekly stats remain unchanged
  weekly_stats: ModificationWeeklyStats[]

  // For sorting
  _totalImpact: number
  _totalCount: number
}

interface ModificationSummaryProps {
  selectedStoreId?: string
  fromDate?: Date
  toDate?: Date
  selectedStore: string
}

export function useModificationSummary({
  selectedStoreId,
  fromDate,
  toDate,
  selectedStore,
}: ModificationSummaryProps) {
  // Get parameters from URL and Redux store
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyIdInParams = params.companyId

  const { companyId, storeFranchise, storeId } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const companyIdToUse = companyIdInParams ?? companyId
  const franchiseToUse = franchiseParam ?? storeFranchise

  // Default dates if not provided
  const defaultStartDate = useMemo(() => add(new Date(), { days: -7 }), [])
  const defaultEndDate = useMemo(() => new Date(), [])

  // Query to fetch data
  const {
    data,
    loading: isLoading,
    error: hasError,
  } = useGetModificationSummaryQuery({
    variables: {
      input: {
        company_id: companyIdToUse!,
        store_id: storeId!,
        franchise_name: franchiseToUse,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formatDateToYYYYMMDD(fromDate ?? defaultStartDate),
        end_date: formatDateToYYYYMMDD(toDate ?? defaultEndDate),
      },
    },
    fetchPolicy: "cache-and-network",
  })

  // Extract store data
  const storeData = useMemo(() => {
    if (!data?.getModificationSummary?.store_data) return []
    return data.getModificationSummary.store_data
  }, [data])

  // Process store comparison data
  const storeComparisonData = useMemo<StoreComparisonData[]>(() => {
    if (!storeData.length) return []

    return storeData
      .map((store) => {
        // Sum metrics across all weeks for each store
        const totalStats = store.weekly_stats.reduce(
          (acc, week) => {
            acc.demarqueUser += week.user_breakage || 0
            acc.demarqueAlgo += week.algo_breakage || 0
            acc.ruptureUser += week.user_stockout || 0
            acc.ruptureAlgo += week.algo_stockout || 0
            acc.reassortUser += week.user_replenishment || 0

            acc.countDemarqueUser += week.n_user_breakage || 0
            acc.countDemarqueAlgo += week.n_algo_breakage || 0
            acc.countRuptureUser += week.n_user_stockout || 0
            acc.countRuptureAlgo += week.n_algo_stockout || 0
            acc.countReassortUser += week.n_user_replenishment || 0

            acc.countModifs += week.n_modified || 0
            acc.countSuivies += week.n_followed || 0

            acc.weekCount++
            return acc
          },
          {
            demarqueUser: 0,
            demarqueAlgo: 0,
            ruptureUser: 0,
            ruptureAlgo: 0,
            reassortUser: 0,
            countDemarqueUser: 0,
            countDemarqueAlgo: 0,
            countRuptureUser: 0,
            countRuptureAlgo: 0,
            countReassortUser: 0,
            countModifs: 0,
            countSuivies: 0,
            weekCount: 0,
          },
        )

        // Calculate totals
        const totalDemarque = totalStats.demarqueUser + totalStats.demarqueAlgo
        const totalRupture =
          totalStats.ruptureUser +
          totalStats.ruptureAlgo +
          totalStats.reassortUser
        const totalCountDemarque =
          totalStats.countDemarqueUser + totalStats.countDemarqueAlgo
        const totalCountRupture =
          totalStats.countRuptureUser +
          totalStats.countRuptureAlgo +
          totalStats.countReassortUser

        return {
          store: store.store_name,
          // Value metrics
          demarqueUser: totalStats.demarqueUser,
          demarqueAlgo: totalStats.demarqueAlgo,
          ruptureUser: totalStats.ruptureUser,
          ruptureAlgo: totalStats.ruptureAlgo,
          reassortUser: totalStats.reassortUser,
          totalDemarque: totalDemarque,
          totalRupture: totalRupture,

          // Count metrics (number of modifications)
          countDemarqueUser: totalStats.countDemarqueUser,
          countDemarqueAlgo: totalStats.countDemarqueAlgo,
          countRuptureUser: totalStats.countRuptureUser,
          countRuptureAlgo: totalStats.countRuptureAlgo,
          countReassortUser: totalStats.countReassortUser,
          countModifs: totalStats.countModifs,
          countSuivies: totalStats.countSuivies,
          totalCountDemarque: totalCountDemarque,
          totalCountRupture: totalCountRupture,

          // Weekly stats remain unchanged
          weekly_stats: store.weekly_stats,

          // For sorting
          _totalImpact: totalDemarque + totalRupture,
          _totalCount: totalCountDemarque + totalCountRupture,
        }
      })
      .sort((a, b) => b._totalImpact - a._totalImpact)
  }, [storeData])

  // Calculate summary metrics and percentages
  const summaryMetrics = useMemo(() => {
    // For all stores
    if (selectedStore === "all") {
      const totalDemarqueUser = storeComparisonData.reduce(
        (sum, store) => sum + store.demarqueUser,
        0,
      )
      const totalDemarqueAlgo = storeComparisonData.reduce(
        (sum, store) => sum + store.demarqueAlgo,
        0,
      )
      const totalRuptureUser = storeComparisonData.reduce(
        (sum, store) => sum + store.ruptureUser,
        0,
      )
      const totalRuptureAlgo = storeComparisonData.reduce(
        (sum, store) => sum + store.ruptureAlgo,
        0,
      )
      const totalReassortUser = storeComparisonData.reduce(
        (sum, store) => sum + (store.reassortUser ?? 0),
        0,
      )
      const totalCountDemarqueUser = storeComparisonData.reduce(
        (sum, store) => sum + store.countDemarqueUser,
        0,
      )
      const totalCountDemarqueAlgo = storeComparisonData.reduce(
        (sum, store) => sum + store.countDemarqueAlgo,
        0,
      )
      const totalCountRuptureUser = storeComparisonData.reduce(
        (sum, store) => sum + store.countRuptureUser,
        0,
      )
      const totalCountRuptureAlgo = storeComparisonData.reduce(
        (sum, store) => sum + store.countRuptureAlgo,
        0,
      )
      const totalCountReassortUser = storeComparisonData.reduce(
        (sum, store) => sum + store.countReassortUser,
        0,
      )

      const totalCountModifs = storeComparisonData.reduce(
        (sum, store) => sum + store.countModifs,
        0,
      )
      const totalCountSuivies = storeComparisonData.reduce(
        (sum, store) => sum + store.countSuivies,
        0,
      )

      const totalDemarque = totalDemarqueUser + totalDemarqueAlgo
      const totalRupture =
        totalRuptureUser + totalRuptureAlgo + totalReassortUser

      const totalCountDemarque = totalCountDemarqueUser + totalCountDemarqueAlgo
      const totalCountRupture =
        totalCountRuptureUser + totalCountRuptureAlgo + totalCountReassortUser

      const percentDemarqueUser =
        totalCountDemarque > 0
          ? (totalCountDemarqueUser / totalCountDemarque) * 100
          : 0
      const percentRuptureUser =
        totalCountRupture > 0
          ? (totalCountRuptureUser / totalCountRupture) * 100
          : 0

      return {
        totalDemarqueUser,
        totalDemarqueAlgo,
        totalRuptureUser,
        totalRuptureAlgo,
        totalReassortUser,
        totalDemarque,
        totalRupture,

        totalCountDemarqueUser,
        totalCountDemarqueAlgo,
        totalCountRuptureUser,
        totalCountRuptureAlgo,
        totalCountReassortUser,
        totalCountDemarque,
        totalCountRupture,
        totalCountModifs,
        totalCountSuivies,

        percentDemarqueUser,
        percentRuptureUser,

        storeCount: storeComparisonData.length,
      }
    }
    // For a specific store
    else {
      const storeInfo = storeComparisonData.find(
        (s) => s.store === selectedStore,
      )
      if (!storeInfo)
        return {
          totalDemarqueUser: 0,
          totalDemarqueAlgo: 0,
          totalRuptureUser: 0,
          totalRuptureAlgo: 0,
          totalReassortUser: 0,
          totalDemarque: 0,
          totalRupture: 0,
          totalCountDemarqueUser: 0,
          totalCountDemarqueAlgo: 0,
          totalCountRuptureUser: 0,
          totalCountRuptureAlgo: 0,
          totalCountReassortUser: 0,
          totalCountDemarque: 0,
          totalCountRupture: 0,
          totalCountModifs: 0,
          totalCountSuivies: 0,
          percentDemarqueUser: 0,
          percentRuptureUser: 0,
          storeCount: 1,
        }

      const totalCountDemarque =
        storeInfo.countDemarqueUser + storeInfo.countDemarqueAlgo
      const totalCountRupture =
        storeInfo.countRuptureUser +
        storeInfo.countRuptureAlgo +
        storeInfo.countReassortUser

      const percentDemarqueUser =
        totalCountDemarque > 0
          ? (storeInfo.countDemarqueUser / totalCountDemarque) * 100
          : 0
      const percentRuptureUser =
        totalCountRupture > 0
          ? (storeInfo.countRuptureUser / totalCountRupture) * 100
          : 0

      return {
        totalDemarqueUser: storeInfo.demarqueUser,
        totalDemarqueAlgo: storeInfo.demarqueAlgo,
        totalRuptureUser: storeInfo.ruptureUser,
        totalRuptureAlgo: storeInfo.ruptureAlgo,
        totalReassortUser: storeInfo.reassortUser,
        totalDemarque: storeInfo.totalDemarque,
        totalRupture: storeInfo.totalRupture,

        totalCountDemarqueUser: storeInfo.countDemarqueUser,
        totalCountDemarqueAlgo: storeInfo.countDemarqueAlgo,
        totalCountRuptureUser: storeInfo.countRuptureUser,
        totalCountRuptureAlgo: storeInfo.countRuptureAlgo,
        totalCountReassortUser: storeInfo.countReassortUser,
        totalCountDemarque,
        totalCountRupture,
        totalCountModifs: storeInfo.countModifs,
        totalCountSuivies: storeInfo.countSuivies,

        percentDemarqueUser,
        percentRuptureUser,

        storeCount: 1,
      }
    }
  }, [storeComparisonData, selectedStore])

  return {
    storeData,
    storeComparisonData,
    summaryMetrics,
    isLoading,
    hasError,
  }
}
