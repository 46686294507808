import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Card, CardContent } from "@/components/ui/card"
import { StoreSalesPerformance } from "@/utils/__generated__/graphql"
import type { StateType } from "@/types"
import { useSelector } from "react-redux"
import { useState } from "react"
import { Loader2, AlertCircle, TrendingDown } from "lucide-react"
import { useWindowSize } from "@/hooks/useWindowSize"

export const IncreasedOrdersTable = ({
  worstProducts,
  loading,
  withColorfulHeader = false,
}: {
  worstProducts: StoreSalesPerformance[]
  loading: boolean
  withColorfulHeader?: boolean
}) => {
  const { storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const currencyCode = storeCurrency ?? "EUR"
  const [sortedProducts] = useState(worstProducts)

  const getHeaderStyle = () => {
    if (withColorfulHeader) {
      return {
        background: `radial-gradient(
          circle at 50% 50%, 
          rgba(254, 242, 242, 0.9) 0%, 
          rgba(254, 226, 226, 0.6) 35%, 
          rgba(252, 165, 165, 0.3) 70%, 
          rgba(248, 113, 113, 0.1) 100%
        )`,
      }
    }
    return {}
  }

  const renderTableContent = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={5} className="h-24 md:h-32">
            <div className="w-full h-full flex items-center justify-center">
              <Loader2 className="size-6 md:size-8 animate-spin text-blue-500" />
            </div>
          </TableCell>
        </TableRow>
      )
    }

    if (!sortedProducts || sortedProducts.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5} className="h-24 md:h-32">
            <div className="w-full h-full flex flex-col items-center justify-center gap-1 md:gap-2 text-gray-500">
              <AlertCircle className="size-6 md:size-8" />
              <p className="text-sm md:text-base">Aucune donnée disponible</p>
            </div>
          </TableCell>
        </TableRow>
      )
    }

    return sortedProducts.map((row, i) => {
      const difference =
        row.product_cluster_quote_part - row.product_store_quote_part

      return (
        <TableRow
          key={row.sale_id}
          className="hover:bg-slate-50 transition-colors"
        >
          <TableCell className="hidden md:table-cell text-gray-600 font-medium p-2 md:p-4">
            {i + 1}
          </TableCell>
          <TableCell className="font-medium text-gray-800 p-2 md:p-4 max-w-[120px] md:max-w-none truncate">
            {row.sale_name}
          </TableCell>
          <TableCell className=" hidden md:table-cell font-medium text-gray-800 p-2 md:p-4 max-w-[120px] md:max-w-none truncate">
            {row.sale_name_ida}
          </TableCell>

          <TableCell className="p-2 md:p-4">
            <span className="text-red-600 font-medium">
              {typeof row.product_store_quote_part === "number"
                ? row.product_store_quote_part.toFixed(1)
                : row.product_store_quote_part}
              %
            </span>
          </TableCell>
          <TableCell className="p-2 md:p-4">
            <span className="text-red-600 font-medium">
              {typeof row.product_cluster_quote_part === "number"
                ? row.product_cluster_quote_part.toFixed(1)
                : row.product_cluster_quote_part}
              %
            </span>
          </TableCell>
          <TableCell className="p-2 md:p-4">
            <div className="flex items-center gap-2">
              {difference > 0 && (
                <span className="hidden md:inline text-red-600 font-medium">
                  -{difference.toFixed(1)}%
                </span>
              )}
              {row.potential_revenue_loss > 0 && (
                <span className="hidden md:inline text-gray-700 text-sm">
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: currencyCode,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(row.potential_revenue_loss)}
                </span>
              )}
            </div>
          </TableCell>
        </TableRow>
      )
    })
  }
  const { isSM } = useWindowSize()

  return (
    <Card
      className={`${withColorfulHeader ? "border-0 shadow-md" : "shadow-sm"}`}
    >
      <div
        className={`rounded-t-xl ${
          withColorfulHeader
            ? "text-gray-800 bg-white"
            : "bg-white text-gray-800"
        } p-4 md:p-6`}
        style={getHeaderStyle()}
      >
        <div className="flex flex-col space-y-1 md:space-y-2">
          <div className="flex flex-row items-center gap-1 md:gap-2">
            <TrendingDown className="size-5 md:size-6 text-red-400" />
            <h3 className="text-lg md:text-xl font-semibold text-gray-800">
              {isSM
                ? "Articles en sous-performance"
                : "Articles en sous-performance vs. comparables"}
            </h3>
          </div>
          <p className="hidden md:block text-sm text-gray-600">
            Produits qui se vendent moins dans votre magasin par rapport aux
            magasins comparables. Augmentez les commandes et les mises en avant.
          </p>
        </div>
      </div>
      <CardContent className="p-0">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow className="bg-gray-50 border-b">
                <TableHead className="hidden md:table-cell w-8 md:w-12 font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  #
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "Produit" : "Produit"}
                </TableHead>
                <TableHead className=" hidden md:table-cell  font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "Code vente" : "Code vente"}
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "QP Mag" : "Quote part magasin"}
                </TableHead>
                <TableHead className="font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "QP Comp." : "Quote part comparables"}
                </TableHead>
                <TableHead className=" md:table-cell font-semibold text-gray-700 p-2 md:p-4 text-xs md:text-sm">
                  {isSM ? "" : "Manque à gagner"}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>{renderTableContent()}</TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  )
}
