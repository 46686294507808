import { FormatPackageUnit } from "./FormatPackageUnit"
interface QuantityComparisonProps {
  orderedQuantity: number | null | undefined
  predictedQuantity: number | null | undefined
  colisage: number | null | undefined
}

export function QuantityComparison({
  orderedQuantity,
  predictedQuantity,
  colisage,
}: QuantityComparisonProps) {
  const formattedOrderedQuantity = FormatPackageUnit(orderedQuantity, colisage)
  const formattedPredictedQuantity = FormatPackageUnit(
    predictedQuantity,
    colisage,
  )

  if (
    orderedQuantity === predictedQuantity ||
    orderedQuantity === null ||
    predictedQuantity === null
  ) {
    return (
      <span className="font-medium text-gray-800">
        {formattedOrderedQuantity}
      </span>
    )
  }

  return (
    <div className="flex items-center">
      <span className="line-through text-gray-400 mr-2">
        {formattedPredictedQuantity}
      </span>
      <span className="font-bold text-black-700">
        {formattedOrderedQuantity}
      </span>
    </div>
  )
}
