import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StoreReducerState } from "@/reducers/storeReducer"
import { UserInterfaceReducerState } from "@/reducers/userInterfaceReducer"
import { GetQuarterValueResult } from "@/utils/getQuarterValue"
import { InventoryInput } from "./InventoryInput"
import OutstandingOrders from "../OustandingOrders"
import { InventoryType } from "@/utils/__generated__/graphql"
import { EquivalentInventory } from "./EquivalentInventory"
import { TotalStock } from "./TotalStock"
import { getTotalStocks } from "./getTotalStocks"
import { getDisplayedUnit } from "@/utils/getUnit"
import { useOrderContext } from "@/contexts/OrderContext"
import { ResetPredictionPopover } from "../ResetPrediction/ResetPredictionPopover"
import { ExtraOrderInputSection } from "./ExtraElements/ExtraOrderInputSection"
import { useMemo } from "react"

interface InventoriesInputsDesktopLayoutProps {
  shelfFloorSizeQuarterValue: GetQuarterValueResult
  reference: AllMercurialInfo
  firstReference: AllMercurialInfo
  referenceIndex: number
  storeSettings: StoreReducerState["storeSettings"]
  isOrderDisabled: boolean
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  isOnline: boolean
  backInventoryEquivalent: number
  floorInventoryEquivalent: number
  shelfFloorSizeEquivalent: number
  hasBigBreakage: boolean
  isBestSeller: boolean
  companyName: string | null
  franchise: string | null
  SaleIdIndex: number
  setDisplayedList?: () => void
  isPopoverTriggerable?: boolean
  isRuptureToVerify: boolean
}

export function InventoriesInputsDesktopLayout({
  reference,
  referenceIndex,
  storeSettings,
  isOrderDisabled,
  updateInventory,
  backInventoryEquivalent,
  floorInventoryEquivalent,
  shelfFloorSizeEquivalent,
  firstReference,
  isOnline,
  hasBigBreakage,
  isBestSeller,
  companyName,
  franchise,
  SaleIdIndex,
  setDisplayedList,
  isRuptureToVerify,
  isPopoverTriggerable,
}: InventoriesInputsDesktopLayoutProps) {
  const totalInventoryQuantity = getTotalStocks({
    backInventoryQty: firstReference.back_inventory_qty,
    floorInventoryQty: firstReference.floor_inventory_qty,
    isToVerify: firstReference.stock_to_verify_flag,
  })

  const displayedUnit = getDisplayedUnit(
    storeSettings?.use_kg_pce === true ? reference.unit : "cs",
    companyName,
    franchise,
  )

  const hasBigBreakageNotBestSeller =
    hasBigBreakage &&
    !isBestSeller &&
    reference.promotion !== true &&
    storeSettings?.limit_order_for_high_breakage === true

  const { setLastInputSelected } = useOrderContext()

  const setLastInputSelectedGeneric = (type: InventoryType) => {
    setDisplayedList?.()
    setLastInputSelected({
      type,
      saleId: reference.sale_id ?? "",
      mercurialeId: reference.mercuriale_id ?? "",
      index: referenceIndex,
      saleIdIndex: SaleIdIndex,
    })
  }

  const setLastInputSelectedBack = () =>
    setLastInputSelectedGeneric(InventoryType.Back)
  const setLastInputSelectedShelf = () =>
    setLastInputSelectedGeneric(InventoryType.Shelf)
  const setLastInputSelectedOrder = () =>
    setLastInputSelectedGeneric(InventoryType.Order)

  const potentialRuptureToVerify = useMemo(() => {
    return (
      (reference.time_rupture_flag && !reference.time_rupture_flag_verified) ??
      false
    )
  }, [reference.time_rupture_flag, reference.time_rupture_flag_verified])

  return (
    <div className="flex flex-row lg:justify-between self-center lg:self-end xl:min-w-[450px]">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-end">
          <div className="flex items-end">
            <div className="p-2 flex items-end gap-2 border rounded rounded-br-none relative">
              <p className="block absolute top-1 -right-2 bg-[#6f6f6f] font-bold py-0.5 px-2 rounded text-white text-xs">
                {displayedUnit}
              </p>
              <InventoryInput
                name={InventoryType.Back}
                stockAecouler={reference.stock_a_ecouler ?? false}
                stock_too_high_flag={reference.stock_too_high_flag}
                stock_too_low_flag={reference.stock_too_low_flag}
                stock_to_verify_flag={reference.stock_to_verify_flag}
                mercurialeId={reference.mercuriale_id!}
                unit={reference.unit ?? ""}
                setLastInputSelected={setLastInputSelectedBack}
                waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
                promoWaitedQuantityOrdered={
                  reference.promo_waited_quantity_ordered ?? 0
                }
                value={
                  referenceIndex > 0
                    ? backInventoryEquivalent / (reference.colisage ?? 1)
                    : (reference.back_inventory_qty ?? 0)
                }
                floorInventoryQuantity={
                  referenceIndex > 0
                    ? floorInventoryEquivalent / (reference.colisage ?? 1)
                    : (reference.floor_inventory_qty ?? 0)
                }
                updateInventory={updateInventory}
              />
              <p className="flex items-center text-sm font-bold h-[56px]">+</p>
              <InventoryInput
                name={InventoryType.Shelf}
                mercurialeId={reference.mercuriale_id!}
                unit={reference.unit ?? ""}
                setLastInputSelected={setLastInputSelectedShelf}
                value={
                  referenceIndex > 0
                    ? shelfFloorSizeEquivalent / (reference.colisage ?? 1)
                    : (reference.shelf_floor_size ?? null)
                }
                floorInventoryQuantity={
                  referenceIndex > 0
                    ? floorInventoryEquivalent / (reference.colisage ?? 1)
                    : (reference.floor_inventory_qty ?? 0)
                }
                updateInventory={updateInventory}
              />
              <p className="flex items-center text-sm font-bold h-[56px]">=</p>
              <p className="flex items-center font-bold h-[56px] w-11">
                <TotalStock
                  backInventoryQty={reference.back_inventory_qty}
                  floorInventoryQty={reference.floor_inventory_qty}
                  isToVerify={reference.stock_to_verify_flag}
                  displayZeroBeforeQuarter
                />
              </p>
            </div>
            <div>
              {((reference.waited_quantity_ordered ?? 0) > 0 ||
                (reference.promo_waited_quantity_ordered ?? 0) > 0) && (
                <div className="border border-l-0 bg-gray-50 rounded-r px-1 py-0.5">
                  {(reference.waited_quantity_ordered ?? 0) > 0 && (
                    <OutstandingOrders
                      isPromo={false}
                      quantity={reference.waited_quantity_ordered}
                      displayedUnit={displayedUnit}
                    />
                  )}
                  {(reference.promo_waited_quantity_ordered ?? 0) > 0 && (
                    <OutstandingOrders
                      isPromo={true}
                      quantity={reference.promo_waited_quantity_ordered}
                      displayedUnit={displayedUnit}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="relative ml-2">
            <ResetPredictionPopover
              reference={reference}
              disabled={isOrderDisabled || !isOnline}
              storeSettings={storeSettings}
              hasBigBreakage={hasBigBreakage}
            />
            <div className="flex absolute left-full bottom-[18px] justify-center items-center">
              {/* <ResetPredictionButton
              reference={reference}
              disabled={isUpdateOrderDisabled || !isOnline}
            /> */}
            </div>
          </div>
        </div>
        {referenceIndex === 0 && (
          <div className="flex items-center pl-2 py-0.5">
            <EquivalentInventory
              value={firstReference.back_inventory_qty ?? 0}
              colisage={firstReference.colisage ?? 1}
              unit={firstReference.unit ?? ""}
              useKgPce={storeSettings?.use_kg_pce === true}
              toVerify={firstReference.stock_to_verify_flag === true}
              companyName={companyName}
              franchise={franchise}
              className=" w-[62px] flex items-center justify-center"
            />
            <div className="w-[25px]" />
            <EquivalentInventory
              value={firstReference.floor_inventory_qty ?? 0}
              colisage={firstReference.colisage ?? 1}
              unit={firstReference.unit ?? ""}
              useKgPce={storeSettings?.use_kg_pce === true}
              companyName={companyName}
              franchise={franchise}
              className="w-[86px] flex items-center justify-center"
            />
            <div className="w-[16px]" />
            <EquivalentInventory
              value={totalInventoryQuantity}
              colisage={firstReference.colisage ?? 1}
              unit={firstReference.unit ?? ""}
              useKgPce={storeSettings?.use_kg_pce === true}
              className="w-[78px] flex items-center pl-3"
              companyName={companyName}
              franchise={franchise}
            />
          </div>
        )}
      </div>
      <div className="flex items-center justify-between flex-col gap-2">
        <div className="relative py-2">
          <InventoryInput
            name={InventoryType.Order}
            prediction_uncertain={reference.prediction_uncertain}
            mercurialeId={reference.mercuriale_id!}
            unit={reference.unit ?? ""}
            value={reference?.quantity_actual ?? 0}
            updateInventory={updateInventory}
            setLastInputSelected={setLastInputSelectedOrder}
            disabled={isOrderDisabled}
            isLineaireUpdatedTo0={
              reference.lineaire_updated_to_zero_flag ?? false
            }
            isPrecommande={reference.is_precommande ?? false}
            isPopoverTriggerable={isPopoverTriggerable}
            isBreakage={hasBigBreakageNotBestSeller}
            waitedQuantityOrdered={reference.waited_quantity_ordered ?? 0}
            promoWaitedQuantityOrdered={
              reference.promo_waited_quantity_ordered ?? 0
            }
            potentialRuptureToVerify={
              (potentialRuptureToVerify && isRuptureToVerify) ?? false
            }
            extraOrderInputSection={
              <ExtraOrderInputSection
                reference={reference}
                updateInventory={updateInventory}
                isPotentialRupture={potentialRuptureToVerify}
                displayExtraOrderInputSection={isRuptureToVerify ?? false}
              />
            }
          />
        </div>
        <EquivalentInventory
          value={reference.quantity_actual ?? 0}
          colisage={reference.colisage ?? 1}
          unit={reference.unit ?? ""}
          useKgPce={storeSettings?.use_kg_pce === true}
          companyName={companyName}
          franchise={franchise}
          className={
            reference.time_rupture_flag
              ? "w-[62px] ml-2 flex items-start justify-start"
              : "w-[62px] flex items-center justify-center"
          }
        />
      </div>
    </div>
  )
}
