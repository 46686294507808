"use client" // Make this a client component so it can use React DnD hooks

import { useCallback, useEffect, useMemo, useState } from "react"
import { Outlet, Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import type { DispatchActionType, StateType } from "../types"
import type { ReactElement } from "react"
import LogRocket from "logrocket"

import {
  useGetStoreLazyQuery,
  useGetUserLazyQuery,
} from "../utils/__generated__/graphql"
import { Spinner } from "../ui/Spinner"
import { useAuth0 } from "@auth0/auth0-react"
import { captureException } from "@sentry/react"
import { usePermissions } from "../hooks/usePermissions"
import { toast } from "sonner"

// --- React DnD imports:
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useIntercom } from "@/hooks/useIntercom"

const Root = (): ReactElement => {
  const dispatch = useDispatch<DispatchActionType>()
  const navigate = useNavigate()
  const { loginWithRedirect, isAuthenticated, user } = useAuth0()
  const { isReadOnly } = usePermissions()
  useIntercom()

  const authReducer = useSelector((state: StateType) => state.authReducer)
  const { storeId, companyId, storeName, selectedStoreId } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const storedUser = useSelector((state: StateType) => state.userReducer)

  const [isInfosReady, setIsinfosReady] = useState(false)

  const [getStoreQuery, { loading: isStoreQueryLoading }] =
    useGetStoreLazyQuery()
  const [getUserQuery, { loading: isUserQueryLoading }] = useGetUserLazyQuery()

  const retrieveInformations = useCallback(async () => {
    try {
      const userResult = await getUserQuery()
      if (userResult.data?.user === undefined) {
        throw new Error("User does not exist")
      }

      const storeId = userResult.data.user.store_id
      if (typeof storeId !== "string") {
        throw new Error("No store linked to user")
      }

      const resultStore = await getStoreQuery({
        variables: {
          input: {
            store_id: selectedStoreId ?? storeId,
          },
        },
      })

      if (resultStore.data?.getStore?.error !== null) {
        throw new Error("Une erreur est survenue")
      }

      dispatch({
        type: "setUser",
        payload: {
          email: userResult.data.user.email,
          storeId: userResult.data.user.store_id,
          storeName: userResult.data.user.store_name,
          companyName: userResult.data.user.company_name,
          franchiseName: userResult.data.user.franchise_name ?? undefined,
          userHash: userResult.data.user.user_hash,
        },
      })

      dispatch({
        type: "setStore",
        payload: {
          storeId: resultStore.data?.getStore?.store?.id,
          storeName: resultStore.data?.getStore?.store?.store_name,
          storeCity: resultStore.data?.getStore?.store?.city,
          companyId: resultStore.data?.getStore?.store?.company_id,
          storeCountry: resultStore.data?.getStore?.store?.country,
          storeSettings: resultStore.data?.getStore?.store?.settings,
          storeSuppliers: resultStore.data?.getStore?.suppliers,
          storeStoreSuppliers: resultStore.data.getStore.storeSuppliers,
          storeCurrency: resultStore.data.getStore.store?.currency,
          companyName: resultStore.data.getStore.store?.company_name,
          storeFranchise: resultStore.data.getStore.store?.franchise,
          storeCode: resultStore.data.getStore.store?.store_code,
        },
      })

      if (isReadOnly) {
        dispatch({
          type: "setTrainingMode",
          payload: true,
        })
      }
      setIsinfosReady(true)
    } catch (e) {
      captureException(e)
      console.error(e)
      navigate("/no-store-linked")
    }
  }, [
    dispatch,
    getStoreQuery,
    getUserQuery,
    isReadOnly,
    navigate,
    selectedStoreId,
  ])

  useEffect(() => {
    if (!isAuthenticated && !authReducer.isAuthenticated) {
      const path = window.location.pathname + window.location.search
      dispatch({ type: "setRedirectPath", payload: path })
      loginWithRedirect()
      return
    }
    if (!isAuthenticated) return
    retrieveInformations()
  }, [
    authReducer.isAuthenticated,
    dispatch,
    retrieveInformations,
    isAuthenticated,
    loginWithRedirect,
    navigate,
  ])

  const isLoading = useMemo(() => {
    if (
      isStoreQueryLoading ||
      isUserQueryLoading ||
      !isInfosReady ||
      !isAuthenticated ||
      typeof storeId !== "string"
    ) {
      return true
    }
    return false
  }, [
    isAuthenticated,
    isInfosReady,
    isStoreQueryLoading,
    isUserQueryLoading,
    storeId,
  ])

  useEffect(() => {
    if (
      isLoading ||
      user === undefined ||
      storedUser.storeId === undefined ||
      typeof storeId !== "string" ||
      typeof storeName !== "string" ||
      typeof companyId !== "string"
    )
      return

    LogRocket.identify(user.sub!, {
      email: user.email!,
      storeId: storeId,
      storeName: storeName,
      companyId: companyId,
    })
  }, [companyId, isLoading, storeId, storeName, storedUser.storeId, user])

  useEffect(() => {
    const handleOnline = (): void => {
      dispatch({ type: "setOnline", payload: true })
      toast.success("Connexion internet rétablie")
    }

    const handleOffline = (): void => {
      dispatch({ type: "setOnline", payload: false })
      toast.error("Aucune connexion internet")
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [dispatch])

  if (isLoading) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Spinner invertColors className="w-10 h-10" />
      </div>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {user !== undefined &&
        storedUser.storeId !== undefined &&
        typeof storeId === "string" &&
        storedUser.storeId !== storeId && (
          <div className="fixed bottom-0 left-0 w-full p-1 pb-5 md:pb-1 bg-yellow-500 text-yellow-900 z-50 text-xs justify-center flex gap-2">
            <p>
              <span className="hidden md:inline">
                Vous êtes connecté sur le magasin{" "}
              </span>
              {storeName}
              <span className="hidden md:inline"> !</span>
            </p>
            <Link className="underline" to={`/stores/${storedUser.storeId}`}>
              Revenir
              <span className="hidden md:inline">
                {" "}
                au magasin d&apos;origine
              </span>
            </Link>
          </div>
        )}
      <Outlet />
    </DndProvider>
  )
}

export default Root
