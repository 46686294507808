import { QuantityInput } from "@/components/molecules/quantityInput"
import { ProductQuantitySelector } from "@/components/organisms/productQuantitySelector"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { DispatchActionType, StateType } from "@/types"
import {
  GetOrderItem,
  useUpdateSalesInfoMutation,
} from "@/utils/__generated__/graphql"
import { getDisplayedUnit } from "@/utils/getUnit"
import { captureException } from "logrocket"
import { Trash2 } from "lucide-react"
import { Img } from "react-image"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "sonner"
import { isGetOrderItem } from "../isGetOrderItem"

interface DesktopTableRowProps {
  product: AllMercurialInfo | GetOrderItem
  online: boolean
  quantityActualInUnit: number
  storeCurrency: string | null
  handleDeleteProduct: (product: AllMercurialInfo | GetOrderItem) => void
  orderId: string | null
  isOrderConfirmed: boolean
  quantityIssueClass: string
}

const INCREMENT_PRICE = "INCREMENT_PRICE"
const DECREMENT_PRICE = "DECREMENT_PRICE"

const timeouts: Record<string, NodeJS.Timeout> = {}

export const DesktopTableRow = ({
  product,
  online,
  quantityActualInUnit,
  storeCurrency,
  handleDeleteProduct,
  orderId,
  isOrderConfirmed,
  quantityIssueClass,
}: DesktopTableRowProps) => {
  const { storeId, companyId, storeSettings, companyName, storeFranchise } =
    useSelector((state: StateType) => state.storeReducer)

  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )

  const dispatch = useDispatch<DispatchActionType>()

  const [updateSalesInfo] = useUpdateSalesInfoMutation()

  const formatCurrency = (value: number, currency: string | null) => {
    return new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style: "currency",
      currency: currency ?? "EUR",
    }).format(value)
  }

  const deleteProduct = () => {
    handleDeleteProduct(product)
  }

  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const handleUpdatePv = (newPv?: number) => {
    if (!("sale_id" in product)) return

    dispatch({
      type: "updatePvMercuriale",
      payload: {
        saleId: product.sale_id ?? "",
        pv: newPv ?? 0,
      },
    })

    clearTimeout(timeouts[product.mercuriale_id ?? ""])

    timeouts[product.mercuriale_id ?? ""] = setTimeout(() => {
      const savePvModifications = async (): Promise<void> => {
        try {
          if (!enable) {
            const updateResult = await updateSalesInfo({
              variables: {
                input: {
                  pv: newPv ?? 0,
                  sale_id: product.sale_id ?? "",
                  store_id: storeId ?? "",
                },
              },
            })

            if (updateResult.data?.updateSalesInfo?.error) {
              throw new Error("Échec de la mise à jour du prix de vente")
            }
          }
        } catch (error) {
          console.error(error)
          const errorMessage =
            error instanceof Error ? error.message : "Erreur inconnue"
          captureException(new Error(errorMessage))
          dispatch({
            type: "setDataSynchronizationStatus",
            payload: DataSynchronizationStatus.FAILURE,
          })
          toast.error("Le prix de vente n'a pas pu être mis à jour.")
        }
      }

      if (isOnline && !isTestMode) {
        void savePvModifications()
      } else {
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.UNSYNCHRONIZED,
        })
      }

      delete timeouts[product.mercuriale_id ?? ""]
    }, 1000)
  }

  const handleClick = (type: string) => {
    let newPv = product.pv ?? 0

    if (type === INCREMENT_PRICE) {
      newPv += 0.1
    } else if (type === DECREMENT_PRICE) {
      newPv = Math.max(0, newPv - 0.1)
    }

    newPv = parseFloat(newPv.toFixed(2))
    handleUpdatePv(newPv)
  }

  const isSoldOut = "is_sold_out" in product && (product.is_sold_out ?? false)

  return (
    <div className={`${!isSoldOut && quantityIssueClass} relative`}>
      {isSoldOut && (
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
          <span className="text-red-500 text-sm font-bold">
            Rupture fournisseur
          </span>
        </div>
      )}
      <div
        className={`grid items-center  h-full gap-1 lg:gap-4 p-2 text-base lg:text-lg md:grid-cols-12 lg:grid-cols-9 border-b border-gray-200 ${
          isSoldOut ? "opacity-30" : ""
        }`}
      >
        <div
          className={`flex items-center flex-row gap-4 ${
            orderId === null && !isOrderConfirmed
              ? "lg:col-span-2"
              : "lg:min-w-[550px] lg:col-span-3"
          } md:col-span-3`}
        >
          {online && product?.photo_id ? (
            <Img
              className="w-16 h-16 rounded object-cover bg-white border border-gray-300"
              src={`https://storage.googleapis.com/references_images/${companyId}/${product.photo_id}.jpeg`}
              alt={product?.mercuriale_name ?? ""}
              unloader={
                <div className="w-16 h-16 min-w-16 min-h-16 rounded object-cover bg-white border border-gray-300" />
              }
            />
          ) : (
            <div className="w-16 h-16 min-w-16 min-h-16 rounded object-cover bg-white border border-gray-300" />
          )}
          <p className="flex flex-col items-start">
            <span
              className={`text-left text-md sm:text-sm md:text-md uppercase text-black font-bold line-clamp-2 ${
                isSoldOut ? "line-through" : ""
              }`}
            >
              {product?.mercuriale_name ?? ""}
            </span>
          </p>
        </div>

        <div className="md:table-cell p-0 text-center col-span-1">
          {typeof product.colisage === "number"
            ? `${product.colisage} ${getDisplayedUnit(product.unit, companyName, storeFranchise)}`
            : "-"}
        </div>

        <div className="md:col-span-3 lg:col-span-2">
          {storeSettings?.edit_pv ? (
            <div className="flex align-center justify-center w-full">
              <QuantityInput
                disabled={isSoldOut}
                value={product.pv ?? 0}
                onReducedQuantity={() => handleClick(DECREMENT_PRICE)}
                onIncreasedQuantity={() => handleClick(INCREMENT_PRICE)}
                onQuantityChange={handleUpdatePv}
                unit="€"
              />
            </div>
          ) : (
            <p className="text-center">
              {typeof product.pv === "number"
                ? formatCurrency(product.pv, storeCurrency)
                : "-"}
            </p>
          )}
        </div>

        <div className="table-cell items-center justify-center lg:col-span-1 md:col-span-2">
          <div className="flex align-center justify-center w-full">
            {orderId === null &&
            !isOrderConfirmed &&
            !isGetOrderItem(product) ? (
              <ProductQuantitySelector
                product={product}
                disabled={
                  (orderId === null && !isOrderConfirmed) === false ||
                  isSoldOut === true
                }
              />
            ) : (
              <>
                <p>
                  {product.quantity_actual}{" "}
                  {getDisplayedUnit("cs", companyName, storeFranchise)}
                </p>
              </>
            )}
          </div>
        </div>

        <div
          className={`flex flex-row gap-2 py-0 px-4 justify-center items-center col-span-1 ${isSoldOut ? "line-through" : ""}`}
        >
          <p>
            {typeof product.pa === "number" &&
            !isNaN(product.pa * quantityActualInUnit) &&
            product.pa * quantityActualInUnit > 0
              ? formatCurrency(product.pa * quantityActualInUnit, storeCurrency)
              : "-"}
          </p>
        </div>

        <div
          className={`flex flex-row justify-center items-center gap-2 p-0 col-span-1 ${
            isSoldOut ? "line-through" : ""
          }`}
        >
          <p>
            {typeof product.pv === "number" &&
            !isNaN(product.pv * quantityActualInUnit) &&
            product.pv * quantityActualInUnit > 0
              ? formatCurrency(product.pv * quantityActualInUnit, storeCurrency)
              : "-"}
          </p>
        </div>

        {orderId === null && !isOrderConfirmed && (
          <div className="flex justify-center items-center col-span-1">
            <Dialog>
              <DialogTrigger>
                <Trash2 className="cursor-pointer" />
              </DialogTrigger>

              <DialogContent className="max-w-screen-sm md:min-h-min md:max-h-min">
                <DialogTitle className="text-md md:text-lg">
                  Voulez-vous vraiment retirer {product.mercuriale_name} de
                  votre commande ?
                </DialogTitle>
                <DialogFooter className="flex flex-row justify-end gap-2">
                  <DialogClose asChild>
                    <Button variant="outline">Annuler</Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button
                      className="bg-red-600 hover:bg-red-700"
                      onClick={deleteProduct}
                    >
                      Supprimer
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  )
}
