import { OrderRootOutletContext } from "@/components/order/OrderRoot"
import { Dispatch, SetStateAction, useState } from "react"
import { Outlet, useOutletContext } from "react-router-dom"

export interface MissingReference {
  name: string
  quantity: number
  photoId?: string
  order_id?: string
  error_message?: string
}

export interface SuccessState {
  isOrderConfirmed: boolean
  errorMessage: string | undefined
  missingReferences: MissingReference[]
}

export type RecapContext = [
  {
    synchronisationLoading: boolean
    successState: SuccessState
  },
  Dispatch<SetStateAction<SuccessState>>,
]

export function RootRecap() {
  const [{ synchronisationLoading }] =
    useOutletContext<OrderRootOutletContext>()

  const [successState, setSuccessState] = useState<SuccessState>({
    isOrderConfirmed: false,
    errorMessage: undefined,
    missingReferences: [],
  })

  const context: RecapContext = [
    {
      synchronisationLoading,
      successState,
    },
    setSuccessState,
  ]

  return (
    <div>
      <Outlet context={context} />
    </div>
  )
}
