import { StoreSettings } from "@/utils/__generated__/graphql"

export const filterSteps = (
  storeSettings: StoreSettings | null,
  {
    isPaChanged,
    hasIdaRecommandations,
  }: {
    isPaChanged: boolean
    hasIdaRecommandations: boolean
  },
) => {
  const allSteps = [
    {
      title: "Recommandations IDA",
      key: "ida-recommandations",
      isDisplayed: hasIdaRecommandations,
    },
    {
      title: "Les bonnes affaires",
      key: "price_modifications",
      isDisplayed:
        (isPaChanged && storeSettings?.show_price_modifications) ?? false,
    },
  ]

  return allSteps.filter((step) => step.isDisplayed)
}
