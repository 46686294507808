import { AllMercurialInfo } from "@/reducers/mercurialReducer"

export function getLocalDeliveryOnWeekdayValue(reference: AllMercurialInfo) {
  const today = new Date()
  const day = today.getDay()

  if (day === 1) return reference.local_delivery_on_monday
  if (day === 2) return reference.local_delivery_on_tuesday
  if (day === 3) return reference.local_delivery_on_wednesday
  if (day === 4) return reference.local_delivery_on_thursday
  if (day === 5) return reference.local_delivery_on_friday
  if (day === 6) return reference.local_delivery_on_saturday
  if (day === 0) return reference.local_delivery_on_sunday

  return false
}
