import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StoreSettings } from "./__generated__/graphql"

export function isOrderDisabled(
  reference: AllMercurialInfo,
  bestSellers: AllMercurialInfo[],
  storeSettings: StoreSettings | null,
  hasPermission: boolean,
): boolean {
  const isBackInventoryInvalid = (reference?.back_inventory_qty ?? 0) < 0
  const isFloorInventoryInvalid = (reference?.floor_inventory_qty ?? 0) < 0
  const isSoldButNonActive = reference.sold_but_not_active ?? false
  const isNotActive = reference.active === false
  const hasStockToVerifyFlag = reference.stock_to_verify_flag === true
  const isBestSeller = bestSellers.some(
    (bestSeller) => bestSeller.mercuriale_id === reference.mercuriale_id,
  )

  const hasBigBreakage =
    typeof reference.breakage_percentage === "number" &&
    reference.breakage_percentage > 10

  const hasBigBreakageNotBestSeller =
    hasBigBreakage &&
    !isBestSeller &&
    reference.promotion !== true &&
    storeSettings?.limit_order_for_high_breakage === true
  const isEngagementPromo =
    (reference.is_precommande ?? false) &&
    (storeSettings?.block_order_on_precommande ?? false)
  const receptionDate = reference.mercuriale_reception_date ?? undefined

  const isDisabled =
    isBackInventoryInvalid ||
    isFloorInventoryInvalid ||
    isSoldButNonActive ||
    isNotActive ||
    hasStockToVerifyFlag ||
    hasBigBreakageNotBestSeller ||
    isEngagementPromo ||
    receptionDate === undefined ||
    !hasPermission

  return isDisabled
}
