// components/tab-navigation.tsx
interface Tab {
  id: string
  label: string
  hidden?: boolean
}

interface TabNavigationProps {
  activeTab: string
  onChange: (tabId: string) => void
  tabs: Tab[]
}

export const TabNavigation = ({
  activeTab,
  onChange,
  tabs,
}: TabNavigationProps) => {
  return (
    <nav className="flex -mb-px space-x-8">
      {tabs.map(
        (tab) =>
          !tab.hidden && (
            <button
              key={tab.id}
              onClick={() => onChange(tab.id)}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === tab.id
                  ? "border-black text-black"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              {tab.label}
            </button>
          ),
      )}
    </nav>
  )
}
