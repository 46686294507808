import { Card } from "@/components/ui/card"
import { Package2, ShoppingBag } from "lucide-react"
import { formatEuro } from "./formatter"

interface SummaryCardsProps {
  summaryMetrics: {
    totalDemarque: number
    totalDemarqueUser: number
    totalDemarqueAlgo: number
    totalRupture: number
    totalRuptureUser: number
    totalRuptureAlgo: number
    totalReassortUser: number
    totalCountDemarqueUser: number
    totalCountDemarqueAlgo: number
    totalCountRuptureUser: number
    totalCountRuptureAlgo: number
    totalCountReassortUser: number
    totalCountModifs: number
    totalCountSuivies: number
  }
}

export default function SummaryCards({ summaryMetrics }: SummaryCardsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
      {/* Démarque Card */}
      <Card className="flex items-center p-3 border-red-200">
        <div className="flex items-center p-2 bg-red-50 rounded-full mr-3">
          <Package2 className="w-5 h-5 text-red-600" />
        </div>

        <div className="flex-1">
          <div className="flex items-center justify-between">
            <p className="font-medium text-gray-700">Démarque total</p>
            <p className="font-bold text-lg text-red-600">
              {formatEuro(summaryMetrics.totalDemarque)}
            </p>
          </div>

          <div className="text-xs mt-1 text-gray-500">
            <p className="text-xs font-semibold mb-1">
              Pertes dû à une commande:
            </p>
            <div className="flex justify-between pl-2">
              <div>
                <span className="font-medium"> de l&apos;algo:</span>{" "}
                {formatEuro(summaryMetrics.totalDemarqueAlgo)}
              </div>
              <div>
                <span className="font-medium">de l&apos;utilisateur:</span>{" "}
                {formatEuro(summaryMetrics.totalDemarqueUser)}
              </div>
            </div>
          </div>
        </div>
      </Card>

      {/* Rupture Card */}
      <Card className="flex items-center p-3 border-blue-200">
        <div className="flex items-center p-2 bg-blue-50 rounded-full mr-3">
          <ShoppingBag className="w-5 h-5 text-blue-600" />
        </div>

        <div className="flex-1">
          <div className="flex items-center justify-between">
            <p className="font-medium text-gray-700">Rupture total</p>
            <p className="font-bold text-lg text-blue-600">
              {formatEuro(summaryMetrics.totalRupture)}
            </p>
          </div>

          <div className="text-xs mt-1 text-gray-500">
            <p className="text-xs font-semibold mb-1">
              Pertes dû à une commande:
            </p>
            <div className="flex justify-between pl-2">
              <div>
                <span className="font-medium">de l&apos;algo:</span>{" "}
                {formatEuro(summaryMetrics.totalRuptureAlgo)}
              </div>
              <div>
                <span className="font-medium">de l&apos;utilisateur:</span>{" "}
                {formatEuro(summaryMetrics.totalRuptureUser)}
              </div>
            </div>

            <p className="text-xs font-semibold mt-2 mb-1">
              Perte dû à un manque de réassort:
            </p>
            <div className="pl-2">
              {formatEuro(summaryMetrics.totalReassortUser)}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
