import { Category } from "@/utils/__generated__/graphql"
import { compact } from "lodash"
import { SortSectionOption } from "@/components/order/nav/ScrollToSectionDropdown"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { Predicate, reduceInSections } from "@/utils/array"
import { computeHistoricalRevenue } from "@/utils/revenue"

export type SortSectionMapper = (params: {
  inventory: AllMercurialInfo[]
  unfilteredInventory: AllMercurialInfo[]
  bestSellers: AllMercurialInfo[]
  showNewReferencesFirst?: boolean
  showPromotionsFirst?: boolean
  categoriesOrder?: Category[] | null
  companyName?: string
}) => SortSectionOption[]

const findIndexAndCount = <T>(array: T[], predicate: (item: T) => boolean) => ({
  index: array.findIndex(predicate),
  count: array.filter(predicate).length,
})

export const generateCategorySections: SortSectionMapper = ({
  categoriesOrder,
  inventory,
}) =>
  compact(
    categoriesOrder?.map(({ name }) => {
      const { index, count } = findIndexAndCount(
        inventory,
        ({ family_name }) => (family_name ?? "Autres") === name,
      )
      const category = inventory.find(
        ({ family_name }) => (family_name ?? "Autres") === name,
      )

      return (
        category && {
          index,
          count,
          label: name,
        }
      )
    }),
  )

export const generateAlphabeticalSections: SortSectionMapper = ({
  inventory,
}) =>
  [..."#abcdefghijklmnopqrstuvwxyz"].map((letter) => {
    if (letter === "#") {
      const hasSpecialCharacters = inventory.some(
        (item) => !/[a-z]/.test((item.mercuriale_name ?? "")[0].toLowerCase()),
      )
      return {
        index: hasSpecialCharacters ? 0 : -1,
        label: "#",
      }
    }

    return {
      // no count here to not clutter UI
      index: inventory.findIndex(
        (item) => (item.mercuriale_name ?? "")[0].toLowerCase() === letter,
      ),
      label: letter.toUpperCase(),
    }
  })

export const generateBreakageSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(
      inventory,
      ({ breakage_percentage }) => (breakage_percentage ?? 0) > 10,
    ),
    label: "Pires démarques",
  },
  {
    ...findIndexAndCount(
      inventory,
      ({ breakage_percentage }) => (breakage_percentage ?? 0) <= 10,
    ),
    label: "Sans tri",
  },
]

export const generateNoBreakageSections: SortSectionMapper = ({
  inventory,
}) => [
  {
    ...findIndexAndCount(inventory, (item) => item.pas_de_demarque === true),
    label: "Attention à la démarque",
  },
]

export const generateNoRuptureSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(inventory, (item) => item.pas_de_rupture === true),
    label: "Attention à la rupture",
  },
]

export const generatePromotionSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(inventory, ({ promotion }) => !!promotion),
    label: "Promotion",
  },
  {
    ...findIndexAndCount(inventory, ({ promotion }) => !promotion),
    label: "Sans promotion",
  },
]

export const generateLocalSections: SortSectionMapper = ({ inventory }) => [
  {
    ...findIndexAndCount(inventory, (item) => item.local_flag === true),
    label: "Local",
  },
  {
    ...findIndexAndCount(
      inventory,
      (item) => (item.local_flag ?? false) === false,
    ),
    label: "Reste",
  },
]
const extractInventoryItemId = (item?: AllMercurialInfo) =>
  item?.mercuriale_id ?? undefined

type WithCumulativePercentage = AllMercurialInfo & {
  cumulativeRevenuePercentage?: number
  revenue?: number
}

const addCumulativeRevenuePercentage = (inventory: AllMercurialInfo[]) => {
  let cumulativeRevenuePercentage = 0

  const inventoryWithRevenue = inventory.map((item) => ({
    ...item,
    revenue: computeHistoricalRevenue(item),
  }))

  const totalRevenue = inventoryWithRevenue.reduce(
    (total, item) => total + item.revenue,
    0,
  )

  return inventoryWithRevenue.map((item) => {
    const revenuePercentage = (item.revenue / totalRevenue) * 100
    cumulativeRevenuePercentage += revenuePercentage

    return {
      ...item,
      cumulativeRevenuePercentage,
    }
  })
}

const notSoldSince = (item: WithCumulativePercentage, days: number) => {
  if (
    !item.sale_historical_quantities ||
    item.sale_historical_quantities.length === 0
  ) {
    return true
  }
  const now = new Date()
  const daysAgo = new Date(now.setDate(now.getDate() - days))
  return !item.sale_historical_quantities.some((sale: { date: string }) => {
    const saleDate = new Date(sale.date)
    return saleDate >= daysAgo
  })
}

const notSoldSince30Days = (item: WithCumulativePercentage) =>
  item.has_historical === false

export const generateBestSellerSections: SortSectionMapper = ({
  unfilteredInventory,
  inventory,
  showNewReferencesFirst,
  showPromotionsFirst,
  bestSellers,
}) => {
  const unfilteredInventoryWithRevenue =
    addCumulativeRevenuePercentage(unfilteredInventory)

  // reconcile filtered items with their cumulative revenue on the whole store
  const inventoryWithRevenue = inventory.map((item) => {
    const storeItem = unfilteredInventoryWithRevenue.find(
      ({ mercuriale_id }) => mercuriale_id === item.mercuriale_id,
    )
    return {
      ...item,
      revenue: storeItem?.revenue,
      cumulativeRevenuePercentage: storeItem?.cumulativeRevenuePercentage,
    }
  })

  const promotionsPredicates: Predicate<WithCumulativePercentage>[] = [
    {
      predicate: (item) => !!item.promotion,
      label: "Promotions",
    },
  ]

  const newReferencesPredicates: Predicate<WithCumulativePercentage>[] = [
    {
      predicate: (item) => !!item.new_reference,
      label: "Nouveaux produits",
    },
  ]

  const predicates: Predicate<WithCumulativePercentage>[] = [
    {
      predicate: (item) =>
        bestSellers.some(
          ({ mercuriale_id }) => mercuriale_id === item.mercuriale_id,
        ),
      label: "Top ventes",
    },
    {
      predicate: (item: WithCumulativePercentage) =>
        !!item.revenue &&
        !!item.cumulativeRevenuePercentage &&
        item.cumulativeRevenuePercentage < 50,
      label: "Ventes principales",
      description: "⅓ des ventes du rayon",
    },
    {
      predicate: (item: WithCumulativePercentage) =>
        !!item.revenue &&
        !!item.cumulativeRevenuePercentage &&
        item.cumulativeRevenuePercentage < 75,
      label: `Ventes secondaires`,
      description: "¼ des ventes du rayon",
    },
    {
      predicate: (item: WithCumulativePercentage) =>
        (!!item.revenue &&
          !!item.cumulativeRevenuePercentage &&
          item.cumulativeRevenuePercentage <= 100) ||
        computeHistoricalRevenue(item) > 0,
      label: "Ventes faibles",
    },
    {
      predicate: (item: WithCumulativePercentage) => {
        const quantityReceptions =
          item.delivery_historical_quantities?.reduce(
            (acc, curr) => acc + (curr.quantity ?? 0) / (item.colisage ?? 1),
            0,
          ) ?? 0

        return quantityReceptions > 0
      },
      label: "Livrés, non vendus",
    },
    {
      predicate: (item: WithCumulativePercentage) => notSoldSince(item, 7),
      label: "Non vendus depuis 7 jours",
    },
    {
      predicate: (item: WithCumulativePercentage) => notSoldSince30Days(item),
      label: "Non vendus depuis 30 jours",
    },
  ]

  return reduceInSections(
    inventoryWithRevenue,
    [
      ...(showPromotionsFirst ? promotionsPredicates : []),
      ...(showNewReferencesFirst ? newReferencesPredicates : []),
      ...predicates,
    ],
    extractInventoryItemId,
  )
}

export const generateSortIndexSections: SortSectionMapper = ({
  inventory,
  companyName,
}: {
  inventory: AllMercurialInfo[]
  companyName?: string
}) => {
  const familyNameCounts: Record<string, number> = {}
  inventory.forEach((item) => {
    if (
      item.family_name_navigation !== null &&
      item.family_name_navigation !== undefined
    ) {
      const familyName = item.family_name_navigation
      familyNameCounts[familyName] = (familyNameCounts[familyName] || 0) + 1
    }
  })

  const sortedFamilyNames = Object.entries(familyNameCounts).map(
    ([familyName, count]) => {
      const index = inventory.findIndex(
        (item) => item.family_name_navigation === familyName,
      )
      return {
        label: `${familyName}`,
        index: index,
        count: count,
      }
    },
  )

  if (companyName !== "systemeu" || sortedFamilyNames.length === 0) {
    return [
      {
        label: "Tous les produits",
        index: 0,
      },
    ]
  }

  return sortedFamilyNames.sort((a, b) => a.index - b.index)
}
