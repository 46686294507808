import { useMemo, useEffect, useState } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  GetDetailedModificationProductsQuery,
  useGetDetailedModificationProductsLazyQuery,
} from "@/utils/__generated__/graphql"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { PerformancesPageContext } from "../../PerformancesPage"
import { Spinner } from "@/ui/Spinner"
import { Badge } from "@/components/ui/badge"
import { TrendingDown, TrendingUp, AlertCircle, BarChart3 } from "lucide-react"
import { motion } from "framer-motion"
import { QuantityComparison } from "../QuantityComparison"
import { formatNumber } from "@/utils/formatting"
import { FormatPackageUnit } from "../FormatPackageUnit"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

const modificationTypes: Record<string, string> = {
  USER_BREAKAGE: "Démarque (utilisateur)",
  ALGO_BREAKAGE: "Démarque (algorithme)",
  USER_STOCKOUT: "Rupture (utilisateur)",
  ALGO_STOCKOUT: "Rupture (algorithme)",
  USER_REPLENISHMENT: "Rupture (réassort)",
  USER_OVERSUPPLY: "Surstockage (utilisateur)",
  ALGO_OVERSUPPLY: "Surstockage (algorithme)",
  MISSING_DATA: "Donnée manquante",
}

const typeColors: Record<string, string> = {
  BREAKAGE: "bg-red-100 text-red-900 border border-red-300",
  STOCKOUT: "bg-amber-100 text-amber-900 border border-amber-300",
  OVERSUPPLY: "bg-blue-100 text-blue-900 border border-blue-300",
  REPLENISHMENT: "bg-indigo-100 text-indigo-900 border border-indigo-300",
  MISSING: "bg-gray-100 text-gray-900 border border-gray-300",
}

export interface ProductOccurrence {
  sale_name?: string | null
  sale_name_ida?: string | null
  store_name?: string | null
  order_request_date?: string | null
  value?: number | null
  sold_quantity?: number | null
  stock?: number | null
  ordered_quantity?: number | null
  predicted_quantity?: number | null
  demand_to_last?: number | null
  modification_types?: string | null
  colisage?: number | null
  promotion?: boolean | null
}

export interface QueryResultType {
  data: GetDetailedModificationProductsQuery | undefined
  loading: boolean
  variables?: {
    input: {
      week: number
    }
  }
}

export function ReferenceDetailsView() {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined

  const { selectedStoreId, companyId, storeId } =
    useOutletContext<PerformancesPageContext>()

  const { storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  // Get reference ID and issue type from params
  const referenceId = params.referenceId
  const issueType = params.issueType

  // State for query results
  const [queryResults, setQueryResults] = useState<QueryResultType[]>([])
  const [isLoading, setIsLoading] = useState(true)

  // Get lazy query hook
  const [fetchDetailedProducts] = useGetDetailedModificationProductsLazyQuery()

  // Build a list of weeks (last 12 weeks by default)
  const weeksToFetch = useMemo(() => {
    const today = new Date()
    const weekNumber = getWeekNumber(today)

    // Generate last 12 weeks
    const weeks: number[] = []
    for (let i = 0; i < 12; i++) {
      weeks.push(weekNumber - i)
    }

    return weeks.filter((w) => w > 0) // Ensure all week numbers are positive
  }, [])

  function getWeekNumber(d: Date): number {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
    )
    // Return week number
    return weekNo
  }

  // Fetch data for all weeks
  useEffect(() => {
    const fetchAllWeeks = async () => {
      setIsLoading(true)

      const results = await Promise.all(
        weeksToFetch.map(async (week) => {
          const result = await fetchDetailedProducts({
            variables: {
              input: {
                store_id: storeId ?? "",
                company_id: companyId ?? "",
                franchise_name: franchiseParam ?? storeFranchise,
                week: week,
                modification_type: issueType ?? "",
                selected_store_id: selectedStoreId,
              },
            },
          })

          return {
            ...result,
            variables: {
              input: {
                week,
              },
            },
          }
        }),
      )

      setQueryResults(results)
      setIsLoading(false)
    }

    fetchAllWeeks()
  }, [
    weeksToFetch,
    storeId,
    companyId,
    franchiseParam,
    storeFranchise,
    issueType,
    selectedStoreId,
    fetchDetailedProducts,
  ])

  // Get occurrences of the reference
  const occurrences = useMemo(() => {
    if (isLoading || !referenceId) return []

    const results: ProductOccurrence[] = []

    queryResults.forEach((query) => {
      if (!query.data?.getDetailedModificationProducts?.products) return

      const matchingProducts =
        query.data.getDetailedModificationProducts.products.filter(
          (product: ProductOccurrence) => product.sale_name_ida === referenceId,
        )

      results.push(...matchingProducts)
    })

    return results.sort((a, b) => {
      const dateA = a.order_request_date
        ? new Date(a.order_request_date)
        : new Date(0)
      const dateB = b.order_request_date
        ? new Date(b.order_request_date)
        : new Date(0)

      return dateB.getTime() - dateA.getTime()
    })
  }, [queryResults, isLoading, referenceId])

  function parseModificationTypes(modificationTypes: string) {
    if (!modificationTypes) return []
    return modificationTypes.slice(1, -1).replaceAll(" ", "").split(",")
  }

  function getModificationTypeLabel(modificationType: string) {
    return modificationTypes[modificationType] || "Type inconnu"
  }

  function getBadgeClass(modificationType: string) {
    for (const [key] of Object.entries(typeColors)) {
      if (modificationType.includes(key)) {
        return typeColors[key]
      }
    }
    return "bg-gray-200 text-gray-800 border border-gray-300"
  }

  function getValueColumnTitle() {
    const modType = issueType ?? ""

    if (modType.includes("BREAKAGE")) {
      return "Démarque"
    } else if (
      modType.includes("STOCKOUT") ||
      modType.includes("REPLENISHMENT")
    ) {
      return "Rupture"
    }
    return "Valeur"
  }

  function getValueIcon(value: number) {
    if (value > 0) {
      return <TrendingUp className="h-4 w-4 text-rose-600 inline mr-1" />
    } else if (value < 0) {
      return <TrendingDown className="h-4 w-4 text-emerald-600 inline mr-1" />
    }
    return null
  }

  const MotionCard = motion(Card)

  // Get reference name
  const referenceName =
    occurrences.length > 0 ? occurrences[0].sale_name : referenceId
  const issueTypeLabel = issueType
    ? getModificationTypeLabel(issueType)
    : "Analyse détaillée"

  return (
    <div className="p-6">
      <MotionCard
        className="border border-indigo-100 shadow-lg rounded-xl overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, ease: "easeOut" }}
      >
        <CardHeader className="border-b bg-gradient-to-r from-indigo-100 to-blue-100 p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <CardTitle className="text-xl font-bold text-gray-800 flex items-center flex-wrap">
              <BarChart3 className="mr-3 h-6 w-6 text-indigo-600" />
              Analyse de{" "}
              <span className="mx-1.5 text-indigo-700 font-extrabold underline decoration-2 decoration-indigo-300">
                {issueTypeLabel.toLowerCase()}
              </span>{" "}
              pour la référence{" "}
              <motion.span
                className="ml-2 px-3 py-1 bg-indigo-100 text-indigo-800 rounded-lg shadow-sm border border-indigo-200"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                {referenceName}
              </motion.span>
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-0 bg-white">
          {isLoading ? (
            <motion.div
              className="flex flex-col items-center justify-center p-20"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
              >
                <Spinner className="text-indigo-600 h-10 w-10" />
              </motion.div>
              <p className="mt-6 text-gray-600 font-medium">
                Chargement des données...
              </p>
            </motion.div>
          ) : occurrences.length > 0 ? (
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow className="bg-gradient-to-r from-gray-50 to-indigo-50">
                    <TableHead className="font-semibold text-gray-700">
                      Magasin
                      <div className="text-xs font-normal text-gray-500">
                        Date de commande
                      </div>
                    </TableHead>

                    <TableHead className="font-semibold text-gray-700 py-4">
                      <div>
                        <span>Référence</span>
                        <div className="text-xs font-normal text-gray-500 flex justify-between items-center">
                          <span className="text-gray-500">Code vente</span>
                          <span className="text-indigo-600 font-medium">
                            Colisage
                          </span>
                        </div>
                      </div>
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      {getValueColumnTitle()}
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Ventes (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Stock (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Préd. vs Cmd. (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Demande (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Analyse
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {occurrences.map((product, i) => (
                    <motion.tr
                      key={i}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: i * 0.05 }}
                      className="hover:bg-indigo-50/30 transition-colors border-b border-gray-100"
                      whileHover={{
                        backgroundColor: "rgba(238, 242, 255, 0.5)",
                      }}
                    >
                      <TableCell>
                        <div className="space-y-1">
                          <div className="font-medium text-gray-800">
                            {product.store_name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {product.order_request_date}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <div className="font-medium text-gray-800 flex items-center gap-2">
                            {product.sale_name}
                            {(product.promotion ?? true) && (
                              <Badge
                                variant="secondary"
                                className="bg-blue-100 text-blue-800 border border-blue-300 text-xs px-1.5 py-0.5"
                              >
                                Promo
                              </Badge>
                            )}
                          </div>
                          <div className="text-xs text-gray-500 flex justify-between items-center">
                            <span className="font-mono bg-gray-50 px-2 py-0.5 rounded">
                              {product.sale_name_ida}
                            </span>
                            <span className="bg-indigo-50 text-indigo-700 px-1.5 py-0.5 rounded-md">
                              {product.colisage ?? "N/A"}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="font-semibold">
                        <motion.div
                          className="flex items-center"
                          whileHover={{ scale: 1.05 }}
                          transition={{ duration: 0.2 }}
                        >
                          {getValueIcon(product.value ?? 0)}
                          {formatNumber(product.value ?? 0)} €
                        </motion.div>
                      </TableCell>
                      <TableCell className="text-gray-700">
                        {FormatPackageUnit(
                          product.sold_quantity,
                          product.colisage,
                        )}
                      </TableCell>
                      <TableCell className="text-gray-700">
                        {FormatPackageUnit(product.stock, product.colisage)}
                      </TableCell>
                      <TableCell className="text-gray-700">
                        <div className="flex items-center">
                          <QuantityComparison
                            orderedQuantity={product.ordered_quantity}
                            predictedQuantity={product.predicted_quantity}
                            colisage={product.colisage}
                          />
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-700">
                        {FormatPackageUnit(
                          product.demand_to_last,
                          product.colisage,
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-wrap gap-1.5">
                          {parseModificationTypes(
                            product.modification_types ?? "",
                          ).map((modificationType) => (
                            <motion.div
                              key={modificationType}
                              whileHover={{ scale: 1.05 }}
                              transition={{ duration: 0.2 }}
                            >
                              <Badge
                                className={`font-medium py-1 px-2.5 rounded-lg shadow-sm ${getBadgeClass(modificationType)}`}
                              >
                                {getModificationTypeLabel(modificationType)}
                              </Badge>
                            </motion.div>
                          ))}
                        </div>
                      </TableCell>
                    </motion.tr>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <motion.div
              className="flex flex-col items-center justify-center p-20 text-gray-500"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <AlertCircle className="h-16 w-16 text-gray-400 mb-6" />
              </motion.div>
              <p className="text-xl font-medium">Aucune donnée disponible</p>
              <p className="text-sm mt-2 text-gray-400">
                Aucune occurrence de cette référence trouvée
              </p>
            </motion.div>
          )}
        </CardContent>
      </MotionCard>
    </div>
  )
}
