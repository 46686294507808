import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "@/types"
import {
  InventoryType,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { computeModificationObject } from "@/utils/sendLogsModificationUpdate"
import { captureException } from "@sentry/react"

import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { QuantityInput } from "@/components/molecules/quantityInput"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"

const INCREMENT_QUANTITY = "INCREMENT_QUANTITY"
const DECREMENT_QUANTITY = "DECREMENT_QUANTITY"

interface OrderQuantityButtonProps {
  product: AllMercurialInfo
  disabled?: boolean
}

const timeouts: Record<string, NodeJS.Timeout> = {}

export const ProductQuantitySelector = ({
  product,
  disabled,
}: OrderQuantityButtonProps) => {
  const dispatch = useDispatch<DispatchActionType>()
  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const storeSettings = useSelector(
    (state: StateType) => state.storeReducer.storeSettings,
  )
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const dimMercurialeId = useSelector(
    (state: StateType) => state.mercurialReducer.selectedDimMercurialeId,
  )
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) => dimMercuriale.dimMercurialeId === dimMercurialeId,
  )?.dimOrderRequestId

  const handleQuantityInputChange = (quantity?: number) => {
    if (!("mercuriale_id" in product) || !("order_id" in product)) return
    if (typeof product.mercuriale_id !== "string") return
    if (typeof quantity !== "number") return

    dispatch({
      type: "updateReference",
      payload: {
        mercurialeId: product.mercuriale_id ?? "",
        orderInventoryQuantity: quantity,
        isOrderInventoryQuantityUpdated: true,
      },
    })

    clearTimeout(timeouts[product.mercuriale_id])

    timeouts[product.mercuriale_id] = setTimeout(() => {
      const saveModifications = async (): Promise<void> => {
        const modification = computeModificationObject({
          inventoryType: InventoryType.Order,
          mercurialeInfo: product,
          modifiedValue: quantity,
        })

        try {
          const batchUpdateResult = await batchUpdateMutation({
            variables: {
              input: {
                batch_data: [
                  {
                    order_id: product.order_id,
                    mercuriale_id: product.mercuriale_id!,
                    quantity_actual: quantity,
                    back_inventory: null,
                    floor_inventory: null,
                    shelf_floor_size: null,
                    colisage: product.colisage,
                    dim_mercuriale_id: dimMercurialeId,
                    time_rupture_flag_verified:
                      product.time_rupture_flag_verified,
                    sale_id: null,
                  },
                ],
                dim_order_request_id: dimOrderRequestId,
                store_id: storeId ?? "",
                inventory_type: InventoryType.Order,
              },
            },
          })
          if (batchUpdateResult.data?.batchUpdate?.error !== null) {
            captureException(batchUpdateResult.data?.batchUpdate?.error)
            return
          }

          if (
            typeof batchUpdateResult.data.batchUpdate.dim_order_request_id ===
            "string"
          ) {
            dispatch({
              type: "setDimOrderRequestId",
              payload: {
                dimOrderRequestId:
                  batchUpdateResult.data?.batchUpdate.dim_order_request_id,
                dimMercurialeId: product.dim_mercuriale_id ?? "",
              },
            })
          }

          const logsModificationResult = await logsModificationUpdate({
            variables: {
              input: {
                modifications_logs_items: [modification],
                store_id: storeId!,
              },
            },
          })

          if (
            logsModificationResult.data?.logsModificationUpdate?.error !== null
          ) {
            throw logsModificationResult.data?.logsModificationUpdate?.error
          }
        } catch (error) {
          console.error(error)
          const errorMessage =
            error instanceof Error ? error.message : "Données non sauvegardées"
          captureException(new Error(errorMessage))
          dispatch({
            type: "setDataSynchronizationStatus",
            payload: DataSynchronizationStatus.FAILURE,
          })
          dispatch({
            type: "addModification",
            payload: modification,
          })
        }
      }
      if (isOnline && !isTestMode) {
        void saveModifications()
      } else {
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.UNSYNCHRONIZED,
        })
      }

      delete timeouts[product.mercuriale_id!]
    }, 1000)
  }

  const handleClick = (
    type: typeof INCREMENT_QUANTITY | typeof DECREMENT_QUANTITY,
  ) => {
    const quantity = product.quantity_actual ?? 0
    if (!("mercuriale_id" in product) || !("order_id" in product)) return
    if (type === DECREMENT_QUANTITY && quantity <= 0) return

    const colisage = storeSettings?.use_kg_pce ? (product.colisage ?? 1) : 1
    const newQuantity =
      type === INCREMENT_QUANTITY ? quantity + colisage : quantity - colisage
    handleQuantityInputChange(newQuantity)
  }

  return (
    <QuantityInput
      disabled={disabled}
      value={product.quantity_actual ?? 0}
      onReducedQuantity={() => handleClick(DECREMENT_QUANTITY)}
      onIncreasedQuantity={() => handleClick(INCREMENT_QUANTITY)}
      onQuantityChange={handleQuantityInputChange}
      allowZero
    />
  )
}
