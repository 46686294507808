"use client"

import { useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { useSelector } from "react-redux"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { PerformancesPageContext } from "../../PerformancesPage"
import { useGetEvolutionSalesInfoQuery } from "@/utils/__generated__/graphql"
import { ChartBar, Percent } from "lucide-react"
import ChartTooltipContent from "../predictedCaVsSales/ChartTooltipContent"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { StateType } from "@/types"

interface ChartData {
  date: string
  selectedStore: number
  otherStores: number
}

enum Mode {
  Amount = "amount",
  Quantity = "quantity",
}

// Enum for the trend view toggle
enum TrendView {
  Global = "global",
  Weekly = "weekly",
}

function getISOWeekYear(date: Date): { week: number; year: number } {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  )
  const dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  const year = d.getUTCFullYear()
  const firstDayOfYear = new Date(Date.UTC(year, 0, 1))
  const firstDayNum = firstDayOfYear.getUTCDay() || 7
  const diff = d.getTime() - firstDayOfYear.getTime()
  const daysPassed = Math.floor(diff / (24 * 3600 * 1000))
  const week = Math.floor((daysPassed + (firstDayNum - 1)) / 7) + 1
  return { week, year }
}

export function CAPerformancesPage() {
  const {
    selectedStoreId,
    selectedStoreName,
    fromDate,
    toDate,
    companyId,
    franchise,
  } = useOutletContext<PerformancesPageContext>()

  const { storeId } = useSelector((state: StateType) => state.storeReducer)
  const { data, loading, error } = useGetEvolutionSalesInfoQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise_name: franchise ?? undefined,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        store_id: storeId!,
        selected_store_id: selectedStoreId!,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !companyId || !selectedStoreId,
  })

  const [mode, setMode] = useState<Mode>(Mode.Amount)
  const [trendView, setTrendView] = useState<TrendView>(TrendView.Global)

  const formattedData = useMemo(() => {
    if (!data?.getEvolutionSalesInfo) return []
    const selectedSales = data.getEvolutionSalesInfo.selected_store_sales ?? []
    const otherSales = data.getEvolutionSalesInfo.other_stores_sales ?? []
    const dataMap: Record<string, ChartData> = {}

    selectedSales.forEach((item) => {
      if (!item) return
      const dateKey = item.sale_date
      const value =
        mode === Mode.Amount ? item.sales_amount : item.quantity_sold
      dataMap[dateKey] = {
        date: dateKey,
        selectedStore: value,
        otherStores: 0,
      }
    })

    otherSales.forEach((item) => {
      if (!item) return
      const dateKey = item.sale_date
      const value =
        mode === Mode.Amount ? item.sales_amount : item.quantity_sold
      if (dataMap[dateKey]) {
        dataMap[dateKey].otherStores = value
      } else {
        dataMap[dateKey] = {
          date: dateKey,
          selectedStore: 0,
          otherStores: value,
        }
      }
    })

    const result = Object.values(dataMap)
    result.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    )
    return result
  }, [data, mode])

  const weeklyData = useMemo(() => {
    if (formattedData.length === 0) return []
    const weekGroups: Record<
      string,
      {
        selected: number
        other: number
        count: number
        week: number
        year: number
      }
    > = {}

    formattedData.forEach((entry) => {
      const entryDate = new Date(entry.date)
      const { week, year } = getISOWeekYear(entryDate)
      const key = `${year}-S${week}`
      if (!weekGroups[key]) {
        weekGroups[key] = { selected: 0, other: 0, count: 0, week, year }
      }
      weekGroups[key].selected += entry.selectedStore
      weekGroups[key].other += entry.otherStores
      weekGroups[key].count += 1
    })

    const weeks = Object.values(weekGroups).sort((a, b) => {
      if (a.year === b.year) return a.week - b.week
      return a.year - b.year
    })

    if (weeks.length > 1) {
      const lastWeek = weeks[weeks.length - 1]
      if (lastWeek.count < 7) {
        weeks.pop()
      }
    }
    return weeks
  }, [formattedData])

  const weeklyEvolutions = useMemo(() => {
    if (weeklyData.length < 2) return []
    const evolutions = []
    for (let i = 1; i < weeklyData.length; i++) {
      const prev = weeklyData[i - 1]
      const curr = weeklyData[i]
      const selectedGrowth =
        prev.selected === 0
          ? "0"
          : (((curr.selected - prev.selected) / prev.selected) * 100).toFixed(2)
      const otherGrowth =
        prev.other === 0
          ? "0"
          : (((curr.other - prev.other) / prev.other) * 100).toFixed(2)
      evolutions.push({
        fromWeekKey: `${prev.year}-S${prev.week}`,
        toWeekKey: `${curr.year}-S${curr.week}`,
        selectedGrowth,
        otherGrowth,
        prevSelected: prev.selected,
        currSelected: curr.selected,
        prevOther: prev.other,
        currOther: curr.other,
      })
    }
    return evolutions
  }, [weeklyData])

  const globalTrend = useMemo(() => {
    if (formattedData.length === 0) return null
    const firstNonZeroSelected =
      formattedData.find((day) => day.selectedStore !== 0) ?? formattedData[0]
    const firstNonZeroOther =
      formattedData.find((day) => day.otherStores !== 0) ?? formattedData[0]
    const last = formattedData[formattedData.length - 1]
    const diffDaysSelected =
      (new Date(last.date).getTime() -
        new Date(firstNonZeroSelected.date).getTime()) /
      (1000 * 3600 * 24)
    const diffDaysOther =
      (new Date(last.date).getTime() -
        new Date(firstNonZeroOther.date).getTime()) /
      (1000 * 3600 * 24)
    const selectedGrowth =
      diffDaysSelected > 0 && firstNonZeroSelected.selectedStore !== 0
        ? (Math.pow(
            last.selectedStore / firstNonZeroSelected.selectedStore,
            1 / diffDaysSelected,
          ) -
            1) *
          100
        : 0
    const otherGrowth =
      diffDaysOther > 0 && firstNonZeroOther.otherStores !== 0
        ? (Math.pow(
            last.otherStores / firstNonZeroOther.otherStores,
            1 / diffDaysOther,
          ) -
            1) *
          100
        : 0
    return {
      firstDate: firstNonZeroSelected.date,
      lastDate: last.date,
      selectedGrowth,
      otherGrowth,
    }
  }, [formattedData])

  return (
    <div className="p-2 w-full max-w-[1200px] mx-auto space-y-2">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="flex items-center gap-2">
            <ChartBar className="text-gray-500" />
            Évolution{" "}
            {mode === Mode.Amount
              ? "du chiffre d'affaire"
              : "de la quantité vendue"}
          </CardTitle>
          <Select value={mode} onValueChange={(value: Mode) => setMode(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Mode" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={Mode.Amount} defaultChecked>
                Chiffre d&apos;affaire
              </SelectItem>
              <SelectItem value={Mode.Quantity}>Quantité vendue</SelectItem>
            </SelectContent>
          </Select>
        </CardHeader>
        <CardContent>
          {!selectedStoreId && (
            <p>
              Aucun magasin sélectionné. Veuillez sélectionner un magasin pour
              afficher les performances.
            </p>
          )}
          {loading && <Skeleton className="h-[350px] w-full" />}
          {error && (
            <div className="text-red-500 p-4">Erreur lors du chargement.</div>
          )}
          {!loading && formattedData.length > 0 && (
            <div className="w-full" style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={formattedData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value) =>
                      new Intl.DateTimeFormat("fr-FR", {
                        month: "2-digit",
                        day: "2-digit",
                      }).format(new Date(value))
                    }
                  />
                  <YAxis
                    tickFormatter={(value) =>
                      mode === Mode.Amount
                        ? new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                            notation: "compact",
                            compactDisplay: "short",
                          }).format(value)
                        : new Intl.NumberFormat("fr-FR", {
                            style: "decimal",
                            notation: "compact",
                            compactDisplay: "short",
                          }).format(value)
                    }
                  />
                  <Tooltip
                    content={({ active, payload, label }) => (
                      <ChartTooltipContent
                        active={active}
                        payload={payload}
                        label={label}
                        type={mode}
                      />
                    )}
                  />
                  <Legend />
                  <Line
                    name={selectedStoreName}
                    type="monotone"
                    dataKey="selectedStore"
                    stroke="#3b82f6"
                    strokeWidth={2}
                  />
                  <Line
                    name="Moyenne autres magasins"
                    type="monotone"
                    dataKey="otherStores"
                    stroke="#10b981"
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </CardContent>
      </Card>

      {selectedStoreId && (
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <CardTitle className="flex items-center gap-2">
              <Percent className="text-gray-500" />
              Taux de croissance{" "}
              {mode === Mode.Amount ? "du CA" : "de la quantité"}
            </CardTitle>
            <Select
              value={trendView}
              onValueChange={(value: TrendView) => setTrendView(value)}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Mode de tendance" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={TrendView.Global} defaultChecked>
                  Globale
                </SelectItem>
                <SelectItem value={TrendView.Weekly}>Par semaine</SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
          <CardContent className="min-h-[100px]">
            {loading ? (
              <Skeleton className="h-20 w-full" />
            ) : trendView === TrendView.Global ? (
              globalTrend ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <span className="font-semibold">{selectedStoreName}</span>
                    <span
                      className={
                        Number(globalTrend.selectedGrowth) > 0
                          ? "text-green-600"
                          : "text-red-600"
                      }
                    >
                      {globalTrend.selectedGrowth.toString().slice(0, 5)}%
                    </span>
                    <span className="text-xs text-gray-500">
                      ({globalTrend.firstDate} → {globalTrend.lastDate})
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      Moyenne autres magasins
                    </span>
                    <span
                      className={
                        Number(globalTrend.otherGrowth) > 0
                          ? "text-green-600"
                          : "text-red-600"
                      }
                    >
                      {globalTrend.otherGrowth.toString().slice(0, 5)}%
                    </span>
                    <span className="text-xs text-gray-500">
                      ({globalTrend.firstDate} → {globalTrend.lastDate})
                    </span>
                  </div>
                </div>
              ) : (
                <p>Aucune donnée globale complète disponible.</p>
              )
            ) : weeklyEvolutions.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Période
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {selectedStoreName} (%)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Moyenne autres (%)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {weeklyEvolutions.map((evo) => (
                      <tr key={`${evo.fromWeekKey}-${evo.toWeekKey}`}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {evo.fromWeekKey} → {evo.toWeekKey}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={
                              Number(evo.selectedGrowth) > 0
                                ? "text-green-600"
                                : "text-red-600"
                            }
                          >
                            {evo.selectedGrowth === "N/A"
                              ? "N/A"
                              : `${evo.selectedGrowth}%`}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={
                              Number(evo.otherGrowth) > 0
                                ? "text-green-600"
                                : "text-red-600"
                            }
                          >
                            {evo.otherGrowth === "N/A"
                              ? "N/A"
                              : `${evo.otherGrowth}%`}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Skeleton className="h-20 w-full" />
            )}
          </CardContent>
        </Card>
      )}
    </div>
  )
}
