import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { MercurialReducerState } from "@/reducers/mercurialReducer"
import { DispatchActionType, StateType } from "@/types"
import {
  BatchUpdateData,
  BatchUpdateMutation,
  LogsModificationUpdateMutation,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { getBatchData } from "@/utils/getBatchData"
import { FetchResult } from "@apollo/client"
import { captureException } from "@sentry/react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "sonner"

interface UseSaveDataReturn {
  isLoading: boolean
  saveData: () => Promise<void>
}

export function useSaveData(): UseSaveDataReturn {
  const dispatch = useDispatch<DispatchActionType>()
  const [batchUpdateMutation, { loading: batchUpdateLoading }] =
    useBatchUpdateMutation()
  const [
    logsModificationUpdateMutation,
    { loading: logsModificationUpdateLoading },
  ] = useLogsModificationUpdateMutation()

  const {
    updatedReferences,
    modifications,
    mercurialAndStoreInventories,
    dimMercuriales,
  } = useSelector((state: StateType) => state.mercurialReducer)
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  async function saveData() {
    const notSavedReferences = Object.values(updatedReferences).reduce<
      MercurialReducerState["updatedReferences"]
    >((acc, curr) => {
      if (
        modifications.some(
          (modification) => modification.mercuriale_id === curr.mercurialeId,
        )
      ) {
        acc[curr.mercurialeId] = curr
        return acc
      }
      return acc
    }, {})
    const batchData = getBatchData(
      mercurialAndStoreInventories,
      notSavedReferences,
    )
    try {
      let batchUpdateResults: FetchResult<BatchUpdateMutation>[] | undefined =
        undefined

      const filteredBatchDatas = dimMercuriales?.reduce<
        {
          dimOrderRequestId: string | undefined
          data: BatchUpdateData[]
        }[]
      >((acc, curr) => {
        const filteredBatchData = batchData.filter(
          (data) => data.dim_mercuriale_id === curr.dimMercurialeId,
        )
        if (filteredBatchData.length === 0) return acc
        return [
          ...acc,
          {
            dimOrderRequestId: curr.dimOrderRequestId,
            data: filteredBatchData,
          },
        ]
      }, [])

      if (filteredBatchDatas !== undefined) {
        batchUpdateResults = await Promise.all(
          filteredBatchDatas.map((filteredBatchData) =>
            batchUpdateMutation({
              variables: {
                input: {
                  batch_data: filteredBatchData.data,
                  dim_order_request_id: filteredBatchData.dimOrderRequestId,
                  store_id: storeId ?? "",
                  inventory_type: undefined,
                },
              },
            }),
          ),
        )

        batchUpdateResults.forEach((result) => {
          if (result.data?.batchUpdate?.error !== null) {
            throw result.data?.batchUpdate.error
          }
        })
      }

      let logsModificationUpdateResult:
        | FetchResult<LogsModificationUpdateMutation>
        | undefined = undefined

      logsModificationUpdateResult = await logsModificationUpdateMutation({
        variables: {
          input: {
            store_id: storeId ?? "",
            modifications_logs_items: modifications,
          },
        },
      })
      if (
        logsModificationUpdateResult.data?.logsModificationUpdate?.error !==
        null
      ) {
        throw logsModificationUpdateResult.data?.logsModificationUpdate?.error
      }

      batchUpdateResults?.forEach((batchUpdateResult, i) => {
        const dimMercurialeId =
          filteredBatchDatas?.[i].data[0].dim_mercuriale_id
        if (
          typeof batchUpdateResult?.data?.batchUpdate.dim_order_request_id ===
            "string" &&
          typeof dimMercurialeId === "string"
        ) {
          dispatch({
            type: "setDimOrderRequestId",
            payload: {
              dimMercurialeId: dimMercurialeId,
              dimOrderRequestId:
                batchUpdateResult.data?.batchUpdate.dim_order_request_id,
            },
          })
        }
      })
      dispatch({
        type: "setModifications",
        payload: [],
      })
      dispatch({
        type: "setDataSynchronizationStatus",
        payload: DataSynchronizationStatus.SYNCHRONIZED,
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Données non sauvegardées")
    }
  }

  return {
    isLoading: batchUpdateLoading || logsModificationUpdateLoading,
    saveData,
  }
}
