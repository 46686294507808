import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { DispatchActionType, StateType } from "@/types"

export const useOrderModalsLogic = ({
  isLoading,
  isOfflineRefreshModalOpen,
  setIsDataSynchronizeModalOpen,
  setIsOrderReceptionDateModalOpen,
  setIsRefreshModalOpen,
}: {
  isLoading: boolean
  isOfflineRefreshModalOpen: boolean
  setIsDataSynchronizeModalOpen?: (value: boolean) => void
  setIsOrderReceptionDateModalOpen?: (value: boolean) => void
  setIsRefreshModalOpen?: (value: boolean) => void
}) => {
  const dispatch = useDispatch<DispatchActionType>()
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const orderDate = useSelector(
    (state: StateType) => state.mercurialReducer.orderDate,
  )
  const refreshModalCloseDate = useSelector(
    (state: StateType) => state.updateReducer.refreshModalCloseDate,
  )
  const companyName = useSelector(
    (state: StateType) => state.storeReducer.companyName,
  )

  const isValidOrderDate = useMemo(() => {
    if (isLoading) return true
    const currentDate = formatDateToYYYYMMDD(new Date())
    return orderDate === currentDate
  }, [isLoading, orderDate])

  function refreshApplication() {
    window.location.href = `/?date=${new Date().getTime()}`
  }

  useEffect(() => {
    if (online && isOfflineRefreshModalOpen) {
      setIsRefreshModalOpen?.(false)
      dispatch({
        type: "setRefreshModalUpdateDate",
        payload: new Date().toString(),
      })
      refreshApplication()
    }
  }, [dispatch, isOfflineRefreshModalOpen, online, setIsRefreshModalOpen])

  useEffect(() => {
    if (window.location.search.includes("date")) {
      window.history.replaceState({}, document.title, window.location.pathname)
      setIsDataSynchronizeModalOpen?.(true)
      return
    }
    if (!isLoading) {
      setIsDataSynchronizeModalOpen?.(false)
    }
  }, [isLoading, setIsDataSynchronizeModalOpen])

  useEffect(() => {
    const currentDate = new Date()
    if (
      isValidOrderDate ||
      (refreshModalCloseDate !== undefined &&
        getDiffBetweenDates(currentDate, new Date(refreshModalCloseDate)) >= 0)
    ) {
      if (companyName === "biomonde") {
        setIsOrderReceptionDateModalOpen?.(false)
        return
      }
      setIsRefreshModalOpen?.(false)
      return
    }

    if (companyName === "biomonde") {
      setIsOrderReceptionDateModalOpen?.(true)
      return
    }
    setIsRefreshModalOpen?.(true)
  }, [
    companyName,
    isValidOrderDate,
    refreshModalCloseDate,
    setIsOrderReceptionDateModalOpen,
    setIsRefreshModalOpen,
  ])

  return { isValidOrderDate, refreshApplication }
}
