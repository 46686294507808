import { FC } from "react"
import { TooltipProps } from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"

export const ChartTooltipContent: FC<
  TooltipProps<ValueType, NameType> & { type?: "amount" | "quantity" }
> = ({ active, payload, label, type = "amount" }) => {
  if (active && payload?.length) {
    return (
      <div className="backdrop-blur-sm p-4 rounded-md shadow-lg">
        <p className="text-sm font-semibold text-var(--color-tooltip-text)">
          {new Intl.DateTimeFormat("fr-FR", {
            weekday: "long",
            day: "2-digit",
            month: "long",
            year: "numeric",
          }).format(new Date(label))}
        </p>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="flex items-center gap-2 mt-1">
            <span
              className="inline-block w-3 h-3 rounded-full"
              style={{ backgroundColor: entry.color }}
            ></span>
            <span className="text-xs text-var(--color-tooltip-text)">
              {entry.name} :{" "}
              {type === "amount"
                ? new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(entry.value as number)
                : new Intl.NumberFormat("fr-FR", {
                    maximumFractionDigits: 0,
                  }).format(entry.value as number)}
            </span>
          </div>
        ))}
      </div>
    )
  }

  return null
}

export default ChartTooltipContent
