import { OrderRecord } from "../OrderTable"

interface MobileOrderListProps {
  records: OrderRecord[]
}

export default function MobileOrderList({ records }: MobileOrderListProps) {
  return (
    <div className="w-full md:hidden">
      <div className="sticky top-0 z-10 bg-gray-50 border-b py-3 px-2 flex items-center justify-between mb-1">
        <div className="text-sm font-medium text-gray-700">
          Magasin - Produit
        </div>
        <div className="text-sm font-medium text-gray-700">Préd./Cmds.</div>
      </div>

      <div className="max-h-[calc(100vh-180px)] overflow-y-auto">
        {records.map((record, index) => {
          const oldQuantity = record.final_quantity_predicted_in_colis ?? 0
          const newQuantity = record.quantity_ordered_in_colis ?? 0
          const hasChange = oldQuantity !== newQuantity

          return (
            <div key={index} className="py-3 border-b last:border-b-0">
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <div className="text-sm font-bold text-gray-900">
                    {record.store_name} - {record.sale_name}
                  </div>
                  <div className="text-xs text-gray-500">
                    <div className="text-xs text-gray-500">
                      Réception: {record.expected_reception_date?.slice(0, 10)}
                    </div>
                    {record.colisage ? `colisage: ${record.colisage}` : ""}
                    {record.sale_name_ida
                      ? ` - code: ${record.sale_name_ida}`
                      : ""}
                  </div>
                </div>
                <div className="text-right">
                  {hasChange ? (
                    <div className="flex items-center justify-end space-x-1">
                      <span className="text-sm line-through text-gray-500">
                        {oldQuantity}
                      </span>
                      <span className="text-sm font-bold">{newQuantity}</span>
                    </div>
                  ) : (
                    <div className="text-sm">{oldQuantity}</div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
