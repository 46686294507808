import { useMemo } from "react"
import { useGetPredictionsModifByStoreAndReferenceQuery } from "@/utils/__generated__/graphql"

export const usePredictionsData = (
  companyId: string,
  storeId: string,
  selectedStoreId: string | null,
  franchiseParam: string | undefined,
  formattedFromDate: string,
  formattedToDate: string,
) => {
  const { data, loading, error } =
    useGetPredictionsModifByStoreAndReferenceQuery({
      variables: {
        input: {
          company_id: companyId,
          store_id: storeId,
          selected_store_id: selectedStoreId ?? undefined,
          franchise_name: franchiseParam,
          start_date: formattedFromDate,
          end_date: formattedToDate,
        },
      },
      fetchPolicy: "cache-and-network",
    })

  const allRecords = useMemo(
    () =>
      data?.getPredictionsModifByStoreAndReference?.performances_daily ?? [],
    [data],
  )

  const predictionStats = useMemo(() => {
    const totalCount = allRecords.length

    const increaseCount = allRecords.filter(
      (rec) =>
        (rec.updated || 0) > 0 &&
        (rec.quantity_ordered_in_colis ?? 0) >
          (rec.final_quantity_predicted_in_colis ?? 0),
    ).length

    const decreaseCount = allRecords.filter(
      (rec) =>
        (rec.updated || 0) > 0 &&
        (rec.quantity_ordered_in_colis ?? 0) <
          (rec.final_quantity_predicted_in_colis ?? 0),
    ).length

    const dateMap = new Map()
    for (const rec of allRecords) {
      const d = rec.order_request_date?.slice(0, 10) ?? ""
      if (!d) continue
      if (!dateMap.has(d)) {
        dateMap.set(d, { total: 0, modif: 0 })
      }
      const info = dateMap.get(d)
      info.total++
      if ((rec.updated || 0) > 0) {
        info.modif++
      }
    }

    const dateRatios = Array.from(dateMap.entries())
      .map(([dateStr, info]) => {
        const ratio = info.total ? info.modif / info.total : 0
        return { date: dateStr, ratio }
      })
      .sort((a, b) => a.date.localeCompare(b.date))

    let dateTrend = 0
    if (dateRatios.length >= 2) {
      let sumDiff = 0
      for (let i = 1; i < dateRatios.length; i++) {
        sumDiff += dateRatios[i].ratio - dateRatios[i - 1].ratio
      }
      dateTrend = Math.round(sumDiff * 100)
    }

    const updatedRecords = allRecords.filter((rec) => (rec.updated || 0) > 0)
    const modificationsCount = updatedRecords.length

    const totalModDistance = updatedRecords.reduce((acc, rec) => {
      const diff =
        (rec.quantity_ordered_in_colis ?? 0) -
        (rec.final_quantity_predicted_in_colis ?? 0)
      return acc + Math.abs(diff)
    }, 0)

    const meanModDistance = modificationsCount
      ? totalModDistance / modificationsCount
      : 0

    const percentModifications =
      totalCount > 0 ? Math.round((modificationsCount / totalCount) * 100) : 0

    return {
      totalCount,
      increaseCount,
      decreaseCount,
      percentModifications,
      dateTrend,
      meanModDistance,
      totalModDistance,
      modificationsCount,
    }
  }, [allRecords])

  return {
    allRecords,
    predictionStats,
    isLoading: loading,
    error,
  }
}
