import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useDrop } from "react-dnd"
import { DragPayload, ReferencesForMatching } from "./types"
import { toast } from "sonner"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { twJoin } from "tailwind-merge"
import { Input } from "@/components/ui/input"
import { Virtuoso } from "react-virtuoso"
import { UnmatchedReferenceCard } from "./UnmatchedReferenceCard"

interface UnmatchedReferencesSidebarProps {
  unmatched: ReferencesForMatching
  rowSelection: Record<string, boolean>
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>
  localReferences: ReferencesForMatching
  setLocalReferences: Dispatch<SetStateAction<ReferencesForMatching>>
  anyIsDragging: boolean
  dragType: string
  onDelete: (draggedRef: ReferencesForMatching[number]) => Promise<void>
  companyName: string
  franchise: string | undefined
}

export function UnmatchedReferencesSidebar({
  unmatched,
  rowSelection,
  setRowSelection,
  setLocalReferences,
  anyIsDragging,
  dragType,
  onDelete,
  companyName,
  franchise,
}: UnmatchedReferencesSidebarProps) {
  const [unmatchedSearchTerm, setUnmatchedSearchTerm] = useState("")

  // Filter unmatched
  const filteredUnmatched = useMemo(() => {
    if (!unmatchedSearchTerm.trim()) return unmatched
    const lower = unmatchedSearchTerm.toLowerCase()
    return unmatched.filter(
      (u) =>
        u.libelle_base.toLowerCase().includes(lower) ||
        u.sale_name_ida_base.toLowerCase().includes(lower),
    )
  }, [unmatched, unmatchedSearchTerm])

  // Drop zone => un-match references
  const [{ isOver }, dropRef] = useDrop<DragPayload, void, { isOver: boolean }>(
    {
      accept: dragType,
      drop: (dragPayload, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) return

        dragPayload.items.forEach((draggedRef) => {
          if (!draggedRef.is_matched) return // already unmatched

          // optimistic update
          setLocalReferences((prev) =>
            prev.map((item) => {
              if (item.sale_name_ida_base === draggedRef.sale_name_ida_base) {
                return {
                  ...item,
                  is_matched: false,
                  sale_name_ida_cible: "-",
                }
              }
              return item
            }),
          )

          async function deleteMatching() {
            try {
              await onDelete(draggedRef)
            } catch (err) {
              console.error(err)
              if (err instanceof Error) {
                toast.error(err.message)
              }
            }
          }
          deleteMatching()
        })
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
  )

  const handleToggleSelected = useCallback(
    (item: ReferencesForMatching[number]) => {
      setRowSelection((prev) => ({
        ...prev,
        [item.sale_name_ida_base]: !prev[item.sale_name_ida_base],
      }))
    },
    [setRowSelection],
  )

  return (
    <Card
      ref={dropRef}
      className={twJoin(
        "w-64 overflow-y-hidden flex flex-col transition-colors",
        isOver && "border-red-600 border-2",
      )}
    >
      <CardHeader className="p-2">
        <CardTitle className="text-base flex justify-between">
          Références non liées
          <span className="text-gray-500">{filteredUnmatched.length}</span>
        </CardTitle>
        <Input
          type="text"
          placeholder="Rechercher..."
          value={unmatchedSearchTerm}
          onChange={(e) => setUnmatchedSearchTerm(e.target.value)}
          className="mb-2 w-full rounded border px-2 py-1 text-sm"
        />
      </CardHeader>
      <CardContent className="p-2 flex-1 overflow-y-auto">
        {filteredUnmatched.length === 0 ? (
          <p className="text-sm italic">Aucune référence non matchée.</p>
        ) : (
          <Virtuoso
            data={filteredUnmatched}
            itemContent={(_, unmatchedItem) => (
              <UnmatchedReferenceCard
                key={unmatchedItem.sale_name_ida_base}
                refItem={unmatchedItem}
                checked={!!rowSelection[unmatchedItem.sale_name_ida_base]}
                onToggleSelected={() => handleToggleSelected(unmatchedItem)}
                rowSelection={rowSelection}
                unmatched={filteredUnmatched}
                anyIsDragging={anyIsDragging}
                dragType={dragType}
                companyName={companyName}
                franchise={franchise}
              />
            )}
          />
        )}
      </CardContent>
    </Card>
  )
}
