// src/components/UnmatchedReferencesTable.tsx

"use client"

import React, { useState, useEffect, useMemo, useCallback } from "react"
import {
  SalesDeliveryRecord,
  useGetSalesDeliveriesStatusQuery,
  useGetStoresQuery,
} from "@/utils/__generated__/graphql"
import { add } from "date-fns"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { ColumnDef } from "@tanstack/react-table"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { UnMatchedDataTable } from "./UnMatchedDataTable"

import { toast } from "sonner"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { useParams, useSearchParams } from "react-router-dom"
import {
  ArrowUpDown,
  PackageIcon,
  TrendingUpIcon,
  TruckIcon,
} from "lucide-react"
import { Badge } from "@/components/ui/badge"
import MatchSaleIdDialog from "./MatchSaleIdDialog"
import { StoreSelector } from "../../PerformancesPage/components/StoreSelector"
import { DateSelector } from "../../PerformancesPage/components/DateSelector"
import { DateRange } from "react-day-picker"
import { Spinner } from "@/ui/Spinner"

const UnmatchedReferencesTable = () => {
  const [filterType, setFilterType] = useState<
    | "no_sales_but_deliveries"
    | "no_deliveries_but_sales"
    | "no_orders_but_sales"
    | "no_deliveries_but_orders"
    | "no_sales_but_orders"
  >("no_sales_but_deliveries")
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const fromParam = searchParams.get("from") ?? undefined
  const toParam = searchParams.get("to") ?? undefined
  const companyId = params.companyId

  const {
    data: storesData,
    loading: storesLoading,
    error: storesError,
  } = useGetStoresQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise: franchiseParam,
        include_inactive: false,
      },
    },
  })

  const stores = useMemo(
    () => storesData?.getStores?.stores ?? [],
    [storesData],
  )

  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(null)

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: fromParam ? new Date(fromParam) : defaultStartDate,
    to: toParam ? new Date(toParam) : defaultEndDate,
  })

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)

  const { data, loading, error, refetch } = useGetSalesDeliveriesStatusQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise_name: franchiseParam,
        start_date: formatDateToYYYYMMDD(fromDate),
        end_date: formatDateToYYYYMMDD(toDate),
        filter_type: filterType,
        start_rank: 1,
        end_rank: 10000,
        store_id: storeId!,
        selected_store_id: selectedStoreId,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !storeId, // Avoid making the query if no storeId
  })

  useEffect(() => {
    if (error) {
      toast.error("Une erreur est survenue lors du chargement des données.")
    }
  }, [error])

  const performances = useMemo(() => {
    return data?.getSalesDeliveriesStatus.records ?? []
  }, [data])

  const handleFilterSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    refetch()
  }

  // State for dialog
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<SalesDeliveryRecord | null>(
    null,
  )

  // Callback after successful match
  const handleMatchSuccess = useCallback(() => {
    toast.success("Matching réussi avec succès.")
    refetch()
  }, [refetch])

  const columns: ColumnDef<SalesDeliveryRecord>[] = useMemo(
    () => [
      {
        accessorKey: "store_name",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Magasin
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-center">{info.getValue() as string}</div>
        ),
      },
      {
        accessorKey: "sale_name_ida",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Code vente (base)
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: (info) => (
          <div className="text-center">{info.getValue() as string}</div>
        ),
      },
      {
        accessorKey: "sale_name_ida_cible",
        header: "Code vente (cible)",
        cell: ({ row }) =>
          row.original.sale_name_ida_cible ? (
            <Badge variant="default" className="font-mono">
              {row.original.sale_name_ida_cible}
            </Badge>
          ) : (
            <Badge variant="secondary" className="font-mono">
              N/A
            </Badge>
          ),
      },
      {
        accessorKey: "libelle_cible",
        header: "Nom (cible)",
        cell: ({ row }) =>
          row.original.libelle_cible ? (
            <Badge variant="default" className="font-mono text-center">
              {row.original.libelle_cible}
            </Badge>
          ) : (
            <Badge variant="secondary" className="font-mono">
              N/A
            </Badge>
          ),
      },
      {
        accessorKey: "sale_id",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Sale ID
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "store_id",
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Store ID
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "sale_name",
        cell: ({ row }) => (
          <div className="text-center">{row.original.sale_name}</div>
        ),
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Nom
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "total_sales",
        cell: ({ getValue }) => (
          <div className="flex items-center gap-2">
            <TrendingUpIcon className="w-4 h-4" />
            {getValue<number>().toFixed(2)}
          </div>
        ),
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Ventes
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "total_deliveries",
        cell: ({ getValue }) => (
          <div className="flex items-center gap-2">
            <TruckIcon className="w-4 h-4" />
            {getValue<number>().toFixed(2)}
          </div>
        ),
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Livraisons
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        ),
      },
      {
        accessorKey: "total_ordered_quantity",
        cell: ({ getValue }) => (
          <div className="flex items-center gap-2">
            <PackageIcon className="w-4 h-4" />
            {getValue<number>().toFixed(2)}
          </div>
        ),
        header: ({ column }) => (
          <Button
            variant="ghost"
            onClick={() =>
              column.toggleSorting(
                column.getIsSorted() === "asc" ? false : true,
              )
            }
          >
            Commandes
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
      },
      {
        id: "actions", // Unique identifier
        header: "Actions",
        cell: ({ row }) => (
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setSelectedRow(row.original)
              setIsDialogOpen(true)
            }}
          >
            Lier
          </Button>
        ),
      },
    ],
    [],
  )

  if (storesLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (storesError) {
    return <div className="text-red-500">Error fetching stores.</div>
  }

  return (
    <div className="flex flex-col flex-1 overflow-y-hidden pt-2">
      <div className="px-2">
        <form onSubmit={handleFilterSubmit} className="flex gap-2 flex-wrap">
          <StoreSelector
            stores={stores}
            selectedStoreId={selectedStoreId}
            onSelect={(storeId) => setSelectedStoreId(storeId)}
          />
          <DateSelector
            fromDate={fromDate}
            toDate={toDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchParams={setSearchParams}
            franchiseParam={franchiseParam}
          />
          <Select
            value={filterType}
            onValueChange={(value) =>
              setFilterType(
                value as
                  | "no_sales_but_deliveries"
                  | "no_deliveries_but_sales"
                  | "no_orders_but_sales"
                  | "no_deliveries_but_orders"
                  | "no_sales_but_orders",
              )
            }
          >
            <SelectTrigger className="w-64">
              <SelectValue placeholder="Sélectionner un type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="no_sales_but_deliveries">
                Articles Livrés mais non Vendus
              </SelectItem>
              <SelectItem value="no_deliveries_but_sales">
                Articles Vendus mais non Livrés
              </SelectItem>
              <SelectItem value="no_orders_but_sales">
                Articles Vendus mais non Commandés
              </SelectItem>
              <SelectItem value="no_deliveries_but_orders">
                Articles Commandés mais non Livrés
              </SelectItem>
              <SelectItem value="no_sales_but_orders">
                Articles Commandés mais non Vendus
              </SelectItem>
            </SelectContent>
          </Select>
          <Button type="submit">Appliquer</Button>
        </form>
      </div>
      <UnMatchedDataTable
        columns={columns}
        data={performances}
        loading={loading}
      />
      {selectedRow && (
        <MatchSaleIdDialog
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false)
            setSelectedRow(null)
          }}
          baseSaleNameIda={selectedRow.sale_name_ida}
          saleName={selectedRow.sale_name}
          fromDate={fromDate}
          toDate={toDate}
          filterType={filterType}
          companyId={companyId!}
          franchiseParam={franchiseParam}
          storeId={selectedRow.store_id}
          onMatchSuccess={handleMatchSuccess}
        />
      )}
    </div>
  )
}

export default UnmatchedReferencesTable
