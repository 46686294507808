import React from "react"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ModificationItem } from "./InventoriesAdmin"

interface InventoryModificationsChartProps {
  modificationsChartData: ModificationItem[]
  selectedStoreId: string | null
}

export const InventoryModificationsChart: React.FC<
  InventoryModificationsChartProps
> = ({ modificationsChartData, selectedStoreId }) => {
  if (modificationsChartData.length === 0) {
    return (
      <Card>
        <CardHeader className="pb-0">
          <CardTitle>Nombres de modifications de stock</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64 flex items-center justify-center">
            Aucune donnée disponible pour ce magasin ou période.
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader className="pb-0">
        <CardTitle>Nombres de modifications de stock</CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={350}>
          <LineChart
            key={selectedStoreId ?? "ALL"}
            data={modificationsChartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="modification_count"
              stroke="#8884d8"
              name="Nombre de modifications"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="increase_count"
              stroke="#82ca9d"
              name="Nombre d'augmentations"
            />
            <Line
              type="monotone"
              dataKey="decrease_count"
              stroke="#FF0000"
              name="Nombre de diminutions"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}
