import { add } from "date-fns"
import { useOutletContext, useParams, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { PerformancesPageContext } from "../../PerformancesPage"
import { StateType } from "@/types"
import Skeleton from "react-loading-skeleton"

import InventoryStatsBar from "./InventoryStatsBar"
import { StockVsOrderModificationsChart } from "./StockVsOrderModificationChart"
import { InventoryModificationsChart } from "./InventoryModificationChart"
import { InventoryByDayChart } from "./InventoryDayByChart"
import { useInventoryData } from "@/hooks/useInventoryData"
import { usePredictionsData } from "@/hooks/usePredictionsData"
import { useModificationsData } from "@/hooks/useModificationData"

export interface ModificationItem {
  date: string
  modification_count: number
  increase_count: number
  decrease_count: number
  total_increase: number
  total_decrease: number
}

export interface PredictionRecord {
  updated?: number
  order_request_date?: string
  quantity_ordered_in_colis?: number
  final_quantity_predicted_in_colis?: number
}

export default function InventoriesAdmin() {
  const { selectedStoreId, fromDate, toDate } =
    useOutletContext<PerformancesPageContext>()

  const defaultStartDate = add(new Date(), { days: -7 })
  const defaultEndDate = new Date()

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  const companyId = params.companyId

  const formattedFromDate = formatDateToYYYYMMDD(fromDate || defaultStartDate)
  const formattedToDate = formatDateToYYYYMMDD(toDate || defaultEndDate)

  // State for extended loading message
  const [showExtendedMessage, setShowExtendedMessage] = useState(false)

  const {
    modificationsChartData,
    inventoryStats,
    isLoading: modificationsLoading,
    error: modificationsError,
  } = useModificationsData(
    companyId!,
    storeId!,
    selectedStoreId,
    franchiseParam,
    formattedFromDate,
    formattedToDate,
  )

  const {
    allRecords,
    predictionStats,
    isLoading: predictionsLoading,
    error: predictionsError,
  } = usePredictionsData(
    companyId!,
    storeId!,
    selectedStoreId,
    franchiseParam,
    formattedFromDate,
    formattedToDate,
  )

  const {
    inventoryChartData,
    allStores,
    avgStockPerDate,
    isLoading: inventoryLoading,
    error: inventoryError,
  } = useInventoryData(
    companyId!,
    storeId!,
    selectedStoreId,
    franchiseParam,
    formattedFromDate,
    formattedToDate,
  )

  const isLoading =
    modificationsLoading || predictionsLoading || inventoryLoading
  const hasError = modificationsError ?? predictionsError ?? inventoryError

  const isInventoryEmpty = inventoryChartData.length === 0

  // Show extended loading message after 5 seconds of loading
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined
    if (isLoading) {
      timer = setTimeout(() => {
        setShowExtendedMessage(true)
      }, 5000)
    } else {
      setShowExtendedMessage(false)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isLoading])

  // Display loading skeleton with optional extended message
  if (isLoading) {
    return (
      <div className="p-4 w-full max-w-[1200px] mx-auto space-y-4">
        {showExtendedMessage && (
          <div className="mb-4 p-3 bg-blue-50 text-blue-700 rounded-md text-center">
            Patientez, vos analyses mettent un peu plus de temps que prévu mais
            elles sont en route.
          </div>
        )}
        <Card>
          <CardHeader>
            <Skeleton className="h-4 w-44" />
          </CardHeader>
          <CardContent>
            <Skeleton className="h-[350px] w-full" />
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <Skeleton className="h-4 w-44" />
          </CardHeader>
          <CardContent>
            <Skeleton className="h-[350px] w-full" />
          </CardContent>
        </Card>
      </div>
    )
  }

  if (hasError) {
    return (
      <div className="text-red-500 p-4">
        Erreur lors du chargement des données.
      </div>
    )
  }

  return (
    <div className="p-4 w-full max-w-[1200px] mx-auto space-y-6">
      <InventoryStatsBar
        increaseCount={inventoryStats.increaseCount}
        decreaseCount={inventoryStats.decreaseCount}
        totalModifications={inventoryStats.totalCount}
        modificationPercentage={
          (inventoryStats.totalCount /
            (modificationsChartData.length > 0
              ? modificationsChartData.length
              : 1)) *
          100
        }
        stockModificationPercentage={inventoryStats.percentIncrease}
        trend={inventoryStats.dateTrend}
        avgModificationSize={inventoryStats.meanModDistance}
        totalOrderModifications={predictionStats.modificationsCount}
        orderModificationPercentage={predictionStats.percentModifications}
        orderTrend={predictionStats.dateTrend}
      />

      <StockVsOrderModificationsChart
        modificationsChartData={modificationsChartData as ModificationItem[]}
        allRecords={allRecords}
      />

      <InventoryModificationsChart
        modificationsChartData={modificationsChartData as ModificationItem[]}
        selectedStoreId={selectedStoreId}
      />

      {!isInventoryEmpty && (
        <InventoryByDayChart
          inventoryChartData={inventoryChartData}
          allStores={allStores as string[]}
          avgStockPerDate={avgStockPerDate}
        />
      )}
    </div>
  )
}
