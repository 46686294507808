import { Button } from "@/components/ui/button"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"
import { Info, XIcon } from "lucide-react"
import { EngagementQuantityInput } from "./EngagementQuantityInput"
import {
  Promotions,
  UpdateEngagementPromoMutationFn,
} from "@/utils/__generated__/graphql"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { twJoin } from "tailwind-merge"
import { useState } from "react"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface EngagementPromoDrawerProps {
  storeId: string
  promotion: Promotions
  isTestMode: boolean
  dates: Date[]
  updateEngagementPromo: UpdateEngagementPromoMutationFn
}

export function EngagementPromoDrawer({
  storeId,
  promotion,
  isTestMode,
  dates,
  updateEngagementPromo,
}: EngagementPromoDrawerProps) {
  const todayDate = new Date()
  const totalDemand = promotion.engagements.reduce(
    (sum, engagement) => sum + (engagement.demand_predicted ?? 0),
    0,
  )

  const [totalEngaged, setTotalEngaged] = useState(
    promotion.engagements.reduce(
      (sum, engagement) => sum + (engagement.quantity ?? 0),
      0,
    ),
  )

  const remaining = totalDemand - totalEngaged
  const progressPercentage = Math.min(
    ((totalDemand - remaining) / totalDemand) * 100,
    100,
  )

  const updateTotalEngaged = (oldQuantity: number, newQuantity: number) => {
    setTotalEngaged((prev) => prev - oldQuantity + newQuantity)
  }

  return (
    <Drawer direction="right">
      <DrawerTrigger asChild>
        <Button className="w-full bg-green-800 hover:bg-green-900">
          Engagement
        </Button>
      </DrawerTrigger>
      <DrawerContent
        className="h-screen top-0 right-0 left-auto mt-0 w-[340px] rounded-none"
        showDrag={false}
        aria-describedby={undefined}
      >
        <DrawerHeader className="flex justify-between items-center">
          <DrawerTitle>Répartir votre engagement</DrawerTitle>
          <DrawerClose>
            <XIcon className="size-4" />
          </DrawerClose>
        </DrawerHeader>

        <p className="text-center px-1 py-2">{promotion.order_name}</p>
        <div className="p-4 border-b">
          <div className="flex justify-between mb-2">
            <span className="text-sm">Quantité totale: {totalDemand}</span>
            <div className="flex items-center gap-2">
              {totalEngaged > totalDemand && (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="w-3 h-3 text-red-500" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Vous avez dépassé la quantité de produit préconisée</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              <span
                className={`text-sm ${totalEngaged > totalDemand ? "text-red-500 font-medium" : ""}`}
              >
                Commande en cours : {totalEngaged}
              </span>
            </div>
          </div>
          <div className="w-full bg-gray-200 h-2 rounded-full">
            <div
              className="bg-black h-2 rounded-full transition-all duration-300"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-8 p-4 overflow-y-auto">
          {dates.map((date, i) => {
            const engagement = promotion.engagements.find(
              (engagement) =>
                getDiffBetweenDates(new Date(engagement.date), date) === 0,
            )
            const isOldDate = getDiffBetweenDates(todayDate, new Date(date)) < 0

            const isExceptionDate = promotion.delivery_exceptions?.includes(
              // fr-CA because that's the format of the date in delivery_exceptions
              date.toLocaleDateString("fr-CA"),
            )

            return (
              <div
                key={i}
                className={twJoin(
                  "flex flex-col items-center gap-4 pb-8 border-b last:border-b-0",
                  isExceptionDate && "opacity-40",
                )}
              >
                <div className="flex justify-between w-full items-center gap-2">
                  <p
                    className={twJoin(
                      isOldDate && "text-gray-500",
                      "font-bold text-sm",
                    )}
                  >
                    {Intl.DateTimeFormat("fr-FR", { weekday: "long" }).format(
                      new Date(date),
                    )}{" "}
                    {Intl.DateTimeFormat("fr-FR").format(new Date(date))}
                  </p>
                  <p className="text-sm text-gray-500">
                    Demande : {engagement?.demand_predicted ?? 0}
                  </p>
                </div>
                <EngagementQuantityInput
                  storeId={storeId}
                  dimEngagementPromoId={promotion.id}
                  factEngagementPromoId={engagement?.id}
                  date={date}
                  engagementQuantity={engagement?.quantity ?? 0}
                  isTestMode={isTestMode}
                  disabled={isOldDate || isExceptionDate}
                  onQuantityChange={(oldQuantity, newQuantity) =>
                    updateTotalEngaged(oldQuantity, newQuantity)
                  }
                  updateEngagementPromo={updateEngagementPromo}
                />
              </div>
            )
          })}
        </div>
      </DrawerContent>
    </Drawer>
  )
}
