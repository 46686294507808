import { useMemo, useEffect } from "react"
import { useGetStoreInventoryModificationsEvolutionQuery } from "@/utils/__generated__/graphql"
import { parseISO, format } from "date-fns"

export const useModificationsData = (
  companyId: string,
  storeId: string,
  selectedStoreId: string | null,
  franchiseParam: string | undefined,
  formattedFromDate: string,
  formattedToDate: string,
) => {
  const {
    data: modificationsData,
    loading: modificationsLoading,
    error: modificationsError,
    refetch: refetchModifications,
  } = useGetStoreInventoryModificationsEvolutionQuery({
    variables: {
      input: {
        store_id: storeId,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formattedFromDate,
        end_date: formattedToDate,
        company_id: companyId,
        franchise_name: franchiseParam,
      },
    },
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    refetchModifications({
      input: {
        store_id: storeId,
        selected_store_id: selectedStoreId ?? undefined,
        start_date: formattedFromDate,
        end_date: formattedToDate,
        company_id: companyId,
        franchise_name: franchiseParam,
      },
    })
  }, [
    selectedStoreId,
    formattedFromDate,
    formattedToDate,
    refetchModifications,
    companyId,
    franchiseParam,
    storeId,
  ])

  const modificationsChartData = useMemo(() => {
    return (
      modificationsData?.getStoreInventoryModificationsEvolution?.modifications_evolution
        ?.map((item) => {
          try {
            return {
              date: format(parseISO(item.modification_date), "yyyy-MM-dd"),
              modification_count: item.modification_count,
              increase_count: item.increase_count,
              decrease_count: item.decrease_count,
              total_increase: Math.round(item.total_increase * 100) / 100,
              total_decrease: Math.round(item.total_decrease * 100) / 100,
            }
          } catch (error) {
            console.error("Invalid date format:", item.modification_date)
            return null
          }
        })
        .filter(Boolean) ?? []
    )
  }, [modificationsData])

  const inventoryStats = useMemo(() => {
    const totalCount = modificationsChartData.reduce(
      (sum, item) => sum + (item?.modification_count ?? 0),
      0,
    )

    const increaseCount = modificationsChartData.reduce(
      (sum, item) => sum + (item?.increase_count ?? 0),
      0,
    )

    const decreaseCount = modificationsChartData.reduce(
      (sum, item) => sum + (item?.decrease_count ?? 0),
      0,
    )

    const dateMap = new Map()
    for (const item of modificationsChartData) {
      const date = item?.date
      if (!date) continue

      if (!dateMap.has(date)) {
        dateMap.set(date, { total: 0, increases: 0 })
      }
      const info = dateMap.get(date)
      info.total += item?.modification_count ?? 0
      info.increases += item?.increase_count ?? 0
    }

    const dateRatios = Array.from(dateMap.entries())
      .map(([dateStr, info]) => {
        const ratio = info.total ? info.increases / info.total : 0
        return { date: dateStr, ratio }
      })
      .sort((a, b) => a.date.localeCompare(b.date))

    let dateTrend = 0
    if (dateRatios.length >= 2) {
      let sumDiff = 0
      for (let i = 1; i < dateRatios.length; i++) {
        sumDiff += dateRatios[i].ratio - dateRatios[i - 1].ratio
      }
      dateTrend = Math.round(sumDiff * 100)
    }

    const totalModDistance = modificationsChartData.reduce((acc, item) => {
      return acc + (item?.total_increase ?? 0) + (item?.total_decrease ?? 0)
    }, 0)

    const meanModDistance = totalCount > 0 ? totalModDistance / totalCount : 0

    const percentIncrease =
      totalCount > 0 ? Math.round((increaseCount / totalCount) * 100) : 0
    const percentDecrease =
      totalCount > 0 ? Math.round((decreaseCount / totalCount) * 100) : 0

    return {
      totalCount,
      increaseCount,
      decreaseCount,
      percentIncrease,
      percentDecrease,
      dateTrend,
      meanModDistance,
      totalModDistance,
    }
  }, [modificationsChartData])

  return {
    modificationsChartData,
    inventoryStats,
    isLoading: modificationsLoading,
    error: modificationsError,
    isEmpty: modificationsChartData.length === 0,
  }
}
