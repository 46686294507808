import { useDrop } from "react-dnd"
import { DragPayload, ReferencesForMatching } from "./types"
import { toast } from "sonner"
import { twJoin } from "tailwind-merge"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Book } from "lucide-react"
import { Button } from "@/components/ui/button"
import { MatchedReferenceCard } from "./MatchedReferenceCard"
import { getDisplayedUnit } from "@/utils/getUnit"

interface ParentReferenceCardProps {
  main: ReferencesForMatching[number]
  childrenRefs: ReferencesForMatching
  onEditReference: (ref: ReferencesForMatching[number]) => void
  rowSelection: Record<string, boolean>
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  setLocalReferences: React.Dispatch<
    React.SetStateAction<ReferencesForMatching>
  >
  anyIsDragging: boolean
  dragType: string
  onCreate: (
    parent: ReferencesForMatching[number],
    child: ReferencesForMatching[number],
  ) => Promise<void>
  companyName: string
  franchise: string | undefined
}

export function ParentReferenceCard({
  main,
  childrenRefs,
  onEditReference,
  rowSelection,
  setRowSelection,
  setLocalReferences,
  anyIsDragging,
  dragType,
  onCreate,
  companyName,
  franchise,
}: ParentReferenceCardProps) {
  const [{ isOver }, dropRef] = useDrop<DragPayload, void, { isOver: boolean }>(
    {
      accept: dragType,
      drop: (dragPayload, monitor) => {
        const didDrop = monitor.didDrop()
        if (didDrop) return

        const hasDifferentUnit = dragPayload.items.some(
          (draggedRef) => draggedRef.unit !== main.unit,
        )
        if (hasDifferentUnit) {
          toast.error(
            "Impossible de lier des références de différentes unités.",
          )
          return
        }

        dragPayload.items.forEach((draggedRef) => {
          if (
            draggedRef.sale_name_ida_base === main.sale_name_ida_base ||
            draggedRef.sale_name_ida_cible === main.sale_name_ida_base
          ) {
            return
          }
          // Optimistic local update
          setLocalReferences((prev) =>
            prev.map((item) => {
              if (item.sale_name_ida_base === draggedRef.sale_name_ida_base) {
                return {
                  ...item,
                  is_matched: true,
                  sale_name_ida_cible: main.sale_name_ida_base,
                  libelle_cible: main.libelle_base,
                }
              }
              if (item.sale_name_ida_base === main.sale_name_ida_base) {
                return { ...item, isMaster: true }
              }
              return item
            }),
          )

          async function createMatching() {
            try {
              await onCreate(main, draggedRef)
            } catch (err) {
              console.error(err)
              toast.error("Erreur lors du matching (optimiste).")
            }
          }
          createMatching()
        })
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    },
  )

  // If we're dragging anything at all, and this item is selected, blur it
  const isSelectedMaster = rowSelection[main.sale_name_ida_base]
  const masterClass = twJoin(
    "mb-2 transition-colors",
    isOver && "border-green-600 border-2",
    anyIsDragging && isSelectedMaster && "blur-sm",
  )

  return (
    <Card ref={dropRef} className={masterClass}>
      <CardHeader className="p-2 flex-row justify-between items-center">
        <CardTitle className="text-lg w-fit flex items-center gap-1">
          <Book className="size-6" />
          {main.libelle_base}
          <span className="ml-1 text-base font-normal">
            {main.sale_name_ida_base}
          </span>
          <span className="ml-1 text-gray-500 font-normal">
            {getDisplayedUnit(main.unit, companyName, franchise)}
          </span>
        </CardTitle>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onEditReference(main)}
          className="w-fit"
        >
          Modifier
        </Button>
      </CardHeader>
      <CardContent className="p-2">
        {childrenRefs.length === 0 ? (
          <p className="text-sm text-gray-500 italic">Aucune référence liée.</p>
        ) : (
          <ul className="space-y-2">
            {childrenRefs.map((child) => (
              <MatchedReferenceCard
                key={child.sale_name_ida_base}
                child={child}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                onEditReference={onEditReference}
                siblings={childrenRefs}
                anyIsDragging={anyIsDragging}
                dragType={dragType}
              />
            ))}
          </ul>
        )}
      </CardContent>
    </Card>
  )
}
