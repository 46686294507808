import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  InProgressDeliveriesItem,
  useBatchUpdateMutation,
  useGetInProgressDeliveriesQuery,
  useLogsModificationUpdateMutation,
} from "../../utils/__generated__/graphql"
import { type DispatchActionType, type StateType } from "../../types"
import { type AllMercurialInfo } from "../../reducers/mercurialReducer"
import { InventoryPageTemplate } from "../../components/order/InventoryPageTemplate"
import { handleKeyboard } from "../../utils/handleKeyboard"
import { SortOption } from "../../reducers/userInterfaceReducer"
import { InventoryRow } from "../../components/order/InventoryRow"
import {
  bestSellersSelector,
  filteredMercurialeReducerSelector,
  selectedMercurialeInfosSelector,
  selectedRankingsSelector,
} from "../../selectors/mercurialeSelectors"
import { removeDuplicatesValues } from "../../utils/removeDuplicates"
import { useOutletContext } from "react-router-dom"
import { OrderRootOutletContext } from "../../components/order/OrderRoot"
import { useSearchBar } from "../../hooks/useSearchBar"
import {
  filterInventory,
  sortInventory,
  useScrollSectionOptions,
} from "@/utils/filterAndSortInventory"
import { ScrollToSectionDropdown } from "@/components/order/nav/ScrollToSectionDropdown"
import { VirtuosoHandle } from "react-virtuoso"
import { RuptureLayout } from "./RuptureLayout"
import { hasBadges } from "@/utils/hasBadges"
import ReloadModal from "@/components/order/ReloadModal"

// Sort options that should not keep the same order when updating data
const bypassOldSortOptions = [SortOption.SortIndex]

export function OrderPage(): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const [{ isLoading }, getLatestInfos] =
    useOutletContext<OrderRootOutletContext>()

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const {
    storeId,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
    companyName,
    storeFranchise,
  } = useSelector((state: StateType) => state.storeReducer)

  const {
    mercurialAndStoreInventories,
    updatedReferences,
    selectedDimMercurialeId,
  } = useSelector(filteredMercurialeReducerSelector)
  const mercurialeInfos = useSelector(selectedMercurialeInfosSelector)
  const bestSellers = useSelector(bestSellersSelector)
  const rankings = useSelector(selectedRankingsSelector)
  const {
    searchTerm,
    familyName,
    subFamilyName,
    suppliersIds,
    filteredReferences,
    sortOption,
    displayShelfFloorSize,
    promotionMessagesInFilters,
  } = useSelector((state: StateType) => state.userInterfaceReducer.orderPage)
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  // Keep sort list and option to keep order when updating data
  const oldState = useRef<{
    sortList?: string[]
    sortOption?: SortOption
    selectedDimMercurialeId?: string
  } | null>(null)

  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  const [isReloadModalOpen, setIsReloadModal] = useState(false)
  const [isMercurialeModalOpen, setIsMercurialeModalOpen] = useState(false)

  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  const interval = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isReloadModalOpen) return

    interval.current = setInterval(() => {
      setIsReloadModal(true)
      clearInterval(interval.current)
    }, 36000000) // 10 hours

    return () => {
      clearInterval(interval.current)
    }
  }, [isReloadModalOpen])

  const greyedReferences = useMemo(() => {
    return mercurialeInfos.filter(
      (item) =>
        item.has_historical === false && hasBadges(item).hasBadges === false,
    )
  }, [mercurialeInfos])

  // Sort logic
  const sortedMercurialeInfos = useMemo<AllMercurialInfo[]>(() => {
    const _mercurialesInfos = [...mercurialeInfos]

    const withHistorical = _mercurialesInfos.filter(
      (item) =>
        greyedReferences.some(
          (reference) => reference.mercuriale_id === item.mercuriale_id,
        ) === false,
    )

    if (
      !bypassOldSortOptions.includes(sortOption) &&
      oldState.current?.sortOption === sortOption &&
      oldState.current.sortList !== undefined &&
      oldState.current.selectedDimMercurialeId === selectedDimMercurialeId
    ) {
      const sortedItems = oldState.current.sortList
        .map((mercurialeId) => {
          const mercurialeInfo = _mercurialesInfos.find(
            (_filteredMercurialeInfo) =>
              _filteredMercurialeInfo.mercuriale_id === mercurialeId,
          )
          return mercurialeInfo
        })
        .filter(
          (mercurialeInfo): mercurialeInfo is AllMercurialInfo =>
            mercurialeInfo !== undefined,
        )

      return [
        ...sortedItems.filter(
          (item) =>
            greyedReferences.some(
              (reference) => reference.mercuriale_id === item.mercuriale_id,
            ) === false,
        ),
        ...sortedItems.filter((item) =>
          greyedReferences.some(
            (reference) => reference.mercuriale_id === item.mercuriale_id,
          ),
        ),
      ]
    }

    const _sortedWithHistorical = sortInventory(
      withHistorical,
      sortOption,
      storeSettings,
      rankings,
    )

    const _sortedWithoutHistorical = sortInventory(
      greyedReferences,
      sortOption,
      storeSettings,
      rankings,
    )

    const _sortedMercurialeInfos = [
      ..._sortedWithHistorical,
      ..._sortedWithoutHistorical,
    ]

    oldState.current = {
      sortOption,
      sortList: _sortedMercurialeInfos.map(
        (_sortedMercurialeInfo) => _sortedMercurialeInfo.mercuriale_id ?? "",
      ),
      selectedDimMercurialeId: selectedDimMercurialeId,
    }

    return _sortedMercurialeInfos
  }, [
    mercurialeInfos,
    sortOption,
    selectedDimMercurialeId,
    storeSettings,
    rankings,
    greyedReferences,
  ])

  const searchedMercurialeInfos = useSearchBar(
    sortedMercurialeInfos,
    searchTerm,
  )

  const filteredMercurialeInfos = useMemo<AllMercurialInfo[]>(() => {
    return filterInventory(
      searchedMercurialeInfos,
      familyName,
      subFamilyName,
      suppliersIds,
      filteredReferences,
      storeSettings?.typologies,
      promotionMessagesInFilters,
    )
  }, [
    familyName,
    filteredReferences,
    searchedMercurialeInfos,
    storeSettings?.typologies,
    subFamilyName,
    suppliersIds,
    promotionMessagesInFilters,
  ])

  // Other filters logic (separated from search filter to retrieve number of filtered items)
  const deduplicateFilteredMercurialeInfos = useMemo<AllMercurialInfo[]>(() => {
    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
  }, [filteredMercurialeInfos])

  const unfilteredAmount = mercurialeInfos.length
  const searchedAmount = searchedMercurialeInfos.length
  const filteredAmount = filteredMercurialeInfos.length

  const updateInventory = useCallback(
    async (
      value: string,
      _selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
    ): Promise<void> => {
      await handleKeyboard({
        value,
        selectedInventory: _selectedInventory ?? selectedInventory,
        mercurialeInfos: mercurialAndStoreInventories,
        updatedReferences,
        storeSettings,
        dispatch,
        batchUpdateMutation,
        online,
        isTestMode,
        storeId,
        dimOrderRequestId,
        logsModificationUpdate,
      })
    },
    [
      batchUpdateMutation,
      dimOrderRequestId,
      dispatch,
      isTestMode,
      mercurialAndStoreInventories,
      online,
      selectedInventory,
      storeId,
      storeSettings,
      updatedReferences,
      logsModificationUpdate,
    ],
  )

  const scrollSectionOptions = useScrollSectionOptions({
    unfilteredInventory: sortedMercurialeInfos.filter(
      (item) =>
        greyedReferences.some(
          (reference) => reference.mercuriale_id === item.mercuriale_id,
        ) === false,
    ),
    inventory: deduplicateFilteredMercurialeInfos.filter(
      (item) =>
        greyedReferences.some(
          (reference) => reference.mercuriale_id === item.mercuriale_id,
        ) === false,
    ),
    sortOption,
    categoriesOrder: storeSettings?.categories_orders,
    showNewReferencesFirst: storeSettings?.show_new_references_first,
    showPromotionsFirst: storeSettings?.show_promotions_first,
    bestSellers,
  })

  const scrollSections = useMemo(() => {
    return [
      ...scrollSectionOptions,
      ...(deduplicateFilteredMercurialeInfos.some((item) =>
        greyedReferences.some(
          (reference) => reference.mercuriale_id === item.mercuriale_id,
        ),
      )
        ? [
            {
              label: "Non vendus depuis 30 jours",
              index: deduplicateFilteredMercurialeInfos.findIndex((item) =>
                greyedReferences.some(
                  (reference) => reference.mercuriale_id === item.mercuriale_id,
                ),
              ),
            },
          ]
        : []),
    ]
  }, [
    scrollSectionOptions,
    deduplicateFilteredMercurialeInfos,
    greyedReferences,
  ])

  const virtuosoRef = useRef<VirtuosoHandle>(null)

  const { data: inProgressDeliveriesData } = useGetInProgressDeliveriesQuery({
    variables: {
      input: {
        store_id: storeId!,
        sale_ids: mercurialAndStoreInventories
          .map((mercurialeInfo) => mercurialeInfo.sale_id)
          .filter((saleId): saleId is string => saleId !== undefined),
        dim_order_request_id: dimOrderRequestId ?? null,
      },
    },
  })

  const ruptureToVerify = mercurialAndStoreInventories
    .filter(
      (mercurialeInfo) =>
        mercurialeInfo.dim_order_request_status === "ongoing" &&
        Array.isArray(mercurialeInfo.quantity_predicted_array) &&
        mercurialeInfo.quantity_predicted_array.length > 0,
    )
    .sort((a, b) => (b.rupture_loss_amount ?? 0) - (a.rupture_loss_amount ?? 0))
    .slice(0, 5)

  const groupedData = useMemo(() => {
    const groupCounts = scrollSections.map((currentSection, i) => {
      const nextSection = scrollSections[i + 1]
      const count = nextSection
        ? nextSection.index - currentSection.index
        : deduplicateFilteredMercurialeInfos.length - currentSection.index
      return count
    })

    return { groupCounts }
  }, [scrollSections, deduplicateFilteredMercurialeInfos.length])

  const renderGroupHeader = (index: number) => {
    const section = scrollSections[index]

    return (
      <div className="sticky top-0 z-10 bg-white border border-gray-200 rounded-b-xl shadow-sm p-2 px-4 md:p-2 md:px-4">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-2 md:gap-3">
            <p className="text-sm md:text-base font-semibold text-gray-800">
              {section.label}
              {section.count && (
                <span className="ml-2 text-sm text-gray-500">
                  ({section.count})
                </span>
              )}
            </p>
          </div>
          {section.description && (
            <p className="text-xs text-gray-500">{section.description}</p>
          )}
        </div>
      </div>
    )
  }

  const renderItem = (index: number) => {
    const itemIndex = index
    const row = deduplicateFilteredMercurialeInfos[itemIndex]

    if (!row) return null

    const relatedInProgressDeliveries =
      inProgressDeliveriesData?.getInProgressDeliveries.data
        .filter(
          (delivery: InProgressDeliveriesItem) =>
            delivery.sale_id === row.sale_id,
        )
        .reduce(
          (uniqueDeliveries: InProgressDeliveriesItem[], currentDelivery) => {
            const isDuplicate = uniqueDeliveries.some(
              (item) => item.order_id === currentDelivery.order_id,
            )

            if (!isDuplicate) {
              uniqueDeliveries.push(currentDelivery)
            }

            return uniqueDeliveries
          },
          [],
        )

    const references = filteredMercurialeInfos.filter(
      (mercurialeInfo) => mercurialeInfo.sale_id === row.sale_id,
    )

    const isRowGreyed = greyedReferences.some(
      (reference) => reference.mercuriale_id === row.mercuriale_id,
    )

    const itemIsNew = references.some(
      (reference) => reference.new_reference === true,
    )

    const hasBigBreakage = references.some(
      (reference) =>
        typeof reference.breakage_percentage === "number" &&
        reference.breakage_percentage > 10,
    )

    const isRuptureToVerify = ruptureToVerify.some(
      (verify) => verify.mercuriale_id === references[0]?.mercuriale_id,
    )

    return (
      <div key={references[0]?.mercuriale_id ?? ""} className="mb-4">
        {references[0]?.time_rupture_flag && isRuptureToVerify && (
          <RuptureLayout
            references={references}
            updateInventory={updateInventory}
            isWarning={itemIsNew}
            isDanger={hasBigBreakage}
          />
        )}

        <div className="bg-white">
          <InventoryRow
            index={itemIndex}
            storeId={storeId}
            setCurrentIndex={setCurrentIndex}
            searchTerm={searchTerm}
            bestSellers={bestSellers}
            selectedInventory={selectedInventory}
            isOnline={online}
            storeSettings={storeSettings}
            updateInventory={updateInventory}
            displayShelfFloorSize={displayShelfFloorSize}
            storeSuppliers={storeSuppliers}
            storeCurrency={storeCurrency}
            companyId={companyId}
            references={references}
            companyName={companyName}
            franchise={storeFranchise}
            inProgressDeliveries={relatedInProgressDeliveries}
            isRowGreyed={isRowGreyed}
            isRuptureToVerify={isRuptureToVerify}
            className={
              references[0]?.time_rupture_flag
                ? "rounded-t-none"
                : "rounded-t-xl"
            }
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <ReloadModal
        isOpen={isReloadModalOpen}
        companyName={companyName}
        onCancel={() => setIsReloadModal(false)}
        onReload={() => {
          oldState.current = null
          void getLatestInfos()
          setIsReloadModal(false)
        }}
      />
      <InventoryPageTemplate
        page="orderPage"
        deduplicateFilteredMercurialeInfos={deduplicateFilteredMercurialeInfos}
        isMercurialeModalOpen={isMercurialeModalOpen}
        setIsMercurialeModalOpen={setIsMercurialeModalOpen}
        oldState={oldState}
        currentIndex={currentIndex}
        loading={isLoading}
        rawData={mercurialeInfos}
        virtuosoRef={virtuosoRef}
        scrollSectionRenderer={(index) => (
          <ScrollToSectionDropdown
            activeIndex={index}
            options={scrollSections}
            handleScrollTo={(index) =>
              virtuosoRef.current?.scrollToIndex({
                index,
                offset: 0,
                align: "start",
              })
            }
          />
        )}
        dataLength={deduplicateFilteredMercurialeInfos.length}
        searchedAmount={searchedAmount}
        filteredAmount={filteredAmount}
        filteredMercurialeInfos={filteredMercurialeInfos}
        unfilteredAmount={unfilteredAmount}
        updateInventory={updateInventory}
        rowContent={renderItem}
        groupContent={renderGroupHeader}
        groupCounts={groupedData.groupCounts}
      />
    </>
  )
}
