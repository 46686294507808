import { useState } from "react"
import { Button } from "@/components/ui/button"
import { ChevronDown } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Link } from "react-router-dom"
import { links } from "./data"
import { cn } from "@/lib/utils"

export const LinksDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  return (
    <>
      <div className="hidden md:block">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              <ChevronDown />
              <p>Liens utiles</p>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {links.map((link) => (
              <Link key={link.title} to={link.url}>
                <DropdownMenuItem>
                  <p>{link.title}</p>
                </DropdownMenuItem>
              </Link>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="block md:hidden">
        <Button
          variant="ghost"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <ChevronDown
            className={cn(
              "size-4 transition-transform duration-300",
              isDropdownOpen && "rotate-180",
            )}
          />{" "}
          <p>Liens utiles</p>
        </Button>
        <div
          className={cn(
            "gap-2 ml-1 mt-2 hidden border-l-[1px] border-solid border-gray-300",
            isDropdownOpen && "flex flex-col",
          )}
        >
          {links.map((link) => (
            <Link key={link.title} to={link.url}>
              <Button variant="ghost">
                <p>{link.title}</p>
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}
