import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { FlaggedRefGroup } from "@/components/organisms/flaggedRefGroup"
import { Card } from "@/components/ui/card"
import { ArrowDown, ReceiptEuro } from "lucide-react"
import { getVariation } from "@/utils/getVariation"
import { QuantityInput } from "@/components/molecules/quantityInput"
import { useState } from "react"
import { useUpdateSalesInfoMutation } from "@/utils/__generated__/graphql"
import { captureException } from "@sentry/react"
import { toast } from "sonner"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "@/types"
import { InventoryRow } from "../../InventoryRow"

// Store all timeouts in a global variable with order id as key (each timeout will be deleted at the end of the function)
const timeouts: Record<string, NodeJS.Timeout> = {}

function decreaseByPointOne(value: number): number {
  let roundedValue = Math.floor(value * 100)

  const lastDigit = roundedValue % 10
  if (lastDigit === 0) {
    roundedValue -= 10
  } else {
    roundedValue -= lastDigit
  }

  return roundedValue / 100
}

function increaseByPointOne(value: number): number {
  let roundedValue = Math.ceil(value * 100)

  const lastDigit = roundedValue % 10
  if (lastDigit === 0) {
    roundedValue += 10
  } else {
    roundedValue += 10 - lastDigit
  }

  return roundedValue / 100
}

type InputsValues = Record<string, number | undefined>

export function PaChangedCard({
  references,
  bestSellers,
  updateInventory,
}: {
  references: AllMercurialInfo[]
  bestSellers: AllMercurialInfo[]
  updateInventory: (value: string) => Promise<void>
}) {
  const dispatch = useDispatch<DispatchActionType>()
  const [updateSalesInfo] = useUpdateSalesInfoMutation()

  const {
    companyId,
    storeCurrency,
    storeId,
    companyName,
    storeFranchise,
    storeSettings,
    storeSuppliers,
  } = useSelector((state: StateType) => state.storeReducer)

  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.orderPage.displayShelfFloorSize,
  )

  const [inputsValues, setInputsValues] = useState<InputsValues>(
    references.reduce<InputsValues>((acc, reference) => {
      acc[reference.sale_id!] = reference.pv ?? 0
      return acc
    }, {}),
  )

  async function updateArticle(pv: number, saleId: string) {
    try {
      const result = await updateSalesInfo({
        variables: {
          input: {
            store_id: storeId!,
            sale_id: saleId,
            pv,
          },
        },
      })
      if (
        result.data?.updateSalesInfo.error !== null &&
        result.data?.updateSalesInfo.error !== undefined
      ) {
        throw new Error(result.data?.updateSalesInfo.error?.message)
      }
      dispatch({
        type: "updatePvMercuriale",
        payload: {
          pv,
          saleId,
        },
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      toast.error("Le prix de vente n'a pas été mis à jour.")
    }
  }

  function onQuantityChange(value: number | undefined, saleId: string) {
    setInputsValues((prev) => ({ ...prev, [saleId]: value }))

    if (isTestMode) return

    clearTimeout(timeouts[saleId])
    timeouts[saleId] = setTimeout(() => {
      updateArticle(value ?? 0, saleId)
      // Delete timeout to avoid memory leaks
      delete timeouts[saleId]
    }, 1000)
  }

  function onIncreasedQuantity(value: number | undefined, saleId: string) {
    if (value === undefined) {
      return onQuantityChange(value, saleId)
    }
    const newValue = increaseByPointOne(value)
    return onQuantityChange(newValue, saleId)
  }

  function onReducedQuantity(value: number | undefined, saleId: string) {
    if (value === undefined || value === 0) {
      return onQuantityChange(value, saleId)
    }
    const newValue = decreaseByPointOne(value)
    return onQuantityChange(newValue, saleId)
  }

  return (
    <FlaggedRefGroup
      header={
        <div className="flex gap-2 items-center text-green-900">
          <ReceiptEuro className="size-5" />
          <div>
            <p className="font-bold">Baisse de prix d&apos;achat</p>
            <p className="font-medium text-sm italic">
              Optimisez votre marge en achetant ces articles à un prix
              avantageux
              <br />
              Envisagez d&apos;augmenter votre prix de vente
            </p>
          </div>
        </div>
      }
      backgroundColor="bg-green-20"
    >
      {references.map((reference, index) => {
        const pa = reference.pa ?? 0
        const lastMercuPa = reference.last_mercu_pa ?? 0
        const pv = reference.pv ?? 0
        const lastMercuPv = reference.last_mercu_pv ?? 0

        const paVariation = getVariation(pa, lastMercuPa)
        const previousMarge = getVariation(lastMercuPv, lastMercuPa)
        const currentMarge = getVariation(reference.pv ?? 0, pa)

        return (
          <Card
            key={reference.mercuriale_id!}
            className="w-full bg-white shadow rounded"
          >
            <InventoryRow
              key={reference.mercuriale_id!}
              index={index}
              storeId={storeId}
              bestSellers={bestSellers}
              selectedInventory={selectedInventory}
              isOnline={isOnline}
              storeSettings={storeSettings}
              updateInventory={updateInventory}
              displayShelfFloorSize={displayShelfFloorSize}
              storeSuppliers={storeSuppliers}
              storeCurrency={storeCurrency}
              companyId={companyId}
              references={[reference]}
              companyName={companyName}
              franchise={storeFranchise}
              className="border-none"
            />
            <div className="flex flex-row items-center justify-between px-3 pb-3">
              <div className="flex-1">
                <div className="flex items-center lg:items-start gap-4 text-gray-500 font-medium text-sm">
                  <div className="text-left">
                    <p className="flex items-center gap-1">
                      Avant PA :{" "}
                      <span className="line-through">
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: storeCurrency ?? "EUR",
                        }).format(lastMercuPa)}
                      </span>
                    </p>
                    <p className="text-sm text-green-900 font-bold flex items-center gap-1">
                      Après PA :{" "}
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: storeCurrency ?? "EUR",
                      }).format(pa)}
                      <ArrowDown className="size-4" />
                      {new Intl.NumberFormat("fr-FR", {
                        style: "percent",
                      }).format(paVariation)}
                    </p>
                  </div>
                  <div className="text-left">
                    <p className="flex items-center gap-1">
                      PV :{" "}
                      <span>
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: storeCurrency ?? "EUR",
                        }).format(lastMercuPv)}
                      </span>
                      <span>
                        {new Intl.NumberFormat("fr-FR", {
                          style: "percent",
                        }).format(previousMarge)}
                      </span>
                    </p>
                    <p className="font-bold flex items-center gap-1">
                      PV :{" "}
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: storeCurrency ?? "EUR",
                      }).format(pv)}{" "}
                      <span>
                        {new Intl.NumberFormat("fr-FR", {
                          style: "percent",
                        }).format(currentMarge)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-32 md:w-fit">
                <p className="text-sm font-medium">Prix de vente</p>
                <QuantityInput
                  onIncreasedQuantity={() =>
                    onIncreasedQuantity(
                      inputsValues[reference.sale_id!],
                      reference.sale_id!,
                    )
                  }
                  onReducedQuantity={() =>
                    onReducedQuantity(
                      inputsValues[reference.sale_id!],
                      reference.sale_id!,
                    )
                  }
                  onQuantityChange={(value) =>
                    onQuantityChange(value, reference.sale_id!)
                  }
                  value={inputsValues[reference.sale_id!]}
                />
              </div>
            </div>
          </Card>
        )
      })}
    </FlaggedRefGroup>
  )
}
