"use client"

import { useState, useMemo } from "react"
import {
  matchPath,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useSelector } from "react-redux"
import { add } from "date-fns"
import { DateRange } from "react-day-picker"

import { StateType } from "@/types"
import { Store, useGetStoresQuery } from "@/utils/__generated__/graphql"
import { Spinner } from "@/ui/Spinner"
import {
  ChartBar,
  EuroIcon,
  LineChart,
  PackageSearch,
  Send,
  Trash2,
  TrendingDownIcon,
} from "lucide-react"

import { StoreSelector } from "./components/StoreSelector"
import { DateSelector } from "./components/DateSelector"
import { usePermissions } from "@/hooks/usePermissions"
import AdminSidebar from "./components/AdminSidebar"
import { useAppContext } from "@/contexts/AppContext"

export interface PerformancesPageContext {
  storeId: string | null
  companyId: string | undefined
  franchise: string | undefined
  fromDate: Date
  toDate: Date
  selectedStoreId: string | null
  selectedStoreName: string | undefined
  stores: Store[] | undefined
}

type AdminSidebarNavItem = {
  id: string
  title: string
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
  permission?: "isCompanyAdmin" | "isIdaAdmin" | "canReadPerformances"
} & (
  | {
      children: AdminSidebarNavItem[]
      filters?: undefined
    }
  | {
      children?: undefined
      filters: {
        store: boolean
        dates: boolean
      }
    }
)

const sidebarNavItems: AdminSidebarNavItem[] = [
  {
    permission: "canReadPerformances",
    id: "taux-de-modification",
    title: "Suivi des commandes",
    icon: Send,
    filters: {
      store: true,
      dates: true,
    },
  },
  {
    permission: "isIdaAdmin",
    id: "scoring-prediction",
    title: "Analyse des recos",
    icon: LineChart,
    filters: {
      store: true,
      dates: true,
    },
  },
  {
    permission: "isCompanyAdmin",
    id: "indicators",
    title: "Suivi des indicateurs",
    icon: PackageSearch,
    children: [
      {
        permission: "isCompanyAdmin",
        id: "level-stock",
        title: "Suivi des stocks",
        icon: ChartBar,
        filters: {
          store: true,
          dates: true,
        },
      },
      {
        id: "breakage",
        title: "Suivi de la démarque",
        icon: Trash2,
        filters: {
          store: true,
          dates: true,
        },
      },
      {
        id: "potentially-ruptures",
        title: "Suivi des ruptures",
        icon: TrendingDownIcon,
        filters: {
          store: true,
          dates: true,
        },
      },
      {
        id: "performance-board-ca",
        title: "Suivi du chiffre d'affaires",
        icon: ChartBar,
        filters: {
          store: true,
          dates: true,
        },
      },
    ],
  },
  {
    id: "predicted-ca-vs-sales",
    title: "Prédictions VS Ventes",
    icon: EuroIcon,
    filters: {
      store: true,
      dates: true,
    },
  },
]

export function PerformancesPage() {
  const location = useLocation()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const fromParam = searchParams.get("from") ?? undefined
  const toParam = searchParams.get("to") ?? undefined
  const { companyId: companyIdFromReducer, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { headerHeight } = useAppContext()

  const companyId = params.companyId ?? companyIdFromReducer
  const franchiseParam =
    params.companyId !== undefined
      ? (searchParams.get("franchise") ?? undefined)
      : (storeFranchise ?? undefined)

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const { isCompanyAdmin, isIdaAdmin } = usePermissions()

  const isAdminPath = location.pathname.includes("/admin/")
  const isAdminInAdminPage = (isCompanyAdmin || isIdaAdmin) && isAdminPath

  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(
    isAdminInAdminPage ? null : storeId,
  )

  const defaultStartDate = add(new Date(), { days: -15 })
  const defaultEndDate = new Date()

  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: fromParam ? new Date(fromParam) : defaultStartDate,
    to: toParam ? new Date(toParam) : defaultEndDate,
  })

  const fromDate = dateRange?.from ?? defaultStartDate
  const toDate = dateRange?.to ?? defaultEndDate

  // Track sidebar state
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const {
    data: storesData,
    loading: storesLoading,
    error: storesError,
  } = useGetStoresQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise: franchiseParam,
        include_inactive: false,
      },
    },
    skip: !companyId || !(isCompanyAdmin || isIdaAdmin),
  })

  const stores = useMemo(
    () => storesData?.getStores?.stores ?? [],
    [storesData],
  )

  const companyName = stores[0]?.company_name?.toLowerCase() || ""

  const context: PerformancesPageContext = {
    storeId,
    companyId: companyId ?? undefined,
    franchise: franchiseParam,
    fromDate,
    toDate,
    selectedStoreId,
    selectedStoreName: stores.find((store) => store.id === selectedStoreId)
      ?.store_name,
    stores,
  }

  const createPathPatterns = (basePath: string) => {
    return [`/admin/companies/:companyId${basePath}`, basePath]
  }

  const selectedPage = useMemo(() => {
    for (const sidebarItem of sidebarNavItems) {
      if (sidebarItem.children !== undefined) {
        for (const child of sidebarItem.children) {
          const childPathPatterns = createPathPatterns(
            `/performances/${sidebarItem.id}/${child.id}`,
          )

          const isMatch = childPathPatterns.some(
            (pattern) => matchPath(pattern, location.pathname) !== null,
          )

          if (isMatch) {
            return child
          }
        }
      }

      const itemPathPatterns = createPathPatterns(
        `/performances/${sidebarItem.id}`,
      )

      const isMatch = itemPathPatterns.some(
        (pattern) => matchPath(pattern, location.pathname) !== null,
      )

      if (isMatch) {
        return sidebarItem
      }
    }
  }, [location.pathname])

  const getLinkPath = (itemId: string, childId?: string) => {
    const routePath = childId ? `${itemId}/${childId}` : `${itemId}`

    const queryParams =
      franchiseParam !== undefined ? `?franchise=${franchiseParam}` : ""

    return `/admin/companies/${companyId}/performances/${routePath}${queryParams}`
  }

  const handleSidebarToggle = (isOpen: boolean) => {
    setIsSidebarOpen(isOpen)
  }

  if (storesLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (storesError) {
    return <div className="text-red-500">Error fetching stores.</div>
  }

  return (
    <div className="flex w-full h-full">
      {/* Sidebar */}
      <AdminSidebar
        sidebarNavItems={sidebarNavItems}
        franchiseParam={franchiseParam}
        companyName={companyName}
        createPathPatterns={createPathPatterns}
        getLinkPath={getLinkPath}
        onSidebarToggle={handleSidebarToggle}
      />

      {/* Main content */}
      <div
        className="flex-1 overflow-auto flex flex-col"
        style={{
          marginLeft: isSidebarOpen ? "16rem" : "3.5rem",
          height: `calc(100vh - ${headerHeight}px)`,
        }}
      >
        <div className="flex gap-2 px-4 py-4 w-fit flex-wrap sticky top-0  z-10">
          {(isIdaAdmin || isCompanyAdmin) && isAdminPath && (
            <StoreSelector
              stores={stores}
              selectedStoreId={selectedStoreId}
              onSelect={(storeId) => setSelectedStoreId(storeId)}
              disabled={selectedPage?.filters?.store !== true}
            />
          )}
          <DateSelector
            fromDate={context.fromDate}
            toDate={context.toDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchParams={setSearchParams}
            franchiseParam={franchiseParam}
            disabled={selectedPage?.filters?.dates !== true}
          />
        </div>

        <div className="flex-1 px-4 pb-4">
          <Outlet context={context} />
        </div>
      </div>
    </div>
  )
}
