import { DataSynchronizationStatus } from "@/reducers/connectionReducer"
import { DispatchActionType, StateType } from "@/types"
import {
  InventoryType,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "@/utils/__generated__/graphql"
import { getBatchData } from "@/utils/getBatchData"
import { computeModificationObject } from "@/utils/sendLogsModificationUpdate"
import { captureException } from "@sentry/react"
import { useDispatch, useSelector } from "react-redux"

interface UseSaveModificationResult {
  saveModification: (
    inventoryType: InventoryType,
    mercurialeId: string,
    value: number,
  ) => Promise<void>
  isLoading: boolean
}

export function useSaveModification(): UseSaveModificationResult {
  const dispatch = useDispatch<DispatchActionType>()

  const [batchUpdateMutation, { loading: batchUpdateLoading }] =
    useBatchUpdateMutation()
  const [logsModificationUpdate, { loading: logsModificationUpdateLoading }] =
    useLogsModificationUpdateMutation()

  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const { mercurialAndStoreInventories, updatedReferences, dimMercuriales } =
    useSelector((state: StateType) => state.mercurialReducer)
  const { storeId } = useSelector((state: StateType) => state.storeReducer)

  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  async function saveModification(
    inventoryType: InventoryType,
    mercurialeId: string,
    value: number,
  ) {
    const mercuriale = mercurialAndStoreInventories.find(
      (mercuriale) => mercuriale.mercuriale_id === mercurialeId,
    )
    if (!mercuriale) return

    const newUpdatedReference = { ...updatedReferences[mercurialeId] }
    switch (inventoryType) {
      case InventoryType.Back:
        newUpdatedReference.backInventoryQuantity = value
        break
      case InventoryType.Floor:
        newUpdatedReference.floorInventoryQuantity = value
        break
      case InventoryType.Shelf:
        newUpdatedReference.shelfFloorSize = value
        break
      case InventoryType.Order:
        newUpdatedReference.orderInventoryQuantity = value
        break
    }

    const modification = computeModificationObject({
      inventoryType: inventoryType,
      modifiedValue: value,
      mercurialeInfo: mercuriale,
      updatedReference: updatedReferences[mercurialeId],
    })

    if (online === false && isTestMode === false) {
      dispatch({
        type: "addModification",
        payload: modification,
      })
      dispatch({
        type: "setDataSynchronizationStatus",
        payload: DataSynchronizationStatus.UNSYNCHRONIZED,
      })
      return
    }

    try {
      const batchData = getBatchData(mercurialAndStoreInventories, {
        [mercurialeId]: newUpdatedReference,
      })
      const batchUpdateResult = await batchUpdateMutation({
        variables: {
          input: {
            batch_data: batchData,
            dim_order_request_id:
              "dim_order_request_id" in mercuriale
                ? (dimOrderRequestId ?? mercuriale.dim_order_request_id)
                : null,
            store_id: storeId!,
            inventory_type: inventoryType,
          },
        },
      })

      if (batchUpdateResult.data?.batchUpdate?.error !== null) {
        throw batchUpdateResult.data?.batchUpdate?.error
      }
      if (
        typeof batchUpdateResult.data.batchUpdate.dim_order_request_id ===
        "string"
      ) {
        dispatch({
          type: "setDimOrderRequestId",
          payload: {
            dimMercurialeId: mercuriale.dim_mercuriale_id ?? "",
            dimOrderRequestId:
              batchUpdateResult.data?.batchUpdate.dim_order_request_id,
          },
        })
      }

      const logsModificationResult = await logsModificationUpdate({
        variables: {
          input: {
            modifications_logs_items: [modification],
            store_id: storeId!,
          },
        },
      })
      if (logsModificationResult.data?.logsModificationUpdate?.error !== null) {
        throw logsModificationResult.data?.logsModificationUpdate?.error
      }
    } catch (error) {
      console.error(error)
      const errorMesssage =
        error instanceof Error ? error.message : "Données non sauvegardées"
      captureException(new Error(errorMesssage))
      dispatch({
        type: "setDataSynchronizationStatus",
        payload: DataSynchronizationStatus.FAILURE,
      })
      dispatch({
        type: "addModification",
        payload: modification,
      })
    }
  }

  return {
    saveModification,
    isLoading: batchUpdateLoading || logsModificationUpdateLoading,
  }
}
