import { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { type DispatchActionType, type StateType } from "../../types"
import { getNormalizedUnit } from "../../utils/getUnit"
import { InventoryType } from "../../utils/__generated__/graphql"
import { NumericPad } from "../../ui/NumericPad"
import {
  bestSellersSelector,
  filteredMercurialeReducerSelector,
} from "../../selectors/mercurialeSelectors"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { useOrderContext } from "@/contexts/OrderContext"
import { useDebounce } from "@/hooks/useDebounce"
import { isOrderDisabled } from "@/utils/isOrderDisabled"
import { usePermissions } from "@/hooks/usePermissions"

interface NumericPadProps {
  isLoading: boolean
  updateInventory: (value: string) => Promise<void>
  mercurialeInfos: AllMercurialInfo[]
  groupedMercurialeInfos: AllMercurialInfo[][]
}

const UpdateInventoryPad = ({
  groupedMercurialeInfos,
  isLoading,
  updateInventory,
}: NumericPadProps): JSX.Element | null => {
  const dispatch = useDispatch<DispatchActionType>()
  const { canUpdateOrderQuantity } = usePermissions()
  const storeSettings = useSelector(
    (state: StateType) => state.storeReducer.storeSettings,
  )
  const { mercurialAndStoreInventories } = useSelector(
    filteredMercurialeReducerSelector,
  )
  const bestSellers = useSelector(bestSellersSelector)
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const numericPadValue = useSelector(
    (state: StateType) => state.userInterfaceReducer.numericPadValue,
  )

  const [lastClick, setLastClick] = useState<Date>()
  const debouncedLastClick = useDebounce(lastClick, 3000)
  const { lastInputSelected, setLastInputSelected } = useOrderContext()

  useEffect(() => {
    setLastClick(undefined)
  }, [lastInputSelected])

  const currentSelectedInputSaleIdIndex = useMemo(() => {
    return groupedMercurialeInfos?.findIndex(
      (item) => item[0].sale_id === lastInputSelected.saleId,
    )
  }, [groupedMercurialeInfos, lastInputSelected.saleId])

  function handleNumericPadClick(value: string): void {
    if (typeof selectedInventory?.mercurialeId !== "string") return

    // Handle blur (unfocus input)
    if (value === "blur" && storeSettings?.auto_scroll_inventory) {
      const nextReference = groupedMercurialeInfos.reduce<
        AllMercurialInfo | undefined
      >((nextReference, groupedMercurialeInfo, i) => {
        if (nextReference !== undefined) return nextReference

        const isAfterCurrentSaleId = i > currentSelectedInputSaleIdIndex
        if (!isAfterCurrentSaleId) return nextReference

        const activeReference = groupedMercurialeInfo.find((reference) => {
          return (
            selectedInventory.type !== InventoryType.Order ||
            !isOrderDisabled(
              reference,
              bestSellers,
              storeSettings,
              canUpdateOrderQuantity,
            )
          )
        })

        return activeReference
      }, undefined)

      setLastInputSelected({
        type: lastInputSelected.type,
        saleId: nextReference?.sale_id ?? "",
        mercurialeId: nextReference?.mercuriale_id ?? "",
        saleIdIndex: currentSelectedInputSaleIdIndex + 1,
        index: 0,
      })

      dispatch({
        type: "setSelectedInventory",
        payload:
          nextReference !== undefined
            ? {
                type: lastInputSelected.type,
                mercurialeId: nextReference.mercuriale_id ?? "",
              }
            : undefined,
      })
      return
    } else if (value === "blur") {
      dispatch({
        type: "setSelectedInventory",
        payload: undefined,
      })
      return
    }

    setLastClick(new Date())

    // Handle quarter
    if (value === "1/4" || value === "1/2" || value === "3/4") {
      const [integer] = numericPadValue.split(".")
      // If numeric pad value is empty, set it to 0 to automatically add the integer part
      const zero = numericPadValue === "" ? "0" : undefined

      handleNumericPadChange(
        `${zero ?? integer}.${value === "1/4" ? "25" : value === "1/2" ? "50" : "75"}`,
      )
      return
    }

    // Handle delete
    if (value === "-1") {
      if (numericPadValue === "" || numericPadValue.length === 1) {
        handleNumericPadChange("0")
        return
      }
      if (/^\d+\.\d{2}$/.test(numericPadValue)) {
        handleNumericPadChange(`${numericPadValue.slice(0, -3)}`)
        return
      }
      if (/^\d+\.\d{1}$/.test(numericPadValue)) {
        handleNumericPadChange(`${numericPadValue.slice(0, -2)}`)
        return
      }
      handleNumericPadChange(`${numericPadValue.slice(0, -1)}`)
      return
    }

    // Handle numbers
    let newValue = `${numericPadValue}${value}`
    if (numericPadValue === "0") {
      newValue = `${value}`
    }
    if (
      typeof selectedInventory?.mercurialeId === "string" &&
      selectedInventory?.type === "order" &&
      /^\d+\.\d+$/.test(newValue)
    ) {
      handleNumericPadChange(`${Math.ceil(parseFloat(newValue))}`)
      return
    }
    if (
      typeof selectedInventory?.mercurialeId === "string" &&
      /^\d+\.\d{2}$/.test(newValue)
    ) {
      handleNumericPadChange(`${parseFloat(newValue).toFixed(1)}`)
    } else if (typeof selectedInventory?.mercurialeId === "string") {
      handleNumericPadChange(newValue)
    }
  }

  function handleNumericPadChange(value: string): void {
    dispatch({
      type: "setNumericPadValueAction",
      payload: value,
    })
    if (value === "") return
    void updateInventory(value)
  }

  const isDotDisabled = useMemo(() => {
    if (
      isLoading ||
      selectedInventory?.mercurialeId === undefined ||
      selectedInventory?.type === InventoryType.Order
    ) {
      return true
    }
    const mercurialeItem = mercurialAndStoreInventories.find(
      (item) => item.mercuriale_id === selectedInventory?.mercurialeId,
    )
    if (mercurialeItem === undefined) return true
    if (
      mercurialeItem.colisage === 1 &&
      getNormalizedUnit(mercurialeItem.unit) === "pce"
    )
      return true
    return false
  }, [
    isLoading,
    selectedInventory?.mercurialeId,
    selectedInventory?.type,
    mercurialAndStoreInventories,
  ])

  const isDeleteDisabled = useMemo(
    () =>
      isLoading ||
      selectedInventory?.mercurialeId === undefined ||
      numericPadValue === "0",
    [isLoading, numericPadValue, selectedInventory?.mercurialeId],
  )

  const isNumbersDisabled = useMemo(
    () =>
      isLoading ||
      selectedInventory?.mercurialeId === undefined ||
      /^\d+\.\d+$/.test(numericPadValue),
    [isLoading, numericPadValue, selectedInventory?.mercurialeId],
  )

  const isBlurDisabled = useMemo(
    () =>
      isLoading ||
      selectedInventory?.mercurialeId === undefined ||
      groupedMercurialeInfos.length === currentSelectedInputSaleIdIndex + 1,
    [
      isLoading,
      selectedInventory?.mercurialeId,
      groupedMercurialeInfos.length,
      currentSelectedInputSaleIdIndex,
    ],
  )

  useEffect(() => {
    if (debouncedLastClick === undefined) return
    dispatch({
      type: "setSelectedInventory",
      payload: undefined,
    })
  }, [debouncedLastClick, dispatch])

  if (storeId === null) {
    // Handle error, perhaps redirect the user or show a message
    return null // Or render some specific error component
  }

  return (
    <NumericPad
      onClick={handleNumericPadClick}
      disableDot={isDotDisabled}
      disableDelete={isDeleteDisabled}
      disableNumbers={isNumbersDisabled}
      disableBlur={isBlurDisabled}
    />
  )
}

export default UpdateInventoryPad
