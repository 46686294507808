import { CheckinSideBar } from "../components/checkinSideBar"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"
import {
  useGetProductsToConciderQuery,
  useGetStoreSalesPerformanceQuery,
} from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import _ from "lodash"
import { InventoryRow } from "@/components/order/InventoryRow"
import { bestSellersSelector } from "@/selectors/mercurialeSelectors"
import { GroupedVirtuoso } from "react-virtuoso"
import { useMemo } from "react"
import {
  AlertCircle,
  TrendingDown,
  Package,
  ShoppingBag,
  Clock,
  HandCoins,
  Loader2,
} from "lucide-react"
import { Card } from "@/components/ui/card"

type VirtualListItem = AllMercurialInfo & {
  type: "rupture" | "worstItem" | "productToConcider"
  sale_id?: string
  sale_name?: string
  potential_revenue_loss?: number
  store_id?: string
}

export const IdaRecommandations = ({
  gotToNextStep,
  currentStep,
  totalSteps,
  mercurialeInfos,
  closeCheckinModal,
  potentialRupturesData,
  updateInventory,
  isPotentialRupturesDataLoading,
}: {
  gotToNextStep: () => void
  currentStep: number
  totalSteps: number
  mercurialeInfos: AllMercurialInfo[]
  closeCheckinModal: () => void
  potentialRupturesData: AllMercurialInfo[]
  isPotentialRupturesDataLoading: boolean
  updateInventory: (
    value: string,
    selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
  ) => Promise<void>
}) => {
  const { storeId } = useSelector((state: StateType) => state.storeReducer)

  const {
    storeFranchise,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
    companyName,
  } = useSelector((state: StateType) => state.storeReducer)

  const bestSellers = useSelector(bestSellersSelector)

  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const displayShelfFloorSize = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.orderPage.displayShelfFloorSize,
  )

  const {
    data: storeSalesPerformanceData,
    loading: storeSalesPerformanceLoading,
  } = useGetStoreSalesPerformanceQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
    },
  })

  const { data: productsToConcider, loading: productsToConciderLoading } =
    useGetProductsToConciderQuery({
      variables: {
        input: {
          store_id: storeId ?? "",
        },
      },
    })

  const loading =
    storeSalesPerformanceLoading ||
    productsToConciderLoading ||
    isPotentialRupturesDataLoading

  const worstItems =
    storeSalesPerformanceData?.getStoreSalesPerformance?.store_sales_performance?.filter(
      (item) => item.group === "worst",
    ) ?? []

  const worstItemMercurialeInfo = mercurialeInfos
    .filter((item) =>
      worstItems.some((worstItem) => worstItem.sale_id === item.sale_id),
    )
    .sort((a, b) => {
      const worstItemA = worstItems.find(
        (worstItem) => worstItem.sale_id === a.sale_id,
      )
      const worstItemB = worstItems.find(
        (worstItem) => worstItem.sale_id === b.sale_id,
      )

      const lossA = worstItemA?.potential_revenue_loss ?? 0
      const lossB = worstItemB?.potential_revenue_loss ?? 0

      return lossB - lossA
    })
    .slice(0, 3)

  const top3ProductsToConcider = useMemo(() => {
    if (!productsToConcider?.productToConsider?.product_to_consider) return []

    const allProductsToConcider = [
      ...productsToConcider.productToConsider.product_to_consider,
    ]

    const shuffledProducts = _.shuffle(allProductsToConcider)

    const randomThreeProducts = shuffledProducts.slice(0, 3)

    return randomThreeProducts.sort((a, b) => {
      const aValue = a.potential_revenue_loss || 0
      const bValue = b.potential_revenue_loss || 0
      return bValue - aValue
    })
  }, [productsToConcider])

  const virtualListData = useMemo(() => {
    const productsToConciderMercurialeInfo = top3ProductsToConcider
      .map((productToConcider) => {
        const mercurialeInfo = mercurialeInfos.find(
          (item) => item.sale_id === productToConcider.sale_id,
        )
        if (mercurialeInfo) {
          return {
            ...mercurialeInfo,
            type: "productToConcider",
            potential_revenue_loss: productToConcider.potential_revenue_loss,
          }
        }
        return null
      })
      .filter((item) => item !== null)

    const allItems = [
      ...potentialRupturesData.map((item) => ({ ...item, type: "rupture" })),
      ...worstItemMercurialeInfo.map((item) => ({
        ...item,
        type: "worstItem",
      })),
      ...productsToConciderMercurialeInfo,
    ]

    const groupedItems = _.groupBy(allItems, "type")

    const groupCounts: number[] = []
    const groups: { type: string; title: string }[] = []
    const flatItems: VirtualListItem[] = []

    const getGroupTitle = (groupType: string) => {
      switch (groupType) {
        case "rupture":
          return "Ruptures potentielles"
        case "worstItem":
          return "Articles en sous-performance vs comparables"
        case "productToConcider":
          return "Produits à considérer"
        default:
          return groupType
      }
    }

    Object.keys(groupedItems).forEach((groupType) => {
      const items = groupedItems[groupType]
      if (items.length > 0) {
        groups.push({
          type: groupType,
          title: getGroupTitle(groupType),
        })
        groupCounts.push(items.length)
        flatItems.push(
          ...items.map((item) => ({
            ...item,
            type: item.type as "productToConcider" | "rupture" | "worstItem",
            sale_id: item.sale_id ?? undefined,
            store_id: item.store_id ?? undefined,
          })),
        )
      }
    })

    return {
      groupCounts,
      groups,
      flatItems,
    }
  }, [
    potentialRupturesData,
    worstItemMercurialeInfo,
    top3ProductsToConcider,
    mercurialeInfos,
  ])

  const getGroupIcon = (groupType: string) => {
    switch (groupType) {
      case "rupture":
        return <AlertCircle className="size-5 md:size-6  text-red-400" />
      case "worstItem":
        return <TrendingDown className="size-5 md:size-6 text-amber-400" />
      case "productToConcider":
        return <ShoppingBag className="size-5 md:size-6 text-blue-400" />
      default:
        return <Package className="size-5 md:size-6 text-gray-400" />
    }
  }

  const getStaticGradientStyle = (groupType: string) => {
    switch (groupType) {
      case "rupture":
        return {
          background: `radial-gradient(
            circle at 50% 50%, 
            #FFF0F0 0%, 
            #FFE6E6 35%, 
            #FFDADA 70%, 
            #FFF5F5 100%
          )`,
        }
      case "worstItem":
        return {
          background: `radial-gradient(
            circle at 50% 50%, 
            #FFF8E6 0%, 
            #FFF3D0 35%, 
            #FFEEBA 70%, 
            #FFF9EC 100%
          )`,
        }
      case "productToConcider":
        return {
          background: `radial-gradient(
            circle at 50% 50%, 
            #F0F7FF 0%, 
            #E6F0FF 35%, 
            #D6E8FF 70%, 
            #F5F9FF 100%
          )`,
        }
      default:
        return {
          background: `radial-gradient(
            circle at 50% 50%, 
            #FAFAFA 0%, 
            #F5F5F5 35%, 
            #EEEEEE 70%, 
            #F9FAFB 100%
          )`,
        }
    }
  }

  const getGroupDescription = (groupType: string) => {
    switch (groupType) {
      case "rupture":
        return "Articles en rupture de stock hier ou non remis en rayon. Veuillez vérifier les commandes de ces références."
      case "worstItem":
        return "Articles dont les ventes sont inférieures à celles des magasins comparables. Augmentez les commandes ou les mises en avant."
      case "productToConcider":
        return "Produits absents de votre assortiment mais qui génèrent des ventes dans les magasins comparables."
      default:
        return ""
    }
  }

  const renderGroupHeader = (index: number) => {
    const group = virtualListData.groups[index]

    return (
      <div
        className="sticky top-0 z-auto bg-white rounded-xl shadow-sm"
        style={getStaticGradientStyle(group.type)}
      >
        <div className="p-4 md:p-6">
          <div className="flex flex-col space-y-2 md:space-y-3">
            <div className="flex flex-row items-center gap-2 md:gap-3">
              {getGroupIcon(group.type)}
              <h3 className="text-lg md:text-xl font-semibold text-gray-800">
                {group.title}
              </h3>
            </div>
            <p className="hidden md:block text-sm text-gray-600">
              {getGroupDescription(group.type)}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderItem = (index: number) => {
    const item = virtualListData.flatItems[index]

    if (item.type === "rupture") {
      let formattedLastSaleTime = "—"
      if (item.last_sale_time) {
        try {
          formattedLastSaleTime = new Intl.DateTimeFormat("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          }).format(new Date(item.last_sale_time))
        } catch (err) {
          formattedLastSaleTime = "—"
        }
      }

      const referenceLoss = item.rupture_loss_amount ?? 0
      const formattedReferenceLoss = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: storeCurrency ?? "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(referenceLoss)

      return (
        <div className="py-3">
          <Card className="border rounded-lg shadow-sm">
            <div className="bg-white rounded-lg p-4">
              <div className="flex flex-col md:flex-row gap-4 justify-between mb-4">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col space-y-2">
                    <span className="flex items-center text-xs font-medium text-gray-500 uppercase">
                      Heure dernière vente
                    </span>
                    <span className="flex items-center text-xl font-bold text-red-600 underline">
                      <Clock className="w-4 h-4 mr-2" />
                      {formattedLastSaleTime}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 bg-red-600 text-white font-bold px-4 py-2 rounded-full">
                    <TrendingDown className="w-4 h-4" />
                    <span>Perte: {formattedReferenceLoss}</span>
                  </div>
                </div>
              </div>
              <InventoryRow
                key={item.sale_id!}
                index={index}
                storeId={storeId}
                bestSellers={bestSellers}
                selectedInventory={selectedInventory}
                isOnline={isOnline}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                displayShelfFloorSize={displayShelfFloorSize}
                storeSuppliers={storeSuppliers}
                storeCurrency={storeCurrency}
                companyId={companyId}
                references={[item]}
                companyName={companyName}
                franchise={storeFranchise}
                isInCheckinPage={true}
              />
            </div>
          </Card>
        </div>
      )
    }

    if (item.type === "worstItem") {
      const currentWorstItem = worstItems.find(
        (worstItem) => worstItem.sale_id === item.sale_id,
      )

      const difference =
        (currentWorstItem?.product_cluster_quote_part ?? 0) -
        (currentWorstItem?.product_store_quote_part ?? 0)

      return (
        <div className="py-3">
          <Card className="border rounded-lg shadow-sm">
            <div className="bg-white rounded-lg p-4">
              <div className="flex flex-col md:flex-row gap-4 justify-between mb-4">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col space-y-2">
                    <span className="flex items-center text-xs font-medium text-gray-500 uppercase">
                      Quote part magasin
                    </span>
                    <span className="text-amber-600 font-bold text-lg">
                      {typeof currentWorstItem?.product_store_quote_part ===
                      "number"
                        ? currentWorstItem?.product_store_quote_part.toFixed(1)
                        : currentWorstItem?.product_store_quote_part}
                      %
                    </span>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <span className="flex items-center text-xs font-medium text-gray-500 uppercase">
                      Quote part comparables
                    </span>
                    {difference > 0 && (
                      <span className="font-bold text-lg md:inline text-amber-600">
                        {typeof currentWorstItem?.product_cluster_quote_part ===
                        "number"
                          ? currentWorstItem?.product_cluster_quote_part.toFixed(
                              1,
                            )
                          : currentWorstItem?.product_cluster_quote_part}
                        %
                      </span>
                    )}
                  </div>
                  {currentWorstItem?.potential_revenue_loss &&
                  currentWorstItem?.potential_revenue_loss > 0 ? (
                    <div className="flex items-center gap-2 bg-amber-600 text-white font-bold px-2 py-1 md:px-4 md:py-2 rounded-full">
                      <TrendingDown className="w-4 h-4" />
                      <span className="text-xs md:text-base">
                        Perte:{" "}
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: storeCurrency ?? "EUR",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(currentWorstItem?.potential_revenue_loss)}
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <InventoryRow
                key={item.sale_id!}
                index={index}
                storeId={storeId}
                bestSellers={bestSellers}
                selectedInventory={selectedInventory}
                isOnline={isOnline}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                displayShelfFloorSize={displayShelfFloorSize}
                storeSuppliers={storeSuppliers}
                storeCurrency={storeCurrency}
                companyId={companyId}
                references={[item]}
                companyName={companyName}
                franchise={storeFranchise}
                isInCheckinPage={true}
              />
            </div>
          </Card>
        </div>
      )
    }

    if (item.type === "productToConcider") {
      const formattedPotentialRevenueLoss = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: storeCurrency ?? "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(item.potential_revenue_loss ?? 0)

      return (
        <div className="py-3">
          <Card className="border rounded-lg shadow-sm">
            <div className="flex justify-end">
              <div className="flex items-center gap-2 m-4 bg-blue-600 text-white font-bold px-4 py-2 rounded-full">
                <HandCoins className="w-4 h-4" />
                <span>
                  Chiffre d&apos;affaires potentiel:{" "}
                  {formattedPotentialRevenueLoss}
                </span>
              </div>
            </div>
            <div className="bg-white rounded-lg p-4 pt-0">
              <InventoryRow
                key={item.sale_id!}
                index={index}
                storeId={storeId}
                bestSellers={bestSellers}
                selectedInventory={selectedInventory}
                isOnline={isOnline}
                storeSettings={storeSettings}
                updateInventory={updateInventory}
                displayShelfFloorSize={displayShelfFloorSize}
                storeSuppliers={storeSuppliers}
                storeCurrency={storeCurrency}
                companyId={companyId}
                references={[item]}
                companyName={companyName}
                franchise={storeFranchise}
                isInCheckinPage={true}
              />
            </div>
          </Card>
        </div>
      )
    }
  }

  return (
    <div className="flex flex-row gap-4 px-2 w-full">
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center h-64 border rounded-lg">
            <Loader2 className="size-8 animate-spin" />
          </div>
        ) : virtualListData.flatItems.length > 0 ? (
          <div className="overflow-hidden rounded-lg">
            <GroupedVirtuoso
              style={{
                height: "calc(100vh - 160px)",
                border: "none",
                background: "transparent",
                marginBottom: "120px",
              }}
              groupCounts={virtualListData.groupCounts}
              groupContent={renderGroupHeader}
              itemContent={renderItem}
              overscan={100}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-64 border rounded-lg">
            <div className="flex flex-col items-center justify-center gap-3 text-gray-500">
              <Package className="size-8 opacity-40" />
              <p>Aucune donnée disponible</p>
            </div>
          </div>
        )}
      </div>
      <CheckinSideBar
        gotToNextStep={gotToNextStep}
        currentStep={currentStep}
        totalSteps={totalSteps}
        updateInventory={updateInventory}
        mercurialeInfos={mercurialeInfos}
        closeCheckinModal={closeCheckinModal}
        hasGotToNextStepTimeBeforeClick={10}
        hasSkipButtonTimeBeforeClick={20}
      />
    </div>
  )
}
