interface SalesItem {
  sale_name?: string
  sale_name_ida?: string
  store_id?: string
  store_name?: string
  potential_revenue_loss?: number
  revenue?: number
  revenue_difference?: number
  delivered?: boolean
  percentage?: number
  product_store_quote_part?: number
  product_cluster_quote_part?: number
  group?: string
  __typename?: string
  [key: string]: string | number | boolean | undefined
}

type ColumnTranslations = Record<string, string>

const columnTranslations: ColumnTranslations = {
  sale_name: "Produit",
  sale_name_ida: "Code de vente",
  store_id: "Nom du magasin",
  store_name: "Nom du magasin",
  potential_revenue_loss: "Perte en chiffre d'affaires",
  revenue: "Chiffre d'affaires",
  revenue_difference: "Différence de chiffre d'affaires",
  percentage: "Pourcentage",
  product_store_quote_part: "Quote part magasin",
  product_cluster_quote_part: "Quote part comparables",
}

export const convertToCSV = (objArray: SalesItem[]): string => {
  if (objArray.length === 0) return ""

  const originalHeaders = Object.keys(objArray[0]).filter(
    (header) =>
      !header.includes("_id") &&
      !header.includes("_ida") &&
      !header.endsWith("Id") &&
      header !== "delivered" &&
      header !== "group" &&
      header !== "__typename",
  )

  const translatedHeaders = originalHeaders.map(
    (header) => columnTranslations[header] || header,
  )

  const headerRow = translatedHeaders.join(";")

  const rows = objArray
    .map((obj) => {
      return originalHeaders
        .map((header) => {
          let value = obj[header]

          if (
            header === "potential_revenue_loss" ||
            header === "revenue" ||
            header === "revenue_difference"
          ) {
            value =
              typeof value === "number"
                ? new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    .format(value)
                    .replace(/\s/g, "")
                : value
          }

          if (header === "percentage") {
            value = typeof value === "number" ? `${value.toFixed(2)}%` : value
          }

          if (
            header === "product_store_quote_part" ||
            header === "product_cluster_quote_part"
          ) {
            value = typeof value === "number" ? `${value.toFixed(1)}%` : value
          }

          value = String(value ?? "")
          if (value.includes(";")) {
            return `"${value}"`
          }
          return value
        })
        .join(";")
    })
    .join("\n")

  return `${headerRow}\n${rows}`
}

export const downloadCSV = (data: SalesItem[], filename: string): void => {
  const csv = convertToCSV(data)
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)

  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", filename)
  link.style.visibility = "hidden"

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const exportDataToCSV = (data: SalesItem[], filename: string): void => {
  downloadCSV(data, filename)
}
