import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DispatchActionType, StateType } from "../types"
import { Spinner } from "../ui/Spinner"

const Callback: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<DispatchActionType>()
  const redirectPath = useSelector(
    (state: StateType) => state.redirectReducer.path,
  )

  useEffect(() => {
    dispatch({ type: "setIsAuthenticated", payload: true })
    navigate(redirectPath ?? "/order", { replace: true })
  }, [dispatch, navigate, redirectPath])

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner invertColors className="w-10 h-10" />
    </div>
  )
}

export default Callback
