import { AlertTriangleIcon } from "@/assets/AlertTriangle"
import { Success } from "@/assets/Success"
import { Navigate, useOutletContext } from "react-router-dom"
import { RecapContext } from "./RootRecap"
import { Img } from "react-image"
import { getDisplayedUnit } from "@/utils/getUnit"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { Button } from "@/components/ui/button"
import { HomeIcon } from "lucide-react"
import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"

export function SuccessPage() {
  const { companyId, companyName, storeFranchise, storeId } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const user = useSelector((state: StateType) => state.userReducer)

  const [{ successState }] = useOutletContext<RecapContext>()
  const { isOrderConfirmed, errorMessage, missingReferences } = successState

  if (!isOrderConfirmed) {
    return <Navigate to="/order/recap" />
  }

  return (
    <div
      className={twMerge(
        "h-screen md:p-2 flex flex-col gap-2",
        user.storeId !== storeId && "pb-12 md:pb-8",
      )}
    >
      <div className="w-full flex-1 overflow-y-auto flex items-center flex-col justify-center border border-zinc-200 rounded p-2 gap-4">
        {errorMessage !== undefined ? (
          <>
            <AlertTriangleIcon />
            <p className="text-zinc-400 text-xl">{errorMessage}</p>
          </>
        ) : (
          <>
            <Success />
            <p className="text-zinc-400 text-xl">Commande envoyée</p>
          </>
        )}
        {missingReferences.length > 0 && (
          <>
            <h2 className="self-start font-bold text-lg">
              Références indisponibles à la commande
            </h2>
            <div className="w-full overflow-y-auto gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
              {missingReferences.map((missingReference, i) => (
                <div
                  key={i}
                  className="font-bold text-sm py-4 px-4 rounded bg-white shadow flex flex-col gap-2 items-center justify-center"
                >
                  {missingReference.photoId && (
                    <Img
                      src={`https://storage.googleapis.com/references_images/${companyId}/${missingReference.photoId}.jpeg`}
                      className="w-12 h-12 rounded-full"
                    />
                  )}
                  <p className="font-bold text-sm text-center">
                    {missingReference.name}
                  </p>
                  <p className="text-zinc-500">
                    {missingReference.quantity}{" "}
                    {getDisplayedUnit("cs", companyName, storeFranchise)}
                  </p>
                  <p className="text-zinc-500">
                    <span className="font-bold">Raison :</span>{" "}
                    <span className="font-normal italic">
                      {missingReference.error_message}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Button
        className="w-fit uppercase font-bold text-lg py-4 px-5 h-auto bg-green-600 hover:bg-green-700"
        asChild
      >
        <Link to="/home">
          <HomeIcon className="size-6" />
          Accueil
        </Link>
      </Button>
    </div>
  )
}
