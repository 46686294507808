import { Skeleton } from "@/components/ui/skeleton"
import { TableBody, TableCell, TableRow } from "@/components/ui/table"

export function TableBodyLoading() {
  return (
    <TableBody>
      {Array.from({ length: 20 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton className="size-8 rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-48 h-3" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-48 h-3" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-24 h-3" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-24 h-3" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-8 h-3" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-8 h-3" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-14 h-6" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
