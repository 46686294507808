import { StateType } from "@/types"
import {
  GetLastMonthUnmatchedReferencesQuery,
  useGetLastMonthUnmatchedReferencesQuery,
} from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"

interface UseLastMonthUnmatchedReferencesResult {
  unMatchedReferencesCount: number
  unMatchedReferences:
    | GetLastMonthUnmatchedReferencesQuery["lastMonthUnmatchedReferences"]["records"]
    | undefined
  isLoading: boolean
}

export function useLastMonthUnmatchedReferences(): UseLastMonthUnmatchedReferencesResult {
  const { storeId, companyId, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const isOnline = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { data, loading } = useGetLastMonthUnmatchedReferencesQuery({
    variables: {
      input: {
        company_id: companyId!,
        franchise_name: storeFranchise,
        store_id: storeId!,
      },
    },
    skip: !isOnline,
  })

  const unMatchedReferencesCount =
    data?.lastMonthUnmatchedReferences.records.length ?? 0

  return {
    unMatchedReferencesCount,
    unMatchedReferences:
      data?.lastMonthUnmatchedReferences.records ?? undefined,
    isLoading: loading,
  }
}
