import { Card } from "@/components/ui/card"
import {
  ArrowUp,
  ArrowDown,
  TrendingUp,
  TrendingDown,
  LandPlot,
} from "lucide-react"

interface OrderStatsBarProps {
  countUp: number
  countDown: number
  totalCount: number
  dateTrend: number
  meanModDistance: number
  distancePerDay: number
  totalOrderQuantity: number
  totalReferencesOrdered: number
  meanGain: number
  meanLoss: number
  netResult: number
}

interface StatCardProps {
  icon: React.ReactNode
  label: string
  mobileLabel?: string
  value: React.ReactNode
  bgColor?: string
}

export default function OrderStatsBar(props: OrderStatsBarProps) {
  const { countUp, countDown, totalCount, dateTrend, meanModDistance } = props

  const toPercent = (val: number) => {
    if (!totalCount) return 0
    return Math.round((val / totalCount) * 100)
  }

  const StatCard = ({
    icon,
    label,
    mobileLabel,
    value,
    bgColor,
  }: StatCardProps) => (
    <Card className="flex h-full flex-col xl:flex-row w-full xl:items-center xl:justify-between gap-1 px-3 py-1 md:py-4">
      <div className="flex min-w-0 items-center gap-1">
        <div className={`flex shrink-0 rounded-sm p-1 ${bgColor}`}>{icon}</div>
        <p className="text-xs md:text-xs lg:text-sm truncate">
          {mobileLabel ?? label}
        </p>
      </div>
      <p className="font-bold text-xs md:text-sm lg:text-lg shrink-0">
        {value}
      </p>
    </Card>
  )

  return (
    <div className="bg-white py-2 mb-2 border-b">
      <div className="overflow-x-auto lg:overflow-visible pb-2 md:pb-0">
        <div className="flex flex-row md:flex md:flex-row lg:grid lg:grid-cols-4 gap-2 md:gap-3 min-w-max md:min-w-0">
          <StatCard
            icon={
              <ArrowUp className="w-3 h-3 md:w-4 md:h-4 text-red-600 rotate-45" />
            }
            label="Modif à la hausse"
            value={
              <>
                {countUp}/{totalCount} <small>({toPercent(countUp)}%)</small>
              </>
            }
            bgColor="md:bg-red-30"
          />

          <StatCard
            icon={
              <ArrowDown className="w-3 h-3 md:w-4 md:h-4 text-orange-600 -rotate-45" />
            }
            label="Modif à la baisse"
            value={
              <>
                {countDown}/{totalCount}{" "}
                <small>({toPercent(countDown)}%)</small>
              </>
            }
            bgColor="md:bg-orange-30"
          />

          {totalCount > 0 && (
            <StatCard
              icon={
                dateTrend >= 0 ? (
                  <TrendingUp className="w-3 h-3 md:w-4 md:h-4 text-red-700" />
                ) : (
                  <TrendingDown className="w-3 h-3 md:w-4 md:h-4 text-green-700" />
                )
              }
              label="Tendance"
              value={dateTrend >= 0 ? `+${dateTrend}%` : `${dateTrend}%`}
              bgColor={dateTrend >= 0 ? "md:bg-red-30" : "md:bg-green-30"}
            />
          )}

          <StatCard
            icon={<LandPlot className="w-3 h-3 md:w-4 md:h-4 text-gray-700" />}
            label="Dist total /j"
            mobileLabel="Dist moy"
            value={`${meanModDistance.toFixed(1)} cs/ref`}
            bgColor="md:bg-blue-30"
          />
        </div>
      </div>
    </div>
  )
}
