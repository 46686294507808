import { useMemo } from "react"
import { AllMercurialInfo } from "../reducers/mercurialReducer"
import { addZeroBeforeNumber } from "../utils/addZeroBeforeNumber"

/**
 * Search bar filter logic (separated from other filters to retrieve number of filtered items)
 */
export function useSearchBar(
  mercurialeInfos: AllMercurialInfo[],
  searchTerm: string,
) {
  const searchedMercurialeInfos = useMemo<AllMercurialInfo[]>(() => {
    return mercurialeInfos.filter((mercurialeInfo) => {
      const lowerSearchTerm = searchTerm.toLowerCase()
      return (
        searchTerm === "" ||
        // Search by order code
        addZeroBeforeNumber(mercurialeInfo.order_code ?? 0) === searchTerm ||
        // Search by sale name ida
        mercurialeInfo.sale_name_ida?.toLowerCase() === searchTerm ||
        // Search by name
        (mercurialeInfo.mercuriale_name
          ?.toLowerCase()
          .includes(lowerSearchTerm) ??
          false) ||
        // Search by standard UUID
        (mercurialeInfo.mercuriale_id?.includes(lowerSearchTerm) ?? false) ||
        (mercurialeInfo.order_id?.includes(lowerSearchTerm) ?? false) ||
        (mercurialeInfo.sale_id?.includes(lowerSearchTerm) ?? false) ||
        // Search by BigQuery UUID
        (mercurialeInfo.mercuriale_id
          ?.replaceAll("-", "")
          .includes(lowerSearchTerm) ??
          false) ||
        (mercurialeInfo.order_id
          ?.replaceAll("-", "")
          .includes(lowerSearchTerm) ??
          false) ||
        (mercurialeInfo.sale_id
          ?.replaceAll("-", "")
          .includes(lowerSearchTerm) ??
          false)
      )
    })
  }, [mercurialeInfos, searchTerm])

  return searchedMercurialeInfos
}
