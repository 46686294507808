import { useState } from "react"
import Header from "@/components/header"
import { LastWeekPerformancesHome } from "./sections/LastWeekPerformancesHome"

import { OrderHistorical } from "./sections/orderHistorical"
import { ArrowRight, ChartColumn, History, Loader2 } from "lucide-react"
import { usePermissions } from "@/hooks/usePermissions"
import { Button } from "@/ui/Button"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "@/contexts/AppContext"
import { UnMatchedReferencesWidget } from "./sections/UnMatchedReferencesWidget"
import { StateType } from "@/types"
import { useSelector } from "react-redux"
import { storeDisplayRules } from "@/components/header/components/navigation/conditions"
import { IncreasedOrdersTable } from "@/components/tables/IncreaseOrderTable"
import { useGetStoreSalesPerformanceQuery } from "@/utils/__generated__/graphql"
import { EvolutionOfTheWeek } from "./sections/evolutionOfTheWeek"
import { Card } from "@/components/ui/card"

export const HomePage = () => {
  const companyName = useSelector(
    (state: StateType) => state.storeReducer.companyName,
  )
  const { storeId } = useSelector((state: StateType) => state.storeReducer)
  const [activeTab, setActiveTab] = useState<string>("performances")

  const { data, loading } = useGetStoreSalesPerformanceQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
      },
    },
  })

  const worstProducts =
    data?.getStoreSalesPerformance?.store_sales_performance?.filter(
      (item) => item.group === "worst",
    ) ?? []

  const tabs = [
    {
      id: "performances",
      label: "Évolution",
      icon: <ChartColumn />,
      component: (
        <div className="flex flex-col gap-2 mb-20 p-4">
          <LastWeekPerformancesHome />
          {loading ? (
            <Card className="flex items-center justify-center p-12">
              <div className="flex items-center justify-center p-12">
                <Loader2 className="size-10 animate-spin g" />
                <span className="ml-3 text-gray-600">
                  Chargement des données...
                </span>
              </div>
            </Card>
          ) : worstProducts.length > 0 ? (
            <IncreasedOrdersTable
              worstProducts={worstProducts}
              loading={loading}
            />
          ) : (
            <EvolutionOfTheWeek />
          )}
        </div>
      ),
    },
    {
      id: "historical",
      label: "Historique",
      icon: <History />,
      component: <OrderHistorical />,
    },
  ]

  const { headerHeight } = useAppContext()

  const { isIdaAdmin } = usePermissions()
  const navigate = useNavigate()

  const displayRules =
    companyName && companyName in storeDisplayRules
      ? storeDisplayRules[companyName]
      : storeDisplayRules.default

  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="flex-1 block md:hidden">
          <div className="h-full mb-44">
            {tabs.find((tab) => tab.id === activeTab)?.component}
          </div>
        </div>
        <div className="hidden md:grid grid-cols-1 md:grid-cols-2 gap-4 px-4 mb-20 md:mb-0">
          <div
            className="col-span-1 overflow-y-auto"
            style={{
              height: `calc(100vh - ${headerHeight + 24}px)`,
            }}
          >
            <OrderHistorical />
          </div>
          <div
            className="col-span-1 grid grid-cols-1 gap-4 overflow-y-auto"
            style={{
              height: `calc(100vh - ${headerHeight + 24}px)`,
            }}
          >
            {displayRules.showMatching && <UnMatchedReferencesWidget />}
            <LastWeekPerformancesHome />
            {loading ? (
              <Card className="flex items-center justify-center p-12">
                <div className="flex items-center justify-center p-12">
                  <Loader2 className="size-10 animate-spin" />
                  <span className="ml-3 text-gray-600">
                    Chargement des données...
                  </span>
                </div>
              </Card>
            ) : worstProducts.length > 0 ? (
              <IncreasedOrdersTable
                worstProducts={worstProducts}
                loading={loading}
              />
            ) : (
              <EvolutionOfTheWeek />
            )}
          </div>
        </div>

        <div className="fixed bottom-0 left-0 w-full bg-white border-t shadow-lg md:hidden">
          <div
            className={`flex flex-col justify-around py-2 ${isIdaAdmin ? "mb-10" : ""} gap-2`}
          >
            <Button
              onClick={() => {
                navigate("/order")
              }}
              className="bg-green-800 text-white  hover:bg-green-900 hover:text-white py-6 mx-4"
            >
              <p>Passer commande</p>
              <ArrowRight className="w-4 h-4" />
            </Button>
            <div className="flex flex-row justify-around">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex flex-col items-center text-xs px-4 py-1 rounded-md text-black ${
                    activeTab === tab.id ? "bg-[#F1F5F9]" : ""
                  }`}
                >
                  {tab.icon}
                  <span className="mt-1 text-black">{tab.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
