import { Store } from "lucide-react"

export default function EmptyState() {
  return (
    <div className="flex flex-col items-center justify-center py-16 text-gray-500">
      <Store className="h-12 w-12 mb-4 text-gray-400" />
      <p className="text-lg font-medium">Aucune donnée disponible</p>
      <p className="text-sm text-gray-500 mt-2">
        Aucune donnée n&apos;est disponible pour la période sélectionnée.
      </p>
    </div>
  )
}
