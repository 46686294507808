import { AlertTriangle } from "lucide-react"

export default function ErrorState() {
  return (
    <div className="flex flex-col items-center justify-center py-16 text-red-500">
      <AlertTriangle className="h-12 w-12 mb-4" />
      <p className="text-lg font-medium">
        Une erreur est survenue lors du chargement des données.
      </p>
      <p className="text-sm text-gray-500 mt-2">
        Veuillez réessayer ultérieurement.
      </p>
    </div>
  )
}
