// ComparisonParcsPage.tsx
import { useState, useEffect } from "react"
import Header from "@/components/header"
import { useAppContext } from "@/contexts/AppContext"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { IncreasedOrdersTable } from "@/components/tables/IncreaseOrderTable"
import { PerformantProductTable } from "@/components/tables/PerformantProductTable"
import { ProductToConciderTable } from "@/components/tables/ProductToConciderTable"
import { Button } from "@/components/ui/button"
import { MetricCard } from "@/components/metric-card"
import { TabNavigation } from "@/components/tab-nav"
import { useSalesPerformanceData } from "@/hooks/useSalesPerformance"
import { exportDataToCSV } from "@/hooks/csv-export"
import { Loader2, RefreshCw, RefreshCwOff, Download } from "lucide-react"
import {
  StoreSalesPerformance,
  StoreSalesProductToConsider,
} from "@/utils/__generated__/graphql"

export const ComparisonParcsPage = () => {
  const { headerHeight } = useAppContext()
  const { storeId } = useSelector((state: StateType) => state.storeReducer)
  const [activeTab, setActiveTab] = useState("all")

  const {
    bestItems,
    worstItems,
    productsToConciderItems,
    worstItemsTotalLoss,
    potentialGain,
    isLoading,
    refreshData,
  } = useSalesPerformanceData(storeId!)

  // Date formatting
  const today = new Date().toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  })

  const formatProductCount = (count: number) => {
    return count >= 10 ? "10+" : count.toString()
  }

  // Check if data is available
  const isDataAvailable =
    worstItems.length > 0 ||
    bestItems.length > 0 ||
    productsToConciderItems.length > 0

  // Reset to "all" tab if the current tab has no data
  useEffect(() => {
    if (
      (activeTab === "worst" && worstItems.length === 0) ||
      (activeTab === "best" && bestItems.length === 0) ||
      (activeTab === "productToConcider" &&
        productsToConciderItems.length === 0)
    ) {
      setActiveTab("all")
    }
  }, [
    activeTab,
    worstItems.length,
    bestItems.length,
    productsToConciderItems.length,
  ])

  const handleExport = () => {
    const storeName = "magasin"

    if (activeTab === "all" || activeTab === "worst") {
      if (worstItems.length > 0) {
        exportDataToCSV(
          worstItems,
          `${storeName}_manque_a_gagner_${today.replace(/\s/g, "_")}.csv`,
        )
      }
    }

    if (activeTab === "all" || activeTab === "best") {
      if (bestItems.length > 0) {
        exportDataToCSV(
          bestItems,
          `${storeName}_produits_performants_${today.replace(/\s/g, "_")}.csv`,
        )
      }
    }

    if (activeTab === "all" || activeTab === "productToConcider") {
      if (productsToConciderItems.length > 0) {
        exportDataToCSV(
          productsToConciderItems,
          `${storeName}_produits_a_considerer_${today.replace(/\s/g, "_")}.csv`,
        )
      }
    }
  }

  return (
    <div className="min-h-screen bg-transparent">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Page header */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            Analyse comparative des performances
          </h1>
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <div className="flex flex-col">
              <p className="text-gray-500">
                Analyse des commandes et ventes de votre magasins par rapport à
                des magasins similaires.
              </p>
              <p className="text-gray-500">
                Inspirez-vous en pour booster vos ventes.
              </p>
            </div>
            <div className="mt-2 sm:mt-0 bg-white px-3 py-1.5 rounded-lg shadow-sm text-sm text-gray-700 border border-gray-200">
              Données mises à jour le {today}
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingState />
        ) : !isDataAvailable ? (
          <EmptyState onRefresh={refreshData} />
        ) : (
          <DataContent
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            worstItems={worstItems as StoreSalesProductToConsider[]}
            bestItems={bestItems as StoreSalesProductToConsider[]}
            productsToConciderItems={
              productsToConciderItems as StoreSalesProductToConsider[]
            }
            worstItemsTotalLoss={worstItemsTotalLoss}
            potentialGain={potentialGain}
            handleExport={handleExport}
            isLoading={isLoading}
            formatProductCount={formatProductCount}
          />
        )}
      </div>
      <div style={{ height: headerHeight }} />
    </div>
  )
}

// Extracted components
const LoadingState = () => (
  <div className="flex items-center justify-center p-12">
    <Loader2 className="size-10 animate-spin text-blue-500" />
    <span className="ml-3 text-gray-600">Chargement des données...</span>
  </div>
)

const EmptyState = ({ onRefresh }: { onRefresh: () => void }) => (
  <div className="flex flex-col items-center justify-center p-16 bg-white rounded-lg shadow-sm border border-gray-100">
    <div className="w-20 h-20 rounded-full bg-gray-50 flex items-center justify-center mb-6">
      <RefreshCwOff />
    </div>
    <h3 className="text-xl font-medium text-gray-900 mb-2">
      Oups ! Pas d&apos;analyse disponible.
    </h3>
    <p className="text-gray-500 text-center max-w-md mb-6">
      Les analyses comparatives ne sont pas encore disponibles aujourd&apos;hui
      ce magasin. Revenez plus tard.
    </p>
    <Button onClick={onRefresh}>
      <RefreshCw className="size-4" />
      Actualiser
    </Button>
  </div>
)

interface DataContentProps {
  activeTab: string
  setActiveTab: (tab: string) => void
  worstItems: StoreSalesProductToConsider[]
  bestItems: StoreSalesProductToConsider[]
  productsToConciderItems: StoreSalesProductToConsider[]
  worstItemsTotalLoss: number
  potentialGain: number
  handleExport: () => void
  isLoading: boolean
  formatProductCount: (count: number) => string
}

const DataContent = ({
  activeTab,
  setActiveTab,
  worstItems,
  bestItems,
  productsToConciderItems,
  worstItemsTotalLoss,
  potentialGain,
  handleExport,
  isLoading,
  formatProductCount,
}: DataContentProps) => (
  <div className="flex flex-col gap-4">
    {/* Metric cards */}
    <div className="flex flex-row gap-4 mb-6">
      {worstItemsTotalLoss > 0 && worstItems.length > 0 && (
        <MetricCard
          title="Manque à gagner"
          value={worstItemsTotalLoss}
          description="Sur les produits sous-performants"
          type="loss"
        />
      )}

      {potentialGain > 0 && productsToConciderItems.length > 0 && (
        <MetricCard
          title="Potentiel à gagner"
          value={potentialGain}
          description={`Produits à considérer`}
          type="gain"
        />
      )}
    </div>

    {/* Tab navigation with export button */}
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4 border-b border-gray-200">
      <div className="flex items-center justify-between w-full sm:w-auto">
        <TabNavigation
          activeTab={activeTab}
          onChange={setActiveTab}
          tabs={[
            { id: "all", label: "Tous les produits" },
            {
              id: "worst",
              label: `Articles en sous-performance (${worstItems.length})`,
              hidden: worstItems.length === 0,
            },
            {
              id: "productToConcider",
              label: `Produits à considérer (${formatProductCount(
                productsToConciderItems.length,
              )})`,
              hidden: productsToConciderItems.length === 0,
            },
            {
              id: "best",
              label: `Performants (${bestItems.length})`,
              hidden: bestItems.length === 0,
            },
          ]}
        />
        <Button
          onClick={handleExport}
          variant="outline"
          className="sm:hidden p-2 ml-2 text-gray-700 border-gray-300 hover:bg-gray-50"
          aria-label="Exporter en CSV"
        >
          <Download className="h-4 w-4" />
        </Button>
      </div>
      <Button
        onClick={handleExport}
        variant="outline"
        className="hidden sm:flex mt-2 sm:mt-0 text-gray-700 border-gray-300 hover:bg-gray-50"
      >
        <Download className="mr-2 h-4 w-4" />
        Exporter en CSV
      </Button>
    </div>

    {/* Content based on active tab */}
    <div className={`flex flex-col ${activeTab === "all" ? "gap-4" : ""}`}>
      <div className={activeTab === "all" ? "flex flex-col gap-4" : ""}>
        {(activeTab === "all" || activeTab === "worst") &&
          worstItems.length > 0 && (
            <IncreasedOrdersTable
              worstProducts={worstItems as StoreSalesPerformance[]}
              loading={isLoading}
              withColorfulHeader
            />
          )}
        {(activeTab === "all" || activeTab === "best") &&
          bestItems.length > 0 && (
            <PerformantProductTable
              bestProducts={bestItems as StoreSalesPerformance[]}
              loading={isLoading}
              withColorfulHeader
            />
          )}
      </div>
      {(activeTab === "all" || activeTab === "productToConcider") &&
        productsToConciderItems.length > 0 && (
          <ProductToConciderTable
            products={productsToConciderItems}
            loading={isLoading}
            withColorfulHeader={true}
          />
        )}
    </div>
  </div>
)
