import { useSearchParams } from "react-router-dom"
import { formatValue } from "./utils"
import { GroupedReferences } from "."
import { usePotentialRevenue } from "@/hooks/usePotentialRevenue"
import { StoreReducerState } from "@/reducers/storeReducer"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { getDisplayedUnit } from "@/utils/getUnit"
import { GetOrderItem } from "@/utils/__generated__/graphql"

interface GroupContentProps {
  storeStoreSuppliers: StoreReducerState["storeStoreSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  supplierData: GroupedReferences
  toCalculateProducts: (AllMercurialInfo | GetOrderItem)[]
  isOrderConfirmed: boolean
  companyName: string | null
  franchise: string | null
}

export const HeaderGroup = ({
  supplierData,
  storeCurrency,
  storeStoreSuppliers,
  toCalculateProducts,
  isOrderConfirmed,
  companyName,
  franchise,
}: GroupContentProps) => {
  const [searchParams] = useSearchParams()

  const { totalOrders, potentialCommandRevenue, orderPrice } =
    usePotentialRevenue({ references: toCalculateProducts })

  const orderId = searchParams.get("orderId")
  const currentWeekday = Intl.DateTimeFormat("fr-FR", {
    weekday: "long",
  }).format(new Date())

  const minimalOrderValue =
    storeStoreSuppliers?.find(
      (storeSupplier) =>
        storeSupplier.supplier_id === supplierData.supplierId &&
        storeSupplier.order_day === currentWeekday,
    )?.minimal_order_value ?? undefined

  const isOrderValueHighlighted =
    minimalOrderValue !== undefined &&
    Math.floor(orderPrice ?? 0) < minimalOrderValue &&
    (typeof orderId !== "string" || orderId === "")

  return (
    <div className="py-2 bg-gray-200 w-full grid  md:text-lg lg:text-xl hover:bg-gray-200 md:grid-cols-12 lg:grid-cols-9">
      <div
        className={`text-l font-bold whitespace-nowrap ml-6 col-span-4 md:col-span-7${
          orderId === null && !isOrderConfirmed
            ? " lg:col-span-5"
            : " lg:col-span-6"
        }`}
      >
        {supplierData.supplierName.toUpperCase()}
        {minimalOrderValue !== undefined &&
          minimalOrderValue > 0 &&
          (typeof orderId !== "string" || orderId === "") && (
            <span>
              {" | "}
              {formatValue(minimalOrderValue, storeCurrency)}
            </span>
          )}
      </div>
      <div className="hidden md:block font-semibold md:col-span-2 lg:col-span-1 text-gray-900 text-center">
        {totalOrders === undefined || isNaN(totalOrders)
          ? "-"
          : `${Math.floor(totalOrders)} ${getDisplayedUnit("cs", companyName, franchise)}`}
      </div>
      <div
        className={`hidden md:block text-lg font-semibold ${isOrderValueHighlighted ? "text-red-500" : "text-gray-900"} text-center`}
      >
        {orderPrice === undefined || isNaN(orderPrice)
          ? "-"
          : formatValue(Math.floor(orderPrice ?? 0), storeCurrency)}
      </div>
      <div className="hidden md:block font-semibold text-gray-900 text-center">
        {potentialCommandRevenue === undefined || isNaN(potentialCommandRevenue)
          ? "-"
          : formatValue(
              potentialCommandRevenue ? Math.floor(potentialCommandRevenue) : 0,
              storeCurrency,
            )}
      </div>
    </div>
  )
}
