import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { DateTime } from "luxon"
import { useGetCalendarQuery } from "../utils/__generated__/graphql"
import { StateType } from "../types"
import CalendarMobile from "../components/calendar/CalendarMobile"
import { Calendar } from "../components/calendar/Calendar" // new design version
import { getFullCalendarMonth } from "../utils/date"
import Header from "@/components/header"
import { OrderButtonBottomFooter } from "@/components/OrderButtonBottomFooter"
import { useWindowSize } from "@/hooks/useWindowSize"

const CalendarPage = () => {
  const [currentDate, setCurrentDate] = useState(DateTime.now())
  const { storeId, companyName, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const [lastDateFetched, setLastDateFetched] = useState(DateTime.now())
  const allDays = getFullCalendarMonth(currentDate)
  const { isLG } = useWindowSize()

  useEffect(() => {
    if (currentDate.hasSame(lastDateFetched, "day")) return
    setLastDateFetched(currentDate)
  }, [currentDate, lastDateFetched])

  const { data } = useGetCalendarQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
        start_date: allDays[0].toFormat("yyyy-MM-dd"),
        end_date: allDays[allDays.length - 1].toFormat("yyyy-MM-dd"),
      },
    },
    fetchPolicy: "network-only",
  })

  return (
    <>
      <Header />
      <div className="pb-8 px-2 grow">
        {isLG ? (
          <Calendar
            data={data}
            storeCurrency={storeCurrency}
            currentDate={currentDate}
            allDays={allDays}
            setCurrentDate={setCurrentDate}
          />
        ) : (
          <CalendarMobile
            companyName={companyName}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            data={data}
            allDays={allDays}
            storeCurrency={storeCurrency}
          />
        )}
        <OrderButtonBottomFooter />
      </div>
    </>
  )
}

export default CalendarPage
