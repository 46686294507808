import { StateType } from "@/types"
import Intercom, { update } from "@intercom/messenger-js-sdk"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { matchPath, useLocation } from "react-router-dom"

export function useIntercom() {
  const location = useLocation()
  const { email, userHash } = useSelector(
    (state: StateType) => state.userReducer,
  )

  useEffect(() => {
    Intercom({
      app_id: "t5cfckj9",
      email: email,
      user_hash: userHash,
    })
  }, [email, userHash])

  useEffect(() => {
    const isOrderPages = matchPath("/order/*", location.pathname) !== null
    if (isOrderPages) {
      update({
        hide_default_launcher: true,
      })
    } else {
      update({
        hide_default_launcher: false,
      })
    }
  }, [location.pathname])
}
