import { Spinner } from "@/ui/Spinner"

export default function LoadingState() {
  return (
    <div className="flex flex-col items-center justify-center py-16">
      <Spinner />
      <span className="mt-4 text-gray-600">
        Chargement des données en cours...
      </span>
    </div>
  )
}
