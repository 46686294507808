import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { type StateType } from "../types"
import { getDiffBetweenDates } from "../utils/getDiffBetweenDates"
import { filteredMercurialeReducerSelector } from "../selectors/mercurialeSelectors"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { Button } from "./ui/button"
import { ChevronDown } from "lucide-react"
import { twJoin } from "tailwind-merge"

const getWeatherIcon = (weather?: string, wide?: boolean): JSX.Element => {
  if (weather === undefined) return <></>
  const lowerCaseWeather = weather.toLowerCase()

  if (lowerCaseWeather.includes("light rain"))
    return (
      <img
        src="/weather/rain.png"
        alt="light rain"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("snow"))
    return (
      <img
        src="/weather/snow.png"
        alt="snow"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("extreme"))
    return (
      <img
        src="/weather/wind.png"
        alt="extreme"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("clear"))
    return (
      <img
        src="/weather/clear.png"
        alt="clear"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("rain"))
    return (
      <img
        src="/weather/rain.png"
        alt="rain"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("mist"))
    return (
      <img
        src="/weather/clouds.png"
        alt="mist"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("thunderstorm"))
    return (
      <img
        src="/weather/thunderstorm.png"
        alt="thunderstorm"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("drizzle"))
    return (
      <img
        src="/weather/rain.png"
        alt="drizzle"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("fog"))
    return (
      <img
        src="/weather/clouds.png"
        alt="fog"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("clouds"))
    return (
      <img
        src="/weather/clouds.png"
        alt="clouds"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("overcast"))
    return (
      <img
        src="/weather/clouds.png"
        alt="overcast"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )
  if (lowerCaseWeather.includes("partially cloudy"))
    return (
      <img
        src="/weather/partial-clouds.png"
        alt="partially cloudy"
        className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
      />
    )

  return (
    <img
      src="/weather/clear.png"
      alt="light rain"
      className={twJoin("h-[106px]", wide ? "w-full" : "w-[148px]")}
    />
  )
}

interface WeatherIconProps {
  weatherData: { conditions: string; temperature: number }[]
  selectedDay: number
  isLoading: boolean
}

function WeatherIcon({
  weatherData,
  selectedDay,
  isLoading,
}: WeatherIconProps) {
  return (
    <>
      <div className="absolute -top-12 right-0 w-full h-full flex justify-end z-0 blur-3xl">
        {!isLoading &&
          getWeatherIcon(weatherData[selectedDay]?.conditions, true)}
      </div>
      <div className="absolute -top-12 right-10 w-full h-full flex justify-end z-1">
        {!isLoading && getWeatherIcon(weatherData[selectedDay]?.conditions)}
      </div>
    </>
  )
}

const getFrenchWeather = (weather?: string): string => {
  if (weather === undefined) return ""
  const lowerCaseWeather = weather.toLowerCase()

  if (lowerCaseWeather.includes("light rain")) return "Faible pluie"
  if (lowerCaseWeather.includes("snow")) return "Neige"
  if (lowerCaseWeather.includes("extreme")) return "Tempête"
  if (lowerCaseWeather.includes("clear")) return "Dégagé"
  if (lowerCaseWeather.includes("rain")) return "Pluie"
  if (lowerCaseWeather.includes("mist")) return "Brumeux"
  if (lowerCaseWeather.includes("thunderstorm")) return "Orage"
  if (lowerCaseWeather.includes("drizzle")) return "Bruine"
  if (lowerCaseWeather.includes("fog")) return "Nuageux"
  if (lowerCaseWeather.includes("clouds")) return "Nuageux"
  if (lowerCaseWeather.includes("overcast")) return "Couvert"
  if (lowerCaseWeather.includes("partially cloudy")) return "Nuageux"

  return weather
}

export function Weather(): JSX.Element {
  const { storeCity, storeCountry } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const { mercurialAndStoreInventories } = useSelector(
    filteredMercurialeReducerSelector,
  )
  const mercurialeId = useSelector(
    (state: StateType) =>
      state.userInterfaceReducer.selectedInventory?.mercurialeId,
  )
  const [selectedDay, setSelectedDay] = useState(0)
  const [weatherData, setWeatherData] = useState<
    { conditions: string; temperature: number }[]
  >([])
  const [isLoading, setIsLoading] = useState(true)

  const currentDate = useMemo(() => new Date(), [])
  const dates = useMemo(() => {
    return new Array(7).fill(null).map((_, i) => {
      const date = new Date()
      date.setDate(currentDate.getDate() + i)
      return date
    })
  }, [currentDate])

  const getWeather = useCallback(async () => {
    setIsLoading(true)
    const apiKey = "43ZQCYJPLMGUC5V7LE436N83Z"

    // Call the weather API using the coordinates of Paris
    const weatherUrl = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${storeCity ?? "Paris"},${storeCountry ?? "France"}?unitGroup=metric&key=${apiKey}`

    try {
      const weatherResponse = await fetch(weatherUrl)

      const weatherData = await weatherResponse.json()
      // Log the current temperature in Paris

      if (weatherData.days === undefined || weatherData.days.length === 0) {
        throw new Error()
      }
      setWeatherData(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        weatherData.days.map((day: any) => ({
          conditions: day.conditions,
          temperature: day.tempmax.toFixed(0),
        })),
      )
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [storeCity, storeCountry])

  useEffect(() => {
    if (!online) return
    void getWeather()
  }, [currentDate, getWeather, online])

  useEffect(() => {
    const reference = mercurialAndStoreInventories?.find(
      (mercurialeInfos) => mercurialeInfos.mercuriale_id === mercurialeId,
    )
    const referenceReceptionDate =
      reference?.order_expected_reception_date ??
      reference?.mercuriale_reception_date
    if (reference !== undefined && typeof referenceReceptionDate === "string") {
      const receptionDate = new Date(referenceReceptionDate)
      const dayIndex = getDiffBetweenDates(currentDate, receptionDate)
      const selectedDayInded = dayIndex >= 0 && dayIndex <= 6 ? dayIndex : 0
      setSelectedDay(selectedDayInded)
      return
    }
    setSelectedDay(0)
  }, [
    currentDate,
    dates,
    getWeather,
    mercurialAndStoreInventories,
    mercurialeId,
  ])

  const selectedDayName = useMemo(() => {
    if (selectedDay === 0) return "Auj."
    return new Intl.DateTimeFormat("fr-FR", { weekday: "short" }).format(
      dates[selectedDay],
    )
  }, [selectedDay, dates])

  return (
    <div className="relative overflow-hidden">
      <WeatherIcon
        weatherData={weatherData}
        selectedDay={selectedDay}
        isLoading={isLoading}
      />
      <div className="flex justify-between items-center gap-2 py-2 px-3">
        <div className="flex gap-2 z-50">
          <p className="text-sm font-medium">
            {!isLoading ? (
              `${weatherData[selectedDay]?.temperature}°`
            ) : (
              <span className="w-8 h-6 bg-gray-300 animate-pulse rounded-full" />
            )}
          </p>
          <p className="text-sm font-thin">
            {!isLoading ? (
              getFrenchWeather(weatherData[selectedDay]?.conditions)
            ) : (
              <span className="w-28 h-4 bg-gray-300 animate-pulse rounded-full" />
            )}
          </p>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="capitalize h-2 p-4 z-10">
              {selectedDayName} <ChevronDown className="text-gray-400" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-14">
            {dates.map((date, i) => (
              <DropdownMenuItem
                key={i}
                onClick={() => setSelectedDay(i)}
                className={twJoin(
                  "capitalize",
                  selectedDay === i &&
                    "bg-zinc-500 text-white focus:bg-zinc-600 focus:text-white",
                )}
              >
                {i === 0
                  ? "Aujourd'hui"
                  : new Intl.DateTimeFormat("fr-FR", {
                      weekday: "long",
                    }).format(date)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
