import { HomeCard } from "../components/homeCard"
import { useGetLastWeeksPerformancesQuery } from "@/utils/__generated__/graphql"
import { useSelector } from "react-redux"
import { StateType } from "@/types"
import { LastPerformance } from "../components/lastPerformanceHome"
import { Trash, TrendingUp } from "lucide-react"
import { Skeleton } from "@/components/ui/skeleton"

export const LastWeekPerformancesHome = () => {
  const { storeId, storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { data, loading } = useGetLastWeeksPerformancesQuery({
    variables: {
      input: {
        store_id: storeId!,
      },
    },
  })
  const isValidNumber = (value: number) => !isNaN(value) && value !== 0

  const displayBreakages =
    isValidNumber(data?.lastBreakages.one_week_ago ?? 0) &&
    isValidNumber(data?.lastBreakages.two_weeks_ago ?? 0)

  const displayRevenue =
    isValidNumber(data?.lastRevenue.one_week_ago ?? 0) &&
    isValidNumber(data?.lastRevenue.two_weeks_ago ?? 0)

  const displayBreakagesAndRevenue = displayBreakages || displayRevenue

  return (
    <HomeCard
      title="Dernières performances"
      subtitle="Retrouvez vos performances sur les deux dernières semaines"
    >
      {loading ? (
        <div className="flex flex-col gap-6 p-4">
          <Skeleton className="w-full h-20" />
          <Skeleton className="w-full h-20" />
        </div>
      ) : (
        <div className="flex flex-col gap-6 pt-4">
          {displayBreakagesAndRevenue ? (
            <>
              {displayBreakages && (
                <LastPerformance
                  title="Démarque"
                  description="La démarque"
                  oneWeekAgo={data?.lastBreakages.one_week_ago ?? 0}
                  twoWeeksAgo={data?.lastBreakages.two_weeks_ago ?? 0}
                  oneYearAgo={data?.lastBreakages.one_year_ago ?? 0}
                  currency={storeCurrency ?? "EUR"}
                  icon={<Trash className="size-4" />}
                />
              )}
              {displayRevenue && (
                <LastPerformance
                  title="Chiffre d'affaires"
                  description="Le chiffre d'affaires"
                  oneWeekAgo={data?.lastRevenue.one_week_ago ?? 0}
                  twoWeeksAgo={data?.lastRevenue.two_weeks_ago ?? 0}
                  oneYearAgo={data?.lastRevenue.one_year_ago ?? 0}
                  currency={storeCurrency ?? "EUR"}
                  icon={<TrendingUp className="size-4" />}
                  invertColors
                />
              )}
            </>
          ) : (
            <div className="h-full text-center">
              Il n&apos;y a pas d&apos;information sur vos récentes performances
            </div>
          )}
        </div>
      )}
    </HomeCard>
  )
}
