import { useState } from "react"
import { useGetUsersPageInformationsQuery } from "@/utils/__generated__/graphql"
import { SearchIcon } from "@/ui/icons/SearchIcon"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  ArrowLeft,
  ArrowRight,
  ExternalLink,
  UserCog,
  UserRoundPlus,
} from "lucide-react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useDebounce } from "@/hooks/useDebounce"
import { CreateUserModal } from "./components/AddUserModal"
import { UpdateUserModal } from "./components/UpdateUserModal"
import { pluralize } from "@/utils/pluralize"
import { TableBodyLoading } from "./components/TableBodyLoading"

export function UsersPage(): JSX.Element {
  const [searchTerm, setSearchTerm] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<string>()
  const [page, setPage] = useState(0)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const { data, loading, refetch } = useGetUsersPageInformationsQuery({
    variables: { input: { query: debouncedSearchTerm, page } },
  })

  const handleCreateUser = () => {
    setIsModalOpen(false)
    refetch()
  }

  const user = data?.users.users.find((user) => user.id === selectedUser)

  const lastPage = Math.ceil((data?.users.total ?? 0) / 20) - 1

  return (
    <div className="p-2">
      <UpdateUserModal
        user={user}
        setIsOpen={(open) => !open && setSelectedUser(undefined)}
        roles={data?.roles}
        scopes={data?.scopes}
      />
      <CreateUserModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleCreateUser={handleCreateUser}
      />
      <div className="flex gap-2 mb-4">
        <div className="relative w-full">
          <Input
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value)
              setPage(0)
            }}
            type="text"
            name="search"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <SearchIcon className="h-4 w-4 text-gray-400" />
          </div>
        </div>
        <Button onClick={() => setIsModalOpen(true)}>
          <UserRoundPlus className="size-4" />
          Créer un utilisateur
        </Button>
        <Button variant="link" asChild>
          <a
            href="https://accounts.auth0.com/teams/team-np52dt0/"
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLink className="size-4" />
            Dashboard Auth0
          </a>
        </Button>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Avatar</TableHead>
            <TableHead className="w-[100px]">Email</TableHead>
            <TableHead>ID Magasin</TableHead>
            <TableHead>Compagnie</TableHead>
            <TableHead>Magasin</TableHead>
            <TableHead>Dernière connexion</TableHead>
            <TableHead>Nombre de connexions</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        {loading ? (
          <TableBodyLoading />
        ) : (
          <TableBody>
            {data?.users.users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <img src={user.picture} className="size-8 rounded-full" />
                </TableCell>
                <TableCell className="font-medium">{user.email}</TableCell>
                <TableCell>{user.store_id}</TableCell>
                <TableCell>
                  {user.franchise_name ?? user.company_name}
                </TableCell>
                <TableCell>{user.store_name}</TableCell>
                <TableCell>
                  {typeof user.last_login === "string"
                    ? Intl.DateTimeFormat("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      }).format(new Date(user.last_login))
                    : "N/A"}
                </TableCell>
                <TableCell>{user.logins_count ?? 0}</TableCell>
                <TableCell>
                  <Button
                    variant="outline"
                    className="size-10"
                    onClick={() => setSelectedUser(user.id)}
                  >
                    <UserCog className="size-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <div className="flex justify-between items-center">
        <div className="flex gap-2 text-gray-600 text-sm">
          <p>
            <span className="font-medium">{data?.users.total ?? 0}</span>{" "}
            {pluralize("utilisateur", data?.users.total ?? 0)}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            className="size-10"
            onClick={() => setPage((prev) => prev - 1)}
            disabled={page === 0}
          >
            <ArrowLeft className="size-4" />
          </Button>
          <p className="w-8 text-center font-medium">{page + 1}</p>
          <Button
            variant="outline"
            className="size-10"
            onClick={() => setPage((prev) => prev + 1)}
            disabled={page === lastPage || lastPage === -1}
          >
            <ArrowRight className="size-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
