import {
  InventoryType,
  useUpdateDimStoreArticleMutation,
} from "../../utils/__generated__/graphql"
import { Paysan } from "../../ui/icons/Paysan"
import {
  AllMercurialInfo,
  UpdatedReference,
} from "../../reducers/mercurialReducer"
import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "../../types"
import { toast } from "sonner"
import { captureException } from "@sentry/react"
import { twJoin } from "tailwind-merge"
import { Button } from "../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { Switch } from "../ui/switch"
import { useResetPrediction } from "../order/ResetPrediction/useResetPrediction"
import { useSaveModification } from "@/hooks/useSaveModification"
import { getWeekdaysStartingMonday } from "@/utils/getWeekdaysStartingMonday"
import { useMemo } from "react"
import { getOrderQuantity } from "@/utils/getOrderQuantity"
import { omit } from "lodash"

interface DropdownElementProps {
  value: number
  isActive: boolean
  onClick: (value: number) => void
  disabled?: boolean
}

function DropdownElement({
  value,
  isActive,
  onClick,
  disabled = false,
}: DropdownElementProps) {
  return (
    <DropdownMenuItem
      disabled={disabled}
      className={twJoin(
        "flex flex-col items-start gap-0 font-medium",
        isActive && "bg-green-20",
      )}
      onClick={() => onClick(value)}
    >
      A → {String.fromCharCode(65 + value)}
      <span className="font-normal text-xs text-gray-600">
        La livraison prend {value} jour{value > 1 && "s"}
      </span>
    </DropdownMenuItem>
  )
}

interface LocalFlagButtonProps {
  reference: AllMercurialInfo
  storeId: string | null
}

export const LocalFlagButton = ({
  reference,
  storeId,
}: LocalFlagButtonProps) => {
  const dispatch = useDispatch<DispatchActionType>()
  const [updateLocalFlag, { loading: isUpdateLoading }] =
    useUpdateDimStoreArticleMutation()
  const { reset } = useResetPrediction({ reference })
  const { saveModification, isLoading: isSaveLoading } = useSaveModification()
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const localFlag = reference.local_flag ?? false
  const isLoading = isSaveLoading || isUpdateLoading

  const handleSwitch = async () => {
    if (typeof reference.mercuriale_id !== "string") return

    try {
      if (isTestMode === true) {
        dispatch({
          type: "updateReference",
          payload: {
            mercurialeId: reference.mercuriale_id,
            local_flag: !localFlag,
            local_delivery_days: undefined,
          },
        })
        return
      }

      const result = await updateLocalFlag({
        variables: {
          input: {
            order_id: reference.order_id!,
            store_id: storeId ?? "",
            article_name: reference.mercuriale_name ?? "",
            local_flag: !localFlag,
            local_delivery_days: undefined,
          },
        },
      })

      if (!result.data?.updateDimStoreArticle.success) {
        throw new Error(result.data?.updateDimStoreArticle.error?.message)
      }

      dispatch({
        type: "updateReference",
        payload: {
          mercurialeId: reference.mercuriale_id,
          local_flag: !localFlag,
          local_delivery_days: undefined,
        },
      })

      if (!localFlag === false) {
        await reset()
      }
    } catch (e) {
      console.error(e)
      toast.error("Erreur lors de la mise à jour de la référence.")
      captureException(e)
    }
  }

  async function handleLocalDeliveryDaysChange(days: number) {
    if (typeof reference.mercuriale_id !== "string") return

    const oldLocalDeliveryDays = reference.local_delivery_days
    if (oldLocalDeliveryDays === days) return

    const receptionDate = new Date()
    receptionDate.setHours(0, 0, 0, 0)
    receptionDate.setDate(receptionDate.getDate() + days)
    const receptionDay = receptionDate.getDay()

    const predictedSales = [
      reference.prediction_day_zero ?? 0,
      reference.prediction_day_one ?? 0,
      reference.prediction_day_two ?? 0,
      reference.prediction_day_three ?? 0,
      reference.prediction_day_four ?? 0,
      reference.prediction_day_five ?? 0,
      reference.prediction_day_six ?? 0,
    ]
    const totalSales = [...predictedSales]
      // Add 2 if the reception day is Saturday, 1 if Sunday to cover the weekend
      .splice(0, days + (receptionDay === 6 ? 2 : receptionDay === 0 ? 1 : 0))
      .reduce<number>((acc, val) => acc + val / (reference.colisage ?? 1), 0)
    const totalStock =
      (reference.back_inventory_qty ?? 0) +
      (reference.floor_inventory_qty ?? 0) +
      (reference.waited_quantity_ordered ?? 0)
    const stockAfterSales = Math.max(totalStock - totalSales, 0)
    const predictionSales = [...predictedSales]
      // Add 2 if the reception day is Saturday to cover the weekend
      .splice(days, 1 + (receptionDay === 6 ? 2 : 0))
      .reduce<number>((acc, val) => acc + val / (reference.colisage ?? 1), 0)
    const orderQuantity = Math.max(
      Math.round(predictionSales - stockAfterSales),
      0,
    )

    try {
      if (isTestMode === true) {
        dispatch({
          type: "updateReference",
          payload: {
            mercurialeId: reference.mercuriale_id,
            local_delivery_days: days,
            orderInventoryQuantity: orderQuantity,
          },
        })
        return
      }

      const result = await updateLocalFlag({
        variables: {
          input: {
            order_id: reference.order_id!,
            store_id: storeId ?? "",
            local_delivery_days: days,
          },
        },
      })

      if (!result.data?.updateDimStoreArticle.success) {
        throw new Error(result.data?.updateDimStoreArticle.error?.message)
      }

      await saveModification(
        InventoryType.Order,
        reference.mercuriale_id,
        orderQuantity,
      )

      dispatch({
        type: "updateReference",
        payload: {
          mercurialeId: reference.mercuriale_id,
          local_delivery_days: days,
          orderInventoryQuantity: orderQuantity,
        },
      })
    } catch (e) {
      console.error(e)
      toast.error("Erreur lors de la mise à jour de la référence.")
      captureException(e)
    }
  }

  const weekdays = useMemo(
    () => [
      {
        day: 1,
        value: reference.local_delivery_on_monday,
      },
      {
        day: 2,
        value: reference.local_delivery_on_tuesday,
      },
      {
        day: 3,
        value: reference.local_delivery_on_wednesday,
      },
      {
        day: 4,
        value: reference.local_delivery_on_thursday,
      },
      {
        day: 5,
        value: reference.local_delivery_on_friday,
      },
      {
        day: 6,
        value: reference.local_delivery_on_saturday,
      },
      {
        day: 0,
        value: reference.local_delivery_on_sunday,
      },
    ],
    [
      reference.local_delivery_on_friday,
      reference.local_delivery_on_monday,
      reference.local_delivery_on_saturday,
      reference.local_delivery_on_sunday,
      reference.local_delivery_on_thursday,
      reference.local_delivery_on_tuesday,
      reference.local_delivery_on_wednesday,
    ],
  )

  async function handleWeekdayButtonClick(
    day: (typeof weekdays)[number]["day"],
    value: boolean,
  ) {
    const newUpdatedReference: Partial<UpdatedReference> = {}
    switch (day) {
      case 1:
        newUpdatedReference.local_delivery_on_monday = value
        break
      case 2:
        newUpdatedReference.local_delivery_on_tuesday = value
        break
      case 3:
        newUpdatedReference.local_delivery_on_wednesday = value
        break
      case 4:
        newUpdatedReference.local_delivery_on_thursday = value
        break
      case 5:
        newUpdatedReference.local_delivery_on_friday = value
        break
      case 6:
        newUpdatedReference.local_delivery_on_saturday = value
        break
      case 0:
        newUpdatedReference.local_delivery_on_sunday = value
        break
    }

    const today = new Date().getDay()

    if (today === Number(day)) {
      if (value === false) {
        newUpdatedReference.orderInventoryQuantity = 0
      } else {
        const orderQuantity = getOrderQuantity({
          backQuantity: reference.back_inventory_qty ?? 0,
          floorQuantity: reference.floor_inventory_qty ?? 0,
          predictedQuantityArray: reference.quantity_predicted_array ?? [],
        })
        newUpdatedReference.orderInventoryQuantity = orderQuantity
      }
    }

    if (isTestMode === true) {
      dispatch({
        type: "updateReference",
        payload: {
          mercurialeId: reference.mercuriale_id!,
          ...newUpdatedReference,
        },
      })
      return
    }

    const result = await updateLocalFlag({
      variables: {
        input: {
          order_id: reference.order_id!,
          store_id: storeId ?? "",
          article_name: reference.mercuriale_name ?? "",
          ...omit(newUpdatedReference, ["orderInventoryQuantity"]),
        },
      },
    })

    if (!result.data?.updateDimStoreArticle.success) {
      throw new Error(result.data?.updateDimStoreArticle.error?.message)
    }

    dispatch({
      type: "updateReference",
      payload: {
        mercurialeId: reference.mercuriale_id!,
        ...newUpdatedReference,
      },
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant={localFlag ? "default" : "outline"}
          disabled={isLoading}
          className={twJoin(
            "p-0 size-9 text-xs",
            localFlag && "bg-green-800 hover:bg-green-900",
          )}
        >
          <Paysan className="size-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel className="flex justify-between items-center gap-2">
          Référence locale
          <Switch
            checked={localFlag}
            onCheckedChange={handleSwitch}
            className="data-[state=checked]:bg-green-800"
          />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <div className="flex gap-1 md:my-2">
          {getWeekdaysStartingMonday("fr-FR").map((formattedWeekday, i) => {
            const { day, value } = weekdays[i]

            return (
              <Button
                key={day}
                variant="outline"
                disabled={!localFlag || isLoading}
                className={twJoin(
                  "px-1 h-8 md:px-4 md:h-10",
                  value &&
                    "border-green-800 bg-green-800 hover:bg-green-900 text-white hover:text-white",
                )}
                onClick={() => handleWeekdayButtonClick(day, !value)}
              >
                {formattedWeekday}
              </Button>
            )
          })}
        </div>
        {new Array(5).fill(null).map((_, i) => {
          const index = i + 1
          return (
            <DropdownElement
              key={index}
              value={index}
              isActive={reference.local_delivery_days === index}
              onClick={handleLocalDeliveryDaysChange}
              disabled={!localFlag || isLoading}
            />
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
