import { Effect, put, select, takeLatest } from "redux-saga/effects"
import { ActionType, StateType } from "../types"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import { StoreReducerState } from "../reducers/storeReducer"
import { getOrderQuantity } from "../utils/getOrderQuantity"
import { BatchUpdateAnalyticsOrderPredictionMutationFn } from "../utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "../utils/formatDateToYYYYMMDD"
import { DataSynchronizationStatus } from "../reducers/connectionReducer"
import { getLocalDeliveryOnWeekdayValue } from "@/utils/getLocalDeliveryOnWeekdayValue"

export interface MercurialeSageAction {
  type: "dispatchMercurialeInfosAndAnalyticsEvent"
  payload: {
    mercurialAndStoreInventories: MercurialReducerState["mercurialAndStoreInventories"]
    latestOrderInventoryUpdate?: MercurialReducerState["latestOrderInventoryUpdate"]
    latestSaleHistoricalUpdate?: MercurialReducerState["latestSaleHistoricalUpdate"]
    latestMercurialeUpdate?: MercurialReducerState["latestMercurialeUpdate"]
    latestOrderRequestUpdate?: MercurialReducerState["latestOrderRequestUpdate"]
    storeSuppliers: StoreReducerState["storeStoreSuppliers"]
    storeSettings: StoreReducerState["storeSettings"]
    batchUpdateAnalyticsOrderPrediction: BatchUpdateAnalyticsOrderPredictionMutationFn
    orderDate: MercurialReducerState["orderDate"]
  }
}

const getState = (state: StateType): StateType => state

function* handleMercurialeSaga(
  action: MercurialeSageAction,
): Generator<Effect, void, StateType> {
  const state = yield select(getState)

  yield put<ActionType>({
    type: "setDataSynchronizationStatus",
    payload: DataSynchronizationStatus.SYNCHRONIZED,
  })

  const dimMercuriales = action.payload.mercurialAndStoreInventories.reduce<
    MercurialReducerState["dimMercuriales"]
  >((mercuriales, mercurialAndStoreInventory) => {
    if (
      mercuriales?.some(
        (mercuriale) =>
          mercuriale.dimMercurialeId ===
          mercurialAndStoreInventory.dim_mercuriale_id,
      )
    )
      return mercuriales
    return [
      ...(mercuriales ?? []),
      {
        dimMercurialeId: mercurialAndStoreInventory.dim_mercuriale_id ?? "",
        dimOrderRequestId:
          mercurialAndStoreInventory.dim_order_request_id ?? undefined,
      },
    ]
  }, [])

  const newState: MercurialReducerState = {
    ...state.mercurialReducer,
    mercurialAndStoreInventories: action.payload.mercurialAndStoreInventories,
    // Wrong back inventory quantity with no sales histocal are set to 0
    updatedReferences: action.payload.mercurialAndStoreInventories.reduce<
      MercurialReducerState["updatedReferences"]
    >((updatedReferences, mercuriale) => {
      if (typeof mercuriale.mercuriale_id !== "string") return updatedReferences
      if (
        (mercuriale.never_ordered_product_flag === true ||
          (mercuriale.sale_historical_quantities ?? []).length === 0) &&
        (mercuriale.back_inventory_qty ?? 0) < 0
      ) {
        updatedReferences[mercuriale.mercuriale_id] = {
          mercurialeId: mercuriale.mercuriale_id,
          backInventoryQuantity: 0,
        }
      }
      const updatedReference = updatedReferences[mercuriale.mercuriale_id] ?? {}
      if (typeof mercuriale.quantity_actual === "number") {
        updatedReferences[mercuriale.mercuriale_id] = {
          ...updatedReference,
          mercurialeId: mercuriale.mercuriale_id,
          orderInventoryQuantity: mercuriale.quantity_actual,
          isOrderInventoryQuantityUpdated: true,
          prediction_uncertain: false,
        }
        return updatedReferences
      }
      const isLocal = mercuriale.local_flag === true
      const isALocalDeliveryDayToday =
        getLocalDeliveryOnWeekdayValue(mercuriale)

      const applyPrediction =
        Array.isArray(mercuriale.quantity_predicted_array) &&
        ((isLocal && isALocalDeliveryDayToday) || !isLocal) &&
        mercuriale.prediction_uncertain !== true

      if (applyPrediction) {
        const orderQuantity = getOrderQuantity({
          backQuantity:
            updatedReference.backInventoryQuantity ??
            mercuriale.back_inventory_qty ??
            0,
          floorQuantity:
            updatedReference.floorInventoryQuantity ??
            mercuriale.floor_inventory_qty ??
            0,
          predictedQuantityArray: mercuriale.quantity_predicted_array ?? [],
        })
        updatedReferences[mercuriale.mercuriale_id] = {
          ...updatedReference,
          mercurialeId: mercuriale.mercuriale_id,
          orderInventoryQuantity: orderQuantity,
        }
      }
      return updatedReferences
    }, {}),
    dimMercuriales,
    selectedDimMercurialeId: dimMercuriales?.[0]?.dimMercurialeId,
    orderDate: action.payload.orderDate,
    modifications: [],
  }

  if (typeof action.payload.latestMercurialeUpdate === "string") {
    newState.latestMercurialeUpdate = action.payload.latestMercurialeUpdate
  }
  if (typeof action.payload.latestOrderInventoryUpdate === "string") {
    newState.latestOrderInventoryUpdate =
      action.payload.latestOrderInventoryUpdate
  }
  if (typeof action.payload.latestOrderRequestUpdate === "string") {
    newState.latestOrderRequestUpdate = action.payload.latestOrderRequestUpdate
  }

  yield put<ActionType>({ type: "setMercurial", payload: newState })

  try {
    action.payload.batchUpdateAnalyticsOrderPrediction({
      variables: {
        input: {
          store_id: state.storeReducer.storeId ?? "",
          batch_analytics_order_prediction: Object.values(
            newState.updatedReferences,
          )
            .filter((updatedReference) =>
              action.payload.mercurialAndStoreInventories.some(
                (mercurialeAndStoreInventory) =>
                  mercurialeAndStoreInventory.mercuriale_id ===
                    updatedReference.mercurialeId &&
                  typeof mercurialeAndStoreInventory.dim_order_request_id ===
                    "string" &&
                  Array.isArray(
                    mercurialeAndStoreInventory.quantity_predicted_array,
                  ),
              ),
            )
            .map((updatedReference) => {
              const mercurialeInfo =
                action.payload.mercurialAndStoreInventories.find(
                  (mercurialeAndStoreInventory) =>
                    mercurialeAndStoreInventory.mercuriale_id ===
                    updatedReference.mercurialeId,
                )

              return {
                order_id: mercurialeInfo?.order_id ?? "",
                dim_mercuriale_id: mercurialeInfo?.dim_mercuriale_id ?? "",
                dim_order_request_id:
                  mercurialeInfo?.dim_order_request_id ?? "",
                store_id: state.storeReducer.storeId!,
                mercuriale_id: mercurialeInfo?.mercuriale_id ?? "",
                computed_quantity_predicted_analytics:
                  updatedReference.orderInventoryQuantity,
                initial_floor_order_inventory:
                  mercurialeInfo?.floor_inventory_qty,
                initial_back_order_inventory:
                  mercurialeInfo?.back_inventory_qty,
                date_prediction_computed: formatDateToYYYYMMDD(new Date()),
                colisage: mercurialeInfo?.colisage,
              }
            }),
        },
      },
    })
  } catch (e) {
    console.error(e)
  }
}

function* mercurialeSaga() {
  yield takeLatest(
    "dispatchMercurialeInfosAndAnalyticsEvent",
    handleMercurialeSaga,
  )
}

export default mercurialeSaga
