import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useGetDetailedModificationProductsQuery } from "@/utils/__generated__/graphql"
import { useOutletContext, useParams } from "react-router-dom"
import { PerformancesPageContext } from "../../PerformancesPage"
import { Spinner } from "@/ui/Spinner"
import { Badge } from "@/components/ui/badge"
import { TrendingDown, TrendingUp, AlertCircle, BarChart3 } from "lucide-react"
import { motion } from "framer-motion"
import { QuantityComparison } from "../QuantityComparison"
import { formatNumber } from "@/utils/formatting"
import { FormatPackageUnit } from "../FormatPackageUnit"

const modificationTypes: Record<string, string> = {
  USER_BREAKAGE: "Démarque (utilisateur)",
  ALGO_BREAKAGE: "Démarque (algorithme)",
  USER_STOCKOUT: "Rupture (utilisateur)",
  ALGO_STOCKOUT: "Rupture (algorithme)",
  USER_REPLENISHMENT: "Rupture (réassort)",
  USER_OVERSUPPLY: "Surstockage (utilisateur)",
  ALGO_OVERSUPPLY: "Surstockage (algorithme)",
  MISSING_DATA: "Donnée manquante",
}

const typeColors: Record<string, string> = {
  BREAKAGE: "bg-red-100 text-red-900 border border-red-300",
  STOCKOUT: "bg-amber-100 text-amber-900 border border-amber-300",
  OVERSUPPLY: "bg-blue-100 text-blue-900 border border-blue-300",
  REPLENISHMENT: "bg-indigo-100 text-indigo-900 border border-indigo-300",
  MISSING: "bg-gray-100 text-gray-900 border border-gray-300",
}

export function DetailedBreakdown() {
  const params = useParams()
  const { selectedStoreId, companyId, storeId, franchise } =
    useOutletContext<PerformancesPageContext>()

  const { data, loading } = useGetDetailedModificationProductsQuery({
    variables: {
      input: {
        store_id: storeId ?? "",
        company_id: companyId ?? "",
        franchise_name: franchise,
        week: Number(params.week),
        modification_type: params.modificationType ?? "",
        selected_store_id: selectedStoreId,
      },
    },
  })

  function parseModificationTypes(modificationTypes: string) {
    return modificationTypes.slice(1, -1).replaceAll(" ", "").split(",")
  }

  function getModificationTypeLabel(modificationType: string) {
    return modificationTypes[modificationType]
  }

  function getBadgeClass(modificationType: string) {
    for (const [key] of Object.entries(typeColors)) {
      if (modificationType.includes(key)) {
        return typeColors[key]
      }
    }
    return "bg-gray-200 text-gray-800 border border-gray-300"
  }

  function getValueColumnTitle() {
    const modificationType = params.modificationType ?? ""
    if (modificationType.toLowerCase().includes("breakage")) {
      return "Démarque"
    } else if (modificationType.toLowerCase().includes("stockout")) {
      return "Rupture"
    }
    return "Valeur"
  }

  function getValueIcon(value: number) {
    if (value > 0) {
      return <TrendingUp className="h-4 w-4 text-rose-600 inline mr-1" />
    } else if (value < 0) {
      return <TrendingDown className="h-4 w-4 text-emerald-600 inline mr-1" />
    }
    return null
  }

  const MotionCard = motion(Card)

  return (
    <div className="p-6">
      <MotionCard
        className="border border-indigo-100 shadow-lg rounded-xl overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, ease: "easeOut" }}
      >
        <CardHeader className="border-b bg-gradient-to-r from-indigo-100 to-blue-100 p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <CardTitle className="text-xl font-bold text-gray-800 flex items-center">
              <BarChart3 className="mr-3 h-6 w-6 text-indigo-600" />
              Analyse de
              <span className="mx-1.5 text-indigo-700 font-extrabold underline decoration-2 decoration-indigo-300">
                {getModificationTypeLabel(
                  params.modificationType ?? "",
                ).toLowerCase()}
              </span>{" "}
              pour la semaine{" "}
              <motion.span
                className="ml-2 px-3 py-1 bg-indigo-100 text-indigo-800 rounded-lg shadow-sm border border-indigo-200"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                {params.week}
              </motion.span>
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="p-0 bg-white">
          {loading ? (
            <motion.div
              className="flex flex-col items-center justify-center p-20"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
              >
                <Spinner className="text-indigo-600 h-10 w-10" />
              </motion.div>
              <p className="mt-6 text-gray-600 font-medium">
                Chargement des données...
              </p>
            </motion.div>
          ) : data?.getDetailedModificationProducts.products.length ? (
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow className="bg-gradient-to-r from-gray-50 to-indigo-50">
                    <TableHead className="font-semibold text-gray-700">
                      Magasin
                      <div className="text-xs font-normal text-gray-500">
                        Date de commande
                      </div>
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700 py-4">
                      <div>
                        <span>Référence</span>
                        <div className="text-xs font-normal text-gray-500 flex justify-between items-center">
                          <span className="text-gray-500">Code vente</span>
                          <span className="text-indigo-600 font-medium">
                            Colisage
                          </span>
                        </div>
                      </div>
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      {getValueColumnTitle()}
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Ventes (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Stock (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Préd. vs Cmd. (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Demande (cs)
                    </TableHead>
                    <TableHead className="font-semibold text-gray-700">
                      Analyse
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.getDetailedModificationProducts.products.map(
                    (product, i) => (
                      <motion.tr
                        key={i}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: i * 0.05 }}
                        className="hover:bg-indigo-50/30 transition-colors border-b border-gray-100"
                        whileHover={{
                          backgroundColor: "rgba(238, 242, 255, 0.5)",
                        }}
                      >
                        <TableCell>
                          <div className="space-y-1">
                            <div className="font-medium text-gray-800 flex items-center gap-2">
                              {product.store_name}
                            </div>
                            <div className="flex items-center space-x-3 text-xs text-gray-500">
                              <span>{product.order_request_date}</span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div>
                            <div className="font-medium text-gray-800 flex items-center gap-2">
                              {product.sale_name}
                              {(product.promotion ?? true) && (
                                <Badge
                                  variant="secondary"
                                  className="bg-blue-100 text-blue-800 border border-blue-300 text-xs px-1.5 py-0.5"
                                >
                                  Promo
                                </Badge>
                              )}
                            </div>
                            <div className="text-xs text-gray-500 flex justify-between items-center">
                              <span className="font-mono bg-gray-50 px-2 py-0.5 rounded">
                                {product.sale_name_ida}
                              </span>
                              <span className="bg-indigo-50 text-indigo-700 px-1.5 py-0.5 rounded-md">
                                {product.colisage ?? "N/A"}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="font-semibold">
                          <motion.div
                            className="flex items-center"
                            whileHover={{ scale: 1.05 }}
                            transition={{ duration: 0.2 }}
                          >
                            {getValueIcon(product.value)}
                            {formatNumber(product.value)} €
                          </motion.div>
                        </TableCell>
                        <TableCell className="text-gray-700">
                          {FormatPackageUnit(
                            product.sold_quantity,
                            product.colisage,
                          )}
                        </TableCell>
                        <TableCell className="text-gray-700">
                          {FormatPackageUnit(product.stock, product.colisage)}
                        </TableCell>
                        <TableCell className="text-gray-700">
                          <div className="flex items-center">
                            <QuantityComparison
                              orderedQuantity={product.ordered_quantity}
                              predictedQuantity={product.predicted_quantity}
                              colisage={product.colisage}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="text-gray-700">
                          {FormatPackageUnit(
                            product.demand_to_last ?? 0.0,
                            product.colisage,
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="flex flex-wrap gap-1.5">
                            {parseModificationTypes(
                              product.modification_types,
                            ).map((modificationType) => (
                              <motion.div
                                key={modificationType}
                                whileHover={{ scale: 1.05 }}
                                transition={{ duration: 0.2 }}
                              >
                                <Badge
                                  className={`font-medium py-1 px-2.5 rounded-lg shadow-sm ${getBadgeClass(modificationType)}`}
                                >
                                  {getModificationTypeLabel(modificationType)}
                                </Badge>
                              </motion.div>
                            ))}
                          </div>
                        </TableCell>
                      </motion.tr>
                    ),
                  )}
                </TableBody>
              </Table>
            </div>
          ) : (
            <motion.div
              className="flex flex-col items-center justify-center p-20 text-gray-500"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <AlertCircle className="h-16 w-16 text-gray-400 mb-6" />
              </motion.div>
              <p className="text-xl font-medium">Aucune donnée disponible</p>
              <p className="text-sm mt-2 text-gray-400">
                Aucun produit trouvé pour les critères sélectionnés
              </p>
            </motion.div>
          )}
        </CardContent>
      </MotionCard>
    </div>
  )
}
