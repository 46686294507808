/**
 * Get the weekdays starting from Monday.
 *
 * @param locale The locale to format the weekdays.
 * @param weekday The formatting style for the weekday (e.g., 'short', 'long').
 * @returns An array of formatted weekday names.
 *
 * @example
 * ```ts
 * const weekdays = getWeekdaysStartingMonday("fr-FR")
 * console.log(weekdays)
 * // Output: ["lun.", "mar.", "mer.", "jeu.", "ven.", "sam.", "dim."]
 * ```
 */
export function getWeekdaysStartingMonday(
  locale: Intl.LocalesArgument,
  weekday: Intl.DateTimeFormatOptions["weekday"] = "short",
) {
  const date = new Date()
  date.setDate(date.getDate() - (date.getDay() - 1))

  const weekdays = new Array(7).fill(null).map((_, i) => {
    const day = new Date(date)
    day.setDate(date.getDate() + i)
    const formattedWeekday = new Intl.DateTimeFormat(locale, {
      weekday,
    }).format(day)

    return formattedWeekday
  })

  return weekdays
}
