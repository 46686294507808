import { StateType } from "@/types"
import { InventoryType } from "@/utils/__generated__/graphql"
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react"
import { useSelector } from "react-redux"

interface OrderContextType {
  lastInputSelected: {
    type?: InventoryType
    saleId?: string
    mercurialeId?: string
    index?: number
    saleIdIndex?: number
  }
  setLastInputSelected: (value: {
    type?: InventoryType
    saleId?: string
    mercurialeId?: string
    index?: number
    saleIdIndex?: number
  }) => void
}

const OrderContext = createContext<OrderContextType | undefined>(undefined)

export const OrderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const [lastInputSelected, setLastInputSelected] = useState<
    OrderContextType["lastInputSelected"]
  >({
    type: selectedInventory?.type ?? InventoryType.Floor,
    saleId: "",
    mercurialeId: selectedInventory?.mercurialeId ?? "",
    index: 0,
    saleIdIndex: 0,
  })

  const inventoryValues = {
    lastInputSelected,
    setLastInputSelected,
  }

  useEffect(() => {
    if (selectedInventory !== undefined) return
    setLastInputSelected({
      type: undefined,
      saleId: undefined,
      mercurialeId: undefined,
      index: undefined,
      saleIdIndex: undefined,
    })
  }, [selectedInventory])

  return (
    <OrderContext.Provider value={inventoryValues}>
      {children}
    </OrderContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useOrderContext = (): OrderContextType => {
  const context = useContext(OrderContext)
  if (!context) {
    throw new Error("useOrderContext must be used within an InventoryProvider")
  }
  return context
}
