import { QuantityInput } from "@/components/molecules/quantityInput"
import { Button } from "@/components/ui/button"
import {
  GetEngagementPromoDocument,
  GetEngagementPromoResult,
  UpdateEngagementPromoMutationFn,
  useAddEngagementPromoMutation,
} from "@/utils/__generated__/graphql"
import { formatDateToYYYYMMDD } from "@/utils/formatDateToYYYYMMDD"
import { captureException } from "@sentry/react"
import { Trash } from "lucide-react"
import { useState } from "react"
import { toast } from "sonner"

const timeouts: Record<string, NodeJS.Timeout> = {}

interface EngagementQuantityInputProps {
  storeId: string
  date: Date
  dimEngagementPromoId: string
  engagementQuantity: number
  factEngagementPromoId?: string
  isTestMode: boolean
  disabled?: boolean
  onQuantityChange: (oldQuantity: number, newQuantity: number) => void
  updateEngagementPromo: UpdateEngagementPromoMutationFn
}

export function EngagementQuantityInput({
  storeId,
  date,
  dimEngagementPromoId,
  factEngagementPromoId,
  engagementQuantity,
  isTestMode,
  disabled = false,
  onQuantityChange,
  updateEngagementPromo,
}: EngagementQuantityInputProps) {
  const [addEngagementPromo] = useAddEngagementPromoMutation()

  const [quantity, setQuantity] = useState<number | undefined>(
    engagementQuantity,
  )

  const handleQuantityInputChange = (newQuantity?: number) => {
    const oldQuantity = quantity ?? 0
    setQuantity(newQuantity)

    if (typeof newQuantity !== "number") return

    onQuantityChange(oldQuantity, newQuantity)

    const timeoutId = `${dimEngagementPromoId}-${date.toISOString()}`
    clearTimeout(timeouts[timeoutId])
    timeouts[timeoutId] = setTimeout(() => {
      const addEngagementPromoMutation = async (): Promise<void> => {
        try {
          const setEngagementPromoResult = await addEngagementPromo({
            variables: {
              input: {
                store_id: storeId,
                date: formatDateToYYYYMMDD(date),
                dim_engagement_promo_id: dimEngagementPromoId,
                quantity: newQuantity,
              },
            },
            update(cache, { data }) {
              const engagementPromoQuery = cache.readQuery({
                query: GetEngagementPromoDocument,
                variables: {
                  input: {
                    store_id: storeId,
                  },
                },
              })
              const engagementPromo: {
                getEngagementPromo: GetEngagementPromoResult
              } = JSON.parse(JSON.stringify(engagementPromoQuery))
              if (
                !engagementPromo?.getEngagementPromo?.promotions ||
                !data?.addEngagementPromo.engagement
              )
                return

              const promotionIndex =
                engagementPromo.getEngagementPromo.promotions.findIndex(
                  (promotion) => promotion.id === dimEngagementPromoId,
                )
              if (
                promotionIndex === undefined ||
                engagementPromo.getEngagementPromo.promotions[
                  promotionIndex
                ] === undefined
              )
                return

              engagementPromo.getEngagementPromo.promotions[
                promotionIndex
              ].engagements = [
                ...engagementPromo.getEngagementPromo.promotions[promotionIndex]
                  .engagements,
                data.addEngagementPromo.engagement,
              ]
              cache.writeQuery({
                query: GetEngagementPromoDocument,
                variables: {
                  input: {
                    store_id: storeId,
                  },
                },
                data: engagementPromo,
              })
            },
          })
          if (
            setEngagementPromoResult.data?.addEngagementPromo?.error !== null
          ) {
            throw setEngagementPromoResult.data?.addEngagementPromo?.error
          }
        } catch (error) {
          console.error(error)
          const errorMessage =
            error instanceof Error ? error.message : "Données non sauvegardées"
          captureException(new Error(errorMessage))
          toast.error("Données non sauvegardées")
        }
      }

      const updateEngagementPromoMutation = async (
        _factEngagementPromoId: string,
      ): Promise<void> => {
        try {
          const setEngagementPromoResult = await updateEngagementPromo({
            variables: {
              input: {
                store_id: storeId,
                fact_engagement_promo_id: _factEngagementPromoId,
                quantity: newQuantity,
              },
            },
          })
          if (
            setEngagementPromoResult.data?.updateEngagementPromo?.error !== null
          ) {
            throw setEngagementPromoResult.data?.updateEngagementPromo?.error
          }
        } catch (error) {
          console.error(error)
          const errorMessage =
            error instanceof Error ? error.message : "Données non sauvegardées"
          captureException(new Error(errorMessage))
          toast.error("Données non sauvegardées")
        }
      }

      if (!isTestMode) {
        if (typeof factEngagementPromoId === "string") {
          void updateEngagementPromoMutation(factEngagementPromoId)
        } else {
          void addEngagementPromoMutation()
        }
      }

      delete timeouts[timeoutId]
    }, 1000)
  }

  return (
    <div className="flex items-center gap-8 justify-between">
      <QuantityInput
        value={quantity}
        onReducedQuantity={() => handleQuantityInputChange((quantity ?? 1) - 1)}
        onIncreasedQuantity={() =>
          handleQuantityInputChange((quantity ?? 0) + 1)
        }
        onQuantityChange={handleQuantityInputChange}
        disabled={disabled}
        allowZero
      />
      <Button
        variant="outline"
        onClick={() => handleQuantityInputChange(0)}
        disabled={disabled}
      >
        <Trash className="size-4" />
      </Button>
    </div>
  )
}
