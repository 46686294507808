import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Button } from "@/components/ui/button"
import { ArrowDownCircleIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { isNumber } from "lodash"

export interface SortSectionOption {
  index: number
  count?: number
  label: string
  description?: string
}

export const ScrollToSectionDropdown = ({
  options,
  handleScrollTo,
  activeIndex = 0,
}: {
  activeIndex: number | null
  options: SortSectionOption[]
  handleScrollTo: (index: number) => void
}) => {
  const idx = !activeIndex || activeIndex < 0 ? 0 : activeIndex
  const activeOption = options
    .slice()
    .reverse()
    .filter((option) => option.index >= 0)
    .find((option) => option.index <= idx)

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          disabled={!options.length}
          size="icon"
          variant="outline"
          className="min-w-min text-primary relative cursor-default rounded-lg py-3 lg:py-2 px-4 text-left border focus:outline-none text-sm"
        >
          {activeOption && (
            <>
              <span className="font-medium text-sm">
                {activeOption?.label.length > 10 && window.innerWidth < 768
                  ? `${activeOption?.label.substring(0, 10)}...`
                  : activeOption?.label}
              </span>
              {Boolean(activeOption?.count) && (
                <span className="hidden md:block font-[monospace] text-muted-foreground text-xs ml-1">
                  {activeOption?.count}
                </span>
              )}
            </>
          )}
          <ArrowDownCircleIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="max-w- md:max-w-56 max-h-96 overflow-auto"
        align="end"
      >
        <DropdownMenuLabel>Accéder à ...</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {options.map((option) => (
          <DropdownMenuCheckboxItem
            checked={option.index === activeOption?.index}
            onClick={() => {
              if (isNumber(option.count) && !option.count) return
              handleScrollTo(option.index)
            }}
            className={cn(
              "flex justify-between items-center gap-4 font-medium",
              option.index === activeOption?.index
                ? "[&_svg]:text-green-600"
                : "",
            )}
            key={option.label}
          >
            <div className="flex flex-col gap-1">
              <span>{option.label}</span>
              {option.description && (
                <span className="text-xs text-gray-500">
                  {option.description}
                </span>
              )}
            </div>
            {isNumber(option.count) && (
              <span className="font-[monospace] text-muted-foreground font-medium text-xs pt-[1px]">
                {option.count}
              </span>
            )}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
