export interface UpdateReducerState {
  refreshModalCloseDate: string | undefined
  refreshModalUpdateDate: string | undefined
}

export const defaultUpdateReducerState: UpdateReducerState = {
  refreshModalCloseDate: undefined,
  refreshModalUpdateDate: undefined,
}

interface SetUpdateAction {
  type: "setUpdate"
  payload: UpdateReducerState
}

export interface SetRefreshModalCloseDateAction {
  type: "setRefreshModalCloseDate"
  payload: UpdateReducerState["refreshModalCloseDate"]
}

export interface SetRefreshModalUpdateDateAction {
  type: "setRefreshModalUpdateDate"
  payload: UpdateReducerState["refreshModalUpdateDate"]
}

export type UpdateReducerAction =
  | SetUpdateAction
  | SetRefreshModalCloseDateAction
  | SetRefreshModalUpdateDateAction

const updateReducer = (
  state: UpdateReducerState = defaultUpdateReducerState,
  action: UpdateReducerAction,
): UpdateReducerState => {
  switch (action.type) {
    case "setUpdate":
      return {
        ...state,
        ...action.payload,
      }
    case "setRefreshModalCloseDate":
      return {
        ...state,
        refreshModalCloseDate: action.payload,
      }
    case "setRefreshModalUpdateDate":
      return {
        ...state,
        refreshModalUpdateDate: action.payload,
      }
    default:
      return state
  }
}

export default updateReducer
