import React from "react"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Bar,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ModificationItem, PredictionRecord } from "./InventoriesAdmin"

interface StockVsOrderModificationsChartProps {
  modificationsChartData: ModificationItem[]
  allRecords: PredictionRecord[]
}

export const StockVsOrderModificationsChart: React.FC<
  StockVsOrderModificationsChartProps
> = ({ modificationsChartData, allRecords }) => {
  if (modificationsChartData.length === 0) {
    return (
      <Card>
        <CardHeader className="pb-0">
          <CardTitle>
            Comparaison des modifs de stocks vs modifs de commandes
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64 flex items-center justify-center">
            Aucune donnée disponible pour ce magasin ou période.
          </div>
        </CardContent>
      </Card>
    )
  }

  const enhancedData = modificationsChartData.map((item) => {
    const matchingPredictions = allRecords.filter(
      (pred) => pred.order_request_date?.slice(0, 10) === item.date,
    )

    const totalPredictionUpdates = matchingPredictions.filter(
      (pred) => (pred.updated ?? 0) > 0,
    ).length

    const predictionIncreases = matchingPredictions.filter(
      (pred) =>
        (pred.updated ?? 0) > 0 &&
        (pred.quantity_ordered_in_colis ?? 0) >
          (pred.final_quantity_predicted_in_colis ?? 0),
    ).length

    const predictionDecreases = matchingPredictions.filter(
      (pred) =>
        (pred.updated ?? 0) > 0 &&
        (pred.quantity_ordered_in_colis ?? 0) <
          (pred.final_quantity_predicted_in_colis ?? 0),
    ).length

    return {
      ...item,
      prediction_count: totalPredictionUpdates,
      prediction_increase: predictionIncreases,
      prediction_decrease: predictionDecreases,
    }
  })

  return (
    <Card>
      <CardHeader className="pb-0">
        <CardTitle>
          Comparaison des modifs de stocks vs modifs de commandes
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            data={enhancedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="modification_count"
              fill="#8884d8"
              name="Modifications de stock"
            />
            <Bar
              dataKey="prediction_count"
              fill="#82ca9d"
              name="Modifications de prévision"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}
