import { FunctionComponent } from "react"
import Logo from "../assets/Logo"
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom"
import { Button } from "../ui/Button"
import { useDispatch } from "react-redux"
import { persistor } from "../store"
import { EnvelopeIcon } from "@heroicons/react/24/outline"

const NotFound: FunctionComponent = () => {
  const error = useRouteError()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const is404Error = isRouteErrorResponse(error) && error.status === 404
  const encodedState = encodeURIComponent(JSON.stringify(localStorage))

  return (
    <div className="items-center">
      <h1 />
      <div className="flex items-center justify-center h-[90vh] flex-col gap-4">
        <Logo />
        {is404Error && (
          <>
            <h1 className="font-black text-2xl">Aucune page trouvée</h1>
            <Button
              size="lg"
              className="h-14 uppercase font-black xl:text-base 2xl:text-base"
              onClick={() => navigate("/order")}
            >
              Revenir à l&apos;accueil
            </Button>
          </>
        )}
        {!is404Error && (
          <>
            <h1 className="font-black text-2xl">Une erreur est survenue</h1>
            <div className="flex flex-col gap-2 justify-center">
              <a
                href={`mailto:contact@ai-ida.com?cc=mathieu@ai-ida.com&subject=state&body=${encodedState}`}
                className="w-fit h-14 px-5 py-4 rounded border-2 border-green-500 justify-start items-center gap-4 inline-flex text-green-500 font-black"
              >
                <EnvelopeIcon className="w-6 h-6" />
                ENVOYER LE RAPPORT D&apos;ERREUR
              </a>
              <Button
                size="lg"
                className="h-14 uppercase font-black xl:text-base 2xl:text-base"
                onClick={async () => {
                  console.error(error)
                  dispatch({ type: "resetState" })
                  await persistor.flush()
                  await persistor.purge()
                  navigate("/login")
                }}
              >
                Revenir à l&apos;accueil
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default NotFound
