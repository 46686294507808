import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import {
  Link,
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { type StateType } from "../../types"
import MinimalLogo from "../../assets/MinimalLogo"

import { usePermissions } from "../../hooks/usePermissions"

import { AppNavigation } from "./components/navigation/navigation"
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs"
import { useAppContext } from "@/contexts/AppContext"
import { debounce } from "lodash"

import { Lock, ShieldCheck, Store } from "lucide-react"
import { CompanySelector } from "./components/navigation/CompanySelector"
import { Button } from "../ui/button"
import { twJoin } from "tailwind-merge"

const Header = (): JSX.Element => {
  const location = useLocation()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const companyIdparam = params.companyId
  const companyFranchiseQuery = searchParams.get("franchise") ?? undefined

  const [isScrolled, setIsScrolled] = useState(false)

  const appMode = useMemo(() => {
    return location.pathname.includes("/admin") ? "admin" : "store"
  }, [location.pathname])

  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const { companyId, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { storeName } = useSelector((state: StateType) => state.storeReducer)

  const { hasPermissions, isIdaAdmin, isAuthAdmin } = usePermissions()

  const headerRef = useRef<HTMLDivElement>(null)

  const { setHeaderHeight } = useAppContext()
  useEffect(() => {
    const updateHeaderHeight = (): void => {
      setHeaderHeight(headerRef.current?.clientHeight ?? 0)
    }

    const handleScroll = (): void => {
      setIsScrolled(window.scrollY > 0)
    }

    const debouncedUpdateHeight = debounce(updateHeaderHeight, 200)

    updateHeaderHeight()

    window.addEventListener("resize", debouncedUpdateHeight)
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("resize", debouncedUpdateHeight)
      window.removeEventListener("scroll", handleScroll)
      debouncedUpdateHeight.cancel()
    }
  }, [setHeaderHeight])

  return (
    <header
      ref={headerRef}
      className={twJoin(
        "px-2 md:px-4 flex items-center w-full",
        window.innerHeight > 700 ? "py-4" : "py-2",
        appMode === "store" && "sticky top-0 z-50",
        isScrolled || appMode === "admin" ? "bg-white" : "bg-transparent",
      )}
      style={{
        boxShadow:
          window.innerWidth < 768 || isScrolled
            ? "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
            : "none",
      }}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row justify-between gap-2 w-full">
          <div className="flex gap-2 items-center">
            {appMode === "store" ? (
              <>
                <Link to="/order">
                  <MinimalLogo
                    companyId={companyId}
                    testMode={enable}
                    franchise={storeFranchise}
                    className="w-6 lg:w-8"
                  />
                </Link>
                <p className="text-base text-[#323232] font-bold">
                  {storeName}
                </p>
              </>
            ) : (
              <>
                <MinimalLogo
                  companyId={companyIdparam}
                  franchise={companyFranchiseQuery}
                  className="w-6 lg:w-8"
                />
                {isIdaAdmin && <CompanySelector />}
              </>
            )}
          </div>
          <div className="hidden md:flex flex-row justify-between gap-2">
            {isAuthAdmin && appMode === "admin" && (
              <Button
                variant={
                  matchPath("/admin/users", location.pathname) !== null
                    ? "default"
                    : "outline"
                }
                asChild
              >
                <Link to="/admin/users">
                  <Lock className="size-4" />
                  Utilisateurs
                </Link>
              </Button>
            )}
            <div className="flex gap-2 items-center">
              {hasPermissions(["arolya:company:admin"]) && (
                <Tabs value={appMode}>
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger
                      value="store"
                      className="flex items-center gap-2"
                      asChild
                    >
                      <Link to="/home">
                        <ShieldCheck className="w-4 h-4" />
                        Mode magasin
                      </Link>
                    </TabsTrigger>
                    <TabsTrigger
                      value="admin"
                      className="flex items-center gap-2"
                      asChild
                    >
                      <Link
                        to={`/admin/companies/${companyId}${
                          storeFranchise !== null
                            ? `?franchise=${storeFranchise}`
                            : ""
                        }`}
                      >
                        <Store className="w-4 h-4" />
                        Mode administrateur
                      </Link>
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              )}
            </div>
          </div>
        </div>
        <span className="h-[1px] my-2 bg-gray-200 w-full" />
        <AppNavigation
          appMode={appMode}
          companyId={companyId}
          storeFranchise={storeFranchise}
        />
      </div>
    </header>
  )
}

export default Header
