import { createSelector } from "@reduxjs/toolkit"
import { StateType } from "../types"
import {
  AllMercurialInfo,
  MercurialReducerState,
  UpdatedReference,
} from "../reducers/mercurialReducer"

export function mercurialeStateSelector(state: StateType) {
  return state.mercurialReducer
}

export const filteredMercurialeReducerSelector = createSelector(
  [mercurialeStateSelector],
  (mercurialeState) => {
    const selectedDimMercurialeId = mercurialeState.selectedDimMercurialeId
    if (selectedDimMercurialeId === undefined) {
      return mercurialeState
    }
    const filteredMercurialeInfos =
      mercurialeState.mercurialAndStoreInventories.filter(
        (mercurialAndStoreInventory) =>
          mercurialAndStoreInventory.dim_mercuriale_id ===
          selectedDimMercurialeId,
      )
    const filteredUpdatedReferences = Object.values(
      mercurialeState.updatedReferences,
    ).reduce<MercurialReducerState["updatedReferences"]>(
      (updatedReferences, updatedReference) => {
        if (
          filteredMercurialeInfos.some(
            (filteredMercurialeInfo) =>
              filteredMercurialeInfo.mercuriale_id ===
              updatedReference.mercurialeId,
          )
        ) {
          updatedReferences[updatedReference.mercurialeId] = updatedReference
        }
        return updatedReferences
      },
      {},
    )

    return {
      ...mercurialeState,
      mercurialAndStoreInventories: filteredMercurialeInfos,
      updatedReferences: filteredUpdatedReferences,
    }
  },
)

export const selectedRankingsSelector = createSelector(
  [mercurialeStateSelector],
  (mercurialeState) => {
    const selectedDimMercurialeId = mercurialeState.selectedDimMercurialeId
    if (selectedDimMercurialeId === undefined) {
      return undefined
    }

    return mercurialeState.rankings[selectedDimMercurialeId] ?? undefined
  },
)

export const selectedMercurialeInfosSelector = createSelector(
  [filteredMercurialeReducerSelector],
  (mercurialeState): AllMercurialInfo[] => {
    return mercurialeState.mercurialAndStoreInventories.map(
      (mercurialeInfo): AllMercurialInfo => {
        const updatedReference =
          mercurialeState.updatedReferences[mercurialeInfo.mercuriale_id!]
        if (updatedReference === undefined) return mercurialeInfo
        return {
          ...mercurialeInfo,
          back_inventory_qty:
            updatedReference.backInventoryQuantity ??
            mercurialeInfo.back_inventory_qty,
          floor_inventory_qty:
            updatedReference.floorInventoryQuantity ??
            mercurialeInfo.floor_inventory_qty,
          shelf_floor_size:
            updatedReference.shelfFloorSize ?? mercurialeInfo.shelf_floor_size,
          shelf_floor_size_updated_at:
            updatedReference.shelfFloorSizeUpdatedAt ??
            mercurialeInfo.shelf_floor_size_updated_at,
          quantity_actual:
            updatedReference.orderInventoryQuantity ??
            mercurialeInfo.quantity_actual,
          stock_too_high_flag:
            updatedReference?.stock_too_high_flag ??
            mercurialeInfo.stock_too_high_flag,
          stock_too_low_flag:
            updatedReference?.stock_too_low_flag ??
            mercurialeInfo.stock_too_low_flag,
          stock_to_verify_flag:
            updatedReference?.stock_to_verify_flag ??
            mercurialeInfo.stock_to_verify_flag,
          local_flag: updatedReference?.local_flag ?? mercurialeInfo.local_flag,
          time_rupture_flag_verified:
            updatedReference?.time_rupture_flag_verified ??
            mercurialeInfo.time_rupture_flag_verified,
          local_delivery_days:
            updatedReference?.local_delivery_days ??
            mercurialeInfo.local_delivery_days,
          local_delivery_on_monday:
            updatedReference.local_delivery_on_monday ??
            mercurialeInfo.local_delivery_on_monday,
          local_delivery_on_tuesday:
            updatedReference.local_delivery_on_tuesday ??
            mercurialeInfo.local_delivery_on_tuesday,
          local_delivery_on_wednesday:
            updatedReference.local_delivery_on_wednesday ??
            mercurialeInfo.local_delivery_on_wednesday,
          local_delivery_on_thursday:
            updatedReference.local_delivery_on_thursday ??
            mercurialeInfo.local_delivery_on_thursday,
          local_delivery_on_friday:
            updatedReference.local_delivery_on_friday ??
            mercurialeInfo.local_delivery_on_friday,
          local_delivery_on_saturday:
            updatedReference.local_delivery_on_saturday ??
            mercurialeInfo.local_delivery_on_saturday,
          local_delivery_on_sunday:
            updatedReference.local_delivery_on_sunday ??
            mercurialeInfo.local_delivery_on_sunday,
          prediction_uncertain:
            updatedReference.prediction_uncertain ??
            mercurialeInfo.prediction_uncertain,
        }
      },
    )
  },
)

export const getUpdatedReferenceSelector = (mercurialeId: string) =>
  createSelector(
    [filteredMercurialeReducerSelector],
    (mercurialeState): UpdatedReference | undefined => {
      return mercurialeState.updatedReferences[mercurialeId]
    },
  )

export const potentialRupturesSelector = createSelector(
  [selectedMercurialeInfosSelector],
  (allInfos) => {
    return allInfos.filter(
      (info) => info.time_rupture_flag === true && info.last_sale_time !== null,
    )
  },
)

export const bestSellersSelector = createSelector(
  [filteredMercurialeReducerSelector],
  (mercurialeState) => {
    return [...mercurialeState.mercurialAndStoreInventories]
      .filter((item) => item.last_weeks_sales)
      .sort((a, b) => {
        if (a.last_weeks_sales === b.last_weeks_sales) return 0
        return (a.last_weeks_sales ?? 0) > (b.last_weeks_sales ?? 0) ? -1 : 1
      })
      .slice(0, 20)
  },
)
