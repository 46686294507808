import {
  ChevronLeft,
  ChevronRight,
  Truck,
  Receipt,
  ArrowRightIcon,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { DateTime } from "luxon"
import { Link } from "react-router-dom"
import { Label } from "@/ui/Label"
import { GetCalendarQuery } from "@/utils/__generated__/graphql"
import { twJoin } from "tailwind-merge"

interface CalendarProps {
  data: GetCalendarQuery | undefined
  storeCurrency: string | null
  currentDate: DateTime
  allDays: DateTime[]
  setCurrentDate: (date: DateTime) => void
}

function Calendar({
  data,
  storeCurrency,
  currentDate,
  allDays,
  setCurrentDate,
}: CalendarProps) {
  const today = DateTime.now()
  const currencyCode = storeCurrency ?? "EUR"

  const handlePrevMonth = () => {
    const newDate = currentDate.minus({ months: 1 }).startOf("month")
    setCurrentDate(newDate)
  }

  const handleNextMonth = () => {
    const newDate = currentDate.plus({ months: 1 }).startOf("month")
    setCurrentDate(newDate)
  }

  const handleToday = () => {
    setCurrentDate(today.startOf("month"))
  }

  if (!data) {
    return (
      <div className="min-h-screen overflow-auto p-4 space-y-4">
        <div className="flex items-center justify-between border-b bg-slate-50 p-2">
          <div className="flex items-center gap-2">
            <Button variant="ghost" size="icon" disabled>
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <div className="h-10 w-48 animate-pulse bg-gray-200 rounded" />
            <Button variant="ghost" size="icon" disabled>
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
          <Button
            variant="outline"
            size="lg"
            disabled
            className="h-10 w-32 animate-pulse bg-gray-200 rounded text-base"
          >
            Aujourd&apos;hui
          </Button>
        </div>
        <div className="grid grid-cols-7 gap-0">
          {["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"].map((_, i) => (
            <div
              key={i}
              className="border-b border-r p-1 text-center text-sm font-semibold text-slate-500 animate-pulse bg-gray-200 h-10 rounded"
            />
          ))}
        </div>

        <div className="grid grid-cols-7 gap-0">
          {Array.from({ length: allDays.length }).map((_, i) => (
            <div
              key={i}
              className="relative border-b border-r p-2 min-h-[80px]"
            >
              <div className="flex items-start justify-between">
                <div className="h-4 w-6 animate-pulse bg-gray-200 rounded" />
              </div>
              <div className="mt-2 space-y-1">
                <div className="h-3 w-12 animate-pulse bg-gray-200 rounded" />
                <div className="h-3 w-16 animate-pulse bg-gray-200 rounded" />
                <div className="flex items-center justify-between">
                  <div className="h-3 w-20 animate-pulse bg-gray-200 rounded" />
                  <div className="h-3 w-6 animate-pulse bg-gray-200 rounded" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="flex items-center justify-between border-b bg-slate-50 p-2">
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-7 w-7"
            onClick={handlePrevMonth}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <span className="text-2xl font-semibold">
            {currentDate.toFormat("LLLL yyyy", { locale: "fr-FR" })}
          </span>
          <Button
            variant="ghost"
            size="icon"
            className="h-7 w-7"
            onClick={handleNextMonth}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
        <Button
          variant="outline"
          size="lg"
          className="h-10 w-32 text-base"
          onClick={handleToday}
        >
          Aujourd&apos;hui
        </Button>
      </div>

      <div className="grid grid-cols-7">
        {["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"].map((day) => (
          <div
            key={day}
            className="border-b border-r p-1 text-center text-sm font-semibold text-slate-500"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 ">
        {allDays.map((day, index, days) => {
          const info = data?.getCalendar.calendar?.find((item) => {
            if (typeof item?.date !== "string") return false
            const date = DateTime.fromISO(item.date)
            return day.hasSame(date, "day")
          })

          const previousDate = index > 0 ? days[index - 1] : undefined
          const previousDay = data?.getCalendar.calendar?.find((item) => {
            if (typeof item?.date !== "string") return false
            const date = DateTime.fromISO(item.date)
            return previousDate?.hasSame(date, "day")
          })
          const isHoliday =
            typeof info?.holidayReason === "string" ||
            typeof info?.schoolHolidaysReason === "string"

          const displayHolidayReason =
            isHoliday &&
            (typeof previousDay?.holidayReason !== "string" ||
              previousDay?.holidayReason !== info?.holidayReason) &&
            (typeof previousDay?.schoolHolidaysReason !== "string" ||
              previousDay?.schoolHolidaysReason !== info?.schoolHolidaysReason)

          return (
            <div key={index}>
              <div
                className={twJoin(
                  "w-full min-h-4 flex border-r",
                  displayHolidayReason && "pl-0",
                )}
              >
                {displayHolidayReason && (
                  <p className="flex-1 bg-red-20 border-red-40 text-red-900 rounded-b-md px-2 text-xs">
                    {info?.holidayReason ?? info?.schoolHolidaysReason}
                  </p>
                )}
                {isHoliday && !displayHolidayReason && (
                  <div className="w-full h-1 bg-red-20" />
                )}
              </div>
              <div
                className={cn(
                  "relative border-b border-r p-2 min-h-[120px]",
                  day.hasSame(today, "day") && "bg-gray-100",
                )}
              >
                <div className="flex items-start justify-between">
                  <span className="text-xs font-medium">{day.day}</span>
                </div>
                <div className="mt-2 space-y-1">
                  {info?.orderItems && info.orderItems.length > 0 && (
                    <div className="space-y-1">
                      {info.orderItems.map((orderItem) => (
                        <div
                          key={orderItem.dimOrderRequestId}
                          className="flex items-center gap-1"
                        >
                          <Link
                            key={orderItem.dimOrderRequestId}
                            to={`/order/recap?orderId=${orderItem.dimOrderRequestId}`}
                            className="w-full"
                          >
                            <Label
                              className="w-full justify-between space-y-1 gap-2"
                              color="gray"
                              variant="filled"
                              rightIcon={<ArrowRightIcon className="w-4 h-4" />}
                            >
                              <span className="text-xs font-semibold">
                                {new Intl.NumberFormat("fr-FR", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency: currencyCode,
                                }).format(orderItem.potentialCA)}
                              </span>
                              <span className="flex items-center gap-1 text-sm">
                                <Truck className="w-4 h-4" />
                                <span>CA commandé</span>
                              </span>
                            </Label>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                  {typeof info?.dailyCA === "number" && (
                    <div className="flex items-center justify-between text-xs">
                      <div className="flex items-center gap-1 text-sm">
                        <Receipt className="w-4 h-4 text-green-800" />
                        <span className="text-green-800">CA réalisé</span>
                      </div>
                      <span className="font-medium text-xs">
                        {new Intl.NumberFormat("fr-FR", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: currencyCode,
                        }).format(info.dailyCA)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Calendar.displayName = "Calendar"

export { Calendar }
