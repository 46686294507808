import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { SortOption } from "@/reducers/userInterfaceReducer"
import {
  alphabeticalSort,
  bestSellerSort,
  categoriesSort,
  customBooleanComparator,
  customNullLastComparator,
  numericalSort,
} from "@/utils/sort"
import {
  computeQuantityActual,
  computeStockQuantity,
} from "@/utils/computeInventoriesValues"
import { StoreReducerState } from "@/reducers/storeReducer"
import { MercurialeRanking, type StoreSettings } from "./__generated__/graphql"
import {
  generateAlphabeticalSections,
  generateBestSellerSections,
  generateBreakageSections,
  generateCategorySections,
  generateNoRuptureSections,
  generateNoBreakageSections,
  generatePromotionSections,
  SortSectionMapper,
  generateLocalSections,
  generateSortIndexSections,
} from "@/utils/sortSections"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

const itemHasSupplierId =
  (item: AllMercurialInfo, suppliersIds: string[]) =>
  ({ supplier_id, mercuriale_name }: AllMercurialInfo) =>
    mercuriale_name === item.mercuriale_name &&
    supplier_id &&
    suppliersIds.includes(supplier_id)

const itemHasPromotionId =
  (item: AllMercurialInfo, promotionIds: string[]) =>
  ({ message_promotion, mercuriale_name }: AllMercurialInfo) =>
    mercuriale_name === item.mercuriale_name &&
    message_promotion &&
    promotionIds.includes(message_promotion)

export function filterInventory(
  inventory: AllMercurialInfo[],
  familyName?: string,
  subFamilyName?: string,
  suppliersIds?: string[],
  filteredReferences?: string[],
  storeTypologies?: string[] | null,
  promotionMessagesInFilters?: string[],
): AllMercurialInfo[] {
  return inventory.filter(
    (item) =>
      // Filter by family
      (familyName === undefined ||
        familyName === "autres" ||
        item.family_name?.toLowerCase() === familyName) &&
      // Filter by subfamily
      (subFamilyName === undefined ||
        item.sub_family_name?.toLowerCase() === subFamilyName) &&
      // Filter by promotion
      (promotionMessagesInFilters === undefined ||
        promotionMessagesInFilters.length === 0 ||
        inventory.some(itemHasPromotionId(item, promotionMessagesInFilters))) &&
      // Filter by supplier
      (suppliersIds === undefined ||
        suppliersIds.length === 0 ||
        inventory.some(itemHasSupplierId(item, suppliersIds)) ||
        suppliersIds.includes(item.supplier_id ?? "")) &&
      // Filter by references to verify
      ((filteredReferences ?? []).length === 0 ||
        filteredReferences?.includes(item.mercuriale_id ?? "")) &&
      // Filter by typology setting
      (storeTypologies === undefined ||
        storeTypologies === null ||
        storeTypologies.includes(item.typology ?? "")),
  )
}

const sortSectionsMap: Partial<Record<SortOption, SortSectionMapper>> = {
  [SortOption.Categories]: generateCategorySections,
  [SortOption.Alphabetical]: generateAlphabeticalSections,
  [SortOption.Breakage]: generateBreakageSections,
  [SortOption.NoBreakage]: generateNoBreakageSections,
  [SortOption.NoRupture]: generateNoRuptureSections,
  [SortOption.Promotion]: generatePromotionSections,
  [SortOption.BestSeller]: generateBestSellerSections,
  [SortOption.Local]: generateLocalSections,
  [SortOption.SortIndex]: generateSortIndexSections,
}

export const useScrollSectionOptions = ({
  sortOption,
  inventory,
  unfilteredInventory,
  showNewReferencesFirst,
  showPromotionsFirst,
  categoriesOrder,
  bestSellers,
}: {
  inventory: AllMercurialInfo[]
  unfilteredInventory: AllMercurialInfo[]
  bestSellers: AllMercurialInfo[]
  sortOption: SortOption
  showNewReferencesFirst: StoreSettings["show_new_references_first"]
  showPromotionsFirst: StoreSettings["show_promotions_first"]
  categoriesOrder: StoreSettings["categories_orders"]
}) => {
  const mapper = sortSectionsMap[sortOption]
  const { companyName } = useSelector((state: StateType) => state.storeReducer)
  if (!mapper) {
    return [
      {
        label: "Tous les produits",
        index: 0,
      },
    ]
  }

  const sections = mapper({
    inventory,
    categoriesOrder,
    bestSellers,
    unfilteredInventory,
    showNewReferencesFirst: showNewReferencesFirst !== false,
    showPromotionsFirst: showPromotionsFirst !== false,
    companyName: companyName ?? undefined,
  })

  return sections.filter((section) => section.index >= 0)
}

export function sortInventory(
  inventory: AllMercurialInfo[],
  sortOption: SortOption,
  storeSettings: StoreReducerState["storeSettings"],
  rankings?: MercurialeRanking[],
): AllMercurialInfo[] {
  return inventory.slice().sort((a, b) => {
    switch (sortOption) {
      case SortOption.Alphabetical:
        return alphabeticalSort(
          a.mercuriale_name ?? "",
          b.mercuriale_name ?? "",
        )
      case SortOption.NoBreakage:
        return customBooleanComparator(!!a.pas_de_demarque, !!b.pas_de_demarque)
      case SortOption.NoRupture:
        return customBooleanComparator(!!a.pas_de_rupture, !!b.pas_de_rupture)
      case SortOption.BestSeller:
        return bestSellerSort(
          a,
          b,
          storeSettings?.show_new_references_first !== false,
          storeSettings?.show_promotions_first !== false,
        )
      case SortOption.Breakage:
        return numericalSort(
          a.breakage_percentage ?? 0,
          b.breakage_percentage ?? 0,
          "desc",
        )
      case SortOption.Promotion:
        return customBooleanComparator(a.promotion, b.promotion)
      case SortOption.QuantityActual:
        return numericalSort(
          computeQuantityActual(a, storeSettings?.use_kg_pce) ?? 0,
          computeQuantityActual(b, storeSettings?.use_kg_pce) ?? 0,
          "desc",
        )
      case SortOption.StockQuantity:
        return numericalSort(
          computeStockQuantity(a, storeSettings?.use_kg_pce) ?? 0,
          computeStockQuantity(b, storeSettings?.use_kg_pce) ?? 0,
          "desc",
        )
      case SortOption.SortIndex: {
        const sortIndexA = rankings?.find(
          (ranking: MercurialeRanking) =>
            ranking.mercuriale_id === a.mercuriale_id,
        )?.ranking_filter
        const sortIndexB = rankings?.find(
          (ranking: MercurialeRanking) =>
            ranking.mercuriale_id === b.mercuriale_id,
        )?.ranking_filter
        return customNullLastComparator(sortIndexA, sortIndexB)
      }
      case SortOption.Typology:
        return alphabeticalSort(a.typology ?? "", b.typology ?? "")
      case SortOption.Categories:
        return categoriesSort(a, b, storeSettings?.categories_orders)
      case SortOption.Local:
        return customBooleanComparator(a.local_flag, b.local_flag)
      default:
        return numericalSort(
          a.last_weeks_sales ?? 0,
          b.last_weeks_sales ?? 0,
          "desc",
        )
    }
  })
}
