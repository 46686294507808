import {
  InventoryType,
  type MercurialeInfo,
} from "../utils/__generated__/graphql"

export enum SortOption {
  BestSeller = "bestSeller",
  Alphabetical = "alphabetical",
  Breakage = "breakage",
  Promotion = "promotion",
  QuantityActual = "quantity_actual",
  NoBreakage = "noBreakage",
  NoRupture = "noRupture",
  Typology = "typology",
  SortIndex = "sortIndex",
  StockQuantity = "stockQuantity",
  Categories = "categories",
  Local = "local",
}

export enum RecapSortOption {
  MercurialeName = "mercuriale_name",
  Colisage = "colisage",
  PA = "pa",
  PV = "pv",
  QuantityActual = "quantity_actual",
  PotentialRevenue = "potential_revenue",
}

export type PageName = "orderPage" | "recapPage"

interface PageFilters {
  suppliersIds: string[] | undefined
  familyName: string | undefined
  subFamilyName: string | undefined
  sortOption: SortOption
  searchTerm: string
  filteredReferences?: Exclude<
    MercurialeInfo["mercuriale_id"],
    undefined | null
  >[]
  displayShelfFloorSize: boolean
  promotionMessagesInFilters: string[] | undefined
}

export interface UserInterfaceReducerState {
  selectedInventory:
    | {
        type: InventoryType | undefined
        mercurialeId: string | undefined
      }
    | undefined
  orderPage: PageFilters
  numericPadValue: string
  recapPage: {
    searchTerm: string
    sortOption: RecapSortOption | null
    sortOrder: "asc" | "desc"
  }
}

export const defaultUserInterfaceReducerState: UserInterfaceReducerState = {
  selectedInventory: {
    type: undefined,
    mercurialeId: undefined,
  },
  orderPage: {
    suppliersIds: undefined,
    familyName: undefined,
    subFamilyName: undefined,
    sortOption: SortOption.BestSeller,
    searchTerm: "",
    filteredReferences: [],
    displayShelfFloorSize: false,
    promotionMessagesInFilters: [],
  },
  recapPage: {
    searchTerm: "",
    sortOption: RecapSortOption.QuantityActual,
    sortOrder: "desc",
  },
  numericPadValue: "",
}

interface SetUserInterfaceAction {
  type: "setUserInterface"
  payload: UserInterfaceReducerState
}

interface SetSelectedInventoryAction {
  type: "setSelectedInventory"
  payload: UserInterfaceReducerState["selectedInventory"]
}

interface SetSearchBarAction {
  type: "setSearchBar"
  payload: {
    page: PageName
    searchTerm: PageFilters["searchTerm"]
  }
}

interface SetFiltersAction {
  type: "setFilters"
  payload: {
    page: "orderPage"
    supplierId?: Exclude<PageFilters["suppliersIds"], undefined>[number]
    familyName?: PageFilters["familyName"]
    subFamilyName?: PageFilters["subFamilyName"]
  }
}

interface SetSortOptionAction {
  type: "setSortOption"
  payload:
    | {
        page: "orderPage"
        sortOption: PageFilters["sortOption"]
      }
    | {
        page: "recapPage"
        sortOption: UserInterfaceReducerState["recapPage"]["sortOption"]
        sortOrder: UserInterfaceReducerState["recapPage"]["sortOrder"]
      }
}

interface SetFilteredReferencesAction {
  type: "setFilteredReferences"
  payload: {
    page: PageName
    filteredReferences: PageFilters["filteredReferences"]
  }
}

interface SetNumericPadValueAction {
  type: "setNumericPadValueAction"
  payload: UserInterfaceReducerState["numericPadValue"]
}

interface ToggleDisplayShelfFloorSize {
  type: "toggleDisplayShelfFloorSize"
  payload: {
    page: "orderPage"
  }
}

interface SetPromotionMessagesFilter {
  type: "setPromotionMessagesFilter"
  payload: {
    page: "orderPage"
    promotionMessagesInFilters: string[]
  }
}

interface ResetPageFiltersAction {
  type: "resetPageFilters"
  payload: {
    page: PageName
  }
}

export type UserInterfaceReducerAction =
  | SetUserInterfaceAction
  | SetSelectedInventoryAction
  | SetSearchBarAction
  | SetFiltersAction
  | SetSortOptionAction
  | SetFilteredReferencesAction
  | SetNumericPadValueAction
  | ToggleDisplayShelfFloorSize
  | SetPromotionMessagesFilter
  | ResetPageFiltersAction

const userInterfaceReducer = (
  state: UserInterfaceReducerState = defaultUserInterfaceReducerState,
  action: UserInterfaceReducerAction,
): UserInterfaceReducerState => {
  switch (action.type) {
    case "setUserInterface":
      return {
        ...action.payload,
      }
    case "setSelectedInventory":
      return {
        ...state,
        selectedInventory: action.payload,
        numericPadValue: "",
      }
    case "setSearchBar":
      return {
        ...state,
        selectedInventory: undefined,
        [action.payload.page]: {
          ...state[action.payload.page],
          searchTerm: action.payload.searchTerm,
        },
      }
    case "setFilters": {
      const { page, ...filters } = action.payload
      const { supplierId, ...otherFilters } = filters
      const newPageState = { ...state[page], ...otherFilters }

      if (supplierId !== undefined) {
        if (newPageState.suppliersIds?.includes(supplierId)) {
          newPageState.suppliersIds = newPageState.suppliersIds.filter(
            (_supplierId) => _supplierId !== supplierId,
          )
        } else {
          newPageState.suppliersIds = [
            ...(newPageState.suppliersIds ?? []),
            supplierId,
          ]
        }
      } else if ("supplierId" in filters) {
        newPageState.suppliersIds = undefined
      }

      return {
        ...state,
        selectedInventory: undefined,
        [page]: newPageState,
      }
    }
    case "setSortOption": {
      const sortOrder =
        action.payload.page === "recapPage"
          ? { sortOrder: action.payload.sortOrder }
          : {}
      return {
        ...state,
        selectedInventory: undefined,
        [action.payload.page]: {
          ...state[action.payload.page],
          sortOption: action.payload.sortOption,
          ...sortOrder,
        },
      }
    }
    case "setFilteredReferences": {
      const firstFilteredReferences = action.payload.filteredReferences?.[0]
      return {
        ...state,
        selectedInventory:
          firstFilteredReferences !== undefined
            ? {
                type: InventoryType.Back,
                mercurialeId: firstFilteredReferences,
              }
            : undefined,
        [action.payload.page]: {
          ...state[action.payload.page],
          supplierId: undefined,
          familyName: undefined,
          subFamilyName: undefined,
          filteredReferences: action.payload.filteredReferences,
          displayShelfFloorSize: false,
        },
      }
    }
    case "setNumericPadValueAction":
      return {
        ...state,
        numericPadValue: action.payload,
      }
    case "toggleDisplayShelfFloorSize": {
      return {
        ...state,
        selectedInventory: undefined,
        [action.payload.page]: {
          ...state[action.payload.page],
          displayShelfFloorSize:
            !state[action.payload.page].displayShelfFloorSize,
        },
      }
    }
    case "setPromotionMessagesFilter": {
      return {
        ...state,
        selectedInventory: undefined,
        [action.payload.page]: {
          ...state[action.payload.page],
          promotionMessagesInFilters: action.payload.promotionMessagesInFilters,
        },
      }
    }
    case "resetPageFilters": {
      return {
        ...state,
        selectedInventory: undefined,
        [action.payload.page]:
          defaultUserInterfaceReducerState[action.payload.page],
      }
    }
    default:
      return state
  }
}

export default userInterfaceReducer
