import React from "react"
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Legend,
  Bar,
  Line,
  LabelList,
} from "recharts"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

const storeColorMap: Record<string, string> = {}
const colorPalette = [
  "#3b82f6",
  "#ef4444",
  "#f97316",
  "#10b981",
  "#8b5cf6",
  "#ec4899",
  "#eab308",
  "#14b8a6",
]

function getStoreColor(storeName: string) {
  if (!storeColorMap[storeName]) {
    const idx = Object.keys(storeColorMap).length % colorPalette.length
    storeColorMap[storeName] = colorPalette[idx]
  }
  return storeColorMap[storeName]
}

interface InventoryByDayChartProps {
  inventoryChartData: Record<string, string | number>[]
  allStores: string[]
  avgStockPerDate: number
}

export const InventoryByDayChart: React.FC<InventoryByDayChartProps> = ({
  inventoryChartData,
  allStores,
  avgStockPerDate,
}) => {
  return (
    <Card>
      <CardHeader className="flex items-center justify-between pb-0">
        <CardTitle>Inventaires par jour / magasin</CardTitle>
        <span className="inline-block bg-blue-600 text-white px-3 py-1 rounded-full text-sm font-bold">
          Stock moyen par jour en pv:{" "}
          {avgStockPerDate.toLocaleString("fr-FR", {
            minimumFractionDigits: 1,
          })}
        </span>
      </CardHeader>

      <CardContent>
        <ResponsiveContainer width="100%" height={350}>
          <ComposedChart
            data={inventoryChartData}
            margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip formatter={(value, name) => [`${value} €`, name]} />
            <Legend />

            {allStores.map((store) => (
              <Bar
                key={store}
                dataKey={store}
                stackId="inventory"
                fill={getStoreColor(store)}
                name={store}
              >
                {allStores.length === 1 && (
                  <LabelList
                    dataKey={store}
                    position="top"
                    offset={10}
                    formatter={(val: string) => `${val} €`}
                    style={{
                      fill: "#000",
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  />
                )}
              </Bar>
            ))}
            <Line
              type="monotone"
              dataKey="sum"
              stroke="#111"
              strokeWidth={3}
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
              name="Total jour"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}
