// src/components/EditReferenceDialog.tsx

"use client"

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { toast } from "sonner"
import { useState, useEffect, FC } from "react"
import {
  useCreateRegroupingIdaMutation,
  CreateRegroupingIdaMutationVariables,
} from "@/utils/__generated__/graphql"
import { ReferencesForMatching } from "../components/types"

interface EditReferenceDialogProps {
  isOpen: boolean
  onClose: () => void
  record?: ReferencesForMatching[number]
  refetch: () => void
  companyId: string
  franchiseParam: string
  storeId: string
}

const EditReferenceDialog: FC<EditReferenceDialogProps> = ({
  isOpen,
  onClose,
  record,
  refetch,
  companyId,
  franchiseParam,
  storeId,
}) => {
  const [unit, setUnit] = useState<string>()

  const [createRegroupingIda, { loading }] = useCreateRegroupingIdaMutation()

  useEffect(() => {
    if (isOpen) {
      setUnit(record?.unit ?? "piece")
      return
    }
    setUnit(undefined)
  }, [isOpen, record])

  const handleSubmit = async () => {
    if (record === undefined || unit === undefined) return

    // Client-side validation
    if (!["kilo", "piece"].includes(unit)) {
      toast.error("Unité invalide. Veuillez sélectionner 'kilo' ou 'pièce'.")
      return
    }

    // Prepare input for the mutation
    const input: CreateRegroupingIdaMutationVariables["input"] = {
      company_id: companyId,
      franchise_name: franchiseParam,
      sale_name_ida_base: record.sale_name_ida_base,
      libelle_base: record.libelle_base,
      libelle_cible: record.libelle_cible ?? "",
      sale_name_ida_cible: record.sale_name_ida_cible ?? "",
      unit: unit,
      store_id: storeId,
    }

    try {
      const response = await createRegroupingIda({
        variables: { input },
      })

      if (
        response.data?.createRegroupingIda.error ??
        !response.data?.createRegroupingIda.records
      ) {
        const errorMessage =
          response.data?.createRegroupingIda.error?.message ??
          "Échec de la mise à jour."
        toast.error(errorMessage)
      } else {
        toast.success("Mise à jour réussie.")
        refetch() // Refresh data
        onClose() // Close the dialog
      }
    } catch (error) {
      toast.error("Une erreur est survenue lors de la mise à jour.")
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Modifier Référence</DialogTitle>
          <DialogDescription>
            Mettez à jour l&apos;unité de la référence sélectionnée.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {/* Unité Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Unité
            </label>
            <Select value={unit} onValueChange={setUnit}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionnez une unité" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="kilo">Kilo</SelectItem>
                <SelectItem value="piece">Pièce</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={loading}>
            Annuler
          </Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? "Enregistrement..." : "Enregistrer"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditReferenceDialog
