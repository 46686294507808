import {
  type MercurialeAdditionalInfo,
  type MercurialeInfo,
  MercurialeRankingResult,
  UpdateMercurialeMutation,
  ModificationLogItemInput,
} from "../utils/__generated__/graphql"

export type AllMercurialInfo = Omit<MercurialeInfo, "__typename"> &
  Omit<MercurialeAdditionalInfo, "__typename">

export interface UpdatedReference {
  mercurialeId: string
  stock_too_high_flag?: boolean
  stock_too_low_flag?: boolean
  stock_to_verify_flag?: boolean
  backInventoryQuantity?: number
  floorInventoryQuantity?: number
  time_rupture_flag_verified?: boolean
  orderInventoryQuantity?: number
  shelfFloorSize?: number
  isOrderInventoryQuantityUpdated?: boolean
  shelfFloorSizeUpdatedAt?: string
  isOrderConfirmed?: boolean
  local_flag?: boolean
  local_delivery_days?: number
  local_delivery_on_monday?: boolean
  local_delivery_on_tuesday?: boolean
  local_delivery_on_wednesday?: boolean
  local_delivery_on_thursday?: boolean
  local_delivery_on_friday?: boolean
  local_delivery_on_saturday?: boolean
  local_delivery_on_sunday?: boolean
  prediction_uncertain?: boolean
}

export interface MercurialReducerState {
  latestOrderInventoryUpdate: string | undefined
  latestSaleHistoricalUpdate: string | undefined
  latestMercurialeUpdate: string | undefined
  latestOrderRequestUpdate: string | undefined
  mercurialAndStoreInventories: AllMercurialInfo[]
  updatedReferences: Record<string, UpdatedReference>
  lastWeekOrderQty: number | undefined
  selectedDimMercurialeId: string | undefined
  dimMercuriales:
    | {
        dimMercurialeId: string
        dimOrderRequestId: string | undefined
      }[]
    | undefined
  orderDate: string | null | undefined
  rankings: Record<
    string,
    Exclude<MercurialeRankingResult["rankings"], undefined | null>
  >
  modifications: ModificationLogItemInput[]
  checkInModalCloseDates: Record<string, string>
  inactiveReferences: string[]
}

export const defaultMercurialReducerState: MercurialReducerState = {
  latestOrderInventoryUpdate: undefined,
  latestSaleHistoricalUpdate: undefined,
  latestMercurialeUpdate: undefined,
  latestOrderRequestUpdate: undefined,
  inactiveReferences: [],
  mercurialAndStoreInventories: [],
  updatedReferences: {},
  lastWeekOrderQty: undefined,
  selectedDimMercurialeId: undefined,
  dimMercuriales: undefined,
  orderDate: undefined,
  rankings: {},
  modifications: [],
  checkInModalCloseDates: {},
}

interface SetMercurialAction {
  type: "setMercurial"
  payload: MercurialReducerState
}

export interface UpdateReferenceAction {
  type: "updateReference"
  payload: {
    mercurialeId: UpdatedReference["mercurialeId"]
  } & Partial<Omit<UpdatedReference, "mercurialeId">>
}

export interface UpdateReferencesAction {
  type: "updateReferences"
  payload: UpdatedReference[]
}

export interface SetUpdatedReferencesAction {
  type: "setUpdatedReferences"
  payload: MercurialReducerState["updatedReferences"]
}

export interface SetDimOrderRequestIdAction {
  type: "setDimOrderRequestId"
  payload: {
    dimMercurialeId: Exclude<
      MercurialReducerState["dimMercuriales"],
      undefined
    >[number]["dimMercurialeId"]
    dimOrderRequestId: Exclude<
      MercurialReducerState["dimMercuriales"],
      undefined
    >[number]["dimOrderRequestId"]
  }
}

export interface UpdateMercurialeAction {
  type: "updateMercuriale"
  payload: Exclude<
    UpdateMercurialeMutation["updateMercuriale"]["mercuriale"],
    undefined | null
  >
}

export interface UpdatePvMercurialeAction {
  type: "updatePvMercuriale"
  payload: {
    saleId: string
    pv: number
  }
}

export interface SetSelectedDimMercurialeIdAction {
  type: "setSelectedDimMercurialeId"
  payload: MercurialReducerState["selectedDimMercurialeId"]
}

export interface SetRankingsAction {
  type: "setRankings"
  payload: MercurialReducerState["rankings"]
}

export interface SetModificationsAction {
  type: "setModifications"
  payload: MercurialReducerState["modifications"]
}

export interface AddModificationAction {
  type: "addModification"
  payload: MercurialReducerState["modifications"][number]
}

export interface DeleteModificationAction {
  type: "deleteModification"
  payload: number
}

export interface CloseCheckInModal {
  type: "closeCheckInModal"
  payload: {
    dimMercurialeId: string
    date: Date
  }
}

export interface SetInactiveReferencesAction {
  type: "setInactiveReferences"
  payload: string[]
}

export interface CloseLastPerformancesModal {
  type: "closeLastPerformancesModal"
  payload: Date
}

export type MercurialReducerAction =
  | SetMercurialAction
  | UpdateReferenceAction
  | SetUpdatedReferencesAction
  | SetDimOrderRequestIdAction
  | UpdateMercurialeAction
  | UpdatePvMercurialeAction
  | SetSelectedDimMercurialeIdAction
  | UpdateReferencesAction
  | SetRankingsAction
  | SetModificationsAction
  | AddModificationAction
  | DeleteModificationAction
  | CloseCheckInModal
  | SetInactiveReferencesAction
  | CloseLastPerformancesModal

const mercurialReducer = (
  state: MercurialReducerState = defaultMercurialReducerState,
  action: MercurialReducerAction,
): MercurialReducerState => {
  switch (action.type) {
    case "setMercurial":
      return {
        ...state,
        ...action.payload,
      }

    case "updateReference": {
      const newUpdatedReference = {
        ...(state.updatedReferences[action.payload.mercurialeId] ?? {}),
        ...action.payload,
      }
      if (action.payload.local_flag === false) {
        newUpdatedReference.local_delivery_days = undefined
      }
      const newUpdatedReferences = {
        ...state.updatedReferences,
        [action.payload.mercurialeId]: newUpdatedReference,
      }
      return {
        ...state,
        updatedReferences: newUpdatedReferences,
      }
    }

    case "updateReferences": {
      const newUpdatedReferences = action.payload.reduce<
        MercurialReducerState["updatedReferences"]
      >((newUpdatedReferences, newUpdatedReference) => {
        return {
          ...newUpdatedReferences,
          [newUpdatedReference.mercurialeId]: {
            ...(state.updatedReferences[newUpdatedReference.mercurialeId] ??
              {}),
            ...newUpdatedReference,
          },
        }
      }, {})

      return {
        ...state,
        updatedReferences: {
          ...state.updatedReferences,
          ...newUpdatedReferences,
        },
      }
    }

    case "setUpdatedReferences": {
      return {
        ...state,
        updatedReferences: action.payload,
      }
    }

    case "setInactiveReferences": {
      return {
        ...state,
        inactiveReferences: action.payload,
      }
    }

    case "setDimOrderRequestId": {
      const dimMercurialeIndex =
        state.dimMercuriales?.findIndex(
          (dimMercuriale) =>
            dimMercuriale.dimMercurialeId === action.payload.dimMercurialeId,
        ) ?? -1
      if (dimMercurialeIndex === -1) {
        return {
          ...state,
        }
      }
      const newState = { ...state }
      newState.dimMercuriales![dimMercurialeIndex].dimOrderRequestId =
        action.payload.dimOrderRequestId
      return newState
    }
    case "updateMercuriale": {
      const mercurialAndStoreInventories =
        state.mercurialAndStoreInventories.reduce<
          typeof state.mercurialAndStoreInventories
        >((mercurialeInfos, mercurialAndStoreInventory) => {
          const index = action.payload.findIndex(
            (_mercurialeInfo) =>
              _mercurialeInfo.id === mercurialAndStoreInventory.mercuriale_id,
          )
          if (index === -1) return mercurialeInfos
          // Remove id and __typename from mercurialeInfo object
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, __typename, ...newObject } = action.payload[index]
          return [
            ...mercurialeInfos,
            {
              ...mercurialAndStoreInventory,
              ...newObject,
            },
          ]
        }, [])
      return {
        ...state,
        mercurialAndStoreInventories,
      }
    }
    case "setSelectedDimMercurialeId":
      return {
        ...state,
        selectedDimMercurialeId: action.payload,
      }
    case "setRankings":
      return {
        ...state,
        rankings: action.payload,
      }
    case "setModifications":
      return {
        ...state,
        modifications: action.payload,
      }
    case "addModification":
      return {
        ...state,
        modifications: [...state.modifications, action.payload],
      }
    case "deleteModification":
      return {
        ...state,
        modifications: state.modifications.filter(
          (_, index) => index !== action.payload,
        ),
      }
    case "closeCheckInModal":
      return {
        ...state,
        checkInModalCloseDates: {
          ...state.checkInModalCloseDates,
          [action.payload.dimMercurialeId]: action.payload.date.toString(),
        },
      }

    case "updatePvMercuriale":
      return {
        ...state,
        mercurialAndStoreInventories: state.mercurialAndStoreInventories.map(
          (mercurialAndStoreInventory) =>
            mercurialAndStoreInventory.sale_id === action.payload.saleId
              ? { ...mercurialAndStoreInventory, pv: action.payload.pv }
              : mercurialAndStoreInventory,
        ),
      }
    default:
      return state
  }
}

export default mercurialReducer
