export interface UserReducerState {
  email?: string
  storeId?: string
  storeName?: string
  companyName?: string
  franchiseName?: string
  userHash?: string
}

export const defaultUserReducerState: UserReducerState = {}

interface SetUserAction {
  type: "setUser"
  payload: UserReducerState
}

export type UserReducerAction = SetUserAction

export const userReducer = (
  state: UserReducerState = defaultUserReducerState,
  action: UserReducerAction,
): UserReducerState => {
  switch (action.type) {
    case "setUser":
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
