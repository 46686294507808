/**
 * Format currency value with Euro symbol
 */
export const formatEuro = (value: number): string => {
  return `${value.toLocaleString("fr-FR", {
    maximumFractionDigits: 0,
  })} €`
}

/**
 * Format date to French locale format (DD/MM/YYYY)
 */
export const formatDate = (date: Date): string => {
  return date.toLocaleDateString("fr-FR")
}

/**
 * Format percentage value with 1 decimal place
 */
export const formatPercentage = (value: number): string => {
  return `${value.toLocaleString("fr-FR", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })}%`
}
