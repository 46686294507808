import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { DispatchActionType, StateType } from "@/types"
import { CalendarAlertIcon, RefreshAlertIcon } from "@/ui/AlertIcons"
import { getDiffBetweenDates } from "@/utils/getDiffBetweenDates"
import { useDispatch, useSelector } from "react-redux"

interface OutdatedDataModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  refreshApplication: () => void
  setIsOfflineRefreshModalOpen: (value: boolean) => void
}

export function OutdatedDataModal({
  isOpen,
  setIsOpen,
  refreshApplication,
  setIsOfflineRefreshModalOpen,
}: OutdatedDataModalProps) {
  const dispatch = useDispatch<DispatchActionType>()
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const refreshModalUpdateDate = useSelector(
    (state: StateType) => state.updateReducer.refreshModalUpdateDate,
  )

  function closeModal() {
    setIsOpen(false)
    dispatch({
      type: "setRefreshModalCloseDate",
      payload: new Date().toString(),
    })
  }

  const currentDate = new Date()
  const isAlreadyUpdated =
    refreshModalUpdateDate !== undefined &&
    getDiffBetweenDates(currentDate, new Date(refreshModalUpdateDate)) >= 0

  return (
    <Dialog open={isOpen} onOpenChange={(value) => !value && closeModal()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader className="flex flex-col items-center">
          {isAlreadyUpdated ? <CalendarAlertIcon /> : <RefreshAlertIcon />}
          <DialogTitle>
            {isAlreadyUpdated ? "Pas de commande" : "Mettre à jour"}
          </DialogTitle>
        </DialogHeader>
        {isAlreadyUpdated ? (
          <p>
            Vous n&apos;avez pas de commande prevue aujourd&apos;hui.
            <br />
            <br />
            <span className="italic text-gray-500">
              Si c&apos;est une erreur, merci de contacter le support Ida au{" "}
              <span className="not-italic">+33 7 43 39 09 59</span>.
            </span>
          </p>
        ) : (
          <p>
            Les données de l&apos;application ne sont pas à jour. Veuillez
            cliquer sur le bouton pour les mettre à jour.
          </p>
        )}

        <DialogFooter>
          {isAlreadyUpdated ? (
            <Button onClick={closeModal}>Fermer</Button>
          ) : (
            <>
              <Button variant="secondary" onClick={closeModal}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  if (!online) {
                    setIsOpen(false)
                    setIsOfflineRefreshModalOpen(true)
                    return
                  }
                  setIsOpen(false)
                  dispatch({
                    type: "setRefreshModalUpdateDate",
                    payload: new Date().toString(),
                  })
                  refreshApplication()
                }}
              >
                Mettre à jour
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
