import { ModificationWeeklyStats } from "@/utils/__generated__/graphql"
import { formatEuro } from "./formatter"
import { StoreComparisonData } from "./useModificationSummary"
import { Link, useSearchParams } from "react-router-dom"

interface WeeklyBreakdownProps {
  selectedStore: string
  storeComparisonData: StoreComparisonData[]
}

export default function WeeklyBreakdown({
  selectedStore,
  storeComparisonData,
}: WeeklyBreakdownProps) {
  const [searchParams] = useSearchParams()
  const franchiseParam = searchParams.get("franchise") ?? undefined
  if (storeComparisonData.length === 0) return null

  if (selectedStore !== "all") {
    const storeDataForBreakdown = storeComparisonData.find(
      (s) => s.store === selectedStore,
    )

    if (
      !storeDataForBreakdown?.weekly_stats ||
      storeDataForBreakdown.weekly_stats.length === 0
    )
      return null

    return renderWeeklyTable(storeDataForBreakdown.weekly_stats)
  }

  const allWeeks = new Map<number, ModificationWeeklyStats>()

  storeComparisonData.forEach((store) => {
    if (!store.weekly_stats) return

    store.weekly_stats.forEach((weekData) => {
      const weekKey = weekData.week

      if (!allWeeks.has(weekKey)) {
        allWeeks.set(weekKey, {
          week: weekKey,
          n_followed: 0,
          n_modified: 0,
          user_breakage: 0,
          algo_breakage: 0,
          user_stockout: 0,
          algo_stockout: 0,
          n_user_breakage: 0,
          n_algo_breakage: 0,
          n_user_stockout: 0,
          n_algo_stockout: 0,
          algo_oversupply: 0,
          n_algo_oversupply: 0,
          user_oversupply: 0,
          n_user_oversupply: 0,
          user_replenishment: 0,
          n_user_replenishment: 0,
        })
      }

      const currentWeekData = allWeeks.get(weekKey)

      if (currentWeekData) {
        currentWeekData.user_breakage += weekData.user_breakage ?? 0
        currentWeekData.algo_breakage += weekData.algo_breakage ?? 0
        currentWeekData.user_stockout += weekData.user_stockout ?? 0
        currentWeekData.algo_stockout += weekData.algo_stockout ?? 0
        currentWeekData.user_replenishment += weekData.user_replenishment ?? 0

        if (currentWeekData.n_user_breakage !== undefined) {
          currentWeekData.n_user_breakage += weekData.n_user_breakage ?? 0
        }
        if (currentWeekData.n_algo_breakage !== undefined) {
          currentWeekData.n_algo_breakage += weekData.n_algo_breakage ?? 0
        }
        if (currentWeekData.n_user_stockout !== undefined) {
          currentWeekData.n_user_stockout += weekData.n_user_stockout ?? 0
        }
        if (currentWeekData.n_algo_stockout !== undefined) {
          currentWeekData.n_algo_stockout += weekData.n_algo_stockout ?? 0
        }
        if (currentWeekData.n_modified !== undefined) {
          currentWeekData.n_modified += weekData.n_modified ?? 0
        }
        if (currentWeekData.n_followed !== undefined) {
          currentWeekData.n_followed += weekData.n_followed ?? 0
        }
      }
    })
  })

  // Convert the map to an array
  const combinedWeeklyStats = Array.from(allWeeks.values())

  if (combinedWeeklyStats.length === 0) return null

  return renderWeeklyTable(combinedWeeklyStats, franchiseParam)
}

// Extracted table rendering to avoid code duplication
function renderWeeklyTable(
  weeklyStats: ModificationWeeklyStats[],
  franchiseParam?: string,
) {
  return (
    <div className="mt-6">
      <h3 className="text-lg font-medium text-gray-800 mb-4">
        Détail par semaine
      </h3>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50">
              <th className="p-3 border text-left text-sm text-gray-700">
                Semaine
              </th>
              <th className="p-3 border text-right text-sm text-gray-700">
                Démarque € (causée par les modifications utilisateurs)
              </th>
              <th className="p-3 border text-right text-sm text-gray-700">
                Démarque € (causée par l&apos;algorithme)
              </th>
              <th className="p-3 border text-right text-sm text-gray-700">
                Rupture € (causée par les modifications utilisateurs)
              </th>
              <th className="p-3 border text-right text-sm text-gray-700">
                Rupture € (causée par l&apos;algorithme)
              </th>
              <th className="p-3 border text-right text-sm text-gray-700">
                Rupture € (problème de réassort)
              </th>
            </tr>
          </thead>
          <tbody>
            {[...weeklyStats]
              .sort(
                (a, b) =>
                  new Date(a.week).getTime() - new Date(b.week).getTime(),
              )
              .map((week, index) => {
                const weekLabel = week.week
                return (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <td className="p-3 border text-sm font-medium text-gray-700">
                      {weekLabel}
                    </td>
                    <td className="p-3 border text-right text-sm text-gray-900">
                      <Link
                        to={`details/${weekLabel}/USER_BREAKAGE${typeof franchiseParam === "string" ? `?franchise=${franchiseParam}` : ""}`}
                        className="underline"
                      >
                        {formatEuro(week.user_breakage || 0)}
                      </Link>
                    </td>
                    <td className="p-3 border text-right text-sm text-gray-900">
                      <Link
                        to={`details/${weekLabel}/ALGO_BREAKAGE${typeof franchiseParam === "string" ? `?franchise=${franchiseParam}` : ""}`}
                        className="underline"
                      >
                        {formatEuro(week.algo_breakage || 0)}
                      </Link>
                    </td>
                    <td className="p-3 border text-right text-sm text-gray-900">
                      <Link
                        to={`details/${weekLabel}/USER_STOCKOUT${typeof franchiseParam === "string" ? `?franchise=${franchiseParam}` : ""}`}
                        className="underline"
                      >
                        {formatEuro(week.user_stockout || 0)}
                      </Link>
                    </td>
                    <td className="p-3 border text-right text-sm text-gray-900">
                      <Link
                        to={`details/${weekLabel}/ALGO_STOCKOUT${typeof franchiseParam === "string" ? `?franchise=${franchiseParam}` : ""}`}
                        className="underline"
                      >
                        {formatEuro(week.algo_stockout || 0)}
                      </Link>
                    </td>
                    <td className="p-3 border text-right text-sm text-gray-900">
                      <Link
                        to={`details/${weekLabel}/USER_REPLENISHMENT${typeof franchiseParam === "string" ? `?franchise=${franchiseParam}` : ""}`}
                        className="underline"
                      >
                        {formatEuro(week.user_replenishment || 0)}
                      </Link>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
