import { Checkbox } from "@/components/ui/checkbox"
import { cn } from "@/lib/utils"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { UserInterfaceReducerState } from "@/reducers/userInterfaceReducer"
import type { StateType } from "@/types"
import { InventoryType } from "@/utils/__generated__/graphql"
import { Clock, TrendingDownIcon, TriangleAlert } from "lucide-react"
import { useSelector } from "react-redux"

export const RuptureLayout = ({
  references,
  updateInventory,
  isWarning,
  isDanger,
}: {
  references: AllMercurialInfo[]
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  isWarning: boolean
  isDanger: boolean
}) => {
  const { storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const referenceLoss = references[0].rupture_loss_amount ?? 0
  const formattedReferenceLoss = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: storeCurrency ?? "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(referenceLoss)

  const formattedLastSaleTime = (reference: AllMercurialInfo) => {
    if (reference.last_sale_time) {
      try {
        return new Intl.DateTimeFormat("fr-FR", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(reference.last_sale_time))
      } catch (err) {
        return "—"
      }
    }
    return "—"
  }
  return (
    <>
      <div className="bg-red-600 p-1 px-2 rounded-t-xl flex items-center justify-between text-white">
        <div className="flex items-center gap-2">
          <TriangleAlert className="size-5" />
          <p className="font-bold text-sm">RUPTURE</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <span className="flex items-center text-xl font-bold text-red-600">
              <Clock className="w-4 h-4 mr-1 text-white" />
              <p className="text-white underline">
                {formattedLastSaleTime(references[0])}
              </p>
            </span>
          </div>
          <div className="flex items-center gap-2 bg-red-600 text-white font-bold px-3 py-1 rounded-full">
            <TrendingDownIcon className="w-4 h-4" />
            <span>Perte: {formattedReferenceLoss}</span>
          </div>
        </div>
      </div>
      <div
        className={cn(
          "md:hidden bg-white border-x border-slate-200/90 px-6 py-2 flex justify-end items-center gap-2",
          isWarning && " border-x-2 border-yellow-600",
          isDanger && "  border-x-2 border-red-600",
        )}
      >
        <p className="text-sm font-medium ">
          {references[0].time_rupture_flag_verified
            ? "COMMANDE VÉRIFIÉE"
            : "COMMANDE À VÉRIFIER"}
        </p>
        <Checkbox
          className="h-5 w-5 border-2"
          checked={references[0].time_rupture_flag_verified ?? false}
          onCheckedChange={() => {
            updateInventory(references[0].quantity_actual?.toString() ?? "0", {
              type: InventoryType.Order,
              mercurialeId: references[0].mercuriale_id ?? "",
            })
          }}
        />
      </div>
    </>
  )
}
