import Header from "@/components/header"
import { Outlet } from "react-router-dom"

export function Admin(): JSX.Element {
  return (
    <div className="flex flex-col">
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div className="flex-1 w-full  pt-2 pb-8">
        <Outlet />
      </div>
    </div>
  )
}
