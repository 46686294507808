import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"
import { Check, ListFilterIcon } from "lucide-react"
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Label } from "@/components/ui/label"
import { cn } from "@/lib/utils"
import { upperFirst } from "lodash"
import posthog from "posthog-js"
import { useSelector } from "react-redux"
import { StateType } from "@/types"

export const MultiColumnFilter = ({
  filters,
}: {
  filters: {
    label: string
    selected: string[]
    filterOptions: {
      value: string
      label: string
      onSelect: (value: string) => void
    }[]
  }[]
}) => {
  const { storeName } = useSelector((state: StateType) => state.storeReducer)
  const handlePosthogEvent = (label: string, value: string) => {
    posthog.capture("selected_filter", {
      store_name: storeName,
      filter_label: label,
      filter_value: value,
    })
  }
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <ListFilterIcon className="w-4 h-4" />
          <span className="hidden lg:block">Filtrer</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Label className="px-3 py-3 block font-bold">Filtrer par</Label>
        <div className="flex border-t">
          {filters.map((filter) => (
            <div
              key={filter.label}
              className={cn("border-r last:border-r-0", "min-w-[200px]")}
            >
              <div className="px-3 py-2 font-medium border-b bg-gray-50">
                {filter.label}
              </div>
              <Command className="h-72">
                <CommandList>
                  <CommandGroup>
                    {filter.filterOptions.map(({ value, label, onSelect }) => {
                      const isSelected = filter.selected.includes(value)
                      return (
                        <CommandItem
                          key={value}
                          onSelect={() => {
                            handlePosthogEvent(filter.label, value)
                            onSelect(value)
                          }}
                          className="flex items-center gap-2"
                        >
                          <div
                            className={cn(
                              "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                              isSelected
                                ? "bg-green-500 border-none text-primary-foreground"
                                : "opacity-50 [&_svg]:invisible",
                            )}
                          >
                            <Check className="w-4 h-4" />
                          </div>
                          <span className="font-medium">
                            {upperFirst(label.toLowerCase())}
                          </span>
                        </CommandItem>
                      )
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
