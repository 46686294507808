import { Card } from "@/components/ui/card"
import { PackageCheck, ShoppingCart, Repeat } from "lucide-react"

interface InventoryStatsBarProps {
  increaseCount: number
  decreaseCount: number
  totalModifications: number
  modificationPercentage: number
  stockModificationPercentage: number
  trend: number
  avgModificationSize: number
  totalOrderModifications: number
  orderModificationPercentage: number
  orderTrend: number
}

interface StatCardProps {
  icon: React.ReactNode
  label: string
  mobileLabel?: string
  value: React.ReactNode
  bgColor?: string
  subtext?: React.ReactNode
}

export default function InventoryStatsBar({
  totalModifications,
  totalOrderModifications,
  orderModificationPercentage,
}: InventoryStatsBarProps) {
  const ratio =
    totalModifications > 0
      ? (totalOrderModifications / totalModifications).toFixed(2)
      : "0.00"

  const StatCard = ({
    icon,
    label,
    mobileLabel,
    value,
    bgColor,
    subtext,
  }: StatCardProps) => (
    <Card className="flex h-full flex-col xl:flex-row w-full xl:items-center xl:justify-between gap-1 px-3 py-1 md:py-4">
      <div className="flex min-w-0 items-center gap-1">
        <div className={`flex shrink-0 rounded-sm p-1 ${bgColor}`}>{icon}</div>
        <p className="text-xs md:text-xs lg:text-sm truncate">
          {mobileLabel ?? label}
        </p>
      </div>
      <div className="flex flex-col items-end">
        <p className="font-bold text-xs md:text-sm lg:text-lg shrink-0">
          {value}
        </p>
        {subtext && <p className="text-xs text-gray-500 mt-0.5">{subtext}</p>}
      </div>
    </Card>
  )

  return (
    <div className="bg-white py-2 mb-2 border-b">
      <div className="overflow-x-auto lg:overflow-visible pb-2 md:pb-0">
        <div className="flex flex-row md:flex md:flex-row lg:grid lg:grid-cols-3 gap-2 md:gap-3 min-w-max md:min-w-0">
          <StatCard
            icon={
              <PackageCheck className="w-3 h-3 md:w-4 md:h-4 text-blue-700" />
            }
            label="Total modif. stock"
            mobileLabel="Total modif. stock"
            value={totalModifications}
            bgColor="md:bg-blue-50"
          />

          <StatCard
            icon={
              <ShoppingCart className="w-3 h-3 md:w-4 md:h-4 text-purple-700" />
            }
            label="Total modif. commandes"
            mobileLabel="Total modif. cmd"
            value={totalOrderModifications}
            subtext={`${orderModificationPercentage.toFixed(1)}% des recos modifiées`}
            bgColor="md:bg-purple-50"
          />

          <StatCard
            icon={<Repeat className="w-3 h-3 md:w-4 md:h-4 text-indigo-700" />}
            label="Ratio nombre de modifs cmd vs stock"
            mobileLabel="Ratio modif. cmd vs stock"
            value={ratio}
            subtext="commandes:stock"
            bgColor="md:bg-indigo-50"
          />
        </div>
      </div>
    </div>
  )
}
