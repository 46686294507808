import { Checkbox } from "@/components/ui/checkbox"
import { AllMercurialInfo } from "@/reducers/mercurialReducer"
import { StateType } from "@/types"
import { InventoryType } from "@/utils/__generated__/graphql"

export const ExtraOrderInputSection = ({
  reference,
  updateInventory,
  isPotentialRupture,
  displayExtraOrderInputSection,
}: {
  reference: AllMercurialInfo
  updateInventory: (
    value: string,
    selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
  ) => Promise<void>
  isPotentialRupture: boolean
  displayExtraOrderInputSection: boolean
}) => {
  return reference?.time_rupture_flag && displayExtraOrderInputSection ? (
    <Checkbox
      className="md:block"
      checked={!isPotentialRupture}
      onCheckedChange={() => {
        updateInventory(reference.quantity_actual?.toString() ?? "0", {
          type: InventoryType.Order,
          mercurialeId: reference.mercuriale_id ?? "",
        })
      }}
    />
  ) : null
}
